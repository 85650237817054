
import ReactGA4 from 'react-ga4';

const InitializeGoogleAnalytics = () => {
    ReactGA4.initialize("G-3MPCQ3JGQC");

   
};

const TrackGoogleAnalitycsEvent = (category, action , label  ) => {
    ReactGA4.event({
        category: category,
        action: action,
        label: label,
    })
}

const TrackGoogleAnalitycsEventWhitValue = (category, action , label, value,  ) => {
    const valueNum = parseFloat(value);
    const valueInt = !isNaN(valueNum) ? Math.round(valueNum) : 0;
    
    ReactGA4.event({
        category: category,
        action: action,
        label: label,
        value: valueInt
    })
   
}

export default InitializeGoogleAnalytics;
export {
    InitializeGoogleAnalytics,
    TrackGoogleAnalitycsEvent,
    TrackGoogleAnalitycsEventWhitValue
}

