import React from 'react';
import { CategoryDetailHeader } from './categoryDetailHeader';
import { CategoryDetailPosts } from './categoryDetailPosts';

export const CategoryDetail = () => {

  return (
    <>
      <CategoryDetailHeader/>
      <CategoryDetailPosts/>
    </>
  );
};
