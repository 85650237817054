import React from "react";
import Vimeo from "@u-wave/react-vimeo";
import { UilDownloadAlt } from "@iconscout/react-unicons";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
const LOGOCURSO = "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2Fcurso-alta-especialidad%2Flogo-curso-alta-especialidad.png?alt=media&token=a33c5413-8f46-48e6-bd13-4a4f2f645082";

const BackBanner = styled.div`
  background: url("https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fcurso-alta-especialidad%2FbannerDesk.png?alt=media&token=325c28d4-d367-4658-baca-24bf2c221e23")
    right center/contain no-repeat;
  @media only screen and (max-width: 992px) {
    background: none;
  }
  @media only screen and (min-width: 992px) and (max-width: 1400px) {
    background: url("https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fcurso-alta-especialidad%2FbannerDesk.png?alt=media&token=325c28d4-d367-4658-baca-24bf2c221e23")
      right -270px center/contain no-repeat;
  }
  @media only screen and (min-width: 1400px) and (max-width: 1480px) {
    background: url("https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fcurso-alta-especialidad%2FbannerDesk.png?alt=media&token=325c28d4-d367-4658-baca-24bf2c221e23")
      right -100px center/contain no-repeat;
  }
  .container {
    padding-bottom: 82px;
    @media only screen and (max-width: 992px) {
      padding-bottom: 30px;
    }
  }
`;

const VideoDiv = styled.div`
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
`;

const ColTitle = styled(Col)`
  display: flex;
  align-items: left;
  justify-content: center;
  flex-direction: column;
  overflow: show;
  @media only screen and (max-width: 768px) {
    padding: 0px;
  }
  h1 {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    color: #474a4b;
    margin-top: 28px;
    margin-bottom: 28px;
    span {
      font-weight: 700;
    }
    @media only screen and (min-width: 768px) {
      font-size: 34px;
      line-height: 40px;
      margin-top: 43px;
      margin-bottom: 38px;
    }
    @media only screen and (min-width: 992px) {
      text-align: left;
      font-size: 50px;
      line-height: 56px;
      margin-top: 143px;
      margin-bottom: 61px;
    }
  }
  #udlap {
    width: 126px;
    @media only screen and (max-width: 992px) {
      margin-left: 0;
    }
    @media only screen and (max-width: 768px) {
      width: 90px;
    }
  }
  #labopat {
    width: 146px;
    margin-left: 50px;
    @media only screen and (max-width: 768px) {
      width: 100px;
    }
  }
  #HA {
    width: 160px;
    margin-left: 50px;
    @media only screen and (max-width: 768px) {
      width: 100px;
    }
  }
  div div {
    width: auto;
    margin-top: 21px;
    @media only screen and (max-width: 992px) {
      width: 353px;
      margin-top: 31px;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    @media only screen and (max-width: 992px) {
      width: 100%;
    }
  }
  #a-img {
    @media only screen and (max-width: 992px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  a {
    margin: 0 auto;
  }
`;

const ColImage = styled(Col)`
  img {
    width: 100%;
  }
`;

const Link = styled.a`
  background: #00ad90;
  padding: 12px 20px;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  color: #ffffff;
  :hover {
    color: #ffffff;
  }
`;

const Background = styled.div`
  background: #f9ad62;
  border: 2px solid #f9ad62;
  padding: 34px 0;
  font-family: "Faustina";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  color: #474a4b;
  h2 {
    font-weight: 800;
    font-size: 22px;
    margin-bottom: 4px;
    max-width: 260px;
    color: #474a4b;
  }
  p {
    margin-bottom: 5px;
  }
  #direct {
    h2 {
      margin-bottom: 20px;
    }
    padding-bottom: 26px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 18px;
    line-height: 20px;
    padding: 26px 26px 13px 12px;
    h2 {
      font-size: 18px;
      line-height: 23px;
    }
    .col-12 {
      padding-bottom: 16px;
    }
  }
`;

const SectionsBar = styled.div`
  background: #f2f7f9;
  padding: 18px 5px;
  p {
    font-family: "Faustina";
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    color: #474a4b;
    margin-bottom: 0;
    cursor: pointer;
    :hover {
      color: #00ac90;
      text-decoration: underline;
    }
  }
  .container {
    display: flex;
    justify-content: space-between;
  }
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const StyledImg = styled.img`
  @media only screen and (min-width: 992px) {
    display: none;
  }
  width: 100%;
`;

const Download = styled.a`
  font-family: "IBM Plex Sans";
  color: #fff !important;
  text-decoration: none !important;
  padding: 12px 20px 12px 12px;
  font-size: 15px !important;
  background: #f9ad62;
  transition: all ease 0.5s;
  @media only screen and (min-width: 992px) {
    margin-left: 151px !important;
  }
  @media only screen and (max-width: 992px) {
    margin-top: 21px !important;
    margin-bottom: 30px;
  }
  :hover {
    color: #fff;
    background: #e3964b;
  }
`;

const GreenDiv = styled.div`
  background: #00ad90;
  font-weight: 800;
  font-size: 19px;
  line-height: 27px;
  text-align: center;
  color: #ffffff;
  padding: 10px 15px;
  @media only screen and (max-width: 768px) {
    padding: 20px 30px;
    text-align: left;
  }
  p {
    margin-bottom: 0;
    max-width: 944px;
    margin: 0 auto;
  }
`;

const Logo = styled.img`
  width: 673px;
  margin-top: 70px;
  margin-bottom: 50px;
  @media screen and (width <= 768px) {
    width: 90%;
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const Banner = (props) => {
  const scrollTo = (id) => {
    var elem = document.getElementById(id);
    window.scroll({
      top: getOffset(elem) - 80,
      left: 0,
      behavior: "smooth",
    });
  };

  const getOffset = (el) => {
    var _y = 0;
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
      _y += el.offsetTop - el.scrollTop;
      el = el.offsetParent;
    }
    return _y;
  };

  return (
    <>
      <BackBanner>
        <StyledImg src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fcurso-alta-especialidad%2FbannerMobile.png?alt=media&token=7affb6fd-72e6-45fc-8ab6-d239431d40bf" />
        <Container>
          <Row>
            <ColTitle
              xs={{ span: 12, order: 2 }}
              lg={{ span: 9, order: 1 }}
              xl={8}
            >
              <Logo alt="Curso alta especialidad 2024" src={LOGOCURSO} />
              <div id="a-img">
                <Link onClick={() => scrollTo("howToApply")}>
                  ¿CÓMO APLICAR?
                </Link>
                <Download
                  target="_blank"
                  rel="noreferrer"
                  href="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fcurso-alta-especialidad%2FCurso%20patologia.pdf?alt=media&token=ecddd25e-5998-4638-9926-3c85ede533bf"
                >
                  <UilDownloadAlt /> DESCARGA EL PROGRAMA
                </Download>
                <div>
                  <img
                    id="udlap"
                    alt="logo UDLAP"
                    src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fcurso-alta-especialidad%2Flogo-UDLAP.png?alt=media&token=289fe2c8-4131-475c-ab2b-c7341c7f05b4"
                  />
                  <img
                    id="labopat"
                    alt="logo labopat"
                    src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Flogo%20labopat.png?alt=media&token=5835ffef-5e66-4087-8423-a4f20f03288a"
                  />
                  <img
                    id="HA"
                    alt="logo HA"
                    src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fcurso-alta-especialidad%2FlogoHA.png?alt=media&token=1ff9654c-b5a9-490a-8e99-5fab7575ca83"
                  />
                </div>
              </div>
            </ColTitle>
            <ColImage xs={{ span: 12, order: 1 }} md={{ span: 6, order: 2 }}>
              {/* <img id='fondo' alt='fondo' src={width > 768 ? "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fcurso-alta-especialidad%2FbannerDesk.png?alt=media&token=325c28d4-d367-4658-baca-24bf2c221e23" : "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fcurso-alta-especialidad%2FbannerMobile.png?alt=media&token=7affb6fd-72e6-45fc-8ab6-d239431d40bf"}/> */}
            </ColImage>
          </Row>
        </Container>
      </BackBanner>
      <GreenDiv>
        <p>
          Los estudiantes seleccionados serán acreedores a una beca mensual de
          $17,000.00 (873 DLLS), Seguro de Gastos Médicos Mayores y comidas
          otorgadas por el hospital.
        </p>
      </GreenDiv>
      <Background>
        <Container>
          <Row>
            <Col xs={12} md={6} lg={3}>
              <h2>Aval académico:</h2>
              <p>
                Universidad de las Américas Puebla
                <br /> / Consultores UDLAP.
              </p>
            </Col>
            <Col xs={12} md={6} lg={3} id="direct">
              <h2>Dirigido a médicos residentes de patología</h2>
              <Link onClick={() => props.setShowRequirements(true)}>
                VER REQUISITOS
              </Link>
            </Col>
            <Col xs={12} md={6} lg={3}>
              <h2>Total de horas:</h2>
              <p>1800 horas.</p>
              <h2>Periodo comprendido:</h2>
              <p>marzo 2024 - febrero 2025</p>
            </Col>
            <Col xs={12} md={6} lg={3}>
              <h2 style={{ marginBottom: "20px" }}>
                Consulta las fechas importantes
              </h2>
              <Link onClick={() => props.setShowDates(true)}>VER FECHAS</Link>
            </Col>
          </Row>
        </Container>
      </Background>
      <VideoDiv>
        <Vimeo
          color="ffbc45"
          video="https://vimeo.com/887455730"
          responsive={true}
          autoplay
          loop
          muted
        />
      </VideoDiv>
      <SectionsBar>
        <Container>
          <p onClick={() => scrollTo("planDeEstudios")}>Plan de estudios</p>
          <p onClick={() => scrollTo("activities")}>Actividades</p>
          <p onClick={() => scrollTo("academy")}>Academia</p>
          <p onClick={() => scrollTo("howToApply")}>¿Cómo aplicar?</p>
          {/* <p onClick={()=>scrollTo('activities')}>Testimonios</p> */}
        </Container>
      </SectionsBar>
    </>
  );
};
