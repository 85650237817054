import React from 'react';
import { Container } from 'react-bootstrap';
import Slider from 'react-slick';
import styled from 'styled-components';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { UilArrowCircleLeft, UilArrowCircleRight } from '@iconscout/react-unicons';

const Infos = [
  {
    id: 0,
    title: "Talleres teóricos y prácticos",
    image: "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fprograma-transforma%2FSlide1.png?alt=media&token=79db69a1-8d5a-483e-af2f-5046d8a67f96",
  },
  {
    id: 1,
    title: "Consultas  de nutrición",
    image: "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fprograma-transforma%2FSlide2.png?alt=media&token=41f54955-a8ea-44f7-94fb-de0c290c544d",
  },
  {
    id: 2,
    title: "Consultas de activación física",
    image: "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fprograma-transforma%2FSlide3.png?alt=media&token=a098fe54-b986-49c1-a694-87f54082f91e",
  },
  {
    id: 3,
    title: "Estudios de laboratorio",
    image: "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fprograma-transforma%2FSlide4.png?alt=media&token=8eff9d24-98d3-49f1-b3bc-fc4a5e36ccc1",
  },
];

const Title = styled.h1`
  margin-top: 75px;
  margin-bottom: 70px;
  font-weight: 800;
  font-size: 34px;
  line-height: 130%;
  text-align: center;
  color: #00AD90;
  @media only screen and (max-width: 767px) {
    font-size: 24px;
    margin-top: 30px;
    margin-bottom: 27px;
  }
`;

const Card = styled.div`
  width: 304px;
  border-radius: 10px;
  overflow: hidden;
  img {
    width: 100%;
  }
  div {
    background: rgba(111, 190, 160, 0.15);
    width: 100%;
    height: 147px;
    display: flex;
    align-items: center;
    justify-content: center;
    h1 {
      max-width: 227px;
      font-weight: 500;
      font-size: 24px;
      line-height: 30px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #00AD90;
      margin: 0;
    }
  }
`;

const StyledSlider = styled(Slider)`
  margin-bottom: 50px;
  > svg {
    @media only screen and (max-width: 767px) {
      display: none !important;
    }
  }
`;

const LogosImage = styled.img`
  width: 100%;
`;

export const SliderProgram = () => {
  const {width} = useWindowDimensions();

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <UilArrowCircleLeft
        className={className}
        style={{ ...style, display: 'block', color: '#00AD90' }}
        onClick={onClick}
      />
    );
  }
  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <UilArrowCircleRight
        className={className}
        style={{ ...style, display: 'block', color: '#00AD90' }}
        onClick={onClick}
      />
    );
  }

  return (
    <Container>
      <Title>Nuestro programa ofrece:</Title>
      <StyledSlider
        width="100%"
        dots={false}
        infinite={true}
        slidesToShow={width > 992 ? 3 : width > 768 ? 2 : 1}
        slidesToScroll={1}
        autoplay={true}
        autoplaySpeed={4000}
        cssEase={'linear'}
        speed={600}
        prevArrow={<PrevArrow />}
        nextArrow={<NextArrow />}
      >
      {Infos.map((info)=>
        <div className='d-flex justify-content-center' key={info.id}>
          <Card>
            <img src={info.image} alt={info.title}/>
            <div>
              <h1>{info.title}</h1>
            </div>
          </Card>
        </div>
      )}
      </StyledSlider>
      <br/>
      <Title>Conoce a nuestros aliados</Title>
      <br/><br/>
      <LogosImage alt='logo' src={width > 768 ? "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fprograma-transforma%2FlogosDes.png?alt=media&token=ab946657-9dc4-4b80-a39c-cd71f3cf414c" : "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fprograma-transforma%2FlogosMob.png?alt=media&token=ecfd2e1f-2b3d-4e19-8ae4-a802719f4f03"}/>
    </Container>
  );
};
