import moment from 'moment';
import { format } from 'date-fns'; // Import the date-fns library for date formatting
import { es } from 'date-fns/locale'; // Import the locale for Spanish

export const fillDateWithZeros = (date) => {
    date = date.split('/');
    date.forEach((item, i) => {
        if (item.length < 2) {
            date[i] = '0' + item;
        }
    });
    return date.join('/');
};

export const replaceDashForSlash = (date) => date.replace('-', '/').replace('-', '/');

export const formatDate = (date) => {
    date = date.split('-');
    return fillDateWithZeros(`${date[2]}/${date[1]}/${date[0]}`);
};

export const timestampToNormalDate = (date) => {
    date = new Date(date?.seconds * 1000).toLocaleDateString('es');
    return fillDateWithZeros(date);
};

export const isBetweenHours = (startHour, finalHour, date, checkinHour) => {
    let datetime = new Date(checkinHour?.seconds * 1000).toLocaleString('es');
    let initial = moment(`${replaceDashForSlash(date)} ${startHour}`, 'DD/MM/YYYY hh:mm');
    let final = moment(`${replaceDashForSlash(date)} ${finalHour}`, 'DD/MM/YYYY hh:mm');
    datetime = moment(replaceDashForSlash(datetime), 'DD/MM/YYYY hh:mm');
    console.log(`Initial: ${initial.format('DD/MM/YYYY hh:mm')}`);
    console.log(`Final: ${final.format('DD/MM/YYYY hh:mm')}`);
    console.log(`Datetime: ${datetime.format('DD/MM/YYYY hh:mm')}`);
    console.log(`RES 1: ${datetime.isSameOrAfter(initial)}`);
    console.log(`RES 2: ${datetime.isSameOrBefore(final)}`);
    return (datetime.isSameOrAfter(initial) && datetime.isSameOrBefore(final));
};

export const hasAlreadyPassed = (date, hour) => {
    if (date && hour) {
        const current = moment(new Date(), 'DD/MM/YYYY');
        const time = hour.split(':');
        hour = moment(replaceDashForSlash(date), 'DD/MM/YYYY').add(6, 'hours');
        hour.set({ "hour": parseInt(time[0]), "minute": parseInt(time[1]) });
        return hour.isBefore(current) ? true : false;
    }
    return null;
};

export const sortByTimestamp = (array) => array.sort((a, b) => moment(timestampToNormalDate(a?.datetime), 'DD/MM/YYYY').format('DDMMYYYY') - moment(timestampToNormalDate(b?.datetime), 'DD/MM/YYYY').format('DDMMYYYY')).reverse();

export const timestampToSpanishDate = (timestamp) => {
    try {
        const seconds = timestamp?.seconds;
        const date = new Date(seconds * 1000); // Convert seconds to milliseconds
    
        return format(date, "d 'de' MMMM 'de' yyyy", { locale: es });
    } catch (err) {
        return '';
    }
};
