import React, { useState } from "react";
import styled from "styled-components";
import { UilAngleDown, UilAngleUp } from '@iconscout/react-unicons'
import { CLEAR_PINK_OCTOBER, PINK_OCTOBER } from "../../helpers/colors";
import StudyDiv from "./studyDiv";

const MainDiv = styled.div`
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
  position: relative;
  #main-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    border-radius: 15px;
    background: ${ ({open}) => open ? CLEAR_PINK_OCTOBER : PINK_OCTOBER};
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: ${ ({open}) => open ? PINK_OCTOBER : '#FFF'};
    transition: all 0.3s ease;
    z-index: 3;
    position: relative;
    p {
      transition: all 0.3s ease;
      color: ${ ({open}) => open ? PINK_OCTOBER : '#FFF'};
      margin-bottom: 0;
      font-size: 25px;
      font-weight: 400;
      user-select: none;
    }
  }
  #scrollable-div {
    width: 100%;
    padding: 50px 7px 0 11px;
    height: 324px;
    position: absolute;
    top: 25px;
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    z-index: 2;
    > div {
      width: 100%;
      height: 274px;
      padding: 0 7px 0 0;
      overflow: scroll;
      ::-webkit-scrollbar {
        width: 5px;
        border-radius: 10px;
      }
      ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #d9d9d9;
      }
    }
  }
`;

const SeeStudies = ({bottom, studies}) => {
  const [open, setOpen] = useState(false);

  return (
    <div style={bottom ? {position: 'absolute', bottom: '-30px', left: '50%', transform: 'translate(-50%, 0)', width: '100%', maxWidth: '350px', zIndex: '3'} : {width: '100%', maxWidth: '350px', zIndex: '3'}}>
      <MainDiv open={open}>

        {/* Main button - pink */}
        <div id='main-button' onClick={()=>setOpen(!open)}>
          <p>{open ? 'Volver' : 'Ver estudios'}</p>
          {open ? <UilAngleUp size={40}/> : <UilAngleDown size={40}/>}
        </div>

        {/* Scrollable div when it's opened */}
        {open ?
          <div id='scrollable-div'>
            <div>
              {studies?.map((stu)=>
                <StudyDiv key={stu?.id} isGreen={false} study={stu}/>
              )}
            </div>
          </div>
        : null}

      </MainDiv>
    </div>
  )
};

export default SeeStudies;