import React from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { ThanksScreen } from './thanksScreen';

const ThanksDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;

const ConfirmButton = styled.button`
  width: 297px;
  height: 48px;
  background: #6FBEA0;
  border: none;
  border-radius: 24px;
  font-weight: 800;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #FFFFFF;
  :hover {
    background: #81d6b6;
  }
`;

export const Thanks = (props) => {
  return (
    <ThanksDiv>
      <ThanksScreen
        data={props.data}
        personalData={props.personalData}
        isDoctor={props.isDoctor}
        pay={props.pay}
        total={props.subTotal}
        otherPerson={props.otherPersonCheck}
        firstTimeDoctor={props.firstTimeDoctor}
        name={'Example'}
      />
      <div className='d-flex justify-content-center'>
        <ConfirmButton onClick={() => props.onContinue()}>Continuar</ConfirmButton>
      </div>
      <br/>
      <br/>
    </ThanksDiv>
  );
};
