import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { GRADIENT_PAT } from '../../../helpers/colors';
import { UilEye } from '@iconscout/react-unicons'
import { currency, studyInCart } from '../../../helpers/cart';
import { useDispatch, useSelector } from 'react-redux';
import handleAlert from '../../../helpers/handleAlert';
import { handleAddItemToCart } from '../../../actions/shoppingCart';

const PANEL_RESP = 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2Fdoc-banners%2Fimg-baner-doc-panres.png?alt=media&token=51272f04-ab0f-41bc-852e-89dea245f596';

const Background = styled.div`
  background: ${GRADIENT_PAT};
  overflow: hidden;
  position: relative;
`;

const Slide = styled(Container)`
  height: 460px;
  h1 {
    color: #F3F3F3;
    font-size: 34px;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    margin: 0 auto 17px;
  }
  #see-btn {
    color: #F3F3F3;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    margin: 0 auto 20px;
    cursor: pointer;
    span {
      text-decoration-line: underline;
    }
  }
  #pathogens {
    color: #F3F3F3;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    span {
      font-weight: 600;
    }
  }
  h2 {
    color: #F3F3F3;
    text-align: center;
    font-size: 34px;
    font-weight: 300;
    line-height: 130%;
    span {
      font-size: 40px;
      font-weight: 600;
    }
  }

  #date {
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    margin: 0 auto;
  }

  #text-col {
    z-index: 2;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    .row {
      width: 100%;
    }
  }
  #img-col {
    z-index: 1;
    height: 460px;
    display: flex;
    align-items: end;
    justify-content: center;
  }
  img {
    max-width: 750px;
    max-height: 95%;
  }
  @media only screen and (min-width: 1920px) {
    #img-col {
      padding: 0;
    }
    img {
      max-width: 120%;
      max-height: 100%;
    }
  }
  @media only screen and (min-width: 1400px) and (max-width: 1920px) {
    padding: 0 5% 0 5%;
  }
  @media only screen and (min-width: 1200px) {
    img {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
  @media only screen and (max-width: 1200px) {
    position: relative;
    h1 {
      font-size: 28px;
    }
    #see-btn {
      font-size: 20px;
    }
    h2 {
      font-size: 28px;
      span {
        font-size: 30px;
      }
    }
    #date {
      font-size: 18px;
    }
  }
  @media only screen and (max-width: 992px) {
    height: 520px;
    h1 {
      font-size: 32px;
      margin-bottom: 15px;
      margin-top: 15px;
    }
    p {
      font-size: 30px;
    }
    img {
      max-width: 600px;
    }
    #img-col {
      height: 520px;
    }
  }
  @media only screen and (max-width: 768px) {
    padding: 40px 0 0;
    h1 {
      margin-top: 25px;
      font-size: 24px;
      font-weight: 500;
      width: 100%;
      max-width: 230px;
    }
    #see-btn {
      font-size: 17px;
      margin: -7px auto 13px;
    }
    h2 {
      font-size: 22px;
      span {
        font-weight: 500;
        font-size: 24px;
      }
    }
    #date {
      font-size: 14px;
    }
    img {
      width: 125%;
      max-width: 300px;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
`;

const TransparentButton = styled.button`
  color: #F3F3F3;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;

  padding: 5px 28px;
  border: 3px solid #FFF;
  background: none;
  border-radius: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFF;
  margin-bottom: 25px;
  transition: all 0.4s ease;
  :hover {
    background: #FFF;
    color: #00AC90;
  }
  @media only screen and (max-width: 1150px) {
    font-size: 22px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const GreenLabel = styled.div`
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background: #0CAC8C;
  width: 100%;
  position: absolute;
  top: 0;
  padding: 10px 14px;

  color: #F3F3F3;
  text-align: center;
  font-family: IBM Plex Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  span {
    font-weight: 600;
  }
  @media only screen and (max-width: 1200px) {
    font-size: 24px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 13px;
  }
`;

export const PatPanRespBanner = ({data}) => {
  const [seePathogens, setSeePathogens] = useState(false);
  const { items } = useSelector((state) => state.shoppingCart);
  const dispatch = useDispatch();

  const addStudy = () => {
    const exists = studyInCart(data, items);
    if (exists?.exists) {
      handleAlert("warning", exists?.message, "Estudio/paquete ya agregado");
    } else {
      dispatch(handleAddItemToCart(data));
    }
  };

  return (
    <Background>
      <GreenLabel>La <span>tecnología más avanzada</span> para el diagnóstico de enfermedades respiratorias.</GreenLabel>
      <Slide>
        <Row>
          <Col xs={12} md={7} xxl={7} id='text-col'>
            <h1>{data?.title ?? ''}</h1>
            <p id='see-btn' onClick={()=>setSeePathogens(!seePathogens)}><UilEye/> <span>Ver {seePathogens ? 'menos' : 'patógenos'}</span></p>
            {seePathogens &&
              <p id='pathogens'><span>Incluye:</span> SARS-CoV-2 (COVID-19), Virus de influenza tipo A y B, Virus Sincitial Respiratorio en Humano del tipo A, Virus Sincitial Respiratorio en Humano del tipo B, Subtipos del virus de la influenza tipo A (Virus de la influenza tipo A en humanos subtipos H1, H3 y H1pdm09).</p>
            }
            <Row>
              <Col xs={12} md={6}>
                <h2><span>{currency(data?.price ?? 1500)}</span> MXN</h2>
              </Col>
              <Col xs={12} md={6}>
                <TransparentButton onClick={()=>addStudy()}>Solicitar estudio</TransparentButton>
              </Col>
            </Row>
            <p id='date'>Vigencia marzo 2024.</p>
          </Col>
          <Col xs={12} md={5} xxl={5} id='img-col'>
            <img src={PANEL_RESP} alt="PANEL" />
          </Col>
        </Row>
      </Slide>
    </Background>
  );
};
