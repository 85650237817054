import React, { useState, useEffect } from 'react';
import { Banner } from '../../components/categories/Banner';
import { useParams } from 'react-router';
import { Highlights } from './Highlights';
import { Studies } from './Studies';
import axios from 'axios';
import { HelmetTitle } from '../../helpers/HelmetTitle';
import { HelmetSEO } from "../../helpers/HelmetSEO";
import { Doubts } from '../../components/doubts/doubts';
import { useSelector } from 'react-redux';
import { Loader } from '../../components/general/spinner';

export const Main = (props) => {
  const { service } = useParams();
  const [loading, setLoading] = useState(false);
  const idClienteLabopat = useSelector(
    (state) => state.user?.data?.data?.profile?.idClienteLabopat
  );
  const [pageInfo, setPageInfo] = useState({
    description: '',
    descripcion_larga: '',
    icono: '',
    id: 0,
    imagen_desktop: '',
    imagen_mobile: '',
    nombre: '',
  });

  const customHelmetInfo = {
      'nutricion': {
          title: 'Servicios de Nutrición Clínica - Labopat',
          description: 'Consulta de nutrición clínica con expertos en Puebla. Mejora tu salud con un plan personalizado en Labopat. Te ayudamos a tener un estilo de vida saludable'
      },
      'ultrasonido': {
          title: 'Ultrasonido Especializado e Integral - Labopat',
          description: 'Servicios de ultrasonido especializado e integral con equipos avanzados. Resultados confiables y atención personalizada. ¿Qué estudios buscas? Ingresa ya.'
      },
      'toma-biopsia': {
          title: 'Toma de Biopsia en Laboratorio Clínico - Labopat',
          description: 'Toma de biopsias guiadas por ultrasonido con médicos especialistas en radiología. Diagnósticos precisos y atención profesional. Agenda tu estudio.'
    },
      'laboratorio-clinico': {
          title: 'Estudios de Laboratorio | Análisis Clínicos - Labopat',
          description: 'Análisis eficiente e innovador para la detección, tratamiento y prevención de patologías en Labopat. Aprovecha precios exclusivos al pagar en línea.'
      },
      'molecular': {
          title: 'Laboratorio de Diagnóstico en Biología Molecular',
          description: 'Detectamos alteraciones genéticas y enfermedades como VPH cáncer y más. Estudios avanzados con muestras de sangre o tejido. Precios exclusivos en línea.'
      },
      'patologia-quirurgica': {
          title: 'Laboratorio de Patología Quirúrgica - Labopat',
          description: 'Diagnósticos en citología, estudios histopatológicos y más. Detectamos células anormales y analizamos tejidos para la detección temprana de enfermedades.'
      },
      'covid-19': {
          title: 'Pruebas Clínicas | Enfermedades Respiratorias - Labopat',
          description: 'Diagnósticos precisos y confiables para enfermedades respiratorias. Descubre la tecnología avanzada de Labopat para detectar COVID-19, influenza, y más.'
      }
  }

  const getInfo = async () => {
    setLoading(true);
    try {
      let request = axios.get(
        `https://lpqsystem.com/get-category-test/${service}/${
          idClienteLabopat ? `${idClienteLabopat}` : '0'
        }`
      );
      let response = await request;
      setPageInfo(response.data);
      setLoading(false);
      console.log(pageInfo)
    } catch (error) {
      alert(error);
      window.location.href = "/not-found";
    }
  };
  useEffect(() => {
    getInfo();
  }, [service]);

  const helmetTitle = customHelmetInfo[service]?.title || pageInfo?.nombre || 'Estudios...';
  const helmetDescription = customHelmetInfo[service]?.description || pageInfo?.description || 'No establecida';

  return (
    <div style={{ paddingRight: 0, paddingLeft: 0 }}>
      {/*<HelmetTitle title={pageInfo?.nombre ? pageInfo?.nombre +' | '+pageInfo?.descripcion :'Estudios..'} name='description' content='content' />*/}
        <HelmetSEO
            title={helmetTitle}
            description={helmetDescription}
        />
      <Banner
        data={{
          icono: pageInfo?.icono,
          nombre: pageInfo?.nombre,
          descripcion: pageInfo?.descripcion,
        }}
      />
      <Highlights data={{ id: pageInfo.id, service: service }} setLoading={(e)=>setLoading(e)}/>
      <Studies className='mt-4' data={{ id: pageInfo.id, service: service }} setLoading={(e)=>setLoading(e)}/>
      <Doubts />
      {loading && <Loader show={loading} />}
    </div>
  );
};
