import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Row, Col, Container } from 'react-bootstrap';
import { StudiesSlider } from './Slider';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Loader } from '../../components/general/spinner';

const Title = styled.h2`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 33px;
  color: #45494A;
  font-weight: 500;
  margin-top: 26px;
  @media only screen and (min-width: 768px) {
    font-size: 38px;
    line-height: 115%;
    text-align: center;
    letter-spacing: -0.01em;
    margin-top: 46px;
  }
`;

export const Highlights = (props) => {
  const [studies, setStudies] = useState([]);
  const [loading, setLoading] = useState(false);
  const idClienteLabopat = useSelector(
    (state) => state.user?.data?.data?.profile?.idClienteLabopat
  );

  const getStudies = async (id) => {
    setLoading(true);
    try {
      let request = axios.get(
        `https://lpqsystem.com/get-featured-studies-test/${id}/${
          idClienteLabopat ? `${idClienteLabopat}` : '0'
        }`
      );
      let data = await request;
      setStudies(data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getStudies(props.data.id);
  }, [props.data.id]);

  useEffect(()=>{
    if(studies.length===0){
      setLoading(true);
    }else{
      setLoading(false);
    }
  },[studies])

  return (
    <div>
      <Container>
        <Row>
          <Col md={12} className='text-center'>
            <Title>Estudios Destacados</Title>
          </Col>
          <Col md={12}>
            <StudiesSlider
              catID={props.data.id}
              studies={studies}
              idClienteLabopat={idClienteLabopat}
            />
          </Col>
        </Row>
      </Container>
      {loading && <Loader show={loading} />}
    </div>
  );
};
