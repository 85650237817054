import { Container, Row, Col } from "react-bootstrap";
import { UilClipboardAlt } from "@iconscout/react-unicons";
import { useHistory } from "react-router-dom";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { React, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getPatientOrders } from "../../helpers/api";
import { HelmetTitle } from "../../helpers/HelmetTitle";
import axios from "axios";
import { Loader } from "../general/spinner";
import styled from "styled-components";
import { MedicalRecordTable } from "./medicalRecordTable";

const branches = [
  {
    id: 4,
    idLpq: 5,
    name: "Palmas Plaza Drive Thru",
    sunday: true,
    address:
      "Estacionamiento de Palmas Plaza, Av Zeta del Cochero 403, Reserva Territorial Atlixcáyotl, 72820 San Andrés Cholula, Pue.",
    mapURL: "https://goo.gl/maps/8DMZYY7ERixC7xGR6",
    mapsId: "",
    coordinates: ["19.0267687", "-98.2311346"],
  },
  {
    id: 8,
    idLpq: 14,
    name: "Palmas Plaza planta baja",
    sunday: false,
    address:
      "Local 20, Palmas Plaza, Av Zeta del Cochero 403, Reserva Territorial Atlixcáyotl, 72820 San Andrés Cholula, Pue.",
    mapURL: "https://goo.gl/maps/8DMZYY7ERixC7xGR6",
    mapsId: "",
    coordinates: ["19.0267687", "-98.2311346"],
  },
  {
    id: 3,
    idLpq: 13,
    name: "Torres Médicas",
    sunday: false,
    address:
      "Torres Médicas Angelópolis 2. Anillo Perif. Ecológico 3507, local 15 y 16 Tlaxcalancingo, Puebla 72821",
    mapURL: "https://goo.gl/maps/GKRKLQSnVXQPgt8o7",
    mapsId: "",
    coordinates: ["19.02051237624928", "-98.26456209191247"],
  },
  {
    id: 7,
    idLpq: 21,
    name: "La Paz",
    sunday: false,
    address:
      "Plaza Pabellón R&M. Av. Rosendo Márquez 4122, local 5 Colonia La Paz, 72160 Puebla, Pue.",
    mapURL: "https://goo.gl/maps/nfcNL45qi89A5Lhw7",
    mapsId: "",
    coordinates: ["19.0522198", "-98.2362645"],
  },
  {
    id: 2,
    idLpq: 11,
    name: "Lomas de Angelópolis",
    sunday: false,
    address:
      "Torre Elementa. Blvd. América 308, local 3; Lomas de Angelópolis, 72228 San Andrés Cholula, Pue.",
    mapURL: "https://goo.gl/maps/wPfV3oCtYEcmmgxVA",
    mapsId: "",
    coordinates: ["18.9976678", "-98.2818113"],
  },
  {
    id: 6,
    idLpq: 20,
    name: "Zavaleta",
    sunday: false,
    address:
      "Plaza Bosques De Zavaleta, Calz Zavaleta 313-A, local 14 Santa Cruz Buenavista, 72150 Puebla, Pue.",
    mapURL: "https://goo.gl/maps/S7DapoFhy9F8zgNM9",
    mapsId: "",
    coordinates: ["19.066824", "-98.2503983"],
  },
];

const HeaderSection = styled.div`
  margin-top: 5%;
  display: flex;
  flex-direction: column;
`;

const HeaderTitle = styled.p`
  margin: 0;
  font-family: "IBM Plex Sans";
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;
  font-style: normal;
  color: #697077;
  text-align: left;
  @media only screen and (max-width: 768px) {
    font-size: 28px;
  }
`;

const TabTitle = styled.a`
  display: inline-block;
  width: 100%;
  margin-top: -10px;
  text-align: center;
  font-family: "IBM Plex Sans";
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
  line-height: 20px;
  transition: 200ms;
  :hover {
    color: ${(props) => (props.$isDoctor ? "#4F90BA" : "#00AC90")};
    transition: 200ms;
  }
`;

const TabIndicatorCol = styled(Col)`
  height: 10px;
  border-radius: 5px;
  margin-top: 10px;
  background: ${(props) =>
    props.selected && props.$isDoctor
      ? "linear-gradient(90deg, #5097E3 -2.05%, #0CAC8C 72.3%, #30DF99 101.61%, #CEF719 133.72%, #F5EC1D 211.91%)"
      : props.selected &&
        "linear-gradient(83.81deg, #F7EE1B -32.78%, #35E19B 24.37%, #00AE8E 75.27%, #5097E3 138.67%)"};
`;

const StyledNav = styled.nav`
  border-bottom: 1px solid #c1d9d8;
`;

const EmptyPatients = styled.div`
  height: 50vh;
`;

const EmptyIconContainer = styled.div`
  margin: 40px auto 24px;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  left: 110px;
  top: 112px;
  background: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EmptyPatientsText = styled.p`
  font-family: "IBM Plex Sans";
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #45494a;
  margin-bottom: 40px;
`;

export const MedicalRecord = () => {
  const history = useHistory();
  const { width } = useWindowDimensions();
  let isMobile = width <= 768;
  const isDoctor =
    useSelector((state) => state.user?.data?.data?.profile?.type) === 1;

  // Toogle
  const [tab, setTab] = useState(0);
  const dispatch = useDispatch();
  const { patientOrders } = useSelector((state) => state);

  useEffect(() => {
    if (!patientOrders) {
      console.log("Getting patient orders");
      dispatch(getPatientOrders());
    }
  }, [dispatch, patientOrders]);

  // Orders
  const uid = useSelector((state) => state?.auth?.uid);
  const [pendingOrders, setPendingOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [finishedOrders, setFinishedOrders] = useState([]);

  useEffect(() => {
    dd();
  }, []);
  const dd = async () => {
    try {
      setLoading(true);
      let requestPending = await axios.get(
        //`https://clienteslabopat.com/get-pending-orders?user=${uid}`
        `https://lpqsystem.com/detalle-mis-pacientes?user=${uid}`
      );

      // let requestFinished = await axios.get(
      // `https://clienteslabopat.com/get-finished-orders?user=${uid}`
      // );
      // let requestPending = await axios.get(`https://clienteslabopat.com/get-pending-orders?user=i4q2MgUkYaOtodqs1Oxhxdat6ya2`);
      // let requestFinished = await axios.get(`https://clienteslabopat.com/get-finished-orders?user=i4q2MgUkYaOtodqs1Oxhxdat6ya2`);
      //console.log(requestFinished);
      setPendingOrders(requestPending.data.pendientes);
      setFinishedOrders(requestPending.data.historico);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  // Request example https://clienteslabopat.com/get-pending-orders?user=i4q2MgUkYaOtodqs1Oxhxdat6ya2
  // Request example https://clienteslabopat.com/get-finished-orders?user=i4q2MgUkYaOtodqs1Oxhxdat6ya2
  return (
    <Container
      style={{ minHeight: "100vh" }}
      className={isMobile ? "mt-5 pt-4" : ""}
    >
      <HelmetTitle
        title="Historial médico"
        name="description"
        content="content"
      />
      <Row className="mb-5">
        <HeaderSection>
          <HeaderTitle className="mb-5">Historial médico</HeaderTitle>
          <StyledNav>
            <Row className="m-0 p-0">
              <Col xs={6} md={3} lg={3} xl={3} style={{ paddingLeft: "0" }}>
                <TabTitle
                  onClick={() => setTab(0)}
                  selected={tab === 0 ? true : false}
                  $isDoctor={isDoctor}
                >
                  Resultados pendientes
                  <TabIndicatorCol
                    onClick={() => setTab(0)}
                    selected={tab === 0 ? true : false}
                    $isDoctor={isDoctor}
                  />
                </TabTitle>
              </Col>
              <Col xs={6} md={3} lg={3} xl={3} style={{ paddingLeft: "0" }}>
                <TabTitle
                  onClick={() => setTab(1)}
                  selected={tab === 1 ? true : false}
                  $isDoctor={isDoctor}
                >
                  Historial de resultados
                  <TabIndicatorCol
                    onClick={() => setTab(1)}
                    selected={tab === 1 ? true : false}
                    $isDoctor={isDoctor}
                  />
                </TabTitle>
              </Col>
            </Row>
          </StyledNav>
        </HeaderSection>
      </Row>
      {tab === 0 && (
        <Row>
          {pendingOrders.length > 0 ? (
            <MedicalRecordTable
              data={pendingOrders}
              tab={tab}
              isdoctor={isDoctor}
              itemsPerPage={10}
            />
          ) : (
            <EmptyPatients>
              <EmptyIconContainer>
                <UilClipboardAlt color="#A6A6A6" size={100} />
              </EmptyIconContainer>
              <EmptyPatientsText>
                No tienes resultados pendientes
              </EmptyPatientsText>
            </EmptyPatients>
          )}
        </Row>
      )}
      {tab === 1 && (
        <Row>
          {finishedOrders.length > 0 ? (
            <MedicalRecordTable
              data={finishedOrders}
              tab={tab}
              isdoctor={isDoctor}
              itemsPerPage={10}
            />
          ) : (
            <EmptyPatients>
              <EmptyIconContainer>
                <UilClipboardAlt color="#A6A6A6" size={100} />
              </EmptyIconContainer>
              <EmptyPatientsText>No tienes resultados</EmptyPatientsText>
            </EmptyPatients>
          )}
        </Row>
      )}
      {loading ? <Loader show={loading} /> : null}
    </Container>
  );
};
