import React from "react";
import "../../App.css";
import { TrackGoogleAnalitycsEvent } from "../../analytics/google-analytics";
import styled from "styled-components";

const Whats = styled.div`
  position: absolute;
  left: 75px;
  top: 10%;
  width: 212px;
  z-index: 1;
  background-color: rgb(35, 211, 102);
  border-radius: 5px;
  padding: 10px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
`;

const WhatsAppButton = () => {
  const handleWhatsAppClick = () => {
    TrackGoogleAnalitycsEvent(
      "WhatsApp",
      "WhatsApp",
      "click en boton whatsapp"
    );
    window.open("https://wa.me/522212311313", "_blank");
  };
  return (
    <a
      href="https://wa.me/522212311313"
      className="float"
      target="_blank"
      id="whatsapp-in-public"
      onClick={handleWhatsAppClick}
    >
      <i className="fa fa-whatsapp my-float"></i>
      <Whats>
        <p style={{ fontSize: "14px", margin: "0", display: "ruby-text" }}>
          ¡Agenda y cotiza tus estudios!
        </p>
      </Whats>
    </a>
  );
};

export default WhatsAppButton;
