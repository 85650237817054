import React from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { UilArrowLeft } from '@iconscout/react-unicons';

const BackBtn = styled(Button)`
  background-color: white;
  border: none;
`;

export const BackButton = () => {
  return (
    <BackBtn variant='light' onClick={() => window.history.back()}>
      <UilArrowLeft color='#00AD90' size='26' />
      Regresar
    </BackBtn>
  );
};
