import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
const labopat = 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2Flogos%2Flabopat.gif?alt=media&token=c94be994-7a48-404c-a4a5-57e257cadd67';

// const loading = 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2Fecommerce%2Floading.gif?alt=media&token=629f78d3-10a2-44f9-9143-668f5bc19ace';

export const Loader = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  return (
    <Modal
      show={props.show}
      onHide={handleClose}
      backdrop='static'
      keyboard={false}
    >
      <Modal.Body>
        <div className='text-center'>
          <img src={labopat} className='img-fluid' />
        </div>
      </Modal.Body>
    </Modal>
  );
};
