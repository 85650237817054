import React, { useState } from 'react';
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Slider from 'react-slick';
import Modal from 'react-bootstrap/Modal';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { UilArrowDown, UilArrowUp } from '@iconscout/react-unicons';

const ScrollAnnounce =
  'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fcurso-alta-especialidad%2FSCROLL_ANNOUNCE.png?alt=media&token=b0ae6edb-9659-4d4e-a2c0-e6cab9b8e988';

const ApplySection = styled.div`
  position: relative;
  background-color: #ffffff;
  padding-top: 42px;
  padding-bottom: 42px;
  @media only screen and (max-width: 768px) {
    .container {
      padding-left: 24px;
    }
  }
`;

const SectionTitle = styled.h2`
  font-family: 'Faustina';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  text-transform: uppercase;
  color: #c4c4c4;
  width: auto;
  margin-bottom: 12px;
  @media only screen and (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const Subtitle = styled.h3`
  font-family: 'Faustina';
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 45px;
  color: #00ad90;
  text-align: center;
  width: auto;
  margin-bottom: 36px;
  @media only screen and (max-width: 768px) {
    margin-bottom: 18px;
    text-align: left;
  }
  @media only screen and (max-width: 576px) {
    font-size: 24px;
    line-height: 30px;
  }
`;

const StyledSlider = styled(Slider)`
  .col-12 {
    @media only screen and (max-width: 768px) {
      padding-left: 18px;
    }
  }
  p {
    @media only screen and (max-width: 768px) {
      margin-left: 0;
    }
  }
  .slick-dots {
    li {
      margin: 0;
      @media only screen and (max-width: 1200px) {
        width: 50%;
      }
      @media only screen and (max-width: 768px) {
        width: 25%;
      }
    }
    button{
      width: 100%;
      height: 3px;
      padding: 0;
      background: #F2F7F9; 
      transition: all ease .5s;
      margin-top: 15px;
    }
    button:before {
      display: none;
    }
    .slick-active button {
      background: #C4C4C4;
    }
  }
`;

const StepNumber = styled.p`
  font-family: 'Faustina';
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  background-color: #474a4b;
  width: 35px;
  height: 35px;
  margin-right: 0;
  margin-bottom: 24px;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  padding-top: 5px;
`;

const StepDesc = styled.p`
  display: block;
  font-family: 'Faustina';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #474a4b;
  margin-bottom: 0;
  padding-top: 24px;
  border-top: 2px solid #474a4b;
  max-width: 300px;
  margin-left: 8px;
  margin-right: 8px;
  .title {
    font-weight: 800;
  }
  .date {
    color: #00AD90;
  }
  .underline {
    text-decoration: underline;
  }
  .pointer {
    cursor: pointer;
  }
  .link {
    font-weight: 700;
    text-decoration: underline;
    color: #474a4b;
  }
  @media only screen and (max-width: 768px) {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
  }
`;

const ScrollAnnounceImg = styled.img`
  position: absolute;
  top: 90px;
  bottom: 0;
  right: 0;
  margin: auto;
  max-width: 208px;
  max-height: 208px;
  z-index: 0;
  pointer-events: none;
  user-select: none;
  animation: twinkle .8s infinite alternate;
  @keyframes twinkle {
    0%{
        opacity:.8;
    }
    100%{
        opacity:0;
    }
  }
`;

const StyledModal = styled(Modal)`
  display: flex !important;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  .modal-content {
    border-radius: 0;
  }
  .btn-close {
    padding: 0;
    top: 18px;
    right: 20px;
    width: 22px;
    height: 22px;
    z-index: 1;
    background: transparent
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2300AD90'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
      center/1em auto no-repeat;
    background-size: 100% 100%;
    outline: none;
    border: none;
    box-shadow: none;
    :focus {
      box-shadow: none;
    }
    @media only screen and (max-width: 768px) {
      top: 24px;
    }
  }
  .modal-dialog {
    max-width: 988px;
    width: 100%;
    margin: 0;
  }
  .modal-header {
    border: none;
  }
  .modal-body {
    padding-top: 31px;
    padding-bottom: 31px;
  }
  #mex-req {
    @media only screen and (min-width: 768px) {
      padding-left: 0;
      margin-right: 20px;
    }
  }
`;

const ModalTitle = styled.p`
  font-family: 'Faustina';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
  color: #00AD90;
  text-align: left;
  margin-bottom: 17px;
  width: auto;
  @media only screen and (max-width: 768px) {
    text-decoration: underline;
  }
`;

const StyledList = styled.ul`
  margin-bottom: 0;
  li {
    font-family: 'Faustina';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    color: #474A4B;
    max-width: 386px;
    @media only screen and (max-width: 768px) {
      font-size: 18px;
      line-height: 25px;
    }
    span {
      font-weight: 700;
    }
  }
  .first-condition {
    font-family: 'Faustina';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    color: #474A4B;
    margin-bottom: 14px;
    @media only screen and (max-width: 992px) {
      margin-bottom: 22px;
    }
  }
  .second-condition {
    font-family: 'Faustina';
    font-style: italic;
    font-weight: 700;
    font-size: 15px;
    line-height: 23px;
    color: #F9AD62;
    margin: 20px 0 0 -17px;
    max-width: 300px;
  }
  .show-green {
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    text-decoration-line: underline;
    color: #00AD90;
    margin-left: 30px;
    @media only screen and (max-width: 768px) {
      font-size: 18px;
      line-height: 23px;
    }
  }
  @media only screen and (max-width: 768px) {
    padding-left: 32px;
  }
  @media only screen and (min-width: 768px) {
    padding-left: 22px;
  }
`;

const TitleReq = styled.h1`
  font-family: 'Faustina';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  text-transform: uppercase;
  color: #C4C4C4;
`;

export const HowToApply = (props) => {
  const [showReqMex, setShowReqMex] = useState(false);
  const [showReqExt, setShowReqExt] = useState(false);
  const { width } = useWindowDimensions();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    arrows: false,
    useTransform: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  return (
    <ApplySection id='howToApply'>
      <Container>
        <SectionTitle>APLICA AHORA / PROCEDIMIENTO</SectionTitle>
        <Subtitle>¿Cómo aplicar al curso?</Subtitle>
        <StyledSlider {...settings}>
          <div>
            <Col xs={12} className='pe-3 ps-3'>
              <StepNumber>1</StepNumber>
              <StepDesc>
                <span className='title'>Envía tus documentos{' '}</span>
                <span className='d-block date mb-0'>
                  15 de Nov al 2 de Diciembre 2023
                </span>
                <br />
                <span className='underline pointer' onClick={() => props.setShowRequirements(true)}>Escanea los documentos</span>
                {' '}y envíalos al siguiente correo: <br />
                <br />
                <a
                  className='link'
                  href={'mailto:academia@labopat.com'}
                  target={'_blank'}
                  rel={'noreferrer'}
                >
                  academia@labopat.com
                </a>
              </StepDesc>
            </Col>
          </div>
          <div>
            <Col xs={12} className='pe-3 ps-3'>
              <StepNumber>2</StepNumber>
              <StepDesc>
                <span className='title'>Proceso de selección{' '}</span>
                <span className='d-block date mb-0'>
                  4 al 9 de Diciembre 2023
                </span>
                <br />
                <span className='title'>
                  Te notificaremos a tu correo los resultados de la selección. *
                </span>
              </StepDesc>
            </Col>
          </div>
          <div>
            <Col xs={12} className='pe-3 ps-3'>
              <StepNumber>3</StepNumber>
              <StepDesc>
                <span className='title'>Examen teórico y psicométrico</span>
                <span className='d-block date mb-0'>
                  13 al 16 de Diciembre 2023
                </span>
                <span className='title'>Entrevistas</span>
                <span className='d-block date mb-0'>
                  18 al 22 de Diciembre 2023
                </span>
                <br/>
                De ser seleccionado se te realizará una entrevista y realizarás un{' '}
                <span className='title'>
                  examen de conocimientos y examen psicométrico.
                </span>
              </StepDesc>
            </Col>
          </div>
          <div>
            <Col xs={12} className='pe-3 ps-3'>
              <StepNumber>4</StepNumber>
              <StepDesc>
                <span className='title'>Carta de aceptación</span>
                <span className='d-block date mb-0'>
                  5 de Enero 2024
                </span>
                <br/>
                Una vez obtenida la carta de aceptación deberás realizar la 
                inscripción en{' '}
                <a
                  className='link'
                  href={'https://consultores.udlap.mx/'}
                  target={'_blank'}
                  rel={'noreferrer'}
                >
                  UDLAP consultores.
                </a>
              </StepDesc>
            </Col>
          </div>
        </StyledSlider>
        {width <= 1200 && (
          <ScrollAnnounceImg
            src={ScrollAnnounce}
            alt='Desliza para ver más'
          />
        )}
        <StyledModal show={props.showRequirements} onHide={() => props.setShowRequirements(false)}>
          <StyledModal.Header closeButton className='p-0'></StyledModal.Header>
          <StyledModal.Body>
            <Row>
              {width < 768 && <TitleReq>APLICA AHORA / REQUISITOS</TitleReq> }
              <Col md={6} lg={{span:5, offset: 1}} className='pe-0' id="mex-req">
                <ModalTitle
                  style={{color: width < 768 && showReqMex ? '#F9AD62' : '#00AD90'}}
                  onClick={()=>setShowReqMex(!showReqMex)}
                >{width < 768 ? showReqMex ? <UilArrowUp size={26}/> : <UilArrowDown size={26}/> : ''}Requisitos para mexicanos</ModalTitle>
                <StyledList>
                  <li>
                    <span>
                      Título y Cédula de Médico Cirujano, {' '}
                    </span>
                    ambas caras.* 
                    {width < 768 && 
                      <span 
                        className='show-green'
                        onClick={()=>setShowReqMex(!showReqMex)}
                      >{showReqMex ? 'Ver menos' : 'Ver requisitos' }</span>
                    }
                  </li>
                  {(showReqMex || width > 768) && (
                    <>
                    <li>
                      <span>
                        Título de Médico Anatomopatólogo.{' '}
                      </span>
                      (o constancia de término de la residencia avalada por la universidad de origen), ambas caras.*
                    </li>
                    <li>
                      <span>
                        Currículum Vitae.
                      </span>
                    </li>
                    <li>
                      <span>
                        Dos cartas de recomendación de anatomopatólogos con certificación vigente (COMMAP).
                      </span>
                    </li>
                    <li>
                      <span>
                        CURP.
                      </span>
                    </li>
                    <li>
                      <span>
                        RFC {' '}
                      </span>
                      (constancia de situación fiscal).
                    </li>
                    <li>
                      <span>
                        Identificación INE{' '}
                      </span>
                      ambas caras.*
                    </li>
                    <li>
                      <span>
                        3 Fotografías tamaño infantil en color. 
                      </span>
                    </li>
                    <li>
                      <span>
                        Comprobante de nivel de inglés vigente:{' '}
                      </span>
                      Toefl, Toefl IBT o IELTS.
                    </li>
                    </>
                  )}
                </StyledList>
              </Col>
              <Col md={6} lg={5} className='pe-0'>
                <ModalTitle
                  style={{color: width < 768 && showReqExt ? '#F9AD62' : '#00AD90', marginTop: width < 768 ? '34px' : '0'}}
                  onClick={()=>setShowReqExt(!showReqExt)}
                >{width < 768 ? showReqExt ? <UilArrowUp size={26}/> : <UilArrowDown size={26}/> : ''}Requisitos para extranjeros</ModalTitle>
                <StyledList>
                  <li>
                    <span>
                      Título apostillado.
                    </span>
                    {width < 768 && 
                      <span 
                        className='show-green'
                        onClick={()=>setShowReqExt(!showReqExt)}
                      >{showReqExt ? 'Ver menos' : 'Ver requisitos' }</span>
                    }
                  </li>
                  {(showReqExt || width > 768) && (
                    <>
                    <li>
                      <span>
                        Currículum Vitae.
                      </span>
                    </li>
                    <li>
                      <span>
                        Dos cartas de recomendación de anatomopatólogos con certificación vigente. 
                      </span>
                    </li>
                    <li>
                      <span>
                        Pasaporte.
                      </span>
                    </li>
                    <li>
                      <span>
                        3 Fotografías tamaño infantil en color. 
                      </span>
                    </li>
                    <li>
                      <span>
                        Comprobante de nivel de inglés vigente:{' '}
                      </span>
                      Toefl, Toefl IBT o IELTS.
                    </li>
                    </>
                  )}
                  <p className='second-condition'>
                    Estudiantes extranjeros necesitarán tramitar visa estudiantil al ser aceptados.
                  </p>
                </StyledList>
              </Col>
            </Row>
          </StyledModal.Body>
        </StyledModal>
      </Container>
    </ApplySection>
  );
};
