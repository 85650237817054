import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { OUTLINE_CARDS, TEXT_BLACK } from "../../../helpers/colors";
import { UilLocationPinAlt, UilClock } from '@iconscout/react-unicons';

const agsOneDk =  'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2Faguascalientes%2FagsBannerOneDesk.jpg?alt=media&token=6ee43927-37b4-430b-9910-659a7a234f43';
const agsOneMb = 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2Faguascalientes%2FagsBannerOneMB.png?alt=media&token=bc4e6455-47df-4a77-a5e5-65fa721f80de';

const BackBanner = styled.div`
  height: 460px;
  display: flex;
  align-items: center;
  position: relative;
  background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 60%, rgba(255,255,255,0) 100%), url("${agsOneDk}") right center/contain no-repeat;
  @media only screen and (max-width: 992px) {
    height: 520px;
  }
  @media only screen and (min-width: 992px) and (max-width: 1400px) {
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 60%, rgba(255,255,255,0) 100%), url("${agsOneDk}") right 0px center/contain no-repeat;
  }
  @media only screen and (min-width: 1400px) and (max-width: 1480px) {
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 60%, rgba(255,255,255,0) 100%), url("${agsOneDk}") right -100px center/contain no-repeat;
  }
  @media only screen and (max-width: 992px) {
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 20%, rgba(255,255,255,0) 100%), url("${agsOneMb}") right bottom 0px no-repeat;
    align-items: start;
    padding-top: 5px;
  }
`;

const ColTitle = styled(Col)`
  display: flex;
  align-items: left;
  justify-content: center;
  flex-direction: column;
  overflow: show;
  h1 {
    font-size: 52px;
    font-weight: 600;
    line-height: normal;
    background: ${OUTLINE_CARDS};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  h3 {
    color: ${TEXT_BLACK};
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }
  #btn-container {
    height: 64px;
    width: 240px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
  }
  .btn {
    border: none;
    outline: none;
    position: relative;
    z-index: 1;
    border-radius: 5px;
    background: linear-gradient(79deg, #5097E3 -41.6%, #0CAC8C 26.14%, #30DF99 84.04%, #CEF719 107.01%, #F5EC1D 132.38%);
    cursor: pointer;
  }
  .btn::before {
    content: "";
    position: absolute;
    left: 3px;
    right: 3px;
    top: 3px;
    bottom: 3px;
    border-radius: 4px;
    background-color: white;
    z-index: -1;
    transition: 300ms;
  }
  .btn::after {
    content: attr(data);
    background: linear-gradient(79deg, #5097E3 -41.6%, #0CAC8C 26.14%, #30DF99 84.04%, #CEF719 107.01%, #F5EC1D 132.38%);
    -webkit-background-clip: text;
    color: transparent;
    transition: 200ms;
    text-align: center;
    font-size: 29px;
    font-weight: 400;
    line-height: normal;
  }
  .btn:hover::before {
    opacity: 1%;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
  .btn:hover::after{
    color: white;
  }
  @media only screen and (width <= 768px) {
    h3 {
      font-size: 18px;
    }
    h1 {
      font-size: 30px;
    }
    .btn::after {
      font-size: 20px;
    }
    #btn-container {
      border-radius: 4px;
      width: 173px;
      height: 38px;
      margin: 0 auto;
    }
  }
`;

const StyledDiv = styled.div`
  display: flex;
  gap: 10px; /* Adjust the gap as needed */
  svg {
    color: #30DF99;
    margin-right: 10px;
    margin-top: -3px;
  }
  p {
    color: ${TEXT_BLACK};
    font-size: 18px;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: 0.18px;
    span {
      font-size: 20px;
      font-weight: 600;
    }
  }
  @media only screen and (width <= 768px) { 
    p {
      font-size: 14px;
      span {
        font-size: 16px;
      }
    }
  }
`;

const IconContainer = styled.div`
  width: 35px;
`;

const TextContainer = styled.div`
  flex-grow: 1;
`;

export const AguascalientesBannerOne = (props) => {
  return (
    <BackBanner>
      <Container>
        <Row>
          <ColTitle xs={{span: 12, order: 2}} lg={{span: 9, order: 1}} xl={8}>
            <h3>Estamos siempre cerca de ti</h3>
            <h1>Sede Labopat Aguascalientes</h1>
            <Row>
              <Col xs={12} lg={6}>
                <StyledDiv>
                  <IconContainer>
                    <UilLocationPinAlt size={40} />
                  </IconContainer>
                  <TextContainer>
                    <p>
                      <span>Edificio Médico Okabe.</span>
                      <br />
                      Blvd. Luis Donaldo Colosio Murrieta 208, Lomas del Campestre II, 20119, Aguascalientes, Ags.
                    </p>
                  </TextContainer>
                </StyledDiv>
              </Col>
              <Col xs={12} lg={6}>
                <StyledDiv>
                  <IconContainer>
                    <UilClock size={40} />
                  </IconContainer>
                  <TextContainer>
                    <p><span>Horario de atención</span><br/>Lunes a viernes de 8:00 a 20:00 h.<br/>Sábados de 8:00 a 14:00 h.<br/><span>Tel. 449 710 22 51</span></p>
                  </TextContainer>
                </StyledDiv>
              </Col>
            </Row>
            <div id="btn-container">
              <button onClick={() => window.location.href = '/aguascalientes'} className="btn" data="Más información"/>
            </div>
          </ColTitle>
        </Row>
      </Container>
    </BackBanner>
  )
};