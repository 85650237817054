import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";

const mbLogoCAE = 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2Fcurso-alta-especialidad%2FmbLogoCAE.png?alt=media&token=9da48883-5fcc-4863-b794-f6990a2d379d';
const LOGOCURSO = 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2Fcurso-alta-especialidad%2Flogo-curso-alta-especialidad.png?alt=media&token=a33c5413-8f46-48e6-bd13-4a4f2f645082';

const BackBanner = styled.div`
  height: 460px;
  display: flex;
  align-items: center;
  position: relative;
  border-bottom: 15px solid #F9AD62;
  background: url("https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fcurso-alta-especialidad%2FbannerDesk.png?alt=media&token=325c28d4-d367-4658-baca-24bf2c221e23") right center/contain no-repeat;
  @media only screen and (max-width: 992px) {
    height: 520px;
  }
  @media only screen and (min-width: 992px) and (max-width: 1400px) {
    background: url("https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fcurso-alta-especialidad%2FbannerDesk.png?alt=media&token=325c28d4-d367-4658-baca-24bf2c221e23") right -270px center/contain no-repeat;
  }
  @media only screen and (min-width: 1400px) and (max-width: 1480px) {
    background: url("https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fcurso-alta-especialidad%2FbannerDesk.png?alt=media&token=325c28d4-d367-4658-baca-24bf2c221e23") right -100px center/contain no-repeat;
  }
  @media only screen and (max-width: 992px) {
    background-position-y: 300px;
    align-items: start;
    padding-top: 20px;
  }
`;

const ColTitle = styled(Col)`
  display: flex;
  align-items: left;
  justify-content: center;
  flex-direction: column;
  overflow: show;
  @media only screen and (min-width: 768px) {
    #logo-mb {
      display: none;
    }
  }
  @media only screen and (max-width: 768px) {
    padding: 0px;
    #logo-desk {
      display: none;
    }
  }
  h3 {
    color: #474A4B;
    leading-trim: both;
    text-edge: cap;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 28px;
    @media only screen and (max-width: 992px) {
      max-width: 285px;
      padding-left: 20px;
      margin-bottom: 10px;
    }
  }
  #logos-div {
    width: 100%;
    max-width: 570px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media only screen and (max-width: 768px) {
      margin-top: 5px;
      width: 90%;
    }
  }
  #udlap {
    width: 126px;
    @media only screen and (max-width: 992px) {
      margin-left: 0;
    }
    @media only screen and (max-width: 768px) {
      width: 90px;
    }
  }
  #labopat {
    width: 146px;
    @media only screen and (max-width: 768px) {
      width: 100px;
    }
  }
  #HA {
    width: 160px;
    @media only screen and (max-width: 768px) {
      width: 100px;
    }
  }
  div div {
    width: auto;
    margin-top: 21px;
    @media only screen and (max-width: 992px) {
      width: 353px;
      margin-top: 31px;
      margin-left: auto;
      margin-right: auto;
      display: flex; 
      align-items: center;
      justify-content: center;
    }
    @media only screen and (max-width: 992px) {
      width: 100%;
    }
  }
  #a-img {
    @media only screen and (max-width: 992px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  a {
    margin: 0 auto;
  }
`;

const ColImage = styled(Col)`
  img {
    width: 100%;
  }
`;

const Link = styled.a`
  background: #00AD90;
  padding: 12px 20px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  color: #FFFFFF;
  :hover {
    color: #FFFFFF;
  }
  @media screen and (width <= 768px) {
    width: 90%;
    text-align: center;
  }
`;

const Logo = styled.img`
  width: 673px;
  margin-bottom: 20px;
  @media screen and (width <= 768px) {
    width: 90%;
    max-width: 288px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
  }
`;

const GreenDiv = styled.div`
  background: #0CAC8C;
  width: 340px;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: 20%;
  p {
    span {
      font-size: 23px;
      font-weight: 600;
    }
    margin-bottom: 0;
    color: #FFF;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 22px;
    font-weight: 400;
    line-height: normal;
    text-align: center;
  }
  @media only screen and (max-width: 1400px) {
    right: 10%;
  }
  @media only screen and (max-width: 1200px) {
    right: 20px;
  }
  @media only screen and (max-width: 992px) {
    right: 50%;
    left: 50%;
    transform: translate(-50%, 0);
    width: 285px;
    height: 100px;
    p {
      font-size: 18px;
      span {
        font-size: 20px;
      }
    }
  }
`;

export const AltaBanner = (props) => {

  const scrollTo = (id) => {
    var elem = document.getElementById(id);
    window.scroll({
      top: getOffset(elem)-80,
      left: 0,
      behavior: 'smooth'
    });
  }

  const getOffset = ( el ) => {
    var _y = 0;
    while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
      _y += el.offsetTop - el.scrollTop;
      el = el.offsetParent;
    }
    return _y;
  }
  
  return (
    <BackBanner>
      {/*<StyledImg src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fcurso-alta-especialidad%2FbannerMobile.png?alt=media&token=7affb6fd-72e6-45fc-8ab6-d239431d40bf"/>*/}
      <Container>
        <Row>
          <ColTitle xs={{span: 12, order: 2}} lg={{span: 9, order: 1}} xl={8}>
            <Logo alt='Curso alta especialidad 2024' src={LOGOCURSO} id="logo-desk"/>
            <Logo alt='Curso alta especialidad 2024' src={mbLogoCAE} id="logo-mb"/>
            <div id='a-img'>
              <h3>Dirigido a médicos residentes de patología</h3>
              <Link href="/curso-alta-especialidad">Consulte más información aquí</Link>
              <div id="logos-div">
                <img id='udlap' alt='logo UDLAP' src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fcurso-alta-especialidad%2Flogo-UDLAP.png?alt=media&token=289fe2c8-4131-475c-ab2b-c7341c7f05b4"/>
                <img id='labopat' alt='logo labopat' src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Flogo%20labopat.png?alt=media&token=5835ffef-5e66-4087-8423-a4f20f03288a"/>
                <img id='HA' alt='logo HA' src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fcurso-alta-especialidad%2FlogoHA.png?alt=media&token=1ff9654c-b5a9-490a-8e99-5fab7575ca83"/>
              </div>
            </div>
          </ColTitle>
          <ColImage xs={{span: 12, order: 1}} md={{span: 6, order: 2}}>
            {/* <img id='fondo' alt='fondo' src={width > 768 ? "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fcurso-alta-especialidad%2FbannerDesk.png?alt=media&token=325c28d4-d367-4658-baca-24bf2c221e23" : "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fcurso-alta-especialidad%2FbannerMobile.png?alt=media&token=7affb6fd-72e6-45fc-8ab6-d239431d40bf"}/> */}
          </ColImage>
        </Row>
        <GreenDiv>
          <p>
            <span>ENVÍO DE DOCUMENTOS</span><br/>
            15 de Nov al 02 de Dic 2023<br/>academia@labopat.com
          </p>
        </GreenDiv>
      </Container>
    </BackBanner>
  )
};