import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { CreateAccountBanner } from './createAccountBanner';
import { ELabCreateAccountBanner } from './eLabCreateAccountBanner';
import { ELabRequestBanner } from './eLabRequestBanner';
import { ELabAlliesBanner } from './eLabAlliesBanner';
import { KnowELabBanner } from './knowELabBanner';
import { DocPanRespBanner } from './docPanRespBanner';
import axios from 'axios';
import { DocPanTotalBanner } from './docPanTotalBanner';
import { DocPanRespBactBanner } from './docPanRespBactBanner';
import { BannerRayosx } from './bannerRayosx';

// const Slides = [
//   {
//     id: 1,
//     title: 'Queremos ser ese puente  entre tú y tu paciente',
//     desc: 'Utiliza sin costo nuestro generador de solicitudes de estudios personalizado.',
//     textButton: 'Generar Solicitud',
//     urlButton: '/checkout?doctor=1',
//     img: one,
//   },
//   {
//     id: 2,
//     title: 'Queremos ser ese puente  entre tú y tu paciente',
//     desc: 'Recibe los resultados de tus pacientes en tu correo electrónico',
//     textButton: 'Ver pacientes',
//     urlButton: '/pacientes',
//     img: two,
//   },
//   {
//     id: 3,
//     title: 'Queremos ser ese puente  entre tú y tu paciente',
//     desc: 'Personaliza paquetes de estudios para tus pacientes',
//     textButton: 'Paquete de estudios',
//     urlButton: '/mis-paquetes',
//     img: three,
//   },
//   {
//     id: 4,
//     title: 'Queremos ser ese puente  entre tú y tu paciente',
//     desc: 'Resguarda el historial de tus pacientes y consulta su historial digital',
//     textButton: 'Ver pacientes',
//     urlButton: '/pacientes',
//     img: four,
//   },
//   {
//     id: 5,
//     title: 'Queremos ser ese puente  entre tú y tu paciente',
//     desc: 'Recibe boletines sobre nuevos estudios y pruebas',
//     textButton: 'Suscribirse',
//     urlButton: '#',
//     img: five,
//   },
// ]

const StyledSlider = styled(Slider)`
  position: relative;
  > .slick-slider {
    position: absolute;
    bottom: 0;
  }
  > button {
    display: none !important;
  } 
  > .slick-dots li {
    width: 8px;
    height: 8px;
    padding: 0px;
    background: #fff;
    border-radius: 4px;
    margin-bottom: 40px;
  }

  > .slick-dots li button:before {
    display: none;
  }

  > .slick-dots li.slick-active {
    width: 29px;
    background: #CEF719;
  }
`;

export const BannerDoc = () => {
  const [data, setData] = useState([]);

  useEffect(()=>{
    axios.get(`https://lpqsystem.com/get-studies-detail?estudios=581-1025-677`)
      .then(res => {
        setData(res?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },[]);

  return (
    <StyledSlider
      dots={true}
      infinite={true}
      slidesToShow={1}
      slidesToScroll={1}
      autoplay={false}
      autoplaySpeed={4000}
      cssEase={'linear'}
      speed={600}
    >
      {/*<DocPanRespBanner data={data?.find((e) => e.id === 581)}/>
      <DocPanRespBactBanner data={data?.find((e) => e.id === 1025)}/>
  <DocPanTotalBanner data={data?.find((e) => e.id === 677)}/>*/}
      <BannerRayosx/>
      <CreateAccountBanner/>
      <ELabCreateAccountBanner/>
      <ELabRequestBanner/>
      <ELabAlliesBanner/>
      <KnowELabBanner/>
      {/* {Slides.map((slide, index) => (
        <Slide data={slide} key={index}/>
      ))} */}
    </StyledSlider>
  );
};
