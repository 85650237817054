import {
  Container,
  Row,
  ListGroup,
  Col,
  Table,
  Button,
  Modal,
  FloatingLabel,
  Form,
} from "react-bootstrap";
import {
  UilEdit,
  UilTrash,
  UilSave,
  UilTimes,
  UilDownloadAlt,
  UilClipboardAlt,
} from "@iconscout/react-unicons";
import { useHistory } from "react-router-dom";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getPatientOrders } from "../../helpers/api";
import { HelmetTitle } from "../../helpers/HelmetTitle";
import axios from "axios";
import { Loader } from "../general/spinner";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import QRCode from "react-qr-code";
import { useForm } from "react-hook-form";
import { PatientDetailTable } from "./patientDetailTable";

const HeaderSection = styled.div`
  margin-top: 5%;
  display: flex;
  flex-direction: column;
`;

const HeaderTitle = styled.p`
  margin: 0;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;
  font-style: normal;
  color: #697077;
  text-align: left;
  @media only screen and (max-width: 768px) {
    font-size: 28px;
  }
`;

const StyledTable = styled(Table)`
  th {
    border-color: #4f90ba !important;
  }
  tr {
    border-color: rgba(79, 144, 186, 0.3);
  }
`;

const TableHeaderTitle = styled.p`
  margin: 0;
  font-family: "IBM Plex Sans", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 22px;
  color: #45494a;
`;

const NormalText = styled.p`
  margin: 0;
  font-family: "IBM Plex Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #3b3b3b;
`;

const DownloadText = styled.a`
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 800;
  font-size: 15px;
  line-height: 18px;
  text-decoration-line: underline;
  color: #4f90ba;
  text-align: left;
  margin: 0;
  width: auto;
  :hover {
    color: #4f90ba;
    text-decoration: none;
  }
`;

const DeleteButton = styled(Button)`
  max-width: 200px;
  max-height: 56px;
  background-color: #ffffff;
  margin-right: 20px;
  border: 1px solid #4D99E5;
  border-radius: 12px;
  font-family: "IBM Plex Sans", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #4D99E5;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 300ms;
  :hover {
    border: none;
    background: #4D99E5;
    .icon {
      fill: rgb(255, 255, 255);
    }
    transition: 300ms;
  }
`;

const StyledModal = styled(Modal)`
  .modal-header {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #4f90ba;
  }
  .btn-close {
    left: 93%;
  }
  .modal-content {
    padding: 10px;
  }
  .modal-title {
    font-weight: 900;
    font-size: 22px;
    line-height: 33px;
    margin-right: auto;
    color: #4f90ba;
  }
  .modal-footer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn-primary {
    background: #4f90ba;
    border: none;
  }
  .foot-btn {
    max-width: 368px;
    width: 90%;
    margin: 0 auto;
    border: 2px solid #4f90ba;
    border-radius: 18px;
  }
  .row {
    width: 100%;
  }
  span {
    font-weight: 800;
  }
`;

const DataForm = styled.form`
  width: 90%;
  > .form-floating {
    margin-top: 23px;
  }

  > div .form-control {
    border: none;
    background: #f6f6f6;
    border-radius: 12px;
    font-family: "IBM Plex Sans", sans-serif;
  }

  > .form-floating > label {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    color: #828282;
    transform-origin: 0 -10em;
    background: #f6f6f6;
    border-radius: 8px;
    padding: 2px 6px;
    margin-top: 14px;
    margin-left: 16px;
    height: auto;
    font-family: "IBM Plex Sans", sans-serif;
  }

  > .form-floating > .form-control {
    padding-left: 16px;
    padding-top: 0.625em;
  }

  > div input.form-check-input {
    width: 20px;
    height: 20px;
    border: 1px solid #00ad92;
  }

  > div input.form-check-input:checked {
    background-color: #00ad92;
  }
  > div input.form-check-input {
    width: 20px;
    height: 20px;
    border: 1px solid #00ad90;
  }

  > div input.form-check-input:checked {
    background-color: #00ad90;
  }

  > div .form-check label.form-check-label {
    margin-top: 3px;
    margin-left: 8px;
  }
`;

const TabTitle = styled.a`
  display: inline-block;
  width: 100%;
  margin-top: -10px;
  text-align: center;
  font-family: "IBM Plex Sans";
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
  line-height: 20px;
  transition: 200ms;
  :hover {
    color: ${(props) => (props.$isDoctor ? "#4F90BA" : "#00AC90")};
    transition: 200ms;
  }
`;

const TabIndicatorCol = styled(Col)`
  height: 10px;
  border-radius: 5px;
  margin-top: 10px;
  background: ${(props) =>
    props.selected && props.$isDoctor
      ? "linear-gradient(90deg, #5097E3 -2.05%, #0CAC8C 72.3%, #30DF99 101.61%, #CEF719 133.72%, #F5EC1D 211.91%)"
      : props.selected &&
        "linear-gradient(83.81deg, #F7EE1B -32.78%, #35E19B 24.37%, #00AE8E 75.27%, #5097E3 138.67%)"};
`;

const StyledNav = styled.nav`
  border-bottom: 1px solid #c1d9d8;
`;

const EmptyPatients = styled.div`
  height: 50vh;
`;

const EmptyIconContainer = styled.div`
  margin: 40px auto 24px;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  left: 110px;
  top: 112px;
  background: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EmptyPatientsText = styled.p`
  font-family: "IBM Plex Sans";
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #45494a;
  margin-bottom: 40px;
`;

export const PatientDetail = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [editing, setEditing] = useState(false);
  const params = useParams();
  const isDoctor =
    useSelector((state) => state.user?.data?.data?.profile?.type) === 1;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const history = useHistory();
  const { width } = useWindowDimensions();
  let isMobile = width <= 768;
  // const dispatch = useDispatch();
  // const { patientOrders } = useSelector((state) => state);

  // useEffect(() => {
  //   if (!patientOrders) {
  //     console.log('Getting patient orders');
  //     dispatch(getPatientOrders());
  //   }
  // }, [dispatch, patientOrders]);
  const uid = useSelector((state) => state?.auth?.uid);

  // Orders
  const [patients, setPatients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [finishedOrders, setFinishedOrders] = useState([]);
  const [pendingOrders, setPendingOrders] = useState([]);

  // Toogle
  const [tab, setTab] = useState(0);
  const dispatch = useDispatch();
  const { patientOrders } = useSelector((state) => state);

  useEffect(() => {
    dd();
    addOrder();
  }, []);

  const addOrder = async () => {
    try {
      setLoading(true);
      let requestPending = await axios.get(
        `https://lpqsystem.com/detalle-solicitud-pacienteM?id=${params.id}`
      );
      console.log(requestPending);
      setPendingOrders(requestPending.data.pendientes);
      setFinishedOrders(requestPending.data.historico);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (!patientOrders) {
      console.log("Getting patient orders");
      dispatch(getPatientOrders());
    }
  }, [dispatch, patientOrders]);

  const dd = async () => {
    try {
      setLoading(true);
      let requestPending = await axios.get(
        `https://lpqsystem.com/detalle-solicitud-paciente?id=${params.id}`
      );
      // console.log(requestPending.data);
      setPatients(requestPending.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const deletePatient = async () => {
    setLoading(true);
    try {
      // TODO: Change endpoint to patients
      let requestPending = await axios.get(
        `https://lpqsystem.com/mis-pacientes?user=${uid}&id=${params?.id}`
      );
      console.log(requestPending.data);
      setLoading(false);
      history.push("/pacientes");
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const onSubmit = async (datal) => {
    setLoading(true);
    try {
      // TODO: Change endpoint to patients
      let requestPending = await axios.get(
        `https://lpqsystem.com/editsolmed?id=${params?.id}&nombre=${datal?.nombre}&app=${datal?.app}&apm=${datal?.apm}`
      );
      // {id: params?.id, nombre:datal?.nombre, app: datal?.app, apm:datal?.apm}
      console.log(requestPending.data);
      setLoading(false);
      setEditing(false);
      dd();
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    // Set initial values with user info
    if (editing) {
      setValue("nombre", patients?.solicitud[0]?.solicitud?.nombre ?? "");
      setValue("app", patients?.solicitud[0]?.solicitud?.app ?? "");
      setValue("apm", patients?.solicitud[0]?.solicitud?.apm ?? "");
    }
  }, [editing]);

  return (
    <Container
      style={{ minHeight: "100vh" }}
      className={isMobile ? "mt-5 pt-4" : ""}
    >
      <HelmetTitle title="Pacientes" name="description" content="content" />
      <Row className="mb-5">
        <HeaderSection>
          <HeaderTitle>
            {patients.solicitud
              ? `${patients?.solicitud[0]?.solicitud?.nombre} ${patients?.solicitud[0]?.solicitud?.app} ${patients?.solicitud[0]?.solicitud?.apm}`
              : "Cargando..."}
          </HeaderTitle>
        </HeaderSection>
      </Row>
      {!editing && (
        <Row className="mb-4">
          <DeleteButton onClick={() => setEditing(true)}>
            <UilEdit /> &nbsp;&nbsp;Editar
          </DeleteButton>
          <DeleteButton onClick={() => setShowDeleteModal(true)}>
            <UilTrash /> &nbsp;&nbsp;Eliminar
          </DeleteButton>
        </Row>
      )}
      {editing && (
        <>
          <DataForm onSubmit={handleSubmit(onSubmit)}>
            <FloatingLabel controlId="floatingName" label="Nombre">
              <Form.Control
                type="text"
                {...register("nombre", {
                  required: false,
                })}
                placeholder="Nombre"
              />
            </FloatingLabel>
            <FloatingLabel controlId="floatingApp" label="Apellido Paterno">
              <Form.Control
                type="text"
                {...register("app", {
                  required: false,
                })}
                placeholder="Apellido Paterno"
              />
            </FloatingLabel>
            <FloatingLabel controlId="floatingApm" label="Apellido Materno">
              <Form.Control
                type="text"
                {...register("apm", {
                  required: false,
                })}
                placeholder="Apellido Materno"
              />
            </FloatingLabel>
            <div className="d-flex w-100 mb-4 mt-4">
              <DeleteButton type="submit">
                <UilSave /> &nbsp;&nbsp;Guardar cambios
              </DeleteButton>
              <DeleteButton onClick={() => setEditing(false)}>
                <UilTimes /> &nbsp;&nbsp;Cancelar
              </DeleteButton>
            </div>
          </DataForm>
        </>
      )}
      <Row className="mb-5">
        <HeaderSection>
          <HeaderTitle className="mb-5">Historial médico</HeaderTitle>
          <StyledNav>
            <Row className="m-0 p-0">
              <Col xs={6} md={3} lg={3} xl={3} style={{ paddingLeft: "0" }}>
                <TabTitle
                  onClick={() => setTab(0)}
                  selected={tab === 0 ? true : false}
                  $isDoctor={isDoctor}
                >
                  Órdenes Generadas
                  <TabIndicatorCol
                    onClick={() => setTab(0)}
                    selected={tab === 0 ? true : false}
                    $isDoctor={isDoctor}
                  />
                </TabTitle>
              </Col>
              <Col xs={6} md={3} lg={3} xl={3} style={{ paddingLeft: "0" }}>
                <TabTitle
                  onClick={() => setTab(1)}
                  selected={tab === 1 ? true : false}
                  $isDoctor={isDoctor}
                >
                  Resultados
                  <TabIndicatorCol
                    onClick={() => setTab(1)}
                    selected={tab === 1 ? true : false}
                    $isDoctor={isDoctor}
                  />
                </TabTitle>
              </Col>
            </Row>
          </StyledNav>
        </HeaderSection>
      </Row>
      {tab === 0 && (
        <Row>
          {pendingOrders.length > 0 ? (
            <PatientDetailTable
              data={pendingOrders}
              tab={tab}
              isdoctor={isDoctor}
              itemsPerPage={10}
            />
          ) : (
            <EmptyPatients>
              <EmptyIconContainer>
                <UilClipboardAlt color="#A6A6A6" size={100} />
              </EmptyIconContainer>
              <EmptyPatientsText>
                No tienes resultados pendientes
              </EmptyPatientsText>
            </EmptyPatients>
          )}
        </Row>
      )}
      {tab === 1 && (
        <Row>
          {finishedOrders.length > 0 ? (
            <PatientDetailTable
              data={finishedOrders}
              tab={tab}
              isdoctor={isDoctor}
              itemsPerPage={10}
            />
          ) : (
            <EmptyPatients>
              <EmptyIconContainer>
                <UilClipboardAlt color="#A6A6A6" size={100} />
              </EmptyIconContainer>
              <EmptyPatientsText>No tienes resultados</EmptyPatientsText>
            </EmptyPatients>
          )}
        </Row>
      )}
      {/*<Row>
        <div>
          <StyledTable responsive="md" style={{ maxWidth: "872px" }}>
            <thead>
              <tr>
                <th>
                  <TableHeaderTitle># orden</TableHeaderTitle>
                </th>
                <th>
                  <TableHeaderTitle>Fecha</TableHeaderTitle>
                </th>
                <th>
                  <TableHeaderTitle>Estatus</TableHeaderTitle>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {patients?.solicitud?.map((patient, index) => (
                <tr key={index}>
                  <td className="pt-4 pb-4">
                    <NormalText>{patient.drive.folio}</NormalText>
                  </td>
                  <td className="pt-4 pb-4">
                    <NormalText>{patient.drive.fechaYhora}</NormalText>
                  </td>
                  <td className="pt-4 pb-4">
                    {patient.solicitud.fase == 3 ||
                    (patient.solicitud.fase == 4 &&
                      patient.solicitud.estatus !== 6 &&
                      patient.solicitud.casoLB == null) ? (
                      <NormalText>En proceso</NormalText>
                    ) : patient.solicitud.estatus == 6 ||
                      (patient.solicitud.estatus == 7 &&
                        patient.solicitud.fase == 4 &&
                        patient.solicitud.casoLB !== null) ? (
                      <NormalText className="mb-4 d-block">
                        Finalizado
                      </NormalText>
                    ) : (
                      <></>
                    )}
                  </td>
                  <td className="pt-4 pb-4">
                    {patient.solicitud.fase == 3 ||
                    (patient.solicitud.fase == 4 &&
                      patient.solicitud.estatus !== 6 &&
                      patient.solicitud.casoLB == null) ? (
                      patient.drive.tipopago === 3 ? (
                        <DownloadText
                          href={`https://lpqsystem.com/getReceta?id=${patient.drive.id}`}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          <div className="d-flex flex-row w-auto">
                            <span
                              className="d-block w-auto"
                              style={{ marginRight: "10px" }}
                            >
                              Descargar
                            </span>
                            <UilDownloadAlt
                              size={18}
                              color={"#4F90BA"}
                              className="w-auto"
                            />
                          </div>
                        </DownloadText>
                      ) : (
                        <DownloadText
                          href={`https://lpqsystem.com/comprobante-ecommerce?id=${patient.drive.id}`}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          <div className="d-flex flex-row w-auto">
                            <span
                              className="d-block w-auto"
                              style={{ marginRight: "10px" }}
                            >
                              Descargar
                            </span>
                            <UilDownloadAlt
                              size={22}
                              color={"#4F90BA"}
                              className="w-auto"
                            />
                          </div>
                        </DownloadText>
                      )
                    ) : patient.solicitud.estatus == 6 ||
                      (patient.solicitud.estatus == 7 &&
                        patient.solicitud.fase == 4 &&
                        patient.solicitud.casoLB !== null) ? (
                      <DownloadText
                        href={`https://lpqsystem.com/downloadReporte?idCaso=${patient.solicitud.idLB}`}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <div className="d-flex flex-row w-auto">
                          <span
                            className="d-block w-auto"
                            style={{ marginRight: "10px" }}
                          >
                            Descargar
                          </span>
                          <UilDownloadAlt
                            siz
                            e={18}
                            color={"#4F90BA"}
                            className="w-auto"
                          />
                        </div>
                      </DownloadText>
                    ) : (
                      <></>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </StyledTable>
        </div>
        </Row>*/}
      {loading ? <Loader show={loading} /> : null}
      <StyledModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Eliminar Paciente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            ¿Estás seguro de eliminar al paciente: "
            {patients?.solicitud
              ? `${patients?.solicitud[0]?.solicitud?.nombre} ${patients?.solicitud[0]?.solicitud?.app} ${patients?.solicitud[0]?.solicitud?.apm}`
              : ""}
            " del Expediente de pacientes?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Button
              className="foot-btn"
              variant="primary"
              onClick={() => deletePatient()}
            >
              Eliminar
            </Button>
          </Row>
          <Row>
            <Button
              style={{
                color: "#4F90BA",
                background: "none",
                marginTop: "15px",
              }}
              className="foot-btn"
              variant="light"
              onClick={() => setShowDeleteModal(false)}
            >
              Regresar
            </Button>
          </Row>
        </Modal.Footer>
      </StyledModal>
    </Container>
  );
};