import React from 'react';
import ReactGA from 'react-ga';

const useAnalyticsEventTracker = (category = 'Web action') => {
  const eventTracker = (action = 'web action', label = 'action in web') => {
    ReactGA.event({ category, action, label });
  };
  return eventTracker;
};
export default useAnalyticsEventTracker;
