import React, { useState } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { UilAngleDown, UilAngleUp } from '@iconscout/react-unicons';
import { TEXT_BLACK } from '../../helpers/colors';

const ButtonMenu = styled.button`
  color: black;
  width: 105%;
  padding-left: 0px;
  padding-right: 0px;
  background-color: #F1F6F6;
  border: none;
  display: flex;
  justify-content: space-between;
`;

const Description = styled.p`
  color: ${TEXT_BLACK};
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
  margin-top: 15px;
  padding-left: 8px;
`;

const LinkMenu = styled.a`
  text-align: inherit;
  color: black;
  background-color: #F1F6F6;
  border: none;
  @media only screen and (min-width: 586px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    > div {
      justify-content: center;
      > img {
        margin-right: 10px;
      }
    }
  }
  > div span {
    @media only screen and (max-width: 586px) {
      position: absolute;
      left: 60px;
    }
  }
`;

const Title = styled.span`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  /* identical to box height, or 200% */
  color: #000000;
`;

const MenuTitle = styled.span`
  color: #00ad90;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
`;
const LinkContainer = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
  border: none;
  border-top: 1px solid #D0DBDB;
`;

export const MobileSubmenu = (props) => {
  // state for academia
  const [showSubMenuAcademia, setShowSubMenuAcademia] = useState(false);

  const opening = () => {
    if(props.menu.name === 'Academia'){
      setShowSubMenuAcademia(!showSubMenuAcademia);
    }else{
      props.setOpened(!props.opened);
    }
  };
  return (
    <Row style={{ width: '100%', marginLeft: 0 }}>
      {/* <Col
        md={12}
        style={{ padingLeft: 0, padingRight: 0 }}
        className='d-flex flex-row-reverse bd-highlight'
      >
      </Col> */}
      <Col md={12} style={{ paddingLeft: 0 }}>
        <ButtonMenu variant='light' onClick={() => opening()}>
          <Title>{props.menu.name}</Title>
          {(props.opened && props.menu.name !== 'Academia') || (showSubMenuAcademia && props.menu.name === 'Academia') ? (
            <UilAngleUp size='32' color='#00AD90' />
          ) : (
            <UilAngleDown size='32' color='#00AD90' />
          )}
        </ButtonMenu>
      </Col>

      {(props.opened && props.menu.name !== 'Academia') || (showSubMenuAcademia && props.menu.name === 'Academia') ? (
        <Col md={12} className='mt-3'>
            <>
              {props.menu.submenu.map((item, index) => (
                <LinkContainer key={index}>
                  <LinkMenu href={item.link}>
                    <div
                      className='d-flex align-items-center'
                      style={{ position: 'relative', paddingLeft: '10px' }}
                    >
                      {item.icon &&
                        <img
                          src={item.icon}
                          className='img-fluid'
                          alt='category-icon'
                          width={item.width}
                        />
                      }
                      <MenuTitle>{item.name}</MenuTitle>
                    </div>
                    {/* <Description>{item.description}</Description> */}
                  </LinkMenu>
                </LinkContainer>
              ))}
            </>
        </Col>
      ) : null}
    </Row>
  );
};
