import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { Table, Modal, Button, Row } from 'react-bootstrap';
import { UilTimes } from '@iconscout/react-unicons';
import QRCode from 'react-qr-code';
import ReactPaginate from 'react-paginate';

const StyledTable = styled(Table)`
  th {
    border-color: ${props => props.isdoctor === 1 ? '#4F90BA' : '#00AC90'} !important;
  }
  tr {
    border-color: ${props => props.isdoctor === 1 ? 'rgba(79, 144, 186, 0.3)' : '#C1D9D8'};
  }
`;

const TableHeaderTitle = styled.p`
  margin: 0;
	font-family: 'IBM Plex Sans', sans-serif;
	font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 22px;
  color: #45494A;
`;

const NormalText = styled.p`
  margin: 0;
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #3B3B3B;
`;

const DownloadText = styled.a`
	font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 800;
  font-size: 15px;
  line-height: 18px;
	text-decoration-line: underline;
  color: ${props => props.isdoctor === 1 ? '#4F90BA' : '#00AC90'};
  text-align: left;
  margin: 0;
  width: auto;
  :hover {
    color: ${props => props.isdoctor === 1 ? '#4F90BA' : '#00AC90'};
    text-decoration: none;
  }
`;

const OpenQrBtn = styled.a`
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 800;
  font-size: 15px;
  line-height: 18px;
	text-decoration-line: underline;
  color: ${props => props.isdoctor === 1 ? '#4F90BA' : '#00AC90'};
  text-align: left;
  margin: 0;
  width: auto;
  :hover {
    color: ${props => props.isdoctor === 1 ? '#4F90BA' : '#00AC90'};
    text-decoration: none;
  }
`;

const StyledModal = styled(Modal)`
  .modal-dialog {
    display: flex;
    align-items: center;
    @media only screen and (max-width: 768px) {
      margin-top: 20%;
    }
  }
  .modal-header {
    border-bottom: ${props => props.isdoctor === 1 ? 
    '1px solid rgba(79, 144, 186, 0.3)' : '1px solid #C1D9D8'};
  }
  .modal-title {
    font-weight: 900;
    font-size: 16px;
    line-height: 24px;
    color: ${props => props.isdoctor === 1 ? '#4F90BA' : '#00AC90' };
  }
  p {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #717171;
    span {
      font-weight: 800;
    }
  }
  .btn-close {
    width: 40px;
    height: 40px;
    background: ${props => props.isdoctor === 1 ? '#4F90BA' : '#00AC90'};
    border-radius: 50%;
    padding: 0px !important;
    top: -13px;
    right: 33px;
    opacity: 1;
  }
  .closeIcon {
    z-index: 1000;
    top: -13px;
    right: 33px;
    position: absolute;
  }
`;

const WhiteButton = styled(Button)`
  color: #FFFFFF;
  background-color: ${props => props.isdoctor === 1 ? '#4F90BA' : '#00AC90'};
  width: 241px;
  height: 40px;
  border: none;
  border-radius: 102px;
  margin-top: 16px;
  margin-bottom: 0px;
  :hover {
    background-color: #cccccc;
  }
`;

const StyledPaginator = styled(ReactPaginate)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: auto;
  .page, .break {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0;
    width: auto;
    max-width: 20px;
    margin: 0 10px 0 10px;
    a {
      font-family: 'IBM Plex Sans', sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      padding-left: 10px;
      padding-right: 10px;
      color: #000000;
    }
  }
  .active {
    position: relative;
    ::after {
      content: "";
      width: 16.82px;
      height: 4px;
      background-color: ${props => props.isdoctor ? '#4F90BA' : '#00AC90'};
      position: absolute;
      bottom: -4px;
      border-radius: 81px;
      border: none;
    }
  }
  .prev {
    display: block !important;
    margin: 0 15px 0 0;
    width: auto;
    max-width: 20px;
    a {
      color: ${props => props.isdoctor ? '#4F90BA' : '#00AC90'};
    }
  }
  .next {
    display: block !important;
    margin: 0 0 0 15px;
    width: auto;
    max-width: 20px;
    a {
      color: ${props => props.isdoctor ? '#4F90BA' : '#00AC90'};
    }
  }
`;

export const MedicalRecordTable = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [order, setOrder] = useState();
  const [currentItems, setCurrentItems] = useState(null);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    const endOffset = itemOffset + props.itemsPerPage;
    setCurrentItems(props.data?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(props.data?.length / props.itemsPerPage));
  }, [itemOffset, props.itemsPerPage]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * props.itemsPerPage) % props.data?.length;
    setItemOffset(newOffset);
  };

  return(
    <>
      <div className='mb-5' style={{minHeight: '410px'}}>
        <StyledTable responsive='md' style={{maxWidth: '872px'}} isdoctor={props.isdoctor ? 1 : 0}>
          <thead>
            <tr>
              <th><TableHeaderTitle># orden</TableHeaderTitle></th>
              <th><TableHeaderTitle>Fecha de creación</TableHeaderTitle></th>
              <th></th>
              {props.tab === 0 && (
                <th></th>
              )}
            </tr>
          </thead>
          <tbody>
            {currentItems?.map((order, index) => (
              <tr key={index}>
                <td className='pt-4 pb-4'>
                  <NormalText>
                    {order.drive.folio}
                  </NormalText>
                </td>
                <td className='pt-4 pb-4'>
                  <NormalText>
                    {order.drive.created_at}
                  </NormalText>
                </td>
                {props.tab === 0 && (
                  <>
                    <td className='pt-4 pb-4'>
                        <OpenQrBtn isdoctor={props.isdoctor ? 1 : 0} 
                          onClick={() => {
                            setShowModal(true); 
                            setOrder(order.drive.id.toString());}}
                          >
                          Ver código QR
                        </OpenQrBtn>
                    </td>
                    <td className='pt-4 pb-4'>
                    {order.drive.tipopago === 3 ? (
                      <DownloadText 
                        isdoctor={props.isdoctor ? 1 : 0}
                        href={`https://lpqsystem.com/getReceta?id=${order.drive.id}`}
                        target='_blank'
                        rel='noreferrer noopener'>
                        Descargar
                      </DownloadText>
                    ) : (
                      <DownloadText 
                        isdoctor={props.isdoctor ? 1 : 0}
                        href={`https://lpqsystem.com/comprobante-ecommerce?id=${order.drive.id}`}
                        target='_blank'
                        rel='noreferrer noopener'>
                        Descargar
                      </DownloadText>
                    )}
                    </td>
                  </>
                )}
                {props.tab === 1 && (
                  <td className='pt-4 pb-4'>
                    {order.solicitud.estatus === '6' ||
                    order.solicitud.estatus === '7' ? (
                    <DownloadText
                      isdoctor={props.isdoctor ? 1 : 0}
                      href={`https://lpqsystem.com/downloadReporte?idCaso=${order.solicitud.idLB}`}
                      target='_blank'
                      rel='noreferrer noopener'>
                      Descargar resultados
                    </DownloadText>
                    ) : (
                      <NormalText>Resultados pendientes</NormalText>
                    )}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </StyledTable>
        {showModal && (
          <StyledModal
            isdoctor={props.isdoctor ? 1 : 0}
            show={showModal}
            onHide={() => {
              setShowModal(false);
            }}
          >
            <div onClick={()=>setShowModal(false)}>
              <UilTimes color="#fff" style={{cursor: 'pointer'}} className='closeIcon' />
            </div>
            <Modal.Header closeButton>
              <Modal.Title>Código de tu compra</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='p-1 d-flex flex-column align-items-center'>
                <NormalText className='text-center mb-4'>
                  Muestra este código en la caja para acumular los puntos de tu estudio.
                </NormalText>
                <QRCode value={order} className='mb-2' />
                <NormalText className='mb-4'>
                  Es necesario aumentar el brillo de la pantalla
                </NormalText>
              </div>
              <WhiteButton 
                variant="light" 
                isdoctor={props.isdoctor ? 1 : 0}
                onClick={()=>setShowModal(false)}>Cerrar</WhiteButton>
            </Modal.Body>
          </StyledModal>
          )}
      </div>
      {props.data?.length > 10 && (
        <Row className='justify-content-center mb-4' style={{maxWidth: '872px'}}>
          <StyledPaginator
            isdoctor={props.isdoctor}
            breakLabel='...'
            nextLabel='>'
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            previousClassName='prev'
            nextClassName='next'
            pageClassName='page'
            activeClassName="active"
          />
        </Row>
      )}
    </>
  );
};