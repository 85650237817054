import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';

const Background = styled.div`
  background: linear-gradient(90deg, #5097E3 -8.82%, #0CAC8C 74.01%, #30DF99 99.88%, #CEF719 128.77%, #F5EC1D 138.96%);
`;

const Slide = styled(Container)`
  height: 460px;
  position: relative;
  h1, h2 {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 600;
    font-size: 46px;
    line-height: 130%;
    color: #FFFFFF;
    margin-bottom: 18px;
  }
  p {
    font-family: 'IBM Plex Sans';
    font-weight: 400;
    font-size: 40px;
    line-height: 130%;
    color: #FFFFFF;
    margin-bottom: 43px;
    max-width: 628px;
  }
  #text-col {
    z-index: 2;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    .row {
      width: 100%;
    }
  }
  #img-col {
    z-index: 1;
    height: 460px;
    display: flex;
    align-items: end;
    justify-content: center;
  }
  img {
    width: 110%;
    max-width: 600px;
    max-height: 750px;
  }
  @media only screen and (max-width: 992px) {
    #img-col {
      height: 520px;
    }
    h1, h2 {
      font-size: 32px;
      margin-bottom: 15px;
      margin-top: 15px;
    }
    p {
      font-size: 30px;
    }
  }
  @media only screen and (max-width: 992px) {
    height: 520px;
  }
  @media only screen and (max-width: 768px) {
    padding: 20px 0 0;
    h1, h2 {
      width: 100%;
      text-align: center;
    }
    p {
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      margin-bottom: 20px;
      line-height: 120%;
      max-width: 350px;
      padding: 0 10px;
    }
    img {
      width: 70%;
      max-width: 250px;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
`;

const TransparentButton = styled.button`
  padding: 5px 28px;
  border: 3px solid #CEF719;
  background: none;
  border-radius: 12px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 450;
  font-size: 29px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #CEF719;
  margin-bottom: 25px;
  transition: all 0.4s ease;
  :hover {
    background: #CEF719;
    color: #00AC90;
  }
  @media only screen and (max-width: 1150px) {
    font-size: 25px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const ELabCreateAccountBanner = () => {
  return (
    <Background>
      <Slide>
        <Row>
          <Col xs={{span:12, order: 2}} md={{span: 6, order: 1}} id='img-col'>
            <img src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2FdoctorsSlider%2FdraPhone.png?alt=media&token=1dc99687-a0fd-41c0-95b0-33c360e2a2e1" alt="doctora" />
          </Col>
          <Col xs={{span:12, order: 1}} md={{span: 6, order: 2}} id='text-col'>
            <h2>labopat.mx</h2>
            <p>La herramienta tecnológica para un diagnóstico confiable.</p>
            <TransparentButton onClick={()=>window.location.href = '/registro'}>Crear cuenta</TransparentButton>
          </Col>
        </Row>
      </Slide>
    </Background>
  );
};
