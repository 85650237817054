import { types } from "../types/types";

export const patientOrdersReducer = (state = null, action) => {
    switch (action.type) {
        case types.patientOrders:
            return action.payload;

        default:
            return state;
    }
}