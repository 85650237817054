import React from "react";
import styled from "styled-components";
import { TEXT_BLACK } from "../../helpers/colors";
import { UilEnvelopeMinus, UilPhone } from '@iconscout/react-unicons';

const IconContainer = styled.div`
  width: 40px; /* Icon width */
  svg {
    color: #30DF99;
    margin-right: 10px;
    margin-top: -3px;
  }
`;

const TextContainer = styled.div`
  flex-grow: 1;
  cursor: pointer;
`;

const Agreements = styled.div`
  width: 100%;
  background: var(--Aqua, #30DF99);
  display: flex;
  padding: 8px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  color: #FFF;
  font-family: IBM Plex Sans;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 27px;
`;

const AgreementsInfo = styled.div`
  width: 100%;
  max-width: 611px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;
  > div {
    display: flex;
    gap: 10px; /* Adjust the gap as needed */
    p {
      color: ${TEXT_BLACK};
      font-size: 18px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0.18px;
      span {
        font-size: 20px;
        font-weight: 600;
      }
    }
  }
  @media only screen and (width <= 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const AgreementAgs = () => {

  const handleEmailClick = (emailAddress) => {
    window.location.href = `mailto:${emailAddress}`;
  };

  return (
    <div style={{backgroundColor: "#FFF"}}>
      <Agreements>
        Convenios y cotizaciones
      </Agreements>
      <AgreementsInfo>
        <div>
          <IconContainer>
            <UilPhone size={40} />
          </IconContainer>
          <TextContainer>
          <a href="tel:+526142783489"><p>614 278 3489</p></a>
          </TextContainer>
        </div>
        <div>
          <IconContainer>
            <UilEnvelopeMinus size={40} />
          </IconContainer>
          <TextContainer onClick={() => handleEmailClick('mayra.labopat@gmail.com')}>
            <p>mayra.labopat@gmail.com</p>
          </TextContainer>
        </div>
      </AgreementsInfo>
    </div>
  )
};