import { types } from "../types/types";

const initialState = { items: [], totalPrice: 0 };

export const shoppingReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.addItemToCart:
            return {
                ...state,
                items: action.payload
            };

        case types.removeItemFromCart:
            return {
                ...state,
                items: action.payload
            }

        case types.totalPrice:
            return {
                ...state,
                totalPrice: action.payload,
            }            

        case types.addPersonalData:
            return {
                ...state,
                personalData: action.payload
            };

        case types.resetCart:
            return initialState;
    
        default:
            return state;
    }
};