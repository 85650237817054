import React from 'react';
import { ProfileMenu } from '../components/login/ProfileMenu';

export const Profile = () => {

    return (
        <>
            <ProfileMenu />
        </>
    );
}
