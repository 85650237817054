import { types } from "../types/types"

export const uiStartLoading = () => ({
    type: types.uiStartLoading
});

export const uiFinishLoading = () => ({
    type: types.uiFinishLoading
});

export const uiSetError = (msg) => ({
    type: types.uiSetError,
    payload: msg
});

export const uiRemoveError = () => ({
    type: types.uiRemoveError
});