import React from 'react';
import { Col, Button, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { UilInvoice } from '@iconscout/react-unicons';
import { useSelector } from 'react-redux';

export const Checked = ( ) => {
  const { currentCheckin } = useSelector((state) => state);
  // Transform number to currency
  const currency = function (number) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    }).format(number);
  };

  return (
    <>
      <CardDiv>
        <h1>
          Bienvenid{currentCheckin?.genero === 'Mas' ? 'o' : 'a'},{' '}
          {currentCheckin?.name}
        </h1>
        <h1>Ya se realizó el check-in de esta solicitud</h1>
        <h2>En caso de requerir ayuda, dirígete a recepción</h2>
      </CardDiv>
      <InvertedRadius>
        <p style={{ fontSize: '16px', lineHeight: '24px', color: '#3B3B3B' }}>
          Resumen de solicitud
        </p>
        {currentCheckin?.estudios?.map((study, index)=>
          <div className='d-flex w-100'>
            <p>{index+1}. {study.laminilla}</p><p style={{marginLeft: 'auto'}}>{currency(study.precioNeto)} MXN</p>
          </div>
        )}
        <DateP>
          Fecha de mi toma de muestras
          <p>{currentCheckin?.drive?.fechaYhora}</p>
        </DateP>
        <hr className='pointed' />
        <div className='bottom left'></div>
        <div className='bottom right'></div>
        <Row
          style={{
            width: '80%',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '48px',
          }}
        >
          <Col
            xs={8}
            md={{ span: 5, offset: 2 }}
            style={{ textAlign: 'center' }}
          >
            <p
              style={{
                fontWeight: '800',
                marginBottom: '0px',
                fontSize: '16px',
              }}
            >
              Total:
            </p>
            <p style={{ fontSize: '16px', marginBottom: '0px' }}>
              {currency(
                currentCheckin?.estudios.reduce(
                  (n, { precioNeto }) => n + precioNeto,
                  0
                )
              )}{' '}
              MXN
            </p>
          </Col>
          <Col
            xs={4}
            md={3}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <UilInvoice />
          </Col>
        </Row>
      </InvertedRadius>
      <Row className='fw'>
        <PayButton onClick={() => window.location.href="/checkin"}>
          Regresar
        </PayButton>
      </Row>
    </>
  );
};

const CardDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 30px;
  > div svg {
    color: #00ad90;
  }
  > h1 {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 900;
    font-size: 22px;
    line-height: 33px;
    text-align: center;
    color: #00ad90;
  }
  > h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    color: #00ad90;
  }
`;

const PayButton = styled(Button)`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  width: 241px;
  height: 40px;
  background: #00ad90;
  border: none;
  border-radius: 102px;
  margin-top: 28px;
  margin-bottom: 24px;
  margin-left: auto;
  margin-right: auto;
  :focus {
    background: #006d67;
  }
  :hover {
    background: #00ad90;
  }
  :disabled {
    background: #e2e2e2;
    color: #aaa;
  }
`;

const InvertedRadius = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  @media only screen and (min-width: 769px) {
    width: 100%;
  }
  padding: 16px;
  background-color: #f1f6f6;
  position: relative;
  overflow: hidden;
  border-radius: 16px;
  > div.bottom {
    position: absolute;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    background-color: #fff;
  }
  > .top {
    top: 12px;
  }
  > .bottom {
    bottom: 64px;
  }
  > .left {
    left: -16px;
  }
  > .right {
    right: -16px;
  }
  > hr.pointed {
    position: absolute;
    bottom: 65px;
    margin-top: 0px;
    width: 100%;
    height: 0;
    border: medium none;
    border-top: 2px dashed #e0ecec;
  }
  > div div.col-3 svg {
    width: 30px !important;
    height: 30px !important;
  }
  > hr {
    margin-left: auto;
    margin-right: auto;
    width: 296px;
  }
`;

const DateP = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #3b3b3b;
`;
