import React, { useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';
import { UilCheck, UilEditAlt } from '@iconscout/react-unicons';
import { EditPackageDrawer } from './editPackageDrawer';

const Title = styled.h1`
  font-weight: 900;
  font-size: 22px;
  line-height: 33px;
  text-align: center;
  color: #000000;
  margin-bottom: 24px;
`;

const Done = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 86px;
  height: 86px;
  border-radius: 43px;
  background: linear-gradient(180deg, #C2C4C5 0%, #87B5D2 100%);
`;

const Sub = styled.p`
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  color: #45494A;
  margin: 30px auto;
  max-width: 600px;
  span {
    cursor: pointer;
    font-weight: 800;
    color: #00AD90;
    text-decoration: underline;
  }
`;

const ContinueButton = styled(Button)`
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  width: 241px;
  height: 40px;
  background: #00ad90;
  border: none;
  border-radius: 102px;
  margin-top: 16px;
  margin-bottom: 0px;
  :focus {
    background: #0a7a71;
  }
  :hover {
    background: #00ad90;
  }
  :disabled {
    background: #e2e2e2;
    color: #aaa;
  }
`;

const Package = styled.div`
  position: relative;
  padding: 16px;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  background: #FFFFFF;
  border: 1px solid #E9E9E9;
  box-shadow: 0px 4px 4px rgba(71, 74, 75, 0.15);
  border-radius: 12px;
  h1 {
    font-weight: 500;
    font-size: 22px;
  }
  #edit {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background: #E0ECEC;
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }
`;

const Item = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 15px 0;
  position: relative;
  p {
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: #B5B5B5;
    margin-bottom: 4px;
  }
  span {
    font-weight: 800;
    font-size: 20px;
    line-height: 30px;
    color: #00AC90;
  }
  border-bottom: 1px solid rgba(196, 196, 196, 0.5);
  h1 {

  }
`;

export const StepThree = (props) => {
  const history = useHistory();
  const [showEditDrawer, setShowEditDrawer] = useState(false);
  const currency = function (number) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    }).format(number);
  };

  return (
    <>
      <Title>3. Comparte el paquete</Title>
      <Done>
        <UilCheck size={60} color="#fff"/>
      </Done>
      <Sub>¡Eso es todo! Ahora puedes compartir el paquete con tus pacientes o <span onClick={()=> history.push('/mis-paquetes')}>ver tus paquetes guardados</span></Sub>
      <Package>
        <h1>{props.data.name}</h1>
        {/* <div id='edit'>
          <UilEditAlt color="#00AC90" onClick={()=>setShowEditDrawer(true)}/>
        </div> */}
        <p>Incluye:</p>

        {props.data?.studies?.map((study, index)=>
          <Item key={index}>
            <p>En línea: {currency(study.price)} MXN</p>
            <span>{index+1}. {study.title}</span>
            {/* <div onClick={()=>handleDeleteItem(study.id)} id="delete-study">x</div> */}
          </Item>
        )}

        <Row className='justify-content-center'>
          <ContinueButton
            variant='primary'
            onClick={()=>history.push('/mis-paquetes')}
            >
            Compartir
          </ContinueButton>
        </Row>
      </Package>
      <EditPackageDrawer onClose={()=>{setShowEditDrawer(false)}} visible={showEditDrawer} data={props.data}/>
    </>
  );
}