import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Modal } from "react-bootstrap";
import styled from "styled-components";
import { PackageCard } from "../packages/packageCard";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { HelmetTitle } from "../../helpers/HelmetTitle";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Link } from "react-router-dom";
import { Searcher } from "../navbar/searcher";
import { PrivRoutes } from "../../routes/routes";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import {
  UilClipboardAlt,
  UilTimes,
  UilTrashAlt,
} from "@iconscout/react-unicons";
import useAnalyticsEventTracker from "../../analytics/useAnalyticsEventTracker";
import {
  getTotalPrice,
  removeItemFromCart,
  setTotalPrice,
} from "../../actions/shoppingCart";
import { updateCartDB } from "../../helpers/api";
import { ListItemCart } from "../shopping-cart/listItemCart";

const logoB = "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2Flogos%2Flogo1.png?alt=media&token=dffc6866-cf6d-4c14-a494-60ceaf3f3219";

const DesktopLogo = styled.img`
  margin: auto;
  display: block;
  height: 40px;
  position: absolute;
  left: 20px;
  @media only screen and (max-width: 768px) {
    width: 50px;
  }
`;

const AddPackageButon = styled(Button)`
  max-width: 368px;
  max-height: 56px;
  padding-top: 12px;
  margin-top: 10px;
  padding-bottom: 12px;
  border: 2px solid transparent;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(
    90deg,
    #5097e3 -2.05%,
    #0cac8c 72.3%,
    #30df99 101.61%,
    #cef719 133.72%,
    #f5ec1d 211.91%
  );
  border-radius: 10px;
  font-family: "IBM Plex Sans", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  display: flex;
  color: #fff;
  align-items: center;
  justify-content: center;
  transition: 300ms;
  .icon {
    fill: rgb(255, 255, 255);
  }
  :hover {
    .icon {
      fill: #4f90ba;
    }
    background: #fff;
    transition: 300ms;
    color: #4d99e5;
  }
`;

const NoPackages = styled.div`
  padding: 20px;
  background: #fff;
  border-radius: 20px;
  margin-bottom: 20px;
  max-width: 944px;
  span {
    font-weight: 800;
  }
  img {
    width: 120%;
  }
  @media only screen and (max-width: 768px) {
    img {
      width: 100%;
    }
  }
  row {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #text-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const EmptyCart = styled.div`
  #empty {
    margin: 40px auto 24px;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    left: 110px;
    top: 112px;
    background: #f9f9f9;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 27px;
    text-align: center;
    color: #474a4b;
    margin-bottom: 60px;
  }
`;

const CardCont = styled.div`
  max-width: 100%;
  max-height: auto;
  padding: 0rem;
  //margin-left: 800px;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(45deg, #fff, #fff),
    linear-gradient(
      90deg,
      #5097e3 -2.05%,
      #0cac8c 72.3%,
      #30df99 101.61%,
      #cef719 133.72%,
      #f5ec1d 211.91%
    );
  border: 10 px;
  border: 2px solid transparent;
  border-radius: 12px;
  position: absolute;
  @media only screen and (max-width: 1900px) {
    margin-top: 0%;
  }
  @media only screen and (max-width: 1400px) {
    margin-top: -15%;
  }
`;

const WhiteButton1 = styled.button`
  width: 100%;
  //margin-left: 20%;
  height: 40px;
  background: none;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  margin-top: -5px;
  transition: all 0.4s ease;
  :hover {
    color: #fff;
    transition: all 0.4s ease;
    background: ${(props) => props.mainColor};
  }
`;

const ListDiv = styled.div`
  overflow-y: auto;
  //padding-right: 7px;
  margin-right: 5px;
  max-height: 50vh;
  @media only screen and (max-width: 1600px) {
    max-height: 40vh;
  }
  @media only screen and (max-width: 1400px) {
    max-height: 68vh;
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 117, 98, 0.3);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const ListDat = styled.div`
  overflow-y: auto;
  //padding-right: 7px;
  margin-right: 5px;
  max-height: 70vh;
  @media only screen and (max-width: 1600px) {
    max-height: 60vh;
  }
  @media only screen and (max-width: 1400px) {
    max-height: 65vh;
  }
  @media only screen and (max-width: 768px) {
    max-height: 53vh;
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 117, 98, 0.3);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const CardDiv = styled.div`
  padding: 10px;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(45deg, #fff, #fff),
    linear-gradient(
      90deg,
      #5097e3 -2.05%,
      #0cac8c 72.3%,
      #30df99 101.61%,
      #cef719 133.72%,
      #f5ec1d 211.91%
    );
  border-radius: 10px;
  border: 2px solid transparent;
  overflow-y: auto;
  margin: 5px;
  max-height: 40vh;
  //max-width: 20vw;
`;

const StyledDrawer = styled.div`
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(
    90deg,
    #5097e3 -2.05%,
    #0cac8c 72.3%,
    #30df99 101.61%,
    #cef719 133.72%,
    #f5ec1d 211.91%
  );
  overflow: hidden;
  width: 100%;
  padding: 0.1rem;
  border-radius: 10px;
  border: 2px solid transparent;
  margin-top: -2px;
  margin-bottom: 20px;
  height: 90px;
  p {
    color: #fff;
    font-weight: 800;
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 25px;
    line-height: 20px;
    margin-top: 0px;
    text-align: initial;
    margin-bottom: 12px;
    padding: 2rem;
  }
`;

const Bottom = styled.div`
  background: #fff;
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 10px;
  border-radius:10px;
  @media only screen and (max-width: 1900px) {
    //bottom: 100px;
  }
  p {
    text-align: center;
    //margin-botton: 24px;
    .disabled {
      pointer-events: none;
      color: #c4c4c4;
    }
  }
  #genOrder {
    font-weight: 800;
    font-size: 15px;
    line-height: 22px;
    color: ${(props) => props.mainColor};
    text-decoration: underline;
    cursor: pointer;
  }
  #noItems {
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;
    text-align: center;
    color: #a6a6a6;
    span {
      font-weight: 800;
    }
  }
`;

const Total = styled.h5`
  color: #6a6a6a;
  font-weight: 800;
  font-size: 15px;
  margin-top: 16px;
  position: relative;
  span {
    position: absolute;
    font-weight: 500;
    right: 0px;
    color: ${(props) => props.mainColor};
    text-align: right;
  }
  #black {
    color: #6a6a6a;
  }
`;

const BlueButton = styled.button`
  width: 80%;
  margin-left: 10%;
  height: 48px;
  background: ${(props) =>
    props.mainColor === "#4D99E5"
      ? "linear-gradient(84.3deg, #759ABD 15.24%, #97C6C0 97.95%)"
      : "linear-gradient(45deg, #5097E3 -97.58%, #0CAC8C -34.75%, #30DF99 77.36%, #CEF719 119.25%, #F5EC1D 138.96%)"};
  border-radius: 16px;
  border: 2px solid
    ${(props) => (props.mainColor === "#4D99E5" ? "#fff" : "#30DF99")};
  font-size: 16px;
  padding: 0;
  font-weight: 800;
  margin-bottom: 24px;
  color: #fff;
  transition: all 0.4s ease;
  :hover {
    transition: all 0.4s ease;
    background: #fff;
    border: 2px solid ${(props) => props.mainColor};
    color: ${(props) => props.mainColor};
  }
  @media only screen and (max-width: 992px) {
    margin-bottom: 20px;
  }
`;

const ContinueButton = styled(Button)`
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  width: 241px;
  height: 40px;
  background: ${(props) => props.mainColor};
  border: none;
  border-radius: 102px;
  margin-top: 16px;
  margin-bottom: 0px;
  :hover {
    border: 2px solid ${(props) => props.mainColor};
    color: ${(props) => props.mainColor};
    background: #fff;
  }
`;

const StyledModal = styled(Modal)`
  .modal-dialog {
    height: 80%;
    display: flex;
    align-items: center;
  }
  .modal-header {
    border-bottom: 1px solid #c1d9d8;
  }
  .modal-title {
    font-weight: 900;
    font-size: 16px;
    line-height: 24px;
    color: ${(props) => props.mainColor};
  }
  p {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #717171;
    span {
      font-weight: 800;
    }
  }
  .btn-close {
    width: 40px;
    height: 40px;
    background: ${(props) => props.mainColor};
    border-radius: 20px;
    padding: 0px !important;
    top: -12px;
    right: 33px;
    opacity: 1;
  }
  svg {
    z-index: 1000;
    top: -13px;
    right: 33px;
    position: absolute;
  }
`;

const HeaderSection = styled.div`
  margin-top: 5%;
  display: flex;
  flex-direction: column;
`;

const HeaderTitle = styled.p`
  margin: 0;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 600;
  font-size: 32px;
  line-height: 80px;
  font-style: normal;
  color: #474a4b;
  text-align: left;
  @media only screen and (max-width: 768px) {
    font-size: 28px;
    text-align: center;
  }
`;

export const MyPackages = (props) => {
  const { items, totalPrice } = useSelector((state) => state.shoppingCart);
  const [packages, setPackages] = useState([]);
  const valores = window.location.search;
  const urlParams = new URLSearchParams(valores);
  var forPatient = urlParams?.get("forPatient") === "1";
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(false);
  const [showSearcher, setShowSearcher] = useState(false);
  const [showCallModal, setShowCallModal] = useState(false);
  const history = useHistory();
  const uid = useSelector((state) => state?.auth?.uid);
  const [loading, setLoading] = useState(false);
  const [totalSucursal, setTotalSucursal] = useState();
  const [seeBegin, setSeeBegin] = useState(true);
  const { width } = useWindowDimensions();
  const gaEventTracker = useAnalyticsEventTracker("Cart");
  const location = useLocation();
  var isOrder = urlParams.get("doctor");
  const isDoctor =
    useSelector((state) => state.user?.data?.data?.profile?.type) === 1;
  const mainColor =
    PrivRoutes.includes(location.pathname) && isDoctor ? "#4D99E5" : "#00AC90";

  const idClienteLabopat = useSelector(
    (state) => state.user?.data?.data?.profile?.idClienteLabopat
  );

  useEffect(() => {
    getTotal();
  }, [items]);

  let isMobile = width <= 768;
  const handleOpenModalCart = () => {
    props.setShowCart(true);
  };

  const getTotal = () => {
    const pricesArray = items?.map((item) => item.oldPrice);
    const final = pricesArray.reduce(
      (a, b) => parseFloat(a) + parseFloat(b),
      0
    );
    setTotalSucursal(final);
  };

  const handleRemoveItemFromCart = (id) => {
    let currentItem = items.find((item) => item.id === id);
    const newCart = items.filter((item) => item.id !== id);
    let data = newCart;
    console.log(currentItem);
    if (currentItem.howMany > 1) {
      console.log("IF");
      // Original price
      const originalPrice = currentItem.price / currentItem.howMany;
      const newHowMany = currentItem.howMany - 1;
      currentItem.price = originalPrice * newHowMany;
      currentItem.howMany = newHowMany;
      data = [...newCart, currentItem];
      console.log("DATA: ", data);
    } else {
      console.log("else 3");
    }
    // console.log("get total 4: ", data)
    const price = getTotalPrice(data);

    // Update cart
    dispatch(removeItemFromCart(data));

    // Update total price
    dispatch(setTotalPrice(price));

    // Update cart in DB
    dispatch(updateCartDB(data, price));
  };

  const vaciarCarrito = () => {
    // Eliminar todos los elementos del carrito
    dispatch(removeItemFromCart([])); // Pasar un array vacío elimina todos los elementos
    // Actualizar el precio total a cero
    dispatch(setTotalPrice(0));
    // Actualizar el carrito en la base de datos
    dispatch(updateCartDB([], 0)); // Pasar un array vacío y cero como precio
  };

  const someNotAvailableOnline = () => {
    return items.some(
      (study) =>
        study.categoria === 10 ||
        study.categoria === 8 ||
        study.ventaOnline === 0
    );
  };

  const callToSchedule = () => {
    window.location.href =
      `https://wa.me/522212311313?text=Quiero agendar una cita para:` +
      items.map((study) => " " + study.name);
  };

  const dd = async () => {
    try {
      setLoading(true);
      let requestPackages = await axios.get(
        `https://lpqsystem.com/getPaquetesMedico?uid=${uid}`
      );
      // console.log(requestPackages);
      if (requestPackages.data.ok) {
        setPackages(requestPackages.data.paquetes_medico);
        setLoading(false);
      } else {
        console.log("Error al obtener paquetes");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleOrder = () => {
    const patientInfo = JSON.parse(localStorage?.getItem("patientInfo"));

    gaEventTracker("carrito-generar-order");
    if (window.location.pathname === "/checkout" && !isOrder) {
      history.push(
        `/checkout?doctor=1${patientInfo?.id >= 1 ? "&forPatient=1" : ""}`
      );
      window.location.reload();
    } else {
      history.push(
        `/checkout?doctor=1${patientInfo?.id >= 1 ? "&forPatient=1" : ""}`
      );
    }
  };

  const handleDelete = async (id) => {
    console.log(id);
    try {
      console.log("deleting package...?");
      setLoading(true);
      let response = await axios.get(
        `https://lpqsystem.com/eliminarPaquete?id=${id}`
      );
      console.log(response);
      dd();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (!isDoctor) {
      window.location.href = "/perfil";
    }
    dd();
    // if (forPatient){
    //   setShowSearcher(true);
    // }
  }, []);

  useEffect(() => {
    if (packages.length > 0) {
      setSeeBegin(false);
    }
  }, [packages]);

  const currency = function (number) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    }).format(number);
  };

  return (
    <Container
      style={{ minHeight: isMobile ? "180vh" : "110vh" }}
      className={width < 768 ? "mt-5 pt-4" : ""}
    >
      <HelmetTitle title="Paquetes" name="description" content="content" />

      <HeaderSection>
        <HeaderTitle>Paquetes de estudios</HeaderTitle>
      </HeaderSection>

      {isMobile ? (
        <ListDat>
          <NoPackages>
            <Row>
              <Col md={4} id="text-col">
                <AddPackageButon
                  style={{ padding: "10px 40px", maxWidth: "230px" }}
                  onClick={() => history.push("/paquetes")}
                >
                  Crear nuevo paquete
                </AddPackageButon>
              </Col>
            </Row>
          </NoPackages>
          <>
            <Col lg={6}>
              <CardCont
                style={{
                  marginLeft: isMobile ? "0px" : "800px",
                  marginTop: isMobile ? "100%" : "0%",
                }}
              >
                <StyledDrawer>
                  <p>Estudios solicitados</p>
                </StyledDrawer>
                {items?.length > 0 ? (
                  <div style={{ position: "relative", paddingBottom: "106px" }}>
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <Col lg={6} style={{ padding: "2px" }}>
                          <WhiteButton1
                            style={{
                              color: "rgb(0, 172, 144)",
                              border: "2px solid rgb(0, 172, 144)",
                            }}
                            onClick={vaciarCarrito}
                          >
                            <UilTrashAlt size={20} />
                            Vaciar lista
                          </WhiteButton1>
                        </Col>
                      </div>
                    </>
                    <br />
                    <ListDiv>
                      {items.map((item) => (
                        <CardDiv
                          style={{ maxWidth: isMobile ? "86vw" : "22vw" }}
                        >
                          <ListItemCart
                            key={item.id}
                            item={item}
                            mainColor={mainColor}
                            onDelete={handleRemoveItemFromCart}
                            idClienteLabopat={idClienteLabopat}
                          />
                        </CardDiv>
                      ))}
                    </ListDiv>
                    <br />
                  </div>
                ) : (
                  <EmptyCart>
                    <div id="empty">
                      <UilClipboardAlt color="#A6A6A6" size={100} />
                    </div>
                    <p>No hay estudios agregados</p>
                  </EmptyCart>
                )}

                <Bottom minColor={mainColor}>
                  {items?.length > 0 ? (
                    <>
                      {idClienteLabopat && idClienteLabopat !== 0 ? (
                        <>
                          <Total
                            mainColor={
                              mainColor === "#4F90BA" ? "#4D99E5" : "#0CAC8C"
                            }
                          >
                            Total a pagar :{" "}
                            <span>{currency(totalSucursal)} MXN</span>
                          </Total>
                        </>
                      ) : (
                        <>
                          <Total
                            mainColor={
                              mainColor === "#4F90BA" ? "#4D99E5" : "#0CAC8C"
                            }
                          >
                            Total a pagar :{" "}
                            <span>{currency(totalSucursal)} MXN</span>
                          </Total>
                        </>
                      )}
                      {isDoctor && (
                        <>
                          <BlueButton
                            id="genOrder"
                            onClick={handleOrder}
                            mainColor={
                              mainColor === "#4F90BA" ? "#4D99E5" : "#4D99E5"
                            }
                          >
                            Genera una orden aquí
                          </BlueButton>
                        </>
                      )}
                    </>
                  ) : (
                    isDoctor && (
                      <p id="noItems">
                        Para generar una orden{" "}
                        <span>necesitas agregar al menos 1 estudio.</span>
                      </p>
                    )
                  )}
                </Bottom>
                <StyledModal
                  show={showCallModal}
                  onHide={() => setShowCallModal(false)}
                  mainColor={mainColor}
                >
                  <div onClick={() => setShowCallModal(false)}>
                    <UilTimes color="#fff" style={{ cursor: "pointer" }} />
                  </div>
                  <Modal.Header closeButton>
                    <Modal.Title>
                      Estudios no disponibles para reserva en linea
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>
                      Los siguientes estudios solo se pueden agendar mediante
                      llamada:
                    </p>
                    <ul>
                      {items.map((study) => {
                        if (
                          study.categoria === 10 ||
                          study.categoria === 8 ||
                          study.ventaOnline === 0
                        ) {
                          return <li>{study.name}</li>;
                        }
                      })}
                    </ul>
                    <ContinueButton
                      onClick={() => callToSchedule()}
                      mainColor={mainColor}
                    >
                      Llamar para agendar
                    </ContinueButton>
                  </Modal.Body>
                </StyledModal>
              </CardCont>
            </Col>
          </>

          <Col md={7}>
            {packages?.length > 0 && (
              <>
                <Row className="justify-content-center mb-5">
                  <>
                    {packages?.map((pack, index) => (
                      <>
                        <Col lg={6} key={index} className="mb-4">
                          <PackageCard data={pack} onDelete={handleDelete} />
                        </Col>
                      </>
                    ))}
                  </>
                </Row>
              </>
            )}
          </Col>

          <Modal
            show={showSearcher}
            fullscreen={true}
            onHide={() => setShowSearcher(true)}
          >
            <Modal.Body style={{ padding: "0px" }}>
              <Container>
                <div
                  style={{
                    width: "100%",
                    paddingTop: "30px",
                    minHeight: "100vh",
                    position: "relative",
                  }}
                >
                  <Link to="/" replace>
                    <DesktopLogo
                      alt="Logo Labopat"
                      src={logoB}
                      style={{
                        width: "100%",
                        maxWidth: "164px",
                        height: "auto",
                      }}
                    />{" "}
                  </Link>
                  <Searcher
                    hideSearch={() => setShowSearcher(false)}
                    spaceTop="100px"
                    mainColor={mainColor}
                    packages={packages}
                    handleDelete={handleDelete}
                  />
                </div>
              </Container>
            </Modal.Body>
          </Modal>
        </ListDat>
      ) : (
        <>
          <NoPackages>
            <Row>
              <Col md={4} id="text-col">
                <AddPackageButon
                  style={{ padding: "10px 40px", maxWidth: "230px" }}
                  onClick={() => history.push("/paquetes")}
                >
                  Crear nuevo paquete
                </AddPackageButon>
              </Col>
            </Row>
          </NoPackages>
          <>
            <Col lg={6}>
              <CardCont
                style={{
                  marginLeft: isMobile ? "0px" : "720px",
                  marginTop: isMobile ? "100%" : "0%",
                }}
              >
                <StyledDrawer>
                  <p>Estudios solicitados</p>
                </StyledDrawer>
                {items?.length > 0 ? (
                  <div style={{ position: "relative", paddingBottom: "110px" }}>
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <Col lg={6} style={{ padding: "2px" }}>
                          <WhiteButton1
                            style={{
                              color: "rgb(0, 172, 144)",
                              border: "2px solid rgb(0, 172, 144)",
                            }}
                            onClick={vaciarCarrito}
                          >
                            <UilTrashAlt size={20} />
                            Vaciar lista
                          </WhiteButton1>
                        </Col>
                      </div>
                    </>
                    <br />
                    <ListDiv>
                      {items.map((item) => (
                        <CardDiv
                          style={{ maxWidth: isMobile ? "86vw" : "22vw" }}
                        >
                          <ListItemCart
                            key={item.id}
                            item={item}
                            mainColor={mainColor}
                            onDelete={handleRemoveItemFromCart}
                            idClienteLabopat={idClienteLabopat}
                          />
                        </CardDiv>
                      ))}
                    </ListDiv>
                    <br />
                  </div>
                ) : (
                  <EmptyCart>
                    <div id="empty">
                      <UilClipboardAlt color="#A6A6A6" size={100} />
                    </div>
                    <p>No hay estudios agregados</p>
                  </EmptyCart>
                )}

                <Bottom minColor={mainColor}>
                  {items?.length > 0 ? (
                    <>
                      {idClienteLabopat && idClienteLabopat !== 0 ? (
                        <>
                          <Total
                            mainColor={
                              mainColor === "#4F90BA" ? "#4D99E5" : "#0CAC8C"
                            }
                          >
                            Total a pagar :{" "}
                            <span>{currency(totalSucursal)} MXN</span>
                          </Total>
                        </>
                      ) : (
                        <>
                          <Total
                            mainColor={
                              mainColor === "#4F90BA" ? "#4D99E5" : "#0CAC8C"
                            }
                          >
                            Total a pagar :{" "}
                            <span>{currency(totalSucursal)} MXN</span>
                          </Total>
                        </>
                      )}
                      {isDoctor && (
                        <>
                          <BlueButton
                            id="genOrder"
                            onClick={handleOrder}
                            mainColor={
                              mainColor === "#4F90BA" ? "#4D99E5" : "#4D99E5"
                            }
                          >
                            Genera una orden aquí
                          </BlueButton>
                        </>
                      )}
                    </>
                  ) : (
                    isDoctor && (
                      <p id="noItems">
                        Para generar una orden{" "}
                        <span>necesitas agregar al menos 1 estudio.</span>
                      </p>
                    )
                  )}
                </Bottom>
                <StyledModal
                  show={showCallModal}
                  onHide={() => setShowCallModal(false)}
                  mainColor={mainColor}
                >
                  <div onClick={() => setShowCallModal(false)}>
                    <UilTimes color="#fff" style={{ cursor: "pointer" }} />
                  </div>
                  <Modal.Header closeButton>
                    <Modal.Title>
                      Estudios no disponibles para reserva en linea
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>
                      Los siguientes estudios solo se pueden agendar mediante
                      llamada:
                    </p>
                    <ul>
                      {items.map((study) => {
                        if (
                          study.categoria === 10 ||
                          study.categoria === 8 ||
                          study.ventaOnline === 0
                        ) {
                          return <li>{study.name}</li>;
                        }
                      })}
                    </ul>
                    <ContinueButton
                      onClick={() => callToSchedule()}
                      mainColor={mainColor}
                    >
                      Llamar para agendar
                    </ContinueButton>
                  </Modal.Body>
                </StyledModal>
              </CardCont>
            </Col>
          </>

          <Col md={7}>
            {packages?.length > 0 && (
              <>
                <Row className="justify-content-center mb-5">
                  <>
                    {packages?.map((pack, index) => (
                      <>
                        <Col lg={6} key={index} className="mb-4">
                          <PackageCard data={pack} onDelete={handleDelete} />
                        </Col>
                      </>
                    ))}
                  </>
                </Row>
              </>
            )}
          </Col>

          <Modal
            show={showSearcher}
            fullscreen={true}
            onHide={() => setShowSearcher(true)}
          >
            <Modal.Body style={{ padding: "0px" }}>
              <Container>
                <div
                  style={{
                    width: "100%",
                    paddingTop: "30px",
                    minHeight: "100vh",
                    position: "relative",
                  }}
                >
                  <Link to="/" replace>
                    <DesktopLogo
                      alt="Logo Labopat"
                      src={logoB}
                      style={{
                        width: "100%",
                        maxWidth: "164px",
                        height: "auto",
                      }}
                    />{" "}
                  </Link>
                  <Searcher
                    hideSearch={() => setShowSearcher(false)}
                    spaceTop="100px"
                    mainColor={mainColor}
                    packages={packages}
                    handleDelete={handleDelete}
                  />
                </div>
              </Container>
            </Modal.Body>
          </Modal>
        </>
      )}
    </Container>
  );
};
