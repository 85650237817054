import React from 'react';
import { CategoriesHeader } from './categoriesHeader';
import { GridCategories } from './gridCategories';

export const Categories = () => {
  return (
    <>
      <CategoriesHeader/>
      <GridCategories/>
    </>
  );
};
