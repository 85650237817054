import { Helmet  } from 'react-helmet-async';


export const HelmetSEO = ({ title, description, name, content, url, image }) => {
    return (
        <Helmet>
            <title>{title}</title>
            { description && <meta name='description' content={description}/> }
            {title && <meta property="og:title" content={title} />}
            {description && <meta property="og:description" content={description} />}
            {url && <meta property="og:url" content={url} />}
            {image && <meta property="og:image" content={image} />}
            {name && <meta name={name} content={content} />}
        </Helmet>
    )
}