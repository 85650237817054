import { Timestamp, addDoc, collection, deleteDoc, doc, getDoc, getDocs, limit, orderBy, query, setDoc, updateDoc, where } from "firebase/firestore";
import handleAlert from "../helpers/handleAlert";
import { db } from "../firebase/firebase-config";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { containsIgnoreCase, formatPostUrl } from "../helpers/any";


// Create new post
export const createPost = (post, image = false, history) => {
  return async (dispatch) => {

    try {
      const slug = await generateSlug(post.title)
      // New post structure
      const newPost = {
        ...post,
        slug,
        created: Timestamp.fromDate(new Date()),
        updated: Timestamp.fromDate(new Date()),
        comments: [], // Initialize an empty array for comments
      };
      const res = await addDoc(collection(db, "posts"), newPost);
      console.log("🚀 ~ file: blog.jsx:20 ~ .then ~ res:", res);
      // Save post banner
      if(image){
        const storage = getStorage();
        const storageRef = ref(storage, `ecommerce/postBanners/${Date.now()}_${image.name}`);
        await uploadBytes(storageRef, image);

        const downloadURL = await getDownloadURL(storageRef);

        // Update the Firestore document with the image URL
        await updateDoc(doc(db, 'posts', res.id), {
          banner: downloadURL
        });
      }
      // window.location.href = '/datos-personales?new=1'; // TODO: Add go to post detail?
      handleAlert('success', 'El post se ha agregado correctamente', 'Post agregado')
      // Redirect to post detail or handle navigation as needed
      history.push(`/post/${slug}`);
    }catch(error){
      console.error("Error al agregar el post: ", error);
      handleAlert('error', 'Hubo un error al agregar el post', 'No se pudo agregar');
    }
  };
};

// Edit existing post
export const editPost = (postId, updatedPost, image = false, history) => {
  return async (dispatch) => {
    try {
      // Check if the post exists
      const postRef = doc(db, 'posts', postId);
      const postDoc = await getDoc(postRef);

      if (!postDoc.exists()) {
        // Handle case where post doesn't exist
        handleAlert('error', 'El post no existe', 'No se pudo encontrar el post');
        return;
      }

      // Update the existing post
      const updatedPostData = {
        ...updatedPost,
        updated: Timestamp.fromDate(new Date()),
      };

      await updateDoc(postRef, updatedPostData);

      // If an image is provided, update the post banner
      if (image) {
        const storage = getStorage();
        const storageRef = ref(storage, `ecommerce/postBanners/${Date.now()}_${image.name}`);
        await uploadBytes(storageRef, image);

        const downloadURL = await getDownloadURL(storageRef);

        // Update the Firestore document with the new image URL
        await updateDoc(postRef, {
          banner: downloadURL,
        });
      }

      // Redirect to post detail or handle navigation as needed
      history.push(`/post/${postId}`);

      handleAlert('success', 'El post se ha editado correctamente', 'Post editado');
    } catch (error) {
      console.error('Error editing post:', error);
      handleAlert('error', 'Hubo un error al editar el post', 'No se pudo editar el post');
    }
  };
};

// Get all posts
export const getAllPosts = async (category) => {
  try {
    const posts = [];
    let querySnapshot;

    if (category) {
      const q = query(collection(db, "posts"), where("category", "==", category));
      querySnapshot = await getDocs(q);
    } else {
      querySnapshot = await getDocs(collection(db, "posts"));
    }

    querySnapshot.forEach((doc) => {
      const post = doc.data();
      posts.push({ id: doc.id, ...post }); // Include the document ID in the post object
    });

    return posts;
  } catch (error) {
    console.error("Error getting posts: ", error);
    return [];
  }
};

// Get last posts
export const getLastPosts = async (quantity = 5) => {
  try {
    const posts = [];
    let querySnapshot;

    const q = query(collection(db, "posts"), limit(quantity), orderBy('created', 'desc'));
    querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      const post = doc.data();
      posts.push({ id: doc.id, ...post }); // Include the document ID in the post object
    });

    return posts;
  } catch (error) {
    console.error("Error getting posts: ", error);
    return [];
  }
};

// Get post by title filter
export const getPostsByTitle = async (searchTerm) => {
  try {
    const posts = [];
    let querySnapshot;

    querySnapshot = await getDocs(collection(db, 'posts'));

    querySnapshot.forEach((doc) => {
      const post = doc.data();
      if(containsIgnoreCase(post?.title, searchTerm)){
        posts.push({ id: doc.id, ...post }); // Include the document ID in the post object
      }
    });

    return posts;
  } catch (error) {
    console.error('Error searching posts: ', error);
    return [];
  }
};

// Get post info
export const getPost = async (postId) => {
  try {
    const docRef = doc(db, "posts", postId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return { id: docSnap.id, ...docSnap.data() };
    } else {
      console.log("No such document exists!");
      return null;
    }
  } catch (error) {
    console.error("Error getting post: ", error);
    return null;
  }
};

// Get all comments for a post
export const getCommentsForPost = async (postId) => {
  try {
    const comments = [];
    const commentsRef = collection(db, 'posts', postId, 'comments');
    const commentsSnapshot = await getDocs(commentsRef);

    commentsSnapshot.forEach((commentDoc) => {
      const commentData = commentDoc.data();
      comments.push({ id: commentDoc.id, ...commentData });
    });

    return comments;
  } catch (error) {
    console.error('Error getting comments: ', error);
    return [];
  }
};

// Add comment to post
export const addCommentToPost = (postId, comment) => {
  return (dispatch) => {
    // New comment structure
    const newComment = {
      ...comment,
      created: Timestamp.fromDate(new Date()),
      updated: Timestamp.fromDate(new Date()),
    };

    // Save data
    addDoc(collection(db, "posts", postId, "comments"), newComment)
      .then((res) => {
        handleAlert("success", "El comentario se ha agregado correctamente", "Comentario agregado");
      })
      .catch((e) => {
        handleAlert("error", "Hubo un error al agregar el comentario", "No se pudo agregar el comentario");
      });
  };
};

export const getBestScoredPosts = async () => {
  try {
    // Get all posts
    const postsSnapshot = await getDocs(collection(db, 'posts'));

    // Use map to create an array of promises for each post
    const postsPromises = postsSnapshot.docs.map(async (postDoc) => {
      const postData = postDoc.data();
      const postId = postDoc.id;

      // Get all comments for the current post
      const commentsSnapshot = await getDocs(collection(db, 'posts', postId, 'comments'));

      let totalRate = 0;
      let commentCount = 0;

      // Calculate total rate and comment count
      commentsSnapshot.forEach((commentDoc) => {
        const commentData = commentDoc.data();
        if (commentData.rate) {
          totalRate += commentData.rate;
          commentCount++;
        }
      });

      // Calculate average rate
      const averageRate = commentCount > 0 ? totalRate / commentCount : 0;

      return {
        id: postId,
        ...postData,
        averageRate: averageRate,
      };
    });

    // Wait for all promises to resolve
    const posts = await Promise.all(postsPromises);

    // Sort the posts by average rate in descending order
    posts.sort((a, b) => b.averageRate - a.averageRate);

    // Get the top 10 posts
    const top10Posts = posts.slice(0, 10);

    return top10Posts;
  } catch (error) {
    console.error('Error getting best scored posts: ', error);
    return [];
  }
};

// Delete a post by ID
export const deletePost = async (postId) => {
  try {
    await deleteDoc(doc(db, "posts", postId));
    console.log("Post deleted successfully!");
    return true;
  } catch (error) {
    console.error("Error deleting post: ", error);
    return false;
  }
};


const generateSlug = async(title) => {
  const formattedSlug = formatPostUrl(title);
  const slugQuery = query(collection(db, 'posts'), where('slug', '==', formattedSlug))
  let existingSlugs = await getDocs(slugQuery);
  if (!existingSlugs.empty) {
    let count = 1;
    let newSlug = formattedSlug;
    while (!existingSlugs.empty) {
      newSlug = `${formattedSlug}-${count}`;
      const newSlugQuery = query(collection(db, 'posts'), where('slug', '==', newSlug));
      existingSlugs = await getDocs(newSlugQuery);
      count++;
    }
    return newSlug;
  }

  return formattedSlug;
}

export const getPostBySlug = async (slug) => {
  try {

    const slugQuery = query(collection(db, "posts"), where("slug", "==", slug));
    const querySnapshot = await getDocs(slugQuery);

    if (!querySnapshot.empty) {
      const doc = querySnapshot.docs[0];
      return { id: doc.id, ...doc.data() };
    } else {
      console.log("No such document exists!");
      return null;
    }
  } catch (error) {
    console.error("Error getting post: ", error);
    return null;
  }
};

