import React from "react";
import { HelmetTitle } from '../../helpers/HelmetTitle';
import { MisionVision } from "./misionVision";
import { MedicTeam } from "./medicTeam";
import { OurServices } from "./ourServices";
import { Doubts } from "../doubts/doubts";
import { AgreementAgs } from "./agreementAgs";
import { BannerAgs } from "./Banner";

const Aguascalientes = () => {
  return (
    <div style={{backgroundColor: "#F2F7F9"}}>
      <HelmetTitle
        title="Labopat Aguascalientes"
        name="description"
        content="content"
      />
      {/* <MisionVision/> */}
      <BannerAgs/>
      <MedicTeam/>
      <OurServices/>
      <AgreementAgs/>
      <Doubts/>
    </div>
  )
};

export default Aguascalientes;