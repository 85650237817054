import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { UilAngleRightB } from '@iconscout/react-unicons'

const MainDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  margin-top: ${props => `-${props.top/2}px`};
  margin-bottom: ${props => `-${props.top/2}px`};
  img {
    width: 90%;
    max-width: 337px;
    margin: auto;
  }
  #image-col{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  h1{
    font-weight: 700;
    font-size: 25px;
    line-height: 45px;
    color: #051036;
  }
  p{
    font-weight: 400;
    font-size: 19px;
    line-height: 130%;
    color: #051036;    
    margin-bottom: 20px;
  }
  #left-button, #right-button {
    display: flex;
    justify-content: left;
  }
  @media only screen and (max-width: 992px){
    text-align: center;
    #main-row {
      margin: 0 auto;
      max-width: 376px;
    }
    #right-col{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    #left-button, #right-button {
      justify-content: center;
    }
    h1 {
      margin-top: 39px;
      margin-bottom: 26px;
    }
    p {
      margin-bottom: 58px;
    }
  }
  @media only screen and (min-width: 992px){
    #left-button{
      padding-left: 0px;
    }
    #right-button{
      padding-right: 0px;
    }
    #right-col{
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: left;
      justify-content: center;
    }
  }
  button svg {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translate(0,-50%);
  }
`;

const OrangeButton = styled.button`
  width: 307px;
  position: relative;
  background: #FA9722;
  border-radius: 15px;
  border: none;
  font-weight: 400;
  font-size: 25px;
  line-height: 130%;
  color: #FFFFFF;
  padding: 14px 19px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  text-align: left;
  @media only screen and (max-width: 992px){
    margin-bottom: 39px;
  }
`;
  
const GreenButton = styled.button`
  width: 307px;
  position: relative;
  background: #00AD90;
  border-radius: 15px;
  border: none;
  font-weight: 400;
  font-size: 25px;
  line-height: 130%;
  color: #FFFFFF;
  padding: 14px 19px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  text-align: left;
`;

export const PromoEnded = ( ) => {
  let navbar = document.getElementById('topNavBar');
  let rectNav = navbar?.getBoundingClientRect().height;
  let thePhone = '+522212311313';
  
  return (
    <MainDiv top={rectNav}>
      <Container>
        <Row id="main-row">
          <Col xs={12} lg={4} id="image-col">
            <img src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Foctubre-rosa%2Fsabiasque.png?alt=media&amp;token=4a0e7be1-5bcc-42e8-bbb2-06c2ae9d5230" alt="sabias-que"/>
          </Col>
          <Col xs={12} lg={8} id="right-col">
            <h1>Lo sentimos, esta promoción ya no está disponible</h1>
            <p>Elige cómo podemos seguir ayudándote a cuidar tu salud.</p>
            <Row>
              <Col xs={12} lg={6} id="left-button">
                <OrangeButton onClick={()=> window.location.href = "/"}>Mas promociones<UilAngleRightB color="#FFF" size={30}/></OrangeButton>
              </Col>
              <Col xs={12} lg={6} id="right-button">
                <a href={"tel:"+thePhone}>
                <GreenButton>Atención a clientes<UilAngleRightB color="#FFF" size={30}/></GreenButton>
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </MainDiv>
  )
}