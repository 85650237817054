import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap'; 

const options = [
    {
      id: 1,
      description: 'Crea una cuenta o inicia sesión.',
      icon: 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fbranches%2Ficons%2Fuser-check%5B1%5D.svg?alt=media&token=fa2b36f7-8e99-4a5d-8a36-fcaf1e5c575e'
    },
    {
      id: 2,
      description: 'Busca el estudio que necesitas, selecciónalo.',
      icon: 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fbranches%2Ficons%2Fsearch%5B1%5D.svg?alt=media&token=638aa9b0-d7ac-4d2a-9bed-e4a0995fc74a'
    },
    {
      id: 3,
      description: 'Elige sucursal, fecha y hora.',
      icon: 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fbranches%2Ficons%2Fcalendar%5B1%5D.svg?alt=media&token=dbd3b191-5dc8-473d-a6cb-397df605eaf3'
    },
    {
      id: 4,
      description: 'Paga en línea o emite una orden para pagar en sucursal.',
      icon: 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fbranches%2Ficons%2Fdollar-sign%5B1%5D.svg?alt=media&token=fed1d2fc-4bf2-409d-bfad-a5e43dd2fa05'
      
    }
]


const Main = styled.main`
    width: 100%;
    padding: 70px 0px 111px 0px;

    @media screen and (max-width: 576px) {
      padding: 10px 0px 50px 0px;
  
  }
`

const Title = styled.h3`
  color:  #474A4B;
text-align: center;
font-family: "IBM Plex Sans";
font-size: 35px;
font-style: normal;
font-weight: 300;
line-height: 105.5%;
margin-bottom: 55px;
  span{
    background:  linear-gradient(90deg, #5097E3 -97.58%, #0CAC8C -34.75%, #30DF99 77.36%, #CEF719 119.25%, #F5EC1D 138.96%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
font-weight: 600;
  }

`

const Description = styled.p`
color:  #474A4B;
text-align: center;
font-family: "IBM Plex Sans";
font-size: 19px;
font-style: normal;
font-weight: 400;
line-height: normal;
`
const MakeDate = () => {
  return (
    <Main>
    <Container>
        <Title>¿Cómo <span>hacer una cita?</span></Title>
        <Row>
          { options.map((option) => (
            <Col className='d-flex flex-column align-items-center' lg={3}>
              <img src={option.icon} alt={option.description} loading='lazy' />
              <Description className='mt-4'>{option.description}</Description>
            </Col>
          )) }
        </Row>

    </Container>
    </Main>
  )
}

export default MakeDate
