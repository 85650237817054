import Vimeo from '@u-wave/react-vimeo';
import React from 'react';
import styled from 'styled-components';
import { HelmetSEO } from "../../helpers/HelmetSEO";
import { Doubts } from '../doubts/doubts';
import { HorizontalScrollDoctors } from './HorizontalScrollDoctors';
import { BannerDoc } from './banners/BannerDoc';

const VideoDiv = styled.div`
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
`;

export const Doctors = () => {

  return (
    <>
      <HelmetSEO
        title='Servicios de Laboratorio para Médicos - Labopat'
        description='Regístrate en Labopat y accede a diagnósticos médicos rápidos y seguros. Cuidamos tu salud en Puebla. Crea tu cuenta fácil y sencillo ahora.'
      />
      {/* <div
        style={{
          background: ' linear-gradient(90deg, #5097E3 -8.82%, #0CAC8C 74.01%, #30DF99 99.88%, #CEF719 128.77%, #F5EC1D 138.96%)',
        }}
      >
        <Container>
          <Row>
            <Col xs={12}> */}
              <BannerDoc />
            {/* </Col>
          </Row>
        </Container>
      </div> */}
      <VideoDiv>
        <Vimeo
          color='ffbc45'
          video='https://vimeo.com/714575744/53fd23bda8'
          responsive={true}
          autoplay loop muted
        />
      </VideoDiv>
      <HorizontalScrollDoctors/>
      <Doubts mainColor={'#4D99E5'}/>
    </>
  );
};
