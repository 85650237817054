import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from 'redux-thunk';
import { apiReducer } from "../reducers/apiReducer";

import { authReducer } from "../reducers/authReducer";
import { auxReducer } from "../reducers/auxReducer";
import { patientOrdersReducer } from "../reducers/patientOrdersReducer";
import { reCaptchaReducer } from "../reducers/reCaptchaReducer";
import { registerReducer } from "../reducers/registerReducer";
import { shoppingReducer } from "../reducers/shoppingReducer";
import { spinnerReducer } from "../reducers/spinnerReducer";
import { uiReducer } from "../reducers/uiReducer";
import { userReducer } from "../reducers/userReducer";

import { checkinReducer } from '../reducers/checkinReducer';
import { currentCheckinReducer } from '../reducers/currentCheckinReducer';
import { patientInfoReducer } from "../reducers/patientInfoReducer";

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const reducers = combineReducers({
    auth: authReducer,
    user: userReducer,
    ui: uiReducer,
    spinner: spinnerReducer,
    recaptcha: reCaptchaReducer,
    api: apiReducer,
    aux: auxReducer,
    patientOrders: patientOrdersReducer,
    patientInfo: patientInfoReducer,
    register: registerReducer,
    shoppingCart: shoppingReducer,
    checkins: checkinReducer,
    currentCheckin: currentCheckinReducer,
});

export const store = createStore(
    reducers,
    composeEnhancers(
        applyMiddleware(thunk)
    )
);