import React from 'react';
import styled from 'styled-components';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

const SeminarsBg =
  'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fcurso-alta-especialidad%2Factivities-seminarios.png?alt=media&token=70c2b860-6502-4f45-bff5-a01f8ee7bf9f';
const SessionsBg =
  'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fcurso-alta-especialidad%2Fsesiones.png?alt=media&token=4aacacdb-fb73-4622-8b71-ac2e0c62b374';
const PracticesBg =
  'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fcurso-alta-especialidad%2Fpracticas.png?alt=media&token=61762d32-c215-45be-ba26-de3e38359772';
const BiblioBg =
  'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fcurso-alta-especialidad%2Fbiblio.png?alt=media&token=3fa8863f-b75e-4be7-bccf-7136d82c539a';
const ConferencesBg =
  'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fcurso-alta-especialidad%2Fconferencias.png?alt=media&token=5e69c008-173a-40db-b8f5-3e277dc4218f';

const MainSection = styled.div`
  max-width: 1920px;
  position: relative;
  display: flex;
  flex-direction: row;
  margin: auto;
  @media only screen and (max-width: 768px) {
    .container {
      padding: 0;
    }
  }
`;

const LeftSection = styled.div`
  width: 100%;
  max-width: 318px;
  display: flex;
  flex-direction: column;
  padding-top: 36px;
  padding-bottom: 40px;
  padding-right: 36px;
  padding-left: 0;
  @media only screen and (max-width: 768px) {
    padding: 26px 24px 23px;
    width: 100%;
    max-width: 100%;
  }
  @media only screen and (max-width: 576px) {
    padding-top: 26px;
    padding-bottom: 18px;
  }
`;

const SectionTitle = styled.h2`
  font-family: 'Faustina';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
  text-transform: uppercase;
  color: #c4c4c4;
  width: auto;
  margin-bottom: 58px;
  @media only screen and (max-width: 768px) {
    margin-bottom: 18px;
  }
`;

const Subtitle = styled.h3`
  font-family: 'Faustina';
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 45px;
  color: #00ad90;
  text-align: left;
  width: auto;
  margin-bottom: 18px;
  @media only screen and (max-width: 768px) {
    margin-bottom: 14px;
  }
  @media only screen and (max-width: 576px) {
    font-size: 24px;
    line-height: 30px;
  }
`;

const StyledUl = styled.ul`
  li {
    font-family: 'Faustina';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #474a4b;
    margin-bottom: 4px;
    @media only screen and (max-width: 576px) {
      font-size: 15px;
      line-height: 23px;
    }
  }
`;

const RightSection = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  max-width: 100%;
  @media only screen and (max-width: 1400px) {
    position: relative;
    max-width: 71.4%;
    justify-content: flex-start;
  }
  @media only screen and (max-width: 1200px) {
    max-width: 66% !important;
  }
  @media only screen and (max-width: 992px) {
    max-width: 100% !important;
  }
`;

const CardTitle = styled.div`
  background-color: #ffffff;
  height: 83px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 25px;
  padding-right: 0px;
  p {
    font-family: 'Faustina';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 24px;
    color: #474a4b;
    margin-bottom: 0;
    overflow: hidden;
  }
  @media only screen and (max-width: 1400px) {
    padding-left: 14px;
    padding-right: 0px;
  }
  @media only screen and (max-width: 1200px) {
    p {
      font-size: 18px;
    }
  }
  @media only screen and (max-width: 992px) {
    max-width: 55%;
    width: 100%;
    height: auto;
    padding-top: 14px;
    align-items: flex-start;
  }
  @media only screen and (max-width: 768px) {
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 24px;
  }
  @media only screen and (max-width: 576px) {
    font-size: 17px;
    line-height: 24px;
  }
`;

const IlustrationsList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 100%;
  margin: 0;
  @media only screen and (max-width: 1400px) {
    max-width: 100%;
    justify-content: flex-start;
  }
  @media only screen and (max-width: 992px) {
    flex-direction: column;
    flex-wrap: nowrap;
    height: auto;
    padding-bottom: 45px;
  }
  .list-card {
    float: left;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 192px;
    height: 385px;
    opacity: 0.7;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    transition: 300ms;

    &:hover,
    &:first-child ~ .list-card:last-child {
      width: 100%;
      max-width: 250px;
      opacity: 1;
      transition: 300ms;
      @media only screen and (max-width: 1400px) {
        max-width: 185px;
      }
      @media only screen and (max-width: 1200px) {
        max-width: 175px;
      }
    }

    &:hover ~ .list-card:last-child {
      max-width: 192px;
      opacity: 0.7;
      transition: 300ms;
      @media only screen and (max-width: 1400px) {
        max-width: 152px;
      }
      @media only screen and (max-width: 1200px) {
        max-width: 110px;
      }
    }

    &:nth-child(1) > .cover {
      background: url(${SeminarsBg}) top left no-repeat;
      background-size: cover;
      height: 302px;
    }
    &:nth-child(2) > .cover {
      background: url(${SessionsBg}) top left no-repeat;
      background-size: cover;
      height: 302px;
    }
    &:nth-child(3) > .cover {
      background: url(${PracticesBg}) top left no-repeat;
      background-size: cover;
      height: 302px;
    }
    &:nth-child(4) > .cover {
      background: url(${BiblioBg}) top left no-repeat;
      background-size: cover;
      height: 302px;
    }
    &:nth-child(5) > .cover {
      background: url(${ConferencesBg}) top left no-repeat;
      background-size: cover;
      height: 302px;
    }

    @media only screen and (max-width: 1400px) {
      max-width: 152px;
    }
    @media only screen and (max-width: 1200px) {
      max-width: 110px;
    }
    @media only screen and (max-width: 992px) {
      flex-direction: row-reverse;
      width: 100%;
      max-width: 100%;
      height: 124px;
      &:hover {
        max-width: 100%;
        width: 100%;
        height: 320px;
        opacity: 1;
      }
      &:first-child ~ .list-card:last-child {
        max-width: 100%;
        width: 100%;
      }
      
      &:nth-child(1) > .cover {
        background: url(${SeminarsBg}) top left no-repeat;
        background-size: cover;
        height: 100%;
        max-width: 45%;
        width: 100%;
      }
      &:nth-child(2) > .cover {
        background: url(${SessionsBg}) center left no-repeat;
        background-size: cover;
        height: 100%;
        max-width: 45%;
        width: 100%;
      }
      &:nth-child(3) > .cover {
        background: url(${PracticesBg}) top left no-repeat;
        background-size: cover;
        height: 100%;
        max-width: 45%;
        width: 100%;
      }
      &:nth-child(4) > .cover {
        background: url(${BiblioBg}) center left no-repeat;
        background-size: cover;
        height: 100%;
        max-width: 45%;
        width: 100%;
      }
      &:nth-child(5) > .cover {
        background: url(${ConferencesBg}) top left no-repeat;
        background-size: cover;
        height: 100%;
        max-width: 45%;
        width: 100%;
      }
    }
    @media only screen and (max-width: 768px) {
      height: 100px;
      &:hover {
        height: 220px;
      }
    }
    @media only screen and (max-width: 576px) {
      height: 72px;
      &:hover {
        height: 191px;
      }
    }
  }
`;

export const Activities = () => {
  return (
    <div style={{ backgroundColor: '#f2f7f9' }} id='activities'>
      <MainSection>
        <Container>
          <Row className='p-0 w-100 justify-content-start'>
            <LeftSection>
              <SectionTitle>Actividades</SectionTitle>
              <Subtitle>Actividades</Subtitle>
              <StyledUl>
                <li>
                  Actividades asistenciales de diagnóstico y aplicación de
                  inmunohistoquímica y biología molecular.
                </li>
                <li>Actividades académicas y docentes.</li>
              </StyledUl>
            </LeftSection>
            <RightSection>
              <IlustrationsList>
                <div className='list-card'>
                  <div className='cover'></div>
                  <CardTitle>
                    <p>Seminarios</p>
                  </CardTitle>
                </div>
                <div className='list-card'>
                  <div className='cover'></div>
                  <CardTitle>
                    <p>
                      Sesiones
                      <br />
                      anatomoclínicas
                    </p>
                  </CardTitle>
                </div>
                <div className='list-card'>
                  <div className='cover'></div>
                  <CardTitle>
                    <p>
                      Prácticas de
                      <br />
                      laboratorio
                    </p>
                  </CardTitle>
                </div>
                <div className='list-card'>
                  <div className='cover'></div>
                  <CardTitle>
                    <p>
                      Sesiones
                      <br />
                      bibliográficas
                    </p>
                  </CardTitle>
                </div>
                <div className='list-card'>
                  <div className='cover'></div>
                  <CardTitle>
                    <p>Conferencias</p>
                  </CardTitle>
                </div>
              </IlustrationsList>
            </RightSection>
          </Row>
        </Container>
      </MainSection>
    </div>
  );
};
