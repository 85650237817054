import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { CA_GRADIENT_BUTTON } from '../../../helpers/colors';
import { useDispatch, useSelector } from 'react-redux';
import { studyInCart } from '../../../helpers/cart';
import handleAlert from '../../../helpers/handleAlert';
import { handleAddItemToCart } from '../../../actions/shoppingCart';

const ImageBanner = 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2Fbanner-home%2FmockUpBannerCheckUp.png?alt=media&token=f512fc77-7b13-4a06-b119-c482422cf1b0';

const Slide = styled(Container)`
  height: 460px;
  position: relative;
  background: none;
  // overflow: hidden;
  h1 {
    color: var(--Negro, #474A4B);
    font-family: "IBM Plex Sans";
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  h2 {
    color: var(--Grayscale-02, #C4C4C4);
    font-family: "IBM Plex Sans";
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  p {
    color: var(--Negro, #474A4B);
    font-family: "IBM Plex Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 26px */
    margin-top: 45px;
  }
  #text-col {
    z-index: 2;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    .row {
      width: 100%;
    }
  }
  #img-col {
    z-index: 1;
    height: 460px;
    display: flex;
    align-items: end;
    justify-content: center;
    @media only screen and (min-width: 768px) {
      position: relative;
    }
  }
  img {
    max-width: 600px;
    max-height: 100%;
    z-index: 1;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, 0);
  }
  @media only screen and (min-width: 1920px) {
    #img-col {
      padding: 0;
    }
    img {
      max-width: 120%;
      max-height: 105%;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1920px) {
    padding: 0 0 0 3%;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1400px) {
    padding: 0 0 0 3%;
    h1 {
      font-size: 40px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1200px) {
    h1 {
      font-size: 33px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 992px) {
    #img-col {
      height: 520px;
    }
  }

  @media only screen and (max-width: 768px) {
    height: 520px;
    padding: 10px 0 0 20px;
    h1 {
      font-size: 30px;
      max-width: 260px;
    }
    h2 {
      font-size: 20px;
    }
    p {
      font-size: 15px;
      margin-top: 10px;
    }
    img {
      bottom: -30px;
      max-width: 125%;
      max-height: 286px;
      padding-right: 12%;
    }
  }
`;

const Button = styled.button`
  color: #F1F1F1;
  text-align: center;
  font-family: "IBM Plex Sans";
  font-size: 29px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 10px;
  border: none;
  background: ${CA_GRADIENT_BUTTON};
  transition: all 0.3s ease;
  padding: 13px 40px;
  :hover {
    filter: brightness(1.05);
  }
  @media only screen and (min-width: 768px) and (max-width: 1400px) {
    font-size: 24px;
    padding: 10px 38px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 20px;
    padding: 10px 38px;
  }
`;

export const CheckUpBanner = ({data}) => {
  const { items } = useSelector((state) => state.shoppingCart);
  const dispatch = useDispatch();

  const addStudy = () => {
    const exists = studyInCart(data, items);
    if (exists?.exists) {
      handleAlert("warning", exists?.message, "Estudio/paquete ya agregado");
    } else {
      dispatch(handleAddItemToCart(data));
    }
  };

  return (
    <Slide>
      <Row>
        <Col xs={12} md={6} xxl={7} id='text-col'>
          <h2>¡Llegó el momento!</h2>
          <h1>Check Up completo<br/>Pago en línea: $885.00 MXN</h1>
          <Button onClick={() => addStudy()}>Agenda aquí</Button>
          <p>Vigencia al 29 de febrero de 2024.</p>
        </Col>
        <Col xs={12} md={6} xxl={5} id='img-col'>
          <img src={ImageBanner} alt="phone" />
        </Col>
      </Row>
    </Slide>
  );
};
