import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";

const MainDiv = styled.div`
  background-color: #FFFFFF;
  padding: 72px 0 88px;
  .col-12 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  h1, h6 {
    color: #474A4B;
    text-align: center;
    font-size: 44px;
    font-weight: 600;
    line-height: normal;
  }
  p {
    max-width: 524px;
    margin: 0 auto;
    color: #474A4B;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    line-height: 150%; 
    letter-spacing: 0.2px;
    margin-bottom: 22px;
  }
  img {
    margin-bottom: 30px;
  }
  @media only screen and (max-width: 768px) {
    h1, h6 {
      font-size: 32px;
    }
    p {
      font-size: 16px;
    }
    padding: 50px 10px 10px;
  }
`;

const cards = [
  'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2FaboutUs%2FPATOLOGI%CC%81A%20TEXT%20active.png?alt=media&token=09537db0-6ad0-40b0-82b7-649393fc53ed',
  'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2FaboutUs%2FPATOLOGI%CC%81A%20TEXT%20active1.png?alt=media&token=55e920f5-24f5-4746-9f6b-9e537a975c70',
  'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2FaboutUs%2FPATOLOGI%CC%81A%20TEXT%20active2.png?alt=media&token=8728d83b-2cef-41e2-b215-97d25b20a673',
]

const ServicesNetwork = () => {
  return (
    <MainDiv>
      <h6>Red de servicios Labopat</h6>
      <p>Soluciones óptimas, personalizadas y a la medida de las necesidades de nuestros clientes.</p>
      <Container>
        <Row className="d-flex align-items-center justify-content-center">
          {cards?.map( card =>
            <Col xs={12} md={6} lg={4}>
              <img src={card} alt="Servicios" />
            </Col>
          )}
        </Row>
      </Container>
    </MainDiv>
  )
};

export default ServicesNetwork;