import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { GREEN_LAB, PINK_OCTOBER } from '../../../../helpers/colors';
import { UilAngleRight } from "@iconscout/react-unicons";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const MainDiv = styled.div`
  background: url('https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Foctubre-rosa%2FA%20escenarios--verd.png?alt=media&token=d2c74fc5-3718-4bdf-ab46-25ae72322288');
  background-position: center;
  background-size: cover;
`;

const Slide = styled(Container)`
  height: 460px;
  position: relative;
  overflow: hidden;
  #img-col {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  #text-col {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }
  .row {
    height: 100%;
  }
  #looking {
    width: 529px;
  }
  #phrase {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 170px;
    margin-top: 150px;
    margin-left: 100px;
  }
  h1 {
    color: #090C29;
    font-size: 50px;
    font-weight: 700;
    line-height: normal;
  }
  p {
    max-width: 632px;
    padding-left: 30px;
  }
  #first-p {
    color: #051036;
    font-size: 26px;
    font-weight: 400;
    line-height: normal;
    max-width: 600px;
    span {
      color: ${PINK_OCTOBER};
      font-weight: 600;
    }
  }
  #second-p {
    color: #051036;
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
    span {
      font-weight: 600;
    }
  }
  button {
    padding: 0px 5px 0px 16px;
    margin-left: 30px;
    border-radius: 10px;
    background: ${GREEN_LAB};
    border: none;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: #FFF;
    font-size: 22px;
    font-weight: 400;
    transition: all ease 0.3s;
    :hover {
      filter: brightness(115%);
    }
  }

  @media only screen and (max-width: 1920px) {
    width: 100% !important;
    max-width: 100% !important;
  }

  @media only screen and (max-width: 992px) {
    h1 {
      font-size: 40px;
    }
    #first-p {
      font-size: 22px;
    }
    #second-p {
      font-size: 17px;
    }
    #looking {
      width: 400px;
    }
    #phrase {
      width: 170px;
      margin-top: 120px;
      margin-left: 80px;
    }
  }
  @media only screen and (max-width: 768px) {
    #test-br {
      display: none;
    }
  }
  @media only screen and (max-width: 768px) {
    height: 520px;
    #text-col {
      align-items: center;
    }
    h1 {
      font-size: 28px;
      margin-top: -20px;
    }
    #first-p {
      font-size: 18px;
    }
    #second-p {
      font-size: 15px;
    }
    #first-p, #second-p {
      text-align: center;
      padding-left: 0;
      max-width: 314px;
    }
    #looking {
      width: 220px;
      margin-right: 20px;
    }
    #phrase {
      width: 100px;
      margin-top: 60px;
      margin-left: 40px;
    }
    button {
      margin-left: 0;
    }
  }
`;

export const RosaBanner = () => {
  const history = useHistory();
  
  return (
    <MainDiv>
      <div style={{width: '100%', background: 'rgba(255,255,255,0.5)'}}> {/* Filter to apply in front of banner */}

        {/* Slide div container */}
        <Slide>
          <Row>

            {/* Images column */}
            <Col xs={12} md={5} xxl={5} id='img-col'>
              <img id='looking' src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Foctubre-rosa%2FA%20Niplit-2023-%201.png?alt=media&token=3e8e4948-48d6-44fd-883c-ba9573a4c24a" alt="Looking" />
              <img id='phrase' src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Foctubre-rosa%2FA%20FRASE-Borde.png?alt=media&token=e81a3e52-e70b-4950-9c94-aae95816be4e" alt="Looking" />
            </Col>

            {/* Text column */}
            <Col xs={12} md={7} xxl={7} id='text-col'>
              <h1>¿Sabías qué...</h1>
              <p id='first-p'>la <span>tasa de mortalidad en México por cáncer de mama</span> es de 17.19 defunciones por cada 100 mil mujeres de 20 años o más?</p>
              <p id='second-p'><span>¡20 años! ¿Puedes creerlo? ¡Yo sigo en shock!</span> Pero tranquila, estamos juntas en esto.<br/><br id='test-br'/>Acompáñame en esta aventura y descubre conmigo cómo tomar acción sobre tu salud.</p>
              <button onClick={()=>history.push("/estudiosrosas")}>Más información<UilAngleRight size={40}/></button>
            </Col>

          </Row>
        </Slide>
        
      </div>
    </MainDiv>
  );
};
