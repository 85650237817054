import { types } from "../types/types";

const initialState = [];

export const checkinReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.setCheckin:
            return [...action.payload];

        default:
            return state;
    }

}