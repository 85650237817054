import React from 'react';
import styled from 'styled-components';
import { UimStar } from '@iconscout/react-unicons-monochrome';
import { UilArrowRight } from '@iconscout/react-unicons';
import { extractPlainText } from '../../../helpers/any';
import { CATEOGORIES } from '../colors';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const StyledCard = styled.div`
  width: 92%;
  position: relative;
  border-radius: 0px 0px 16px 0px;
  border: 1px solid #F3F3F3;
  padding: 30px 24px;
  margin: 30px auto 20px;
  hr {
    width: 34%;
    position: absolute;
    left: -4%;
    top: 10px;
    color: ${props=>props?.color ?? '#CCC'};
    opacity: 1;
    height: 2px;
  }
  #stars-div {
    position: absolute;
    top: -16px;
    right: 10px;
    svg {
      color: #30DF99;
    }
  }
  h1 {
    color: #474A4B;
    font-family: Faustina;
    font-size: 27px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 129.63%;
    letter-spacing: 0.27px;
    margin-top: -10px !important;
    margin-bottom: 10px;

    max-height: 50px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  #category {
    color: #A28EDC;
    font-family: IBM Plex Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.48px;
  }
  #continue {
    padding: 10px 17px;
    background: #F3F3F3;
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 0px 0px 16px 0px;
    transition: all 0.3s ease;
    cursor: pointer;
    :hover {
      background: #30DF99;
    }
  }
`;

const ContentP = styled.p`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Number of lines to show */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  max-height: 8em; /* Adjust as needed */
  line-height: 2em; /* Adjust as needed */
`;

export const PostCardForSlider = ({data}) => {
  const history = useHistory();
  const truncatedText = extractPlainText(data?.content);
  const currentCat = CATEOGORIES.find(cat => cat?.id === data?.category);

  return (
    <StyledCard color={currentCat?.color}>
      <hr />
      <p>{currentCat?.name}</p>
      <h1>{data?.title}</h1>
      <ContentP className='desc'>{truncatedText}</ContentP>
      <div id='stars-div'>
        {Array.from(new Array(Math.round(data?.averageRate ?? 0))).map((_, i) => (
          <UimStar key={i} size={30}/>
        ))}
      </div>
      <div id='continue' onClick={() => history.push(`/post/${data?.id ?? 0}`)}>Seguir leyendo <UilArrowRight/></div>
    </StyledCard>
  );
};
