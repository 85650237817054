import React from 'react';
import { NewsHeader } from '../newsHeader';
import { Posts } from '../posts';
import { BannerNews } from '../bannerNews';

export const Blog = () => {
  return (
    <>
      <NewsHeader/>
      <Posts/>
      <BannerNews/>
    </>
  );
};
