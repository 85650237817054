import { Container, ListGroup, Row } from 'react-bootstrap';
import { UilArrowLeft } from '@iconscout/react-unicons';
import { useHistory, useLocation } from 'react-router-dom';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { HelmetTitle } from '../../helpers/HelmetTitle';

const doctora = 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2Fprofile%2Fdoctora.png?alt=media&token=0a596fa5-dd52-4866-b5cb-f223de630c67';

export const Indications = () => {
  const containerStyle = {
    // border: '1px solid red',
    margin: '20px auto 0 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  };

  const text0Style = {
    fontFamily: 'IBM Plex Sans',
    fontStyle: 'normal',
    fontWeight: '900',
    fontSize: '24px',
    lineHeight: '36px',
    margin: '20px 0 10px 0',
    color: '#00AD90',
  };

  const text1Style = {
    fontFamily: 'IBM Plex Sans',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#000000',
  };

  const goBackArrowStyle = {
    display: 'flex',
    justifyContent: 'flex-start',
    textAlign: 'left',
    alignItems: 'left',
    cursor: 'pointer',
    width: '100%',
  };

  const rowContent = {
    width: '100%',
  };

  const row2Style = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const textItemBold = {
    fontFamily: 'IBM Plex Sans',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '800',
    letterSpacing: '0em',
    textAlign: 'left',
  };

  const itemListStyle = {
    fontFamily: 'IBM Plex Sans',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '17px',
    lineHeight: '24px',
    textAlign: 'left',
    color: '#000000',
    padding: 0,
  };

  // const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { width } = useWindowDimensions();
  const isMobile = width < 630;

  const listItemsIndications = (title, description) => (
    <ListGroup.Item
      key={title}
      style={{ ...listItemStyle, backgroundColor: '#ffffff' }}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ width: '95%' }}>
          <span style={textItemBold}>{title}</span>
          <div style={{ margin: '10px 0' }}></div>
          <p>{description}</p>
        </div>
      </div>
    </ListGroup.Item>
  );

  return (
    <Container style={containerStyle}>
      <HelmetTitle title='Indicaciones' name='description' content='content' />
      <Row style={goBackArrowStyle} onClick={() => history.push('/perfil')}>
        <UilArrowLeft size='26' color='#00AD90' style={arrowStyle} />
        <p style={goBackLabel(width)}>Regresar</p>
      </Row>
      <Row style={rowContent}>
        <div style={row2Style}>
          <span style={text0Style}>Citometría hemática</span>
        </div>
      </Row>
      <Row style={{ ...rowContent, marginTop: '30px' }}>
        <ListGroup style={itemListStyle}>
          {location.state.list.map((item) =>
            listItemsIndications(item.title, item.description)
          )}
          <ListGroup.Item
            style={{ ...listItemStyle, backgroundColor: '#ffffff' }}
          >
            <img
              src={doctora}
              alt='doctora'
              style={{
                position: 'absolute',
                top: isMobile ? '32%' : '-28%',
                left: isMobile ? '53%' : '80%',
              }}
            />
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ width: '95%' }}>
                <span style={textItemBold}>
                  El día de la muestra no olvides llevar
                </span>
                <div style={{ margin: '10px 0' }}></div>
                <ul style={{ paddingRight: '33%', marginLeft: '-10px' }}>
                  <li>
                    Identificación oficial; INE, Pasaporte y/o Credencial
                    Escolar (en menores de edad)
                  </li>
                  <div style={{ margin: '10px 0' }}></div>
                  <li>
                    Los pacientes deben portar cubrebocas y sólo se lo podrán
                    retirar cuando se les indique.
                  </li>
                </ul>
              </div>
            </div>
          </ListGroup.Item>
        </ListGroup>
      </Row>
    </Container>
  );
};

const containerStyle = {
  // border: '1px solid red',
  margin: '20px auto 0 auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
};

const text0Style = {
  fontFamily: 'IBM Plex Sans',
  fontStyle: 'normal',
  fontWeight: '900',
  fontSize: '24px',
  lineHeight: '36px',
  margin: '20px 0 10px 0',
  color: '#31817D',
};

const text1Style = {
  fontFamily: 'IBM Plex Sans',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '16px',
  lineHeight: '24px',
  textAlign: 'center',
  color: '#000000',
};

const goBackArrowStyle = {
  display: 'flex',
  justifyContent: 'flex-start',
  textAlign: 'left',
  alignItems: 'left',
  cursor: 'pointer',
  width: '100%',
};

const rowContent = {
  width: '100%',
};

const row2Style = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

const textItemBold = {
  fontFamily: 'IBM Plex Sans',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: '800',
  letterSpacing: '0em',
  textAlign: 'left',
};

const itemListStyle = {
  fontFamily: 'IBM Plex Sans',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '17px',
  lineHeight: '24px',
  textAlign: 'left',
  color: '#000000',
  padding: 0,
};

const listItemStyle = {
  padding: '15px',
  // cursor: 'pointer',
  background: '#DBF1FF',
  display: 'flex',
  flexDirection: 'column',
  border: 'none',
};

// Go back arrow
const goBackLabel = (width) => ({
  ...text1Style,
  width: '40px',
  margin: width < 680 ? '0px -29px' : '0px -15px',
});

const arrowStyle = (width) => ({
  width: width < 680 ? '20%' : '6%',
  margin: width < 680 ? '0 0 10px -21px' : '0',
});
