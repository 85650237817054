import React, { useEffect, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

const Background = styled.div`
    background: linear-gradient(90deg, #5097E3 -39.53%, #0CAC8C 13.74%, #30DF99 47.58%, #CEF719 101.67%, #F5EC1D 138.96%);
    @media only screen and (max-width: 768px) {
        background: linear-gradient(90deg, #5097E3 -50.21%, #0CAC8C 14.86%, #30DF99 73.5%, #CEF719 134.41%, #F5EC1D 177.64%);
    }
`;

const ContainerDiv = styled.section`
    position: relative;
    width: 100%;
    min-height: 100vh;
`;

const SpaceHolder = styled.div`
    position: relative;
    width: 100%;
`;

const Sticky = styled.div`
    position: sticky;
    top: 0;
    height: 100vh;
    width: 100%;
    overflow-x: hidden;
`;

const Horizontal = styled.div`
    position: absolute;
    height: 100%;
    margin-top: -12vh;
    will-change: transform;
`;

const Cards = styled.section`
    position: relative;
    height: 100%;
    padding: 0 0 0 70px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    // margin-bottom: -500px;
`;

const SampleCard = styled.div`
    position: relative;
    height: 100vh;
    width: 90vw;
    background-color: none;
    // background-color: #111f301A;
    margin-right: 75px;
    padding-top: 300px;
    flex-shrink: 0;
    @media only screen and (max-width: 768px) {
        padding-top: 270px;
    }
    .text-col {
        display: flex;
        position: relative;
        padding-left: 80px;
        @media only screen and (max-width: 768px) {
            padding-left: 0;
        }
        h1 {
            font-family: 'IBM Plex Sans';
            font-weight: 700;
            font-size: 150px;
            line-height: 80%;
            color: #CEF719;
            margin-right: 25px;
            @media only screen and (max-width: 768px) {
                font-size: 60px;
                margin-top: 5px;
                margin-right: 18px;
            }
        }
        p {
            font-family: 'IBM Plex Sans';
            font-weight: 400;
            font-size: 35px;
            line-height: 50px;
            color: #FFFFFF;
            span {
                font-weight: 700;
            }
            @media only screen and (max-width: 768px) {
                font-size: 23px;
                line-height: 30px;
                max-width: 245px;
            }
        }
    }
    img {
        max-width: 60%;
        max-height: 85vh;
        height: auto;
        position: absolute;
        bottom: 0;
        right: 20px;
        @media only screen and (max-width: 768px) {
            max-width: 120%;
            max-height: 60vh;
            left: 50%;
            transform: translate(-50%, 0);
        }
    }
    a {
        color: #FFFFFF;
        text-decoration: underline;
        font-weight: 600;
    }
    #create {
        h4 {
            font-family: 'IBM Plex Sans';
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            color: #FFFFFF !important;
            max-width: 237px;
        }
        @media only screen and (min-width: 768px) {
            display: none;
        }
    }
    #create-bottom {
        font-family: 'IBM Plex Sans';
        font-weight: 400;
        font-size: 25px;
        line-height: 40px;
        color: #FFFFFF;
        position: absolute;
        bottom: 70px;
        left: 80px;
        max-width: 386px;
        @media only screen and (max-width: 768px) {
            display: none;
        }
    }

`;

const RedDiv = styled.div`
    position: sticky;
    top: 150px;
    left: 150px;
    background: none;
    // background: #FF00001A;
    font-family: 'IBM Plex Sans';
    font-weight: 500;
    font-size: 27px;
    line-height: 130%;
    color: #FFFFFF;
    @media only screen and (max-width: 768px) {
        top: 120px;
        left: 50px;
    }
    p {
        font-style: italic;
        margin-bottom: 10px;
        @media only screen and (max-width: 768px) {
            font-size: 23px;
            margin-bottom: 0;
        }
    }
    h3 {
        color: #FFFFFF;
        font-weight: 700;
        font-size: 44px;
        line-height: 130%;
        @media only screen and (max-width: 768px) {
            font-size: 32px;
        }
    }
`;

export const HorizontalScrollSection = () => {
    // Ref for space holder
    const spaceHolderRef = useRef(null);

    // Ref for width
    const horizontalRef = useRef(null);

    useEffect(() => {

        // Get height dynamically
        const calcDynamicHeight = () => {
            const vw = window.innerWidth;
            const vh = window.innerHeight;
            const objectWidth = horizontalRef.current.scrollWidth;
            return objectWidth - vw + vh;
        };

        // When scroll (vertical) make horizontal scroll
        const handleScroll = () => {
            const sticky = document.querySelector('.sticky');
            horizontalRef.current.style.transform = `translateX(-${sticky.offsetTop}px)`;
        };

        // When resize change the height ofthe space holder
        const handleResize = () => {
            spaceHolderRef.current.style.height = `${calcDynamicHeight()}px`;
        };

        //  Listeners for scroll and resize
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);

        // Change space holder height
        spaceHolderRef.current.style.height = `${calcDynamicHeight()}px`;

        // Remove listeners
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Background>
            <ContainerDiv>
                <SpaceHolder ref={spaceHolderRef}>
                <Sticky className='sticky'>
                    {/* Fixed div with title */}
                    <RedDiv data-aos='fade-up'>
                        <p>Entra y descubre</p>
                        <h3>¿Cómo funciona?</h3>
                    </RedDiv>
                    {/* Horizontal scroll div */}
                    <Horizontal ref={horizontalRef}>
                        {/* Step cards */}
                        <Cards>
                            <SampleCard data-aos='fade-up'>
                                <Row>
                                    <Col xs={12} md={10} lg={10} xl={6} className='text-col'>
                                        <h1>1</h1>
                                        <p><span>Encuentra los estudios</span> que necesitas en nuestra plataforma.</p>
                                    </Col>
                                </Row>
                                <img src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2FhomeSlider%2FHScroll1.png?alt=media&token=0eadf550-4b87-4f6f-9079-227bb6eccbb3" alt="paso 1"/>
                            </SampleCard>
                            <SampleCard data-aos='fade-up'>
                                <h4 id='create-bottom'>Además, <a href='/registro'>sí te haces una cuenta</a> podrás obtener descuentos preferenciales.</h4>
                                <Row>
                                    <Col xs={12} md={10} lg={10} xl={6} className='text-col'>
                                        <h1>2</h1>
                                        <p><span>Paga en línea</span> y obtén descuentos y puntos.</p>
                                    </Col>
                                    <Col xs={12} lg={7} id='create'>
                                        <h4>Además, <a href='#'>sí te haces una cuenta</a> podrás obtener descuentos preferenciales.</h4>
                                    </Col>
                                </Row>
                                <img src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2FhomeSlider%2FHScroll2.png?alt=media&token=42a61c40-dbf9-4410-8d1e-1337206cf79c" alt="paso 2"/>
                            </SampleCard>
                            <SampleCard>
                                <Row>
                                    <Col xs={12} md={10} lg={10} xl={6} className='text-col'>
                                        <h1>3</h1>
                                        <p>Toma de muestra, <span>rápido y sin tiempos de espera.</span></p>
                                    </Col>
                                </Row>
                                <img src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2FhomeSlider%2FHScroll3.png?alt=media&token=06b587b4-9020-4d63-a3fe-3a448896b24e" alt="paso 3"/>
                            </SampleCard>
                            <SampleCard>
                                <Row>
                                    <Col xs={12} md={10} lg={10} xl={6} className='text-col'>
                                        <h1>4</h1>
                                        <p>Recibe tus resultados<span> en tu cuenta.</span></p>
                                    </Col>
                                </Row>
                                <img src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2FhomeSlider%2FHScroll4.png?alt=media&token=54497601-786c-404c-8009-87a17f187a9a" alt="paso 4"/>
                            </SampleCard>
                        </Cards>
                    </Horizontal>
                </Sticky>
                </SpaceHolder>
            </ContainerDiv>
        </Background>
    );
};