import { types } from "../types/types"

export const setAux = (value) => ({
    type: types.aux,
    payload: value
});

export const setOfferBanner = (value) => ({
    type: types.offerBanner,
    payload: value
});
