import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { getUserInfo } from "../../actions/user";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {
  UilBookMedical,
  UilDocumentLayoutLeft,
  UilQuestionCircle,
  UilPhoneAlt,
} from "@iconscout/react-unicons";
import { startLogout } from "../../actions/auth";
import { SideDrawer } from "./drawer";
import { Sections } from "./sections";
import { Cart } from "../shopping-cart/cart";
import { Searcher } from "../navbar/searcher";
import { PrivRoutes } from "../../routes/routes";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { allowedUsersToPost } from "../blog/colors";
import { Fotoperfil } from "../profile-menu/Fotoperfil";

const LogOutIconUser =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2Fsidebar%2FLogoutIconUser.png?alt=media&token=d3030c57-cd4e-4b12-a4a4-8962c3fb473e";
const LogOutIcon =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2Fsidebar%2FLogoutIcon.png?alt=media&token=c6a25cc4-8ac9-43a6-bb96-df9f13995733";
const logoB =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2Flogos%2Flogo1.png?alt=media&token=dffc6866-cf6d-4c14-a494-60ceaf3f3219";

const SidebarSection = styled.div`
  display: ${(props) => (props.isMobile ? "none" : "")};
  background-color: ${(props) => (props.isDoctor ? "#E7F0F6" : "#F2F7F9")};
  width: 100%;
  height: 100%;
`;

const StickyContent = styled.div`
  padding: 12% 8% 12% 8%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
`;

const LogoDocContainer = styled.div`
  border-radius: 50%;
  width: 80px;
  height: 80px;
  max-width: 80px;
  max-height: 80px;
  position: relative;
  overflow: hidden;
  background-image: ${(props) => `url('${props.bg}')`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 0;
  @media only screen and (max-width: 1200px) {
    width: 48px;
    height: 48px;
  }
  @media only screen and (max-width: 768px) {
    width: 52px;
    height: 52px;
  }
`;

const LogoImg = styled.img`
  max-width: 160px;
`;

const NormalText = styled.p`
  margin: 0;
  font-family: "IBM Plex Sans";
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #474a4b;
`;

const StyledNav = styled.nav`
  margin-bottom: ${(props) => (props.isDoctor ? "20%" : "50%")};
  a {
    font-family: "IBM Plex Sans";
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    margin: 0;
    color: #878d96;
    :hover {
      color: ${(props) => props.mainColor};
    }
  }
  .cart {
    color: ${(props) => (props.showCart ? props.mainColor : "#878D96")};
    :hover {
      color: ${(props) => props.mainColor};
    }
  }
  .icon {
    max-width: 25px;
    max-height: 25px;
    height: auto;
    @media only screen and (max-width: 992px) {
      max-width: 20px;
      max-height: 20px;
    }
  }
  .bigIcon {
    max-width: 22px;
    max-height: 22px;
    height: auto;
    @media only screen and (max-width: 992px) {
      max-width: 18px;
      max-height: 18px;
    }
  }
  svg {
    color: ${(props) => props.mainColor} !important;
  }
  .active {
    color: ${(props) => props.mainColor} !important;
  }
`;

const StyledHr = styled.hr`
  opacity: 1;
  background-color: #4d99e5;
  padding: 0.5px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  bottom: ${(props) => (props.ondrawer ? "0" : "-9%")};
  left: 0;
  right: 0;
  padding: 1rem 1rem 1rem 1rem;
  background-color: ${(props) => (props.isDoctor ? "#4D99E5" : "#00AC90")};
  border-radius: 12px;
`;

const HelpButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  a {
    color: #fff;
    font-family: "IBM Plex Sans";
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    margin: 0;
  }
`;

const PhoneButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  .helpIcon {
    max-width: 38px;
    margin-top: 3%;
    margin-right: 5%;
  }
  a {
    color: #fff;
    font-family: "IBM Plex Sans";
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    margin: 0;
  }
`;

const LogOutBtn = styled.a`
  font-family: "IBM Plex Sans";
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  color: #697077;
  :hover {
    color: #000000;
    img {
      filter: none;
    }
  }
`;
const CartCount = styled.div`
  background-color: ${(props) =>
    props.isdoctor === 1 ? "#4D99E5" : "#00AC90"};
  padding: 9px;
  position: absolute;
  top: -5px;
  right: -4px;
  color: white;
  width: 16px;
  height: 16px;
  border-radius: 13px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  p {
    margin: 0;
    font-size: 12px;
  }
  @media only screen and (max-width: 1200px) {
    right: -25%;
  }
  @media only screen and (max-width: 992px) {
    right: -14%;
    padding: 6px;
  }
`;

const DesktopLogo = styled.img`
  margin: auto;
  display: block;
  height: 40px;
  position: absolute;
  left: 20px;
  @media only screen and (max-width: 768px) {
    width: 50px;
  }
`;

export const SideNav = (props) => {
  const [showSearcher, setShowSearcher] = useState(props.showSearcher);
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(false);
  const uid = useSelector((state) => state?.auth?.uid);
  const user = useSelector((state) => state?.user?.data.data);
  const { items } = useSelector((state) => state.shoppingCart);
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const isDoctor = user?.profile?.type === 1;
  const location = useLocation();
  const mainColor =
    (PrivRoutes.includes(location.pathname) ||
      location?.pathname?.includes("editar-post/") ||
      location?.pathname?.includes("paciente/") ||
      location?.pathname?.includes("editar-paquete/")) &&
    isDoctor
      ? "#4D99E5"
      : "#00AC90";

  const handleCart = () => {
    props.setShowCartSideBar(!props.showCartSideBar);
  };

  useEffect(() => {
    setShowSearcher(props.showSearcher);
  }, [props.showSearcher]);

  const dd = async () => {
    try {
      setLoading(true);
      let requestPackages = await axios.get(
        `https://lpqsystem.com/getPaquetesMedico?uid=${uid}`
      );
      // console.log(requestPackages);
      if (requestPackages.data.ok) {
        setPackages(requestPackages.data.paquetes_medico);
        setLoading(false);
      } else {
        console.log("Error al obtener paquetes");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    dd();
  }, []);

  const handleDelete = async (id) => {
    console.log(id);
    try {
      console.log("deleting package...?");
      setLoading(true);
      let response = await axios.get(
        `https://lpqsystem.com/eliminarPaquete?id=${id}`
      );
      console.log(response);
      dd();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      {width <= 768 ? (
        <SideDrawer setShowSearcher={(e) => props.setShowSearcher(e)} />
      ) : (
        <SidebarSection
          isDoctor={isDoctor}
          isMobile={width <= 768 ? true : false}
        >
          <StickyContent>
            <Link to="/">
              <LogoImg
                src={logoB}
                alt="Logo Labopat"
                className="img-fluid mb-4"
              />
            </Link>
            <Row className="align-items-center mt-3">
              {isDoctor && user.profile?.logo && (
                <Col
                  md={12}
                  style={{ paddingRight: "0", marginBottom: "16px" }}
                  className="d-flex justify-content-center"
                >
                  <Fotoperfil />
                </Col>
              )}
              <Col md={12} className="d-flex justify-content-center">
                <NormalText className="mb-2">
                  {isDoctor
                    ? user.gender === "Masculino"
                      ? "Dr."
                      : "Dra."
                    : null}{" "}
                  {user?.name ?? ""} {user?.fathersLastname ?? ""}{" "}
                  {user?.mothersLastname ?? ""}
                </NormalText>
              </Col>
            </Row>
            <StyledHr className="mt-4 mb-2" />
            <div className="d-flex flex-column justify-content-between position-relative">
              <StyledNav
                className="pt-1 pb-4"
                showCart={props.showCartSideBar}
                isMobile={width <= 768 ? true : false}
                isDoctor={isDoctor}
                mainColor={mainColor}
              >
                {isDoctor ? (
                  <>
                    {Sections.docSections.map((section, index) => (
                      <>
                        {section.name === "Estudios" ? (
                          <div className="mt-4 mb-4" key={index}>
                            <a className="cart" onClick={handleCart}>
                              <Row className="align-items-center">
                                <Col
                                  xs={2}
                                  style={{ paddingRight: "0" }}
                                  className="position-relative"
                                >
                                  {section.icon}
                                  {items.length > 0 ? (
                                    <CartCount isdoctor={isDoctor ? 1 : 0}>
                                      <p>{items.length}</p>
                                    </CartCount>
                                  ) : null}
                                </Col>
                                <Col xs={10}>{section.name}</Col>
                              </Row>
                            </a>
                          </div>
                        ) : (
                          // Cuando la opcion es buscar abre el buscador que es fullscreen
                          <div className="mt-4 mb-4" key={index}>
                            {section.route === "search" ? (
                              <Row
                                className="align-items-center"
                                onClick={() => props.setShowSearcher(true)}
                                style={{ color: "#878D96", cursor: "pointer" }}
                              >
                                <Col xs={2} style={{ paddingRight: "0" }}>
                                  {section.icon}
                                </Col>
                                <Col xs={10}>{section.name}</Col>
                              </Row>
                            ) : (
                              <NavLink to={section.route}>
                                <Row className="align-items-center">
                                  <Col xs={2} style={{ paddingRight: "0" }}>
                                    {section.icon}
                                  </Col>
                                  <Col xs={10}>{section.name}</Col>
                                </Row>
                              </NavLink>
                            )}
                          </div>
                        )}
                      </>
                    ))}

                    {/* Blog option nav if allowed */}
                    {allowedUsersToPost?.includes(uid) && (
                      <div className="mt-4 mb-4" key={"create-post"}>
                        <NavLink to="/crear-post">
                          <Row className="align-items-center">
                            <Col xs={2} style={{ paddingRight: "0" }}>
                              <UilBookMedical />
                            </Col>
                            <Col xs={10}>Crear post</Col>
                          </Row>
                        </NavLink>
                      </div>
                    )}
                    {/* Blog option nav if allowed */}
                    {allowedUsersToPost?.includes(uid) && (
                      <div className="mt-4 mb-4">
                        <NavLink to="/admin-blog" key={"admin-blog"}>
                          <Row className="align-items-center">
                            <Col xs={2} style={{ paddingRight: "0" }}>
                              <UilDocumentLayoutLeft />
                            </Col>
                            <Col xs={10}>Admin Blog</Col>
                          </Row>
                        </NavLink>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {Sections.userSections.map((section, index) => (
                      <>
                        {section.name === "Estudios" ? (
                          <div className="mt-4 mb-4" key={index}>
                            <a className="cart" onClick={handleCart}>
                              <Row className="align-items-center">
                                <Col
                                  xs={2}
                                  style={{ paddingRight: "0" }}
                                  className="position-relative"
                                >
                                  {section.icon}
                                  {items.length > 0 ? (
                                    <CartCount isdoctor={isDoctor ? 1 : 0}>
                                      <p>{items.length}</p>
                                    </CartCount>
                                  ) : null}
                                </Col>
                                <Col xs={10}>{section.name}</Col>
                              </Row>
                            </a>
                          </div>
                        ) : (
                          <div className="mt-4 mb-4" key={index}>
                            <NavLink to={section.route}>
                              <Row className="align-items-center">
                                <Col xs={2} style={{ paddingRight: "0" }}>
                                  {section.icon}
                                </Col>
                                <Col xs={10}>{section.name}</Col>
                              </Row>
                            </NavLink>
                          </div>
                        )}
                      </>
                    ))}
                  </>
                )}
                <div className="mt-4 mb-4">
                  <LogOutBtn onClick={() => dispatch(startLogout())}>
                    <Row>
                      <Col xs={2} style={{ paddingRight: "0" }}>
                        <img
                          src={isDoctor ? LogOutIcon : LogOutIconUser}
                          alt="Icono cerrar sesión"
                          className="icon"
                        />
                      </Col>
                      <Col xs={10}>Cerrar sesión</Col>
                    </Row>
                  </LogOutBtn>
                </div>
                {/*<div className="mt-4 mb-4">
                  <LogOutBtn onClick={() => dispatch(startLogout())}>
                    <Row>
                      <Col xs={2} style={{ paddingRight: "0" }}>
                        <a
                          href="https://wa.me/522212311313"
                          target="_blank"
                          className="d-flex align-items-center"
                          rel="noreferrer"
                        >
                          <UilQuestionCircle />&nbsp;&nbsp;&nbsp;Ayuda
                        </a>
                      </Col>
                    </Row>
                  </LogOutBtn>
                </div>*/}
              </StyledNav>
              {/*<ButtonsContainer isDoctor={isDoctor ? true : false}>
                {isDoctor ? (
                  <></>
                ) : (
                  <>
                    <PhoneButton isDoctor={isDoctor} className="mb-3">
                      <a
                        href="tel:+52-1-221-231-1313"
                        rel="noreferrer"
                        className="d-flex align-items-center"
                      >
                        <UilPhoneAlt />
                        &nbsp;&nbsp;&nbsp;Teléfonos
                      </a>
                    </PhoneButton>
                  </>
                )}
                <HelpButton isDoctor={isDoctor}>
                  <a
                    href="https://wa.me/522212311313"
                    target="_blank"
                    className="d-flex align-items-center"
                    rel="noreferrer"
                  >
                    <UilQuestionCircle />
                    &nbsp;&nbsp;&nbsp;Ayuda
                  </a>
                </HelpButton>
              </ButtonsContainer>*/}
            </div>
          </StickyContent>
        </SidebarSection>
      )}

      <Modal
        show={showSearcher}
        fullscreen={true}
        onHide={() => props.setShowSearcher(false)}
      >
        <Modal.Body style={{ padding: "0px" }}>
          <Container>
            <div
              style={{
                width: "100%",
                paddingTop: "30px",
                minHeight: "100vh",
                position: "relative",
              }}
            >
              <Link to="/" replace>
                <DesktopLogo
                  alt="Logo Labopat"
                  src={logoB}
                  style={{ width: "100%", maxWidth: "164px", height: "auto" }}
                />{" "}
              </Link>
              <Searcher
                hideSearch={() => props.setShowSearcher(false)}
                spaceTop="100px"
                mainColor={mainColor}
                packages={packages}
                handleDelete={handleDelete}
              />
            </div>
          </Container>
        </Modal.Body>
      </Modal>
      <Cart
        onClose={() => {
          props.setShowCartSideBar(false);
        }}
        visible={props.showCartSideBar}
        openSearcher={() => props.setShowSearcher(true)}
      />
    </>
  );
};
