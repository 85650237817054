import React, { useEffect,useState } from 'react';
import styled from 'styled-components';
import { UilUser, UilCalendarAlt, UilDownloadAlt,UilClipboardAlt } from '@iconscout/react-unicons';
import { Row, Col } from 'react-bootstrap';

const GreenButton = styled.a`
  display: block;
  margin: 0;
  font-family: 'IBM Plex Sans';
  font-weight: 900;
  font-size: 16px;
  line-height: 23.76px;
  text-align: center;
  color: #FFFFFF;
  padding: 8px 23px;
  border-radius: 102px;
  background-color: #00AC90;
  margin-right: 4%;
  width: auto;
  :hover {
    color: #F9F9F9;
  }
`;

const LocationDiv = styled.div`
  max-width: 944px;
  background-color: #FFFFFF;
  border: 1px solid #E0ECEC;
  border-radius: 16px;
  padding: 24px;
`;

const LocationTite = styled.p`
  margin: 0;
  color: #00AD90;
  font-family: 'IBM Plex Sans';
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
`;

const LocationDesc = styled.p`
  margin: 0;
  color: #717171;
  font-family: 'IBM Plex Sans';
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  text-align: left;
`;

const StudyCard = styled.div`
  width: 100%;
  border: 1px solid #E0ECEC;
  box-shadow: 0px 4px 4px rgba(71, 74, 75, 0.15);
  border-radius: 12px;
  padding: 24px;
`;

const StudyTitle = styled.p`
  font-family: 'IBM Plex Sans';
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #121619;
  margin: 0;
`;

const StudyDateText = styled.p`
  font-family: 'IBM Plex Sans';
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: #717171;
  margin: 0;
`;

const DownlaodStudyBtn = styled.a`
  display: block;
  font-family: 'IBM Plex Sans';
  font-weight: 800;
  font-size: 15px;
  line-height: 18px;
  text-decoration-line: underline;
  color: #00AC90;
  padding: 0;
  margin: 0;
  :hover {
    color: #00AC90;
  }
`;

const SubTitle = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  color: #697077;
  margin: 0;
`;
const EmptyResult = styled.div`
  #empty {
    margin: 40px auto 24px;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    left: 110px;
    top: 112px;
    background: #f9f9f9;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  p {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #A6A6A6;
    margin-bottom: 40px;
  }
`;

export const Result =(props)=>{
    const [lastResult, setLastResult] = useState([]);
    console.log(props.data);

    useEffect(() => {
        setLastResult(props.data[props.data.length -1]);
    }, []);

    return(
        <>
        {
            lastResult?.length>0 ? 
            <>
            <Row className='mb-4'>
            <GreenButton>Descargar</GreenButton>
            <GreenButton>Enviar</GreenButton>
          </Row>
          <Row className='mb-4'>
            <LocationDiv>
              <LocationTite className='mb-2'>Palmas plaza Drive Thru</LocationTite>
              <LocationDesc className='mb-3'>
                Estacionamiento de Palmas Plaza, Av Zeta del Cochero 403, 
                Reserva Territorial Atlixcáyotl, 72820 San Andrés Cholula, Pue.
              </LocationDesc>
              <Row className='align-items-center'>
                <UilUser 
                  size={14} 
                  color={'#3B3B3B'} 
                  className='w-auto p-0' />
                <LocationDesc className='w-auto p-0' style={{marginLeft: '1%'}}>
                  Solicitados por: Juan Ramón Cerón Gonzalez
                </LocationDesc>
              </Row>
            </LocationDiv>
          </Row>
          <Row className='mb-4'>
            <StudyCard>
              <Row>
                <Col lg={5} className='d-flex flex-column justify-content-center mb-4 mb-sm-4 mb-md-4 mb-lg-0'>
                  <StudyTitle>
                    Frac. beta libre de gch-embarazo (4)
                  </StudyTitle>
                </Col>
                <Col lg={5} className='d-flex flex-column justify-content-center mb-4 mb-sm-4 mb-md-4 mb-lg-0'>
                  <Row className='align-items-center'>
                    <UilCalendarAlt size={14} color={'#717171'} className='w-auto p-0' />
                    <StudyDateText className='w-auto p-0' style={{marginLeft: '2%'}}>
                      16/07/2020 a las 14:00h
                    </StudyDateText>
                  </Row>
                </Col>
                <Col lg={2} className='d-flex flex-column justify-content-center mb-4 mb-sm-4 mb-md-4 mb-lg-0'>
                  <DownlaodStudyBtn className='w-auto'>
                    Descargar
                    <UilDownloadAlt size={18} color={'#00AC90'} className='w-auto' style={{marginLeft: '5%'}} />
                  </DownlaodStudyBtn>
                </Col>
              </Row>
            </StudyCard>
          </Row>
          <Row className='mb-4'>
            <SubTitle>
              En revisión
            </SubTitle>
          </Row>
          <Row className='mb-5'>
            <StudyCard>
              <Row>
                <Col lg={5} className='d-flex flex-column justify-content-center mb-4 mb-sm-4 mb-md-4 mb-lg-0'>
                  <StudyTitle>
                    Proteína a
                  </StudyTitle>
                </Col>
                <Col lg={5} className='d-flex flex-column justify-content-center mb-4 mb-sm-4 mb-md-4 mb-lg-0'>
                  <Row className='align-items-center'>
                    <UilCalendarAlt size={14} color={'#717171'} className='w-auto p-0' />
                    <StudyDateText className='w-auto p-0' style={{marginLeft: '2%'}}>
                      18/07/2020 a las 08:00h
                    </StudyDateText>
                  </Row>
                </Col>
                <Col lg={2} className='d-flex flex-column justify-content-center mb-4 mb-sm-4 mb-md-4 mb-lg-0'>
                  <DownlaodStudyBtn className='w-auto'>
                    Descargar
                    <UilDownloadAlt size={18} color={'#00AC90'} className='w-auto' style={{marginLeft: '5%'}} />
                  </DownlaodStudyBtn>
                </Col>
              </Row>
            </StudyCard>
          </Row>
            </>
            : <>
            <EmptyResult>
                <div id='empty'>
                    <UilClipboardAlt color='#A6A6A6' size={100} />
                </div>
                <p>Aún no tienes solicitudes</p>
            
            </EmptyResult>
            </>
        }        
        </>
    )
}