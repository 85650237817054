
import { Helmet } from "react-helmet-async";

export const HelmetTitle = ({ title, name, content }) => {
    return (
        <Helmet>
            {/* <title>LABOPAT Ecommerce | {title}</title> */}
            <title>{title}</title>
            <meta
                name={name}
                content={content}
            />
        </Helmet>
    );
}