import { Container, ListGroup, Row } from "react-bootstrap";
import {
    UilAngleRightB,
    UilUserCircle,
    UilAward,
    UilFileMedicalAlt,
    UilSetting,
    UilMegaphone,
    UilHeart,
    UilFileQuestionAlt,
    UilSignOutAlt,
    UilParcel,
    UilUsersAlt,
} from '@iconscout/react-unicons';
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { startLogout } from "../../actions/auth";
import moment from "moment";
import { HelmetTitle } from "../../helpers/HelmetTitle";

export const ProfileMenu = () => {
    const containerStyle = {
        // border: '1px solid red',
        margin: '20px auto 0 auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        width: '100%',
    };

    const text0Style = {
        fontFamily: 'IBM Plex Sans',
        fontStyle: 'normal',
        fontWeight: '900',
        fontSize: '24px',
        lineHeight: '36px',
        margin: '20px 0 10px 0',
        color: '#00AD90',
    };

    const text1Style = {
        fontFamily: 'IBM Plex Sans',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#3B3B3B',
    };

    const textContent0Style = {
        width: '100%',
    };

    const infoStyle = {
        display: 'flex',
        flexDirection: 'row',
    };

    const itemListStyle = {
        fontFamily: 'IBM Plex Sans',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '17px',
        lineHeight: '24px',
        textAlign: 'left',
        color: '#000000',
        padding: 0,
        paddingBottom: '30px'
    };

    const divStudiesStyle = {
        height: '73px',
        width: '100%',
        borderRadius: '12px',
        background: 'rgb(219, 241, 255)',
        display: 'flex',
        justifyContent: 'left',
        textAlign: 'left',
        flexDirection: 'column',
        padding: '10px 0 10px 15px',
        marginTop: '15px',
        marginBottom: '-11px',
    };

    const dispatch = useDispatch();
    const uid = useSelector((state) => state?.auth?.uid);
    const { name, birthday } = useSelector(state => state.user?.data?.data);
    const isDoctor = useSelector((state) => state.user?.data?.data?.profile?.type) === 1;

    const profilePictureStyle = {
        width: '108px',
        height: '108px',
        background: '#F1F6F6',
        borderRadius: '1000px',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
    };

    const cursor = { cursor: 'pointer' };

    const listItemStyle = { padding: '20px', cursor: 'pointer', backgroundColor: '#F1F6F6', };

    // Gretting by the moment of the day
    const getDayMoment = () => {
        let hour = new Date().getHours();
        if (hour < 12) return 'Buenos días.';
        if (hour >= 12 && hour < 19) return 'Buenas tardes.';
        if (hour >= 20) return 'Buenas noches.';
    }

    const { width } = useWindowDimensions();
    const age = moment().diff(moment(`${birthday}`, 'DD/MM/YYYY'), 'years');
    const history = useHistory();

    return (
        <Container style={containerStyle}>
            <HelmetTitle
                title="Menu de perfil"
                name="description"
                content="content"
            />
            <Row style={textContent0Style}>
                <div style={{ display: 'flex' }}>
                    <p style={{ ...text1Style, fontWeight: 800, fontSize: '15px', color: '#000000' }}>
                        <b>Bienvenido a tu expediente digital</b>
                    </p>
                </div>
                <div style={infoStyle}>
                    <div style={{ width: (width < 600 ? '70%' : '90%'), textAlign: 'left' }}>
                        <p style={text0Style}>
                            {name ? name : getDayMoment()}
                        </p>
                        <p style={text1Style}>
                            # {uid} <br />
                            {age} años
                        </p>
                    </div>
                    {/* <div id="circle-gradient">
                        <div style={profilePictureStyle}>
                            <p style={{ ...text1Style, margin: 0 }}>Tienes</p>
                            <p style={{ ...text0Style, margin: 0 }}>25,500</p>
                            <p style={{ ...text1Style, margin: 0 }}>puntos</p>
                        </div>
                    </div> */}
                </div>
            </Row>
            {/* <Row style={textContent0Style}>
                <div style={divStudiesStyle}>
                    <p
                        style={{
                            ...text1Style,
                            fontWeight: 800,
                            fontSize: '18px',
                            color: '#000000',
                            margin: '0 0 5px 0'
                        }}
                    >
                        2 Estudios pendientes
                    </p>
                    <p style={{ ...text1Style, fontSize: '14px' }}>
                        Número de orden: 1110258895
                    </p>
                </div>
            </Row> */}
            <Row style={{ ...textContent0Style, marginTop: '30px' }}>
                <ListGroup style={itemListStyle}>
                    {/* <ListGroup.Item
                        style={listItemStyle}
                        onClick={() => history.push()}
                    >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ width: '95%' }}>
                                <UilAward size="30" color="#00AD90" style={{ marginRight: 15 }} />
                                <b style={cursor}>Membresía</b>
                            </div>
                            <UilAngleRightB size="30" color="#00AD90" />
                        </div>
                    </ListGroup.Item> */}
                    <ListGroup.Item
                        style={listItemStyle}
                        onClick={() => history.push('/datos-personales')}
                    >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ width: '95%' }}>
                                <UilUserCircle size="30" color="#00AD90" style={{ marginRight: 15 }} />
                                <b style={cursor}>Datos personales</b>
                            </div>
                            <UilAngleRightB size="30" color="#00AD90" />
                        </div>
                    </ListGroup.Item>
                    {isDoctor && (
                        <>
                        <ListGroup.Item
                            style={listItemStyle}
                            onClick={() => history.push('/mis-paquetes')}
                        >
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ width: '95%' }}>
                                    <UilParcel size="30" color="#00AD90" style={{ marginRight: 15 }} />
                                    <b style={cursor}>Mis paquetes</b>
                                </div>
                                <UilAngleRightB size="30" color="#00AD90" />
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item
                            style={listItemStyle}
                            onClick={() => history.push('/pacientes')}
                        >
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ width: '95%' }}>
                                    <UilUsersAlt size="30" color="#00AD90" style={{ marginRight: 15 }} />
                                    <b style={cursor}>Mis pacientes</b>
                                </div>
                                <UilAngleRightB size="30" color="#00AD90" />
                            </div>
                        </ListGroup.Item>
                        </>
                    )}
                    <ListGroup.Item
                        style={listItemStyle}
                        onClick={() => history.push('/historial-medico')}
                    >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ width: '95%' }}>
                                <UilFileMedicalAlt size="30" color="#00AD90" style={{ marginRight: 15 }} />
                                <b style={cursor}>Historial médico</b>
                            </div>
                            <UilAngleRightB size="30" color="#00AD90" />
                        </div>
                    </ListGroup.Item>
                    {/* <ListGroup.Item
                        style={listItemStyle}
                        onClick={() => history.push()}
                    >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ width: '95%' }}>
                                <UilHeart size="30" color="#00AD90" style={{ marginRight: 15 }} />
                                <b style={cursor}>Estudios guardados</b>
                            </div>
                            <UilAngleRightB size="30" color="#00AD90" />
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                        style={listItemStyle}
                        onClick={() => history.push()}
                    >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ width: '95%' }}>
                                <UilSetting size="30" color="#00AD90" style={{ marginRight: 15 }} />
                                <b style={cursor}>Configuración</b>
                            </div>
                            <UilAngleRightB size="30" color="#00AD90" />
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                        style={listItemStyle}
                        onClick={() => history.push()}
                    >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ width: '95%' }}>
                                <UilMegaphone size="30" color="#00AD90" style={{ marginRight: 15 }} />
                                <b style={cursor}>Invita a alguien</b>
                            </div>
                            <UilAngleRightB size="30" color="#00AD90" />
                        </div>
                    </ListGroup.Item> */}
                    <ListGroup.Item
                        style={listItemStyle}
                        onClick={() => history.push('/centro-de-ayuda')}
                    >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ width: '95%' }}>
                                <UilFileQuestionAlt size="30" color="#00AD90" style={{ marginRight: 15 }} />
                                <b style={cursor}>Quejas y Sugerencias</b>
                            </div>
                            <UilAngleRightB size="30" color="#00AD90" />
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                        style={listItemStyle}
                        onClick={() => dispatch(startLogout())}
                    >
                        <UilSignOutAlt size="30" color="#00AD90" style={{ marginRight: 15 }} />
                        <b style={cursor}>Salir</b>
                    </ListGroup.Item>
                </ListGroup>
                
            </Row>
        </Container>
    )
}
