import { types } from "../types/types";

const initialState = null;

export const currentCheckinReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.setCurrentCheckin:
            return action.payload;

        default:
            return state;
    }

}