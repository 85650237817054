import React from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { CATEOGORIES, TEXT_BLACK } from '../colors';
import { BlogSearcher } from '../blogSearcher/blogSearcher';
import styled from 'styled-components';
import { UilArrowLeft } from '@iconscout/react-unicons';
import { Container } from 'react-bootstrap';
import { GREEN_LAB } from '../../../helpers/colors';

const StyledFragment = styled.div`
  #back-btn {
    position: absolute;
    top: 0px;
    left: 10px;
    font-size: 15px;
    font-weight: 400;
    cursor: pointer;
  }
  #bread-crumbs {
    text-align: center;
    color: ${TEXT_BLACK};
    font-size: 15px;
    font-weight: 400;
  }
  h1 {
    color: #474A4B;
    text-align: center;
    font-family: Faustina;
    font-size: 100px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
  #hashtag {
    color: #00AD90;
    text-align: center;
    font-family: IBM Plex Sans;
    font-size: 19px;
    font-style: italic;
    font-weight: 500;
    line-height: 26px; /* 136.842% */
  }
  #category-detail-image {
    height: 309px;
    width: 100%;
    background: url('${props => props.banner}');
    background-position: center;
    background-size: cover;
  }
  #desc {
    max-width: 765px;
    width: 90%;
    margin: 30px auto;
    gap: 15px;
    color: #474A4B;
    text-align: center;
    font-family: IBM Plex Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px; /* 161.111% */
    span {
      font-weight: 700;
    }
  }
  #searcher-div {
    max-width: 654px;
    width: 90%;
    margin: 30px auto;
  }
  #color-hr-header {
    opacity: 1;
    width: 180.905px;
    height: 20px;
    background: ${props => props.color};
    margin-top: 0;
    margin-right: 20%;
    margin-left: auto;
  }
  @media screen and (width <= 768px) {
    #back-btn {
      position: inherit;
      text-align: center;
      margin-bottom: 3px;
    }
    h1 {
      font-size: 60px;
    }
    #color-hr-header {
      width: 45px;
    }
  }
`;

export const CategoryDetailHeader = () => {
  const { category } = useParams();
  const currentCat = CATEOGORIES.find(cat => cat?.link === category);

  return (
    <StyledFragment color={currentCat?.color} banner={currentCat?.banner}>
      <Container className='position-relative mt-2'>
        <p id='back-btn' onClick={()=> window.location.href = '/blog/categorias'}><UilArrowLeft color={GREEN_LAB}/> Regresar</p>
      </Container>
      <p id='bread-crumbs'>Blog / Temáticas / {currentCat?.name}</p>
      <h1>{currentCat?.name}</h1>
      <p id='hashtag'>#HacemosCiencia</p>
      <div id='category-detail-image'/>
      <hr id='color-hr-header'/>
      <p id='desc' dangerouslySetInnerHTML={{ __html: currentCat?.desc }} />
      <div id='searcher-div'>
        <BlogSearcher/>
      </div>
    </StyledFragment>
  );
};
