import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Row, Modal } from "react-bootstrap";
import styled from "styled-components";
import { Search } from "../navbar/search";
import { StudySearch } from "./studySearch";
import {
  UilTimesCircle,
  UilTrashAlt,
  UilCommentExclamation,
  UilTimes,
} from "@iconscout/react-unicons";
import { useSelector } from "react-redux";
import handleAlert from "../../helpers/handleAlert";

const loadingGIF = "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2Fecommerce%2Floading.gif?alt=media&token=629f78d3-10a2-44f9-9143-668f5bc19ace";

const Sub = styled.p`
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  color: #45494a;
  span {
    font-weight: 700;
    color: #000000;
  }
`;

const ContinueButton = styled(Button)`
  font-weight: 800;
  max-width: 548px;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  height: 40px;
  background: #4d99e5;
  border: 2px solid #fff;
  border-radius: 10px;
  margin-top: 24px;
  margin-bottom: 20px;
  transition: all 0.4s ease;
  :focus {
    background: #4d99e5;
  }
  :hover {
    transition: all 0.4s ease;
    color: #4d99e5;
    background: #fff;
  }
  :disabled {
    background: #e2e2e2;
    color: #aaa;
  }
`;
const Estudios = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #45494a;
  text-align: center;
  margin-top: 24px;
`;

const ListElem = styled.div`
  margin: 0 auto;
  max-width: 548px;
  padding: 8px 0;
  position: relative;
  border-bottom: 1px solid rgba(196, 196, 196, 0.5);
  p {
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    color: #a6a6a6;
    margin-bottom: 0px;
    span {
      font-size: 13px;
      color: #a6a6a6;
      font-weight: 800;
    }
  }
  h4 {
    font-weight: 800;
    font-size: 15px;
    line-height: 22px;
    color: #4f90ba;
    max-width: 90%;
    margin-bottom: 8px;
  }
  #delete-study {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translate(0, -50%);
    cursor: pointer;
  }
`;

const Title = styled.h1`
  font-weight: 900;
  font-size: 22px;
  line-height: 33px;
  text-align: center;
  color: #000000;
  margin-bottom: 24px;
`;

const Bottom = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 60;
  border-top: 1px solid #c1d9d8;
  h1 {
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #00ac90;
  }
  #added-studies {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 16px auto 0 auto;
    cursor: pointer;
    @media only screen and (max-width: 768px) {
      width: 80%;
    }
  }
  #added-studies-list {
    width: 55%;
    margin: 16px auto 0 auto;
    border: 1px solid #e9e9e9;
    border-radius: 10px;
    padding: 0 16px 16px 16px;
    @media only screen and (max-width: 768px) {
      width: 80%;
    }
  }
  #delete-study {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
`;

const SearchResults = styled.div`
  position: absolute;
  width: 90%;
  left: 50%;
  transform: translate(-50%);
  z-index: 100;
  background: #fff;
  border-radius: 16px;
  padding: 10px;
  border: 1px solid #e9e9e9;
  box-shadow: 0px 8px 8px rgba(71, 74, 75, 0.15);
`;

const ListDiv = styled(Row)`
  max-height: 200px;
  overflow-y: auto;
  padding-right: 3px;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(79, 143, 186, 0.3);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const MoreInfo = styled.p`
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  color: #4f90ba;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 16px;
`;

const StyledModal = styled(Modal)`
  .modal-dialog {
    height: 80%;
    display: flex;
    align-items: center;
  }
  .modal-header {
    border-bottom: 1px solid #c1d9d8;
  }
  .modal-title {
    font-weight: 900;
    font-size: 16px;
    line-height: 24px;
    color: #4f90ba;
  }
  p {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #717171;
  }
  .btn-close {
    width: 40px;
    height: 40px;
    background: #4f90ba;
    border-radius: 20px;
    padding: 0px !important;
    top: -12px;
    right: 33px;
    opacity: 1;
  }
  svg {
    z-index: 1000;
    top: -13px;
    right: 33px;
    position: absolute;
  }
`;

export const StepTwo = (props) => {
  const [studies, setStudies] = useState(props.data.studies);
  const [searching, setSearching] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [items, setItems] = useState();
  const [viewAdded, setViewAdded] = useState(false);
  const [loading, setLoading] = useState(false);
  const idClienteLabopat = useSelector(
    (state) => state.user?.data?.data?.profile?.idClienteLabopat
  );
  useEffect(() => {
    setLoading(true);
    searching !== "" &&
      axios
        .get(
          `https://lpqsystem.com/searching-products-test?search=${searching}${
            idClienteLabopat ? `&idCliente=${idClienteLabopat}` : ""
          }`
        )
        .then((res) => {
          res?.data?.productos?.map((item) => {
            Object.assign(item, {
              title: item.name,
              price: item.precio_promo,
              oldPrice: item.precio_normal,
            });
          });
          setItems(res?.data?.productos);
          setLoading(false);
        });
  }, [searching]);

  const currency = function (number) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    }).format(number);
  };

  const handleAddItem = (item) => {
    setSearching("");
    let existItem = studies.find((e) => e.id === item.id);
    if (existItem) {
      setStudies([...studies, item]);
    } else {
      setStudies([...studies, item]);
      
    }
  };

  useEffect(() => {
    console.log("PROPPPPPS", props);
  }, [props]);

  const handleDeleteItem = (id) => {
    setStudies(studies.filter((e) => e.id !== id));
  };

  const buttonClick = () => {
    props.onStepTwo(studies);
  };

  document.addEventListener("click", function (event) {
    if (
      !(
        event.target.id === "search-results" ||
        event.target.id === "search-results-div" ||
        event.target.id === "search-input" ||
        event.target.parentNode.closest("#search-results")
      )
    ) {
      setSearching("");
    }
  });

  return (
    <>
      <Title>2. Agrega los estudios</Title>
      <Sub>
        Selecciona los estudios que quieres guardar en:
        <br />"<span>{props.data.name}</span>"
      </Sub>
      <div
        style={{ maxWidth: "548px", marginLeft: "auto", marginRight: "auto" }}
      >
        <Search
          setSearching={setSearching}
          hideSearch={() => null}
          showSearch={() => null}
        />
      </div>
      {searching !== "" && (
        <div style={{ position: "relative" }} id="search-results">
          <SearchResults id="search-results-div">
            <p style={{ textAlign: "center", marginBottom: "5px" }}>
              Sugerencias de la busqueda:
            </p>
            <UilTimesCircle
              style={{
                position: "absolute",
                right: "5px",
                top: "5px",
                cursor: "pointer",
                zIndex: "100",
              }}
              color="#A6A6A6"
              onClick={() => {
                setSearching("");
              }}
            />
            {loading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "120px",
                  width: "100%",
                }}
              >
                <img src={loadingGIF} alt="loading" width={75} />
              </div>
            ) : items?.length !== 0 ? (
              <ListDiv>
                {items?.map((item, index) => (
                  <StudySearch
                    key={index}
                    data={item}
                    xs={12}
                    addItem={handleAddItem}
                    studies={studies}
                    idClienteLabopat={idClienteLabopat}
                    mainColor="#4F90BA"
                  />
                ))}
              </ListDiv>
            ) : (
              <p
                style={{
                  textAlign: "center",
                  marginBottom: "25px",
                  marginTop: "25px",
                }}
              >
                No hay resultados
              </p>
            )}
          </SearchResults>
        </div>
      )}
      {studies?.length >= 1 && (
        <>
          <Estudios>{studies?.length} Estudios agregados:</Estudios>
          {studies?.map((study, index) => (
            <ListElem key={index}>
              <h4>{study.title}</h4>
              <p>
                Precio de convenio:{" "}
                <span>{currency(study.price)} MXN</span>
              </p>
              <UilTrashAlt
                onClick={() => handleDeleteItem(study.id)}
                id="delete-study"
                color="#4F90BA"
              />
            </ListElem>
          ))}
        </>
      )}
      <Row className="justify-content-center">
        <ContinueButton
          variant="primary"
          onClick={buttonClick}
          disabled={studies?.length === 0}
        >
          Guardar paquete
        </ContinueButton>
        <p
          style={{
            fontWeight: "400",
            fontSize: "16px",
            color: "#45494A",
            marginTop: "8px",
            marginBottom: "0px",
            textAlign: "center",
          }}
        >
          Puedes nombrar el paquete de estudios cómo: “Check up femenino”
        </p>
        <MoreInfo onClick={() => setShowModal(true)}>
          <UilCommentExclamation />
          Más información&nbsp;
        </MoreInfo>
        {showModal && (
          <StyledModal
            show={showModal}
            onHide={() => {
              setShowModal(false);
            }}
          >
            <div onClick={() => setShowModal(false)}>
              <UilTimes color="#fff" style={{ cursor: "pointer" }} />
            </div>
            <Modal.Header closeButton>
              <Modal.Title>Paquetes de estudios</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Puedes guardar varios estudios en un sólo paquete y encontrarlo
                rapidamente desde el buscador.
                <br />
                <br />
                También podrás compartirlo con tus pacientes las veces que sea
                necesario y generar su orden de estudios.
              </p>
            </Modal.Body>
          </StyledModal>
        )}
      </Row>
    </>
  );
};
