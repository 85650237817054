import React, { useEffect, useState } from 'react';
import MexicoMap from './MexicoMap.svg';
import styled from 'styled-components';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { UilLocationPinAlt, UilSearchPlus, UilTimes } from '@iconscout/react-unicons';

const allowedStates = [
  {
    id: "state2",
    name: "Puebla",
    // sedes: [
    //   { name: 'Labopat Puebla', address: 'Palmas Plaza, Av Zeta del Cochero 403, Reserva Territorial Atlixcáyotl, 72820 San Andrés Cholula, Pue.'},
    //   { name: 'Hospital Puebla', address: 'Priv. de las Ramblas 4, Reserva Territorial Atlixcáyotl, Corredor Comercial Desarrollo Atlixcayotl, 72197 Puebla, Pue.'},
    //   { name: 'Hospital Angeles Puebla', address: 'Av. Kepler 2143, Reserva Territorial Atlixcáyotl, 72190 Puebla, Pue.' },
    //   { name: 'Hospital Christus Muguerza UPAEP', address: 'Av 5 Pte 715, Centro, 72000 Puebla, Pue.'},
    // ],
    sucursales: [
      { name: 'Palmas plaza',
        address: 'Local 20, Palmas Plaza, Av Zeta del Cochero 403, Reserva Territorial Atlixcáyotl, 72820 San Andrés Cholula, Pue.',
        image: 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fsucursales%2FPalmas.png?alt=media&token=31dd89f7-55d3-42a1-b24e-9934839f0c4f'
      },
      { name: 'Torres médicas',
        address: 'Torres Médicas Angelópolis 2. Anillo Perif. Ecológico 3507, local 15 y 16 Tlaxcalancingo, Puebla 72821',
        image: 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fsucursales%2FTorres%20(1).png?alt=media&token=e085b667-2947-49c2-9262-95a867d8566b'
      },
      { name: 'La paz',
        address: 'Plaza Pabellón R&M. Av. Rosendo Márquez 4122, local 5 Colonia La Paz, 72160 Puebla, Pue.',
        image: 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fsucursales%2FZavaleta.png?alt=media&token=ce00a37a-55e7-4d12-a104-e2ad00496875'
      },
      { name: 'Zavaleta',
        address: 'Plaza Bosques De Zavaleta, Calz Zavaleta 133-A, local 14 Santa Cruz Buenavista, 72150 Puebla, Pue.',
        image: 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fsucursales%2FZavaleta.png?alt=media&token=ce00a37a-55e7-4d12-a104-e2ad00496875'
      },
    ],
    mobileAdjust: '-90%, -33%'
  },
  // {
  //   id: "state3",
  //   name: "CDMX",
  //   sedes: [
  //     { name: 'Hospital Angeles México', address: 'C. Agrarismo 208, Escandón II Secc, Miguel Hidalgo, 11800 Ciudad de México, CDMX' },
  //     { name: 'Hospital Angeles Pedregal', address: 'Camino Sta. Teresa 1055-S, Heroes de Padierna, Héroes de Padierna, La Magdalena Contreras, 10700 Ciudad de México, CDMX' },
  //     { name: 'Hospital Angeles Universidad', address: 'Av. Universidad 1080, Xoco, Benito Juárez, 03330 Ciudad de México, CDMX' },
  //     { name: 'Hospital Angeles Santa Mónica', address: 'Temístocles 210, Polanco, Polanco IV Secc, Miguel Hidalgo, 11560 Ciudad de México, CDMX' },
  //   ],
  //   sucursales: [],
  //   mobileAdjust: '-85%, -30%'
  // },
  {
    id: "state28",
    name: "Aguascalientes",
    sedes: [],
    sucursales: [
      { name: 'Labopat Aguascalientes', address: 'Blvd. Luis Donaldo Colosio Murrieta 208, Lomas del Campestre II, 20119 Aguascalientes, Ags.', image: 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fsucursales%2FAguascalientes.png?alt=media&token=aeb384b0-17a2-478d-b73b-2cca968933a0'},
    ],
    mobileAdjust: '-72%, -20%'
  },
];

const StyledFloating = styled.div`
    width: ${({width}) => width}px;
    padding: ${({padding}) => padding};
    margin-left: -${({marginLeft}) => marginLeft}px;
    display: ${({display}) => display};
    position: absolute;
    opacity: 0;
    border-radius: 40px;
    border: 1px solid #E0ECEC;
    background: linear-gradient(0deg, #FFF 77.72%, rgba(255, 255, 255, 0.00) 77.72%), #FFF;
    box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.15);
    transition: all 0.3s ease;
    z-index: 2;
    h1 {
      color: #30DF99;
      text-align: center;
      font-size: 25px;
      font-weight: 600;
      line-height: normal;
      cursor: pointer;
      margin-bottom: 0;
    }
    hr {
      width: 100%;
      margin-bottom: -10px;
    }
    h2 {
      color: #C4C4C4;
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      line-height: normal;
      background: #FFF;
      z-index: 1;
      padding: 0 8px;
    }
    h3 {
      color: #474A4B;
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      line-height: normal;
      cursor: pointer;
      text-decoration: underline;
    }
    p {
      color: #474A4B;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      line-height: normal;
    }
    #close-button {
      cursor: pointer;
      width: 40px;
      height: 40px;
      border-radius: 20px;
      position: absolute;
      top: -10px;
      right: -10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #E0ECEC;
      background: linear-gradient(0deg, #FFF 77.72%, rgba(255, 255, 255, 0.00) 77.72%), #FFF;
      box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.15);
    }
    @media only screen and (max-width: 768px) {
      display: none;
    }
`;

const MainDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  padding: 50px 0;
  #space-div {
    height: 830px;
    position: relative;
  }
  @media only screen and (max-width: 768px) {
    padding: 20px 0;
  }
`;

const StyledDiv = styled.div`
  width: auto;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  font-family: IBM Plex Sans;
  max-width: 1112px;
  margin: 0 auto;
  .row div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }
  @media only screen and (max-width: 768px) {
    transform: translate(-57%, 0);
  }
`;

const DefinitionDiv = styled.div`
  position: absolute;
  right: 0;
  top: 50px;
  border-radius: 20px;
  background: #FFF;
  padding: 10px;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  p {
    text-align: center;
    margin: 0;
    color: #474A4B;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
  }
  @media only screen and (max-width: 768px) {
    // width: 100vw;
    margin-left: -.75rem;
    border-radius: 0;
    position: inherit;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: -200px;
    z-index: 2;
    hr {
      display: none;
    }
    div {
      padding: 0 14px;
      // flex-direction: inherit;
    }
    p {
      padding: 0 5px;
    }
  }
`;

const ConnectWithUs = styled.div`
  position: absolute;
  left: 0;
  bottom: 72px;
  h1 {
    color: #474A4B;
    text-align: center;
    font-size: 70px;
    font-weight: 300;
    line-height: 109%;
    max-width: 430px;
    margin-bottom: 10px;
  }
  p {
    color: #474A4B;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.2px;
  }
  a {
    color: #30DF99;
    font-weight: 600;
    text-decoration: underline;
  }
  @media only screen and (max-width: 992px) {
    h1 {
      font-size: 30px;
    }
    p {
      font-size: 16px;
    }
  }
  @media only screen and (max-width: 768px) {
    position: inherit;
    top: 0;
    z-index: 2;
    h1 {
      max-width: 100%;
    }
  }
`;

const ButtonsMobile = styled.div`
  width: 100%;
  padding: 18px;
  z-index: 2;
  margin-top: -230px;
  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

const MobileOffice = styled.div`
  width: 100%;
  border-radius: 10px;
  padding: 10px 20px;
  background: ${({isHovered}) => isHovered ? '#FFF' : '#30DF99'};
  margin-bottom: 20px;
  transition: all 0.3s ease !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  h1 {
    color:  ${({isHovered}) => isHovered ? '#30DF99' : '#F3F3F3'};
    text-align: center;
    font-size: 25px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0;
    transition: all 0.3s ease;
  }
  text-align: center;
  hr {
    width: 100%;
    margin-bottom: -10px;
    margin-top: 25px;
    z-index: 1;
  }
  h2 {
    color: #C4C4C4;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    background: #FFF;
    z-index: 2;
    padding: 0 8px;
  }
  h3 {
    color: #474A4B;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    text-decoration: underline;
    cursor: pointer;
  }
  p {
    color: #474A4B;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
  }
`;

const StyledModal = styled(Modal)`
  .modal-dialog {
    width: 100%;
    max-width: 321px !important;
    margin: 0 auto;
  }
  .modal-content {
    width: 100%;
    max-width: 321px !important;
    border-radius: 10px;
    overflow: hidden;
  }
  .modal-body {
    background: ${({bg}) => `url('${bg}') no-repeat center`};
    padding: 20px;
    transition: all 0.4s ease;
    position: relative;
    :hover {
      filter: grayscale(0);
      transition: all 0.4s ease;
      #text-div {
        transition: all 0.4s ease;
        opacity: 0;
      }
      #close-button {
        opacity: 1;
        transition: all 0.4s ease;
      }
    }
  }
  h2 {
    color: var(--Negro, #474A4B);
    text-align: center;
    font-family: "IBM Plex Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  p {
    color: var(--Negro, #474A4B);
    text-align: center;
    font-family: "IBM Plex Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 33px;
  }
  img {
    width: 100%;
    margin-bottom: 10px;
  }
  #buttons-div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  #back-btn {
    color: var(--Grayscale-02, #C4C4C4);
    text-align: center;
    font-family: "IBM Plex Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    padding: 5px;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    border-radius: 40px;
    border: 1px solid var(--Grayscale-02, #C4C4C4);
    background: none;
    width: 100px;
  }
  #info-btn {
    background: none;
    color: var(--Blanco, #F3F3F3);
    text-align: center;
    font-family: "IBM Plex Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    padding: 5px;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    border-radius: 40px;
    background: var(--CA-BOTONES, linear-gradient(79deg, #5097E3 -41.6%, #0CAC8C 26.14%, #30DF99 84.04%, #CEF719 107.01%, #F5EC1D 132.38%));
    border: none;
    width: 160px;
  }
`;

const MapComponent = () => {
  const [hoveredState, setHoveredState] = useState(undefined);
  const [showMore, setShowMore] = useState(false);
  const [isShownPhoto, setIsShownPhoto] = useState(["", "", ""]);
  const number = "522212311313";

  const handleClose = () => {
    setShowMore(false);
    setHoveredState(false);
  }

  const handleMouseEnter = (event) => {
    const floatingUI = document.getElementById('floatingUI');
    // Get the ID of the state
    const stateId = event.target.id;

    // Check if the state is from allowed array
    if(allowedStates?.some(allowed => allowed?.id === stateId)){
      // Change the fill color
      event.target.setAttribute('cursor', 'pointer');
      event.target.setAttribute('fill', '#30DF99');
      const myState = allowedStates?.find(allowed => allowed?.id === stateId)
      setHoveredState(myState);
      const svgElement = event.target.closest('svg'); // Find the parent SVG
      const svgRect = svgElement.getBoundingClientRect(); // Get the SVG's bounding box
      const pathRect = event.target.getBoundingClientRect(); // Get the path's bounding box
    
      // Calculate horizontal center
      const centerX = (pathRect.left - svgRect.left + pathRect.width / 2);
      // Calculate vertical center
      const centerY = pathRect.top - svgRect.top - 10;
    
      // Position and display the floating UI
      floatingUI.style.bottom = `${svgRect.height - centerY}px`; // Set 'bottom' to the vertical center
      floatingUI.style.opacity = '1';
      floatingUI.style.left = `${centerX}px`; // Set 'left' to the horizontal center
    }
  };

  useEffect(()=>{
    const mexicoMapObject = document.getElementById('mexicoMapObject');
    const svgDoc = mexicoMapObject.contentDocument;
      // myMapSvg
      if (svgDoc) {
        // To set the drop shadow to all the map
        const svg = svgDoc.querySelectorAll('svg');
        svg[0]?.setAttribute('filter', 'drop-shadow(-10px 20px 1px rgba(0, 0, 0, 0.1))');

        // Query the <path> elements within the SVG
        const paths = svgDoc.querySelectorAll('path');
  
        // Loop through the paths and log their IDs
        paths.forEach((path) => {
          // Check if the state is from allowed array
          if(allowedStates?.some(allowed => allowed?.id === path?.id) && hoveredState?.id !== path?.id){
            // Change the fill color
            path.setAttribute('fill', '#d3d3d3');
          }
        });
      }
  },[hoveredState]);

  useEffect(() => {
    const mexicoMapObject = document.getElementById('mexicoMapObject');

    const handleSvgLoad = () => {
      const svgDoc = mexicoMapObject.contentDocument;
      // myMapSvg
  
      if (svgDoc) {
        // To set the drop shadow to all the map
        const svg = svgDoc.querySelectorAll('svg');
        svg[0]?.setAttribute('filter', 'drop-shadow(-10px 20px 1px rgba(0, 0, 0, 0.1))');

        // Query the <path> elements within the SVG
        const paths = svgDoc.querySelectorAll('path');
  
        // Loop through the paths and log their IDs
        paths.forEach((path) => {
          // Check if the state is from allowed array
          if(allowedStates?.some(allowed => allowed?.id === path?.id)){
            // Change the fill color
            path.setAttribute('fill', '#d3d3d3');
            // Set the cursor to pointer on hover
            path.style.cursor = 'pointer';
            path.style.transition = 'all 0.3s ease';
          }
        });
  
        // Add event listeners to each state
        paths.forEach((state) => {
          state.addEventListener('mouseenter', handleMouseEnter);
        });
  
        // Clean up event listeners when the component unmounts
        return () => {
          paths.forEach((state) => {
            state.removeEventListener('mouseenter', handleMouseEnter);
          });
        };
      } else {
        console.error('SVG document not loaded');
      }
    };
  
    // Add the event listener for SVG load
    mexicoMapObject.addEventListener('load', handleSvgLoad);
  
    // Clean up the SVG load event listener when the component unmounts
    return () => {
      mexicoMapObject.removeEventListener('load', handleSvgLoad);
    };
  }, []);

  const handleAllowedEnter = (tempState) => {
    setHoveredState(tempState);
    // Get map
    const mexicoMapObject = document.getElementById('mexicoMapObject');
    const mapDiv = document.getElementById('mapDiv');
    // Get svg
    const svgDoc = mexicoMapObject?.contentDocument;
    // Use querySelector to get the path by ID
    const path = svgDoc?.querySelector(`path#${tempState?.id}`);

    // Set color fill
    path?.setAttribute('fill', '#30DF99');
    
    mexicoMapObject.style.transition = 'width 0.2s ease';
    mexicoMapObject.style.width = '1600px';

    mapDiv.style.transition = 'transform 0.3s';
    mapDiv.style.transform = `translate(${tempState?.mobileAdjust})`;
  }

  const handleAllowedLeave = (tempState) => {
    setHoveredState(undefined);
    // Get map
    const mexicoMapObject = document.getElementById('mexicoMapObject');
    // Get svg
    const svgDoc = mexicoMapObject?.contentDocument;
    // Use querySelector to get the path by ID
    const path = svgDoc?.querySelector(`path#${tempState?.id}`);
    // Set color fill
    path?.setAttribute('fill', '#d3d3d3');
    // 
    mexicoMapObject.style.width = '1112px';
    // 
    const mapDiv = document.getElementById('mapDiv');
    mapDiv.style.transform = `translate(-57%, 0`;
  }

  // const handleClick = (event) => {
  //   console.log(event.target.className);
  // };
  
  // document.addEventListener("click", handleClick);  

  return (
    <MainDiv>
      <Container id='space-div'>
        <ConnectWithUs>
          <h1>Conecta con nosotros</h1>
          <p>Encuéntranos en estos puntos o<br/>escríbenos a la <a 
            href={`https://wa.me/${number}`}
            target="_blank"
            rel="noreferrer">Línea Labopat</a></p>
        </ConnectWithUs>
        <DefinitionDiv>
          <div>
            <img src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2FaboutUs%2FFrame%20364251.png?alt=media&token=3cc6c4ea-e011-4c3d-b044-9b382bed5a27" alt="Sucursales" />
            <p>Atención a público general.</p>
          </div>
          {/* <hr />
          <div>
            <img src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2FaboutUs%2FFrame%2036425.png?alt=media&token=0dcf904f-d3d5-4781-a42a-8b69f4efd8e6" alt="Sede" />
            <p>Servicio de operación subrogada.</p>
          </div> */}
        </DefinitionDiv>
        <StyledDiv id="mapDiv">
          <object 
            id="mexicoMapObject" 
            data={MexicoMap} 
            type="image/svg+xml" 
            aria-label="Mexico Map"
            style={{ 
              width: '1112px',
              // transition: 'all 0.3s ease',
              transition: 'transform 0.3s ease'
            }}
          >
            Your browser does not support SVGs
          </object>
          <StyledFloating 
            id="floatingUI" 
            width={293}
            // width={hoveredState?.sucursales?.length >= 1 ? '572' : '323'}
            padding={ hoveredState ? '20px 10px' : '0'}
            marginLeft={161}
            display={hoveredState ? 'block' : 'none'}
            // marginLeft={ hoveredState?.sucursales?.length >= 1 ? '286' : '161'}
          >
            {hoveredState?.id?.length >= 1 ?
              <>
              <div id='close-button' onClick={() => handleClose()}><UilTimes/></div>
              <h1 onClick={() => setShowMore(true)}>{!showMore ? <><UilSearchPlus size={30}/> Ver</> : <UilLocationPinAlt size={30}/> } {hoveredState?.name}</h1>
              {showMore ?
                <Row>
                  {/* <Col xs={hoveredState?.sucursales?.length >= 1 ? 6 : 12}>
                    <hr/>
                    <h2>Sede{hoveredState?.sedes?.length >= 2 ? 's' : ''}</h2>
                    {hoveredState?.sedes?.map((sed)=>
                      <>
                      <h3>{sed?.name}</h3>
                      <p>{sed?.address}</p>
                      </>
                    )}
                  </Col> */}
                  {hoveredState?.sucursales?.length >= 1 ?
                    <Col xs={12}>
                      {hoveredState?.sucursales?.length > 1 ?
                        <>
                          <hr/>
                          <h2>Sucursales</h2>
                        </>
                      : null}
                      {hoveredState?.sucursales?.map((suc)=>
                        <>
                        <h3 onClick={() => setIsShownPhoto([suc, 1, hoveredState?.name])}>{suc?.name}</h3>
                        <p>{suc?.address}</p>
                        </>
                      )}
                    </Col>
                  : null}
                </Row>
              : null}
              </>
            : null}
            </StyledFloating>
        </StyledDiv>
      </Container>
      <ButtonsMobile>
        {allowedStates?.map((allowedSta) => 
          <MobileOffice 
            isHovered={hoveredState?.name === allowedSta?.name}
            onClick={ () => handleAllowedEnter(allowedSta) }
            onMouseLeave={ () => handleAllowedLeave(allowedSta) }
            className='buttons-states'
          >
            <h1><UilLocationPinAlt size={35} style={{marginTop: '-5px'}}/>{allowedSta?.name}</h1>
            {hoveredState?.name === allowedSta?.name ?
              <>
              {/* <hr/>
              <h2>Sede{hoveredState?.sedes?.length >= 2 ? 's' : ''}</h2>
              {hoveredState?.sedes?.map((sed)=>
                <>
                <h3>{sed?.name}</h3>
                <p>{sed?.address}</p>
                </>
              )} */}
              {hoveredState?.sucursales?.length >= 1 ?
                <>
                <hr/>
                <h2>Sucursales</h2>
                {hoveredState?.sucursales?.map((suc)=>
                  <>
                  <h3 onClick={() => setIsShownPhoto([suc, 1, hoveredState?.name])}>{suc?.name}</h3>
                  <p>{suc?.address}</p>
                  </>
                )}
                </>
              : null}
              </>
            : null}
          </MobileOffice>
        )}
      </ButtonsMobile>
      <StyledModal
        show={isShownPhoto[1] === 1}
        onHide={() => setIsShownPhoto(["", "", ""])}
        dialogClassName="modal-dialog-slide"
        centered
      >
        <Modal.Body>
            <h2>{isShownPhoto[0].name}</h2>
            <p>{isShownPhoto[0].address}</p>
            <img src={isShownPhoto[0].image} alt="Sucursal" />
            <div id='buttons-div'>
              <button id='back-btn' onClick={() => setIsShownPhoto(["", "", ""])}>Volver</button>
              <button id='info-btn' onClick={() => window.location.href = isShownPhoto[2] === "Puebla" ? '/sucursales' : '/aguascalientes'}>Más información</button>
            </div>
        </Modal.Body>
      </StyledModal>
    </MainDiv>
  );
}

export default MapComponent;
