import axios from "axios";

const idListNewUsers = "f73b8c8576";
const idListDoctors = "3a5d6fd209";

const domain = "https://labopat-5792e.appspot.com"

// Get lists
export const getLists = () => {
  axios.get(`${domain}/lists-mailchimp`)
  .then((res) => {
    return res.data;
  })
  .catch((error) => {
    return undefined;
  })
}

// Add member to the list
export const addMember = async (email, doctor = false) => {
  axios.get(`${domain}/add-member-mailchimp?idList=${doctor ? idListDoctors : idListNewUsers}&email=${email}`)
  .then((res) => {
    return res.data;
  })
  .catch((error) => {
    return error;
  })
}