
export const types = {
    // Auth
    login: '[Auth] Login',
    isNew: '[Auth] Is new user?',
    logout: '[Auth] Logout',
    isLogged: '[Auth] Is logged?',

    // User info
    userInfo: '[User] Info',
    cleanSession: '[User] Clean session',

    // Phone Number verification and reCAPTCHA
    phoneVerification: '[Auth] Phone Verification',
    recaptcha: '[Auth] reCAPTCHA',
    codeVerification: '[Auth] Code Verification',

    // UI
    uiSetError: '[UI] Set Error',
    uiRemoveError: '[UI] Remove Error',

    // Loading
    uiStartLoading: '[UI] Start loading',
    uiFinishLoading: '[UI] Finish loading',

    // Spinner
    showSpinner: '[Spinner] Show or don\'t show spinner',

    // API
    api: '[API] Get API url',

    // Shopping cart
    addItemToCart: '[Shopping cart] Add item',
    removeItemFromCart: '[Shopping cart] Remove item',
    totalPrice: 'Shopping cart] Total',
    resetCart: '[Shopping cart] Reset cart',
    addPersonalData: '[Shopping cart] Add personal data',

    // ResisterTemp
    setRegisterTmp: '[Register Temp] Add register data',
    resetRegisterTmp: '[Register Temp] Reset register data',

    // AUX
    aux: '[Aux]',
    offerBanner: '[Offer Banner]',

    // Patient orders
    patientOrders: '[Orders] Get patient orders',

    // Checkin
    setCheckin: "[Checkin] Asignar checkins",
    setCurrentCheckin: "[Current Checkin] Asignar checkin actual",
}
