import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { UilArrowLeft } from '@iconscout/react-unicons';

const Title = styled.h1`
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 115%;
  color: #00AD90;
  @media only screen and (min-width: 768px) {
    font-size: 38px;
  }
`;

const BackBtn = styled(Button)`
  background: none;
  border: none;
  margin-bottom: 18px;
`;

const RegularP = styled.p`
  font-size: 15px;
`;

const CategoryIcon = styled.img`
  width: 52px;
  @media only screen and (min-width: 768px) {
    width: 76px;
  }
`;

export const Banner = (props) => {
  return (
    <div style={{
      background: "rgba(0, 173, 144, 0.1)", 
      paddingTop: "15px",
      paddingBottom: "40px"
      }}>
      <Container>
        <BackBtn variant='light' onClick={() => window.history.back()}>
          <UilArrowLeft color='#00AD90' size='26' />
          Regresar
        </BackBtn>
        <Row>
          <Col xs={2} lg={{span: 2, offset: 2}} xl={{span: 1, offset: 3}} className="d-flex justify-content-center align-items-start">
            <CategoryIcon src={props.data.icono} alt={props.data.nombre}/>
          </Col>
          <Col xs={10} lg={{span: 6, offset: 0}} xl={{span: 6, offset: 0}}>
            <Title>{props.data.nombre}</Title>
            <RegularP>{props.data.descripcion}</RegularP>
          </Col>
        </Row>
      </Container>
    </div>
  )
};