import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { currency, studyInCart } from '../../../helpers/cart';
import { useDispatch, useSelector } from 'react-redux';
import handleAlert from '../../../helpers/handleAlert';
import { handleAddItemToCart } from '../../../actions/shoppingCart';
import { CA_GRADIENT_BUTTON, GREEN_LAB, TEXT_BLACK } from '../../../helpers/colors';

const Background = styled.div`
  background: #FFF;
  overflow: hidden;
  position: relative;
`;

const Slide = styled(Container)`
  height: 460px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .row {
    width: 100%;
  }
  #top-text {
    color: ${TEXT_BLACK};
    text-align: center;
    font-size: 32px;
    font-weight: 400;
    line-height: normal;
    max-width: 1020px;
    span {
      background: var(--OUTLINE-CARDS-LABOPAT, linear-gradient(90deg, #5097E3 -97.58%, #0CAC8C -34.75%, #30DF99 77.36%, #CEF719 119.25%, #F5EC1D 138.96%));
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 600;
      line-height: normal;
    }
  }
  #date {
    color: ${TEXT_BLACK};
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    margin: 0 auto;
    text-align: center;
    margin-top: 20px;
  }

  @media only screen and (max-width: 992px) {
    height: 520px;
    #top-text {
      font-size: 17px;
      margin-top: 15px;
      margin-bottom: 10px;
    }
    #date {
      font-size: 14px;
      margin-top: 0;
    }
  }
`;


const Card = styled.div`
  height: 220px;
  border-radius: 10px;
  border: 2px solid #5097E3;
  background: #FFF;
  box-shadow: 0px 0px 10px 0px rgba(48, 223, 153, 0.50);
  padding: 18px 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  h1 {
    color: ${TEXT_BLACK};
    text-align: center;
    font-size: 32px;
    font-weight: 400;
    line-height: normal;
  }
  p {
    color: ${GREEN_LAB};
    font-family: IBM Plex Sans;
    font-size: 20px;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  button {
    color: #F1F1F1;
    text-align: center;
    font-size: 29px;
    font-weight: 400;
    padding: 11px 40px;
    border-radius: 10px;
    background: ${CA_GRADIENT_BUTTON};
    border: none;
    transition: all 0.4s ease;
    margin: 0 auto;
    :hover {
      filter: brightness(1.2);
    }
    @media only screen and (max-width: 1150px) {
      font-size: 22px;
    }
    @media only screen and (max-width: 768px) {
      font-size: 20px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  @media only screen and (max-width: 992px) {
    height: 110px;
    padding: 6px 10px;
    margin: 6px auto;
    h1 {
      font-size: 20px;
      margin-bottom: 5px;
    }
    p {
      font-size: 14px;
      margin-bottom: 5px;
    }
    button {
      font-size: 16px;
      padding: 5px 35px;
    }
  }
`;

export const PatDiabetesBanner = ({studies = []}) => {
  const { items } = useSelector((state) => state.shoppingCart);
  const dispatch = useDispatch();

  const addStudy = (data) => {
    const exists = studyInCart(data, items);
    if (exists?.exists) {
      handleAlert("warning", exists?.message, "Estudio/paquete ya agregado");
    } else {
      dispatch(handleAddItemToCart(data));
    }
  };

  return (
    <Background>
      <Slide>
        <p id='top-text'>Toma <span>decisiones informadas sobre tu salud con nuestros estudios</span> para prevención y seguimiento de diabetes.</p>
        <Row>
          {
            studies?.length >= 1 ?? false ? 
              studies?.map((stu, index)=>
                <Col xs={12} lg={4} xxl={4} id='study' key={index}>
                  <Card>
                    <h1>{stu?.title ?? ''}</h1>
                    <p>Pago en línea: {currency(stu?.price)} MXN</p>
                    <button onClick={() => addStudy(stu)}>Compra estudio</button>
                  </Card>
                </Col>
              )
            : null
          }
        </Row>
        <p id='date'>Vigencia al 30 de noviembre de 2023.</p>
      </Slide>
    </Background>
  );
};
