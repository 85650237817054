import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';

const Title = styled.h1`
  font-weight: 800;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: #00AD90;
  margin: 97px auto 80px;
  padding-top: 100px;
  padding-bottom: 100px;
  background: #E9FBF4;
  @media only screen and (max-width: 768px) {
    font-size: 36px;
    line-height: 40px;
    padding: 38px 45px;
  }
`;

const Learn = styled.div`
  position: relative;
  margin-bottom: 133px;
  h2 {
    font-weight: 800;
    font-size: 32px;
    line-height: 42px;
    color: #00AD90;
    @media only screen and (max-width: 992px) {
      font-weight: 500;
      font-size: 24px;
      line-height: 34px;
      text-align: center;
      margin: 20px auto 0;
    }
  }
  p {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #474A4B;
    max-width: 387px;
    @media only screen and (max-width: 992px) {
      display: none;
    }
  }
  #gray-back {
    background: #E5F5F1;
    position: absolute;
    height: 468px;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: -1;
    @media only screen and (max-width: 992px) {
      display: none;
    }
  }
  #gray-back-mobile {
    background: #E5F5F1;
    position: absolute;
    height: 251px;
    width: 260px;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: -1;
    @media only screen and (min-width: 992px) {
      display: none;
    }
  }
  #cooking {
    max-width: 300px;
    width: 80%;
    margin: 0 auto;
    @media only screen and (max-width: 992px) {
      width: 180px;
    }
  }
  #exercise {
    margin-left: -30%;
    margin-right: 0;
    max-width: 720px;
    width: 130%;
    @media only screen and (max-width: 992px) {
      width: 100%;
      max-width: 400px;
      margin: 0 auto !important;
    }
  }
  #meditar {
    max-width: 480px;
    width: 80%;
    margin: 0 auto;
    @media only screen and (max-width: 992px) {
      width: 250px;
    }
  }
  #first-title {
    max-width: 393px;
  }
  #second-title {
    max-width: 393px;
  }
  #third-title {
    max-width: 432px;
  }
`;

export const Learnings = () => {
  return (
    <>
    <Title>“3 meses de aprendizajes y herramientas para toda la vida.”</Title>
    <Container>
      <Learn>
        <Row>
          <Col xs={{span:12, order: 1}} lg={{span: 6, order: 1}} className='d-flex align-items-center position-relative'>
            <img id="cooking" alt='cocinando' src='https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fprograma-transforma%2Fcocinando.png?alt=media&token=6195216e-1779-4dad-ac56-b3b8798c731b'/>
            <div id="gray-back-mobile"/>
          </Col>
          <Col xs={{span:12, order: 2}} lg={{span: 6, order: 2}} className='d-flex justify-content-center flex-column'>
            <h2 id="first-title">Mejorar tus habilidades en la cocina</h2>
            <p>Aprenderás a cocinar saluable a través de nuestro taller de cocina saludable impartido por  Nombre del taller.</p>
          </Col>
        </Row>
        <div id="gray-back"/>
      </Learn>

      <Learn>
        <Row>
          <Col xs={{span:12, order: 2}} lg={{span: 4, offset: 2, order: 1}} className='d-flex justify-content-center flex-column'>
            <h2 id="second-title">Maximizar tu capacidad física y de ejercicio</h2>
            <p>A través de nuestros programas y activicaciones físicas</p>
          </Col>
          <Col xs={{span:12, order: 1}} lg={{span:6, order: 2}} className='d-flex align-items-center position-relative'>
            <img id="exercise" alt='ejercicio' src='https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fprograma-transforma%2Fhombrejerccicio.png?alt=media&token=1140ba77-72cf-4fb2-ad8e-dd0d570a80b8'/>
            <div id="gray-back-mobile"/>
          </Col>
        </Row>
        <div id="gray-back"/>
      </Learn>

      <Learn>
        <Row>
          <Col xs={{span:12, order: 1}} lg={{span: 6, order: 1}} className='d-flex align-items-center position-relative'>
            <img id="meditar" alt='meditar' src='https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fprograma-transforma%2Fmeditacion.png?alt=media&token=28591a80-e1cd-4276-b03c-b8db94c75e09'/>
            <div id="gray-back-mobile"/>
          </Col>
          <Col xs={{span:12, order: 2}} lg={{span: 6, order: 2}} className='d-flex justify-content-center flex-column'>
            <h2 id="third-title">Mejorar tu relación con la comida y contigo mismo</h2>
            <p>De la mano de nuestras  aliadas psicólogas especializadas en alimentación.</p>
          </Col>
        </Row>
        <div id="gray-back"/>
      </Learn>
    </Container>
    </>
  );
};
