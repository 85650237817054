import React from 'react';
import { useForm } from "react-hook-form";
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const LogContainer = styled.div`
    background-color: #efefef;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    padding: 5em;
`;

const Label = styled.label`

`;

const Input = styled.input`
    margin: 1rem 0;
`;

const Button = styled.input`
    
`;

export const PasswordForm = () => {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm();

    const onSubmit = (data) => {
        console.log(data);
    }; 

    return (
        <div className='min-vh-100 d-flex align-items-center'>
            <div className="mt-4 container ">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <LogContainer >
                            <h1>Resetear contraseña</h1>
                        
                            <Label>Correo</Label>
                            <Input placeholder="email" {...register("email", { 
                                required: "El email es requerido", 
                                pattern: {
                                    value: /^[A-Za-z0-9._%+-]+@[a-z0-9._]+\.[a-z]{2,4}$/i, 
                                    message: "No cumple con el formato"
                                }
                            })} />
                            {errors.email && <p>{errors.email.message}</p>}

                            <Button type="submit" value="Enviar"/><br/>

                            <Link to="/login">Inicio de sesión</Link>
                        </LogContainer>
                    </form>
            </div>
        </div>
    );
}
