import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import {
  UilAngleLeftB,
  UilAngleRightB,
} from "@iconscout/react-unicons";
import { UseELabBanner } from "./useELabBanner";
import { PayOnlineBanner } from "./payOnlineBanner";
import { RosaBanner } from "./Pink/rosaBanner";
import axios from "axios";
import { PatPanRespBanner } from "./patPanRespBanner";
import { PatBioProstataBanner } from "./patBioProstataBanner";
import { PatDiabetesBanner } from "./patDiabetesBanner";
import { AltaBanner } from "./AltaBanner";
import { AguascalientesBannerOne } from "./AguascalientesBannerOne";
import { AguascalientesBannerTwo } from "./AguascalientesBannerTwo";
import { HolidaysBanner } from "./holidaysBanner";
import { CheckUpBanner } from "./checkupBanner";
import { CreateAccountBanner } from "./createAccountBanner";
import { BannerRayosx } from "../../doctors/banners/bannerRayosx";

const MySlider = styled(Slider)`
  position: relative;
  .slick-arrow {
    width: 60px;
    height: 60px;
    @media only screen and (max-width: 768px) {
      width: 30px;
      height: 30px;
    }
  }
  .slick-dots {
    position: absolute;
    bottom: 2px;
    @media only screen and (max-width: 768px) {
      bottom: -30px !important;
    }
    button {
      background: #ccc;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      box-shadow: 2px 2px 2px 1px rgba(100, 100, 100, 0.2);
      ::before {
        display: none;
      }
    }

    .slick-active {
      width: 24px;
      margin-right: 10px;
    }
    .slick-active button {
      border-radius: 81px;
      background: #CEF719;
      width: 24px;
    }
  }
`;

export const Banner = (props) => {
  const [data, setData] = useState([]);

  useEffect(()=>{
    axios.get(`https://lpqsystem.com/get-studies-detail?estudios=581-1039-1035-1036-1037-624`)
      .then(res => {
        setData(res?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },[]);

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <UilAngleLeftB
        color="#CCC"
        className={className}
        style={{ ...style, display: "block", left: "5px", zIndex: "1" }}
        onClick={onClick}
      />
    );
  }

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <UilAngleRightB
        color="#CCC"
        className={className}
        style={{ ...style, display: "block", right: "5px" }}
        onClick={onClick}
      />
    );
  }

  return (
    <MySlider
      width="100%"
      dots={true}
      infinite={true}
      slidesToShow={1}
      slidesToScroll={1}
      autoplay={false}
      autoplaySpeed={5000}
      cssEase={"linear"}
      speed={600}
      nextArrow={<SampleNextArrow />}
      prevArrow={<SamplePrevArrow />}
    >
      {/* <HolidaysBanner/> */}
      {/* <AltaBanner/> */}
      {/* <AguascalientesBannerTwo/> */}
      {/*<PatPanRespBanner data={data?.find((e) => e.id === 581)}/>
      <CheckUpBanner data={data?.find((e) => e.id === 624)}/>*/}
      <BannerRayosx/>
      <CreateAccountBanner/>
      {/* <AguascalientesBannerOne/> */}
      {/*<PatDiabetesBanner data={data?.find((e) => e.id === 1039)} studies={data?.filter((e) => e.id === 1035 || e.id === 1036 || e.id === 1037)}/>*/}
      {/*<PatBioProstataBanner data={data?.find((e) => e.id === 1039)}/>*/}
      {/* <OctubreBanner/> */}
      {/* <RosaBanner/> */}
      {/* <CreateSearchBanner setShowSearcher={e=> props.setShowSearcher(e)}/> */}
      {/* <MolecularPatoBanner/> */}
      {/*<HereditaryCancerBanner setShowCart={props.setShowCart}/>*/}
      {/* <UseELabBanner/> */}
      {/* <PayOnlineBanner/> */}
    </MySlider>
  );
};
