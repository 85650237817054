import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Drawer } from 'antd';
import { Row, Col, Accordion, FloatingLabel, Form, Button } from 'react-bootstrap';
import { UilAngleRight, UilAngleLeft, UilAngleDown } from '@iconscout/react-unicons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { StudyEdit } from './studyEdit';

const StyledDrawer = styled(Drawer)`
  .ant-drawer-title {
    font-weight: 800;
    font-size: 22px;
    line-height: 33px;
    text-align: center;
    color: #00AC90;
  }
  .ant-drawer-close {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #E0ECEC;
  }
`;

const MyFloating = styled(FloatingLabel)`
  max-width: 358px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  .form-control {
    border: none;
    background: #f6f6f6;
    border-radius: 12px;
    font-family: 'IBM Plex Sans';
    padding-left: 16px;
    padding-top: 0.5em !important;
  }
  label {
    font-weight: normal;
    color: #828282;
    transform-origin: 0 -10em;
    background: #f7f7f7;
    border-radius: 8px;
    padding: 2px 6px;
    margin-top: 14px;
    margin-left: 16px;
    height: auto;
    font-family: 'IBM Plex Sans';
  }
`;

const WhiteButton = styled.button`
  width: 80%;
  margin-left: 10%;
  height: 48px;
  background: none;
  color: #00AD90;
  border: 2px solid #00AD90;
  border-radius: 24px;
  font-size: 16px;
  margin-top: 16px;
  margin-bottom: 10px;
  transition: all 0.4s ease;
  :hover {
    color: #fff;
    transition: all 0.4s ease;
    background: #00AD90;
  }
`;

const ContinueButton = styled(Button)`
  width: 100%;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  height: 40px;
  background: #00ad90;
  border: none;
  border-radius: 102px;
  :focus {
    background: #0a7a71;
  }
  :hover {
    background: #00ad90;
  }
  :disabled {
    background: #e2e2e2;
    color: #aaa;
  }
`;

const Bottom = styled.div`
  background: #F1F6F6;
  position: absolute;
  bottom: 0px;
  width: 100%;
  margin-left: -24px;
  padding: 16px;
`;

const ListDiv = styled.div`
  max-height: 40vh;
  overflow-y: auto;
  padding-right: 3px;
  margin-right: -5px;
  ::-webkit-scrollbar { 
    -webkit-appearance: none; 
    width: 7px; 
  } 
  ::-webkit-scrollbar-thumb { 
    border-radius: 4px; 
    background-color: rgba(0, 117, 98, .3); 
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5); 
  }
`;

const HeaderStep = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  p {
    margin-bottom: 0px;
    font-size: 16px;
  }
`;

export const EditPackageDrawer = (props) => {
  const [data, setData] = useState(props.data);
  const history = useHistory();
  const valores = window.location.search;
  const [showOne, setShowOne] = useState(true);
  const [showTwo, setShowTwo] = useState(true);

  const handleRemoveItem = (id) => {
    setData({...data, estudios: data.studies.filter((e)=> e.id !== id)});
  };

  const saveChanges = () => {
    console.log(data);
    props.onClose();
  }

  return (
    <StyledDrawer
      headerStyle={{
        fontFamily: 'IBM Plex Sans',
        color: '#000',
      }}
      title='Editar paquete'
      placement='right'
      closeIcon={<UilAngleRight color="#00AD90" size={30}/>}
      onClose={props.onClose}
      open={props.visible}
    >
      <div>
        <HeaderStep onClick={()=>setShowOne(!showOne)}>
          <p>1. Nombre del paquete</p>
          {showOne ? <UilAngleDown color="#00AC90" size={25}/> : <UilAngleLeft color="#00AC90" size={25}/>}
        </HeaderStep>
        {showOne &&
          <MyFloating controlId='floatingName' label='Nombre del paquete'>
            <Form.Control
              type='text'
              placeholder='Escribe el nombre aquí'
              value={data?.paquete_medico?.name}
              onChange={(e)=>{setData({...data, name: e.target.value})}}
              />
          </MyFloating>
        }
        <hr/>
        <HeaderStep onClick={()=>setShowTwo(!showTwo)}>
          <p>2. Estudios agregados</p>
          {showTwo ? <UilAngleDown color="#00AC90" size={25}/> : <UilAngleLeft color="#00AC90" size={25}/>}
        </HeaderStep>
        {showTwo &&
          <>
          <WhiteButton onClick={() => {
            props.onClose();}}
            >
            Agregar más estudios
          </WhiteButton>
          <ListDiv>
            {data.estudios?.map((study, index)=>
              <StudyEdit data={study} key={index} index={index} removeItem={handleRemoveItem}/>
            )}
          </ListDiv>
          </>
        }
      </div>
        <Bottom>
          <ContinueButton onClick={() => saveChanges()}>Guardar cambios</ContinueButton>
        </Bottom>
    </StyledDrawer>
  );
};
