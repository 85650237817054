import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Searcher } from '../../searcher/searcher';

export const SearchModal = ({ search }) => {
  const bannerOpen = useSelector((state) => state.aux?.bannerOpen);
  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  let isMobile = width <= 468;
  const modalStyle = {
    background: 'transparent',
    border: '0px solid #C1D9D8',
    boxSizing: 'border-box',
    borderRadius: '12px',
  };

  return (
    <div
      style={modalStyle}
      id='modalSearch'
      className='modalSearch animate__animated animate__fadeIn animate__fast'
    >
      <Searcher search={search} />
    </div>
  );
};
