import { types } from "../types/types";

const initialState = {
    phone: '',
    verified: false,
    status: '',
    code: ''
};

export const reCaptchaReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.phoneVerification:
            return {
                phone: action.payload.phone,
                verified: action.payload.verified,
                status: action.payload.status,
                code: action.payload.code,
            };

        case types.codeVerification:
            return {
                ...state,
                code: action.payload
            };

        default:
            return state;
    }

}