export const containsIgnoreCase = (stringFull, shortString) => {
  const lowercasedStringFull = stringFull?.toLowerCase();
  const lowercasedShortString = shortString?.toLowerCase();

  return lowercasedStringFull?.includes(lowercasedShortString);
};

export const extractPlainText = (html) => {
  const div = document.createElement('div');
  div.innerHTML = html;

  document.body.appendChild(div);

  // Get the rendered text content
  const plainText = div.innerText;

  // Remove the temporary div
  document.body.removeChild(div);

  return plainText;
};

export const formatPostUrl = (title) => {
  return title
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/\s+/g, '-')
      .replace(/ñ/g, 'n')
      .replace(/Ñ/g, 'N')
      .replace(/[áàäâ]/g, 'a')
      .replace(/[éèëê]/g, 'e')
      .replace(/[íìïî]/g, 'i')
      .replace(/[óòöô]/g, 'o')
      .replace(/[úùüû]/g, 'u')
      .replace(/[¿?!"',;:(){}[\]@&#$%+=]/g, '')
      .replace(/--+/g, '-')
      .replace(/^-+|-+$/g, '');
}