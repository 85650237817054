import React, { useEffect, useState } from 'react';
import { Button, CloseButton, FloatingLabel, Form, Modal, Row } from 'react-bootstrap';
import { useForm, useFormState } from 'react-hook-form';
import styled from 'styled-components';
import { UilCommentExclamation, UilTimes } from '@iconscout/react-unicons';

const ContinueButton = styled(Button)`
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  height: 40px;
  background: #4D99E5;
  border: 2px solid #fff;
  border-radius: 10px;
  margin-top: 24px;
  margin-bottom: 20px;
  transition: all 0.4s ease;
  :focus {
    background: #4D99E5;
  }
  :hover {
    transition: all 0.4s ease;
    color: #4D99E5;
    background: #fff;
  }
  :disabled {
    background: #e2e2e2;
    color: #aaa;
  }
`;

const MyFloating = styled(FloatingLabel)`
  
  margin-left: auto;
  margin-right: auto;
  .form-control {
    border: none;
    background: #f6f6f6;
    border-radius: 12px;
    font-family: 'IBM Plex Sans';
    padding-left: 16px;
    padding-top: 0.5em !important;
  }
  label {
    font-weight: normal;
    color: #828282;
    transform-origin: 0 -10em;
    background: #f7f7f7;
    border-radius: 8px;
    padding: 2px 6px;
    margin-top: 14px;
    margin-left: 16px;
    height: auto;
    font-family: 'IBM Plex Sans';
  }
`;

const Title = styled.h1`
  font-weight: 900;
  font-size: 22px;
  line-height: 33px;
  text-align: center;
  color: #000000;
  margin-bottom: 24px;
`;

const MoreInfo = styled.p`
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  color: #4F90BA;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 16px;
`;

const StyledModal = styled(Modal)`
  .modal-dialog {
    height: 80%;
    display: flex;
    align-items: center;
  }
  .modal-header {
    border-bottom: 1px solid #C1D9D8;
  }
  .modal-title {
    font-weight: 900;
    font-size: 16px;
    line-height: 24px;
    color: #4F90BA;
  }
  p {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #717171;
  }
  .btn-close {
    width: 40px;
    height: 40px;
    background: #4F90BA;
    border-radius: 20px;
    padding: 0px !important;
    top: -12px;
    right: 33px;
    opacity: 1;
  }
  svg {
    z-index: 1000;
    top: -13px;
    right: 33px;
    position: absolute;
  }
`;

export const StepOne = (props) => {
  const [name, setName] = useState(props.data.name);
  const [showModal, setShowModal] = useState(false);

  useEffect(()=>{
    setName(props?.data?.name);
  },[props?.data?.name])

  return (
    <>
      <Title>1. {props.editing ? 'Escribe el nuevo nombre' : 'Escribe el nombre'}</Title>
      <p style={{textAlign: 'center'}}>¿Cómo quieres guardar el paquete de los estudios?</p>
      <MyFloating controlId='floatingName' label='Nombre del paquete'>
        <Form.Control
          type='text'
          placeholder='Escribe el nombre aquí'
          value={name}
          onChange={(e)=>{setName(e.target.value)}}
        />
      </MyFloating>
      <Row className='justify-content-center'>
        <ContinueButton
          variant='primary'
          onClick={()=>props.onStepOne(name)}
          disabled={name.length === 0}
        >
          Continuar
        </ContinueButton>
      </Row>
      <p style={{fontWeight: '400', fontSize: '16px', color: '#45494A', marginTop: '8px', marginBottom: '0px', textAlign: 'center'}}>
        Puedes nombrar el paquete de estudios cómo: “Check up femenino”</p>
      <MoreInfo onClick={()=>setShowModal(true)}><UilCommentExclamation/>Más información&nbsp;</MoreInfo>
      {showModal &&
        <StyledModal
          show={showModal}
          onHide={() => {
            setShowModal(false);
          }}
        >
          <div onClick={()=>setShowModal(false)}>
            <UilTimes color="#fff" style={{cursor: 'pointer'}}/>
          </div>
          <Modal.Header closeButton>
            
            <Modal.Title>Paquetes de estudios</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Puedes guardar varios estudios en un sólo paquete y encontrarlo rapidamente desde el buscador.<br/><br/>
            También podrás compartirlo con tus pacientes las veces que sea necesario y generar su orden de estudios.</p>
          </Modal.Body>
        </StyledModal>
      }
    </>
  );
  }