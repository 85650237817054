import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';
import { handleAddItemToCart } from '../../actions/shoppingCart';
import handleAlert from '../../helpers/handleAlert';

const Title = styled.h1`
  font-weight: 800;
  font-size: 48px;
  line-height: 46px;
  text-align: center;
  color: #25A78D;
  margin-top: 40px;
  @media only screen and (min-width: 768px) {
    font-weight: 800;
    font-size: 32px;
    line-height: 46px;
    text-align: center;
    color: #FFFFFF;
    background: #00AD90;
    width: 470px;
    margin: 0 auto 40px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

const Card = styled.div`
  margin: 0 auto;
  width: 92%;
  max-width: 468px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding-top: 52px;
  padding-bottom: 26px;
  div {
    width: 90%;
    margin: 0 auto;
    max-width: 300px;
    margin-bottom: 28px;
  }
  h1 {
    font-weight: 800;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.03em;
    color: #00AD90;
  }
  h2 {
    font-weight: 800;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.03em;
    color: #F9AD62;
  }
  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.03em;
    color: #00AD90;
  }
  h3 {
    font-weight: 800;
    font-size: 20px;
    line-height: 46px;
    color: #25A78D;
  }
  li {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #A6A6A6;
  }
  h4 {
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #00AD90;
    max-width: 320px;
    margin: 0 auto;
  }
  a {
    width: 100% !important;
    text-align: center;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #F9AD62;
    text-decoration: underline;
  }
`;

const Background = styled.div`
  background: rgba(0, 173, 144, 0.05);
  width: 100%:
  padding-top: 26px;
  padding-bottom: 92px;
  @media only screen and (min-width: 768px) {
    padding-top: 80px;
  }
`;

const OrangeButton = styled.button`
  width: 100%;
  height: 40px;
  background: #f9ad62;
  border-radius: 24px;
  border: none;
  font-size: 14px;
  padding: 0;
  font-weight: 600;
  margin-top: 6px;
  margin-bottom: 10px;
  color: #fff;
  transition: all 0.4s ease;
  :hover {
    transition: all 0.4s ease;
    background: #ffbf80;
    border: 2px solid #f9ad62;
  }
`;

const WhiteButton = styled.button`
  width: 100%;
  height: 40px;
  background: none;
  color: #f9ad62;
  border: 2px solid #f9ad62;
  border-radius: 24px;
  font-size: 14px;
  font-weight: 600;
  margin-top: 6px;
  transition: all 0.4s ease;
  :hover {
    transition: all 0.4s ease;
    background: #ffecd9;
  }
`;

export const StartNow = ({study, setShowCart}) => {
  const { items } = useSelector((state) => state.shoppingCart);
  const dispatch = useDispatch();
  const history = useHistory();

  const addStudy = () => {
    const exists = items.filter((item) => item.id === study.id);
    if (exists.length === 1) {
      handleAlert('warning', 'Este estudio ya lo tienes agregado en tu solicitud', 'Estudio ya agregado');
    } else {
      dispatch(handleAddItemToCart(study));
    }
    setShowCart(true);
  };

  const payStudy = () => {
    const exists = items.filter((item) => item.id === study.id);
    if (exists.length === 1) {
      history.push('/checkout');
    } else {
      dispatch(handleAddItemToCart(study));
      history.push('/checkout');
    }
  };


  return (
    <Background>
      <Title>¡Empieza hoy!</Title>
      <Card>
        <div>
          <h1>3 pagos de:</h1>
          <h2>$2,167.00 MXN</h2>
          <p>*A meses sin intereses</p>
          <h3>Incluye:</h3>
          <ul>
            <li>Kit de bienvenida</li>
            <li>Check up: análisis de laboratorio</li>
            <li>3 consultas de nutrición con plan de alimentación</li>
            <li>3 consultas de acondicionamiento físico con plan de ejercicios</li>
            <li>3 mediciones de antropometría a través de la báscula InBody para conocer tu composición corporal</li>
            <li>Registro saludable</li>
            <li>Videos educativos</li>
            <li>12 clases/talleres a lo largo de los tres meses (1 por semana)</li>
            <li>Material para talleres</li>
            <li>Diploma por haber concluido el programa de manera satisfactoria</li>
          </ul>
          <OrangeButton onClick={() => addStudy()}>
            Agregar estudio
          </OrangeButton>
          <WhiteButton  onClick={() => payStudy()}>
            Pagar ahora
          </WhiteButton>
        </div>
      </Card>
    </Background>
  );
};
