import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';
import { UilEditAlt } from '@iconscout/react-unicons'
import { CATEOGORIES } from '../colors';
import { NewBlogCardList } from '../newBlogCardList';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { getAllPosts } from '../../../actions/blog';

const PER_PAGE = 6;

const StyledContainer = styled(Container)`
  .pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    cursor: pointer;
  }
  li {
    margin: 15px 5px;
  }
  .previous {
    margin-right: auto;
  }
  .next {
    margin-left: auto;
  }

  .pagination a {
    padding: 7px 10px;
    border-radius: 5px;
    // border: 1px solid #6c7ac9;
    color: #C4C4C4;
  }

  .pagination__link {
    font-weight: bold;
  }

  .pagination__link--active a {
    color: #C4C4C4;
    background: #F3F3F3;
  }

  .pagination__link--disabled a {
    color: rgb(198, 197, 202);
    // border: 1px solid rgb(198, 197, 202);
  }
`;

const NoPosts = styled.div`
  color: #C4C4C4;
  height: 170px;
  width: 170px;
  border-radius: 100px;
  background: #F3F3F3;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 150px auto 150px;
  p {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    margin-top: 10px;
  }
`;

export const CategoryDetailPosts = () => {
  const { category } = useParams();
  const currentCat = CATEOGORIES.find(cat => cat?.link === category);

  const [currentPage, setCurrentPage] = useState(0);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchPosts = async () => {
    try {
      const posts = await getAllPosts(currentCat?.id);
      setData(posts);
    } catch (error) {
      // Handle error
      console.error('Error fetching posts without comments: ', error);
    }
  };

  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }

  const offset = currentPage * PER_PAGE;

  const currentPageData = data
    .slice(offset, offset + PER_PAGE)
    .map(( post, index ) => 
      <Col xs={12} md={12} lg={12} key={index}>
        <NewBlogCardList data={post} category={CATEOGORIES.find(cat => cat?.id == post?.category)}/>
      </Col>   
    );

  const pageCount = Math.ceil(data.length / PER_PAGE);

  return (
    <StyledContainer>
      {data?.length >= 1 ? 
        <Row>
          {/* Post list */}
          {currentPageData}

          {/* Pagination */}
          <ReactPaginate
            previousLabel={"← Anterior"}
            nextLabel={"Siguiente →"}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link"}
            nextLinkClassName={"pagination__link"}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
          />
        </Row>
      :
        <NoPosts>
          <UilEditAlt size={43} color='#C4C4C4'/>
          <p>Aún no<br/>hay entradas</p>
        </NoPosts>
      }
    </StyledContainer>
  );
};
