import React, { useEffect } from 'react';
import { useState } from 'react';
import { Col, Button, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { UilCreditCard, UilInvoice } from '@iconscout/react-unicons';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { addCheckin } from '../../actions/checkin';
import { Loader } from '../general/spinner';
import { useSelector } from 'react-redux';

const API = 'http://localhost:3001';

export const Pay = (props) => {
  const { currentCheckin } = useSelector((state) => state);
  const [radioValue, setRadioValue] = useState('2');
  const [loading, setLoading] = useState(false);
  const [estudios, setEstudios] = useState([]);
  const [title, setTitle] = useState(
    'Da clic en "Confirmar" para avisar de tu asistencía y pasar a pagar dentro de la sucursal'
  );
  const dispatch = useDispatch();

  const elements = useElements();
  const stripe = useStripe();
  const total = 0;
  // const items = props.items;
  const idCart = useState(1); //It was useParams();
  const days = [
    'Domingo',
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado',
  ];
  const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const showErrorToast = (message) => {
    toast.error(message, {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const savePayment = async (payment) => {
    try {
      let request = axios.post(API + '/save-payment', {
        data: payment,
        id: idCart,
      });
      let { data } = await request;
      console.log(data);
      window.location.href = '/pago-confirmado/' + idCart; //change this for setStep(5)???
    } catch (error) {
      console.error(error);
    }
  };

  // Transform number to currency
  const currency = function (number) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    }).format(number);
  };

  const handleContinue = () => {
    if (currentCheckin) {
      const data = {
        referencia: 'KIOSCO-CLIP', // referencia: "Kiosco clip"
        total: currentCheckin.estudios.reduce(
          (n, { precioNeto }) => n + precioNeto,
          0
        ), // total a pagar
        age: currentCheckin.edad,
        folio: currentCheckin.drive.folio, // folio,
        birthday: currentCheckin.fnac, // No sé si es necesario
        branch: currentCheckin.sucursal.toString(), // Sucursal desde "-1" hasta "5"
        coupon: {
          comment: currentCheckin.drive.cupon + ' coment',
          name: currentCheckin.drive.cupon,
          total: '0',
        },
        datetime: currentCheckin.drive?.fechaYhora
          ? currentCheckin.drive?.fechaYhora
          : '03/05/2022 - 09:40', // Formato --> "DD/MM/YYYY - HH:mm"
        // El "datetime" yo lo paso a timestamp con una función específica de Firebase.
        gender: currentCheckin.genero,
        idS: currentCheckin.solicitud.id,
        id: currentCheckin.drive.id,
        invoice: false, // "Sí" | "No" o un Booleano y yo lo convierto
        paid: true,
        paidInKiosko: true,
        methodPayment: currentCheckin.drive.tipopago,
        patient: `${currentCheckin.nombre} ${currentCheckin.apellidos}`, // Nombre del paciente
        study: currentCheckin.estudios,
      };
      console.log(data);
      saveData(data);
    }
  };
  const saveData = async (data) => {
    await setLoading(true);
    await props.onPay();
    dispatch(addCheckin(data));
    await setLoading(false);
  };

  useEffect(async () => {
    console.log(currentCheckin?.estudios);
    const a = currentCheckin?.estudios.reduce(
      (con, estu) => `${con}${estu.muestra}-`,
      ''
    );
    console.log('estus ', a);
    let request = await axios.get(
      'https://lpqsystem.com/get-study-ecomm?estus=' + a
    );
    let response = await request;
    console.log(response.data);
    await setEstudios(response.data);
  }, [currentCheckin]);

  return (
    <>
      <CardDiv>
        <h1>
          Bienvenid{currentCheckin?.genero === 'Mas' ? 'o' : 'a'},{' '}
          {currentCheckin?.name}
        </h1>
        <h1>{title}</h1>
        <div onClick={console.log('p')}>
          <UilCreditCard size={200} />
        </div>
        <br />
        {/* <button onClick={props.onPay}>Next</button> */}
      </CardDiv>
      <InvertedRadius>
        <p style={{ fontSize: '16px', lineHeight: '24px', color: '#3B3B3B' }}>
          Resumen de solicitud
        </p>
        {currentCheckin?.estudios?.map((study, index)=>
          <div className='d-flex w-100'>
            <p>{index+1}. {study.laminilla}</p><p style={{marginLeft: 'auto'}}>{currency(study.precioNeto)} MXN</p>
          </div>
        )}
        <DateP>
          Fecha de mi toma de muestras
          {/*{days[props.date.getDay()]}{' '}
          {String(props.date.getDate()).padStart(2, '0')} de{' '}
          {months[props.date.getMonth()]} del {props.date.getFullYear()} de{' '}
        {props.time}*/}
          <p>{currentCheckin?.drive?.fechaYhora}</p>
        </DateP>
        <hr className='pointed' />
        <div className='bottom left'></div>
        <div className='bottom right'></div>
        <Row
          style={{
            width: '80%',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '48px',
          }}
        >
          <Col
            xs={8}
            md={{ span: 5, offset: 2 }}
            style={{ textAlign: 'center' }}
          >
            <p
              style={{
                fontWeight: '800',
                marginBottom: '0px',
                fontSize: '16px',
              }}
            >
              Total a pagar:
            </p>
            <p style={{ fontSize: '16px', marginBottom: '0px' }}>
              {currency(
                currentCheckin?.estudios.reduce(
                  (n, { precioNeto }) => n + precioNeto,
                  0
                )
              )}{' '}
              MXN
            </p>
          </Col>
          <Col
            xs={4}
            md={3}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <UilInvoice />
          </Col>
        </Row>
      </InvertedRadius>
      <Row className='fw'>
        <PayButton
          onClick={() => {
            handleContinue();
            // props.onPay();
          }}
        >
          Confirmar
        </PayButton>
      </Row>
      {loading ? <Loader show={loading} /> : null}
    </>
  );
};

const CardDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 30px;
  > div svg {
    color: #00ad90;
  }
  > h1 {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 900;
    font-size: 22px;
    line-height: 33px;
    text-align: center;
    color: #00ad90;
  }
`;

const RowRadius = styled(Row)`
  width: 80%;
  margin-left: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const DataForm = styled.form`
  > .form-floating {
    margin-top: 23px;
  }

  > div .form-control {
    border: none;
    background: #f6f6f6;
    border-radius: 12px;
  }

  > .form-floating > label {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    color: #828282;
    transform-origin: 0 -10em;
    background: #f6f6f6;
    border-radius: 8px;
    padding: 2px 6px;
    margin-top: 14px;
    margin-left: 16px;
    height: auto;
  }

  > .form-floating > .form-control {
    padding-left: 16px;
    padding-top: 0.625em;
  }

  > div input.form-check-input {
    width: 20px;
    height: 20px;
    border: 1px solid #00ad90;
  }

  > div input.form-check-input:checked {
    background-color: #00ad90;
  }

  > div .form-check label.form-check-label {
    margin-top: 3px;
    margin-left: 8px;
  }
`;

const RegularP = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

const CardDate = styled(Row)`
  position: relative;
  > div select {
    border: 1px solid #e9e9e9;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px #f6f6f6;
    border-radius: 12px;
    margin-top: -10px;
  }
  > div label {
    font-size: 12px;
    color: #a6a6a6;
    margin-left: 20px;
    background: #fff;
    margin-bottom: -10px;
    padding-left: 3px;
    padding-right: 3px;
  }
`;

const PayButton = styled(Button)`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  width: 241px;
  height: 40px;
  background: #00ad90;
  border: none;
  border-radius: 102px;
  margin-top: 28px;
  margin-bottom: 24px;
  margin-left: auto;
  margin-right: auto;
  :focus {
    background: #006d67;
  }
  :hover {
    background: #00ad90;
  }
  :disabled {
    background: #e2e2e2;
    color: #aaa;
  }
`;

const InvertedRadius = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  @media only screen and (min-width: 769px) {
    width: 100%;
  }
  padding: 16px;
  background-color: #f1f6f6;
  position: relative;
  overflow: hidden;
  border-radius: 16px;
  > div.bottom {
    position: absolute;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    background-color: #fff;
  }
  > .top {
    top: 12px;
  }
  > .bottom {
    bottom: 64px;
  }
  > .left {
    left: -16px;
  }
  > .right {
    right: -16px;
  }
  > hr.pointed {
    position: absolute;
    bottom: 65px;
    margin-top: 0px;
    width: 100%;
    height: 0;
    border: medium none;
    border-top: 2px dashed #e0ecec;
  }
  > div div.col-3 svg {
    width: 30px !important;
    height: 30px !important;
  }
  > hr {
    margin-left: auto;
    margin-right: auto;
    width: 296px;
  }
`;

const PlaceP = styled.p`
  font-weight: 800;
  font-size: 15px;
  line-height: 22px;
  color: #3b3b3b;
`;

const AddressP = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #717171;
`;

const DateP = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #3b3b3b;
`;
