import React, { useEffect, useState } from "react";
import { Col, FloatingLabel, Form, Row } from "react-bootstrap";
import styled from "styled-components";
import PhoneInput from "react-phone-number-input";
import { useSelector } from "react-redux";
import { UilEdit } from "@iconscout/react-unicons";
import { ChangeInfoModal } from "./changeInfoModal";
import { UIDsHome } from "../../helpers/cart";
import { getUserInfo } from "../../actions/user";

const RegularP = styled.p`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  > a {
    color: #00ad90;
    text-decoration: underline;
  }
`;

const InfoDiv = styled.div`
  background: #dbf1ff;
  border-radius: 12px;
  padding: 14px;
  color: #717171;
  position: relative;
  span {
    color: #3b3b3b;
  }
  h4 {
    font-weight: 600;
    font-size: 15px;
    text-align: center;
  }
  #edit-button {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }
`;

const Birthday = styled(Row)`
  > div select {
    border: 1px solid #e9e9e9;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px #f6f6f6;
    border-radius: 12px;
    margin-top: -10px;
    // background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 100% 50%;
  }

  > div label {
    font-size: 12px;
    color: #a6a6a6;
    margin-left: 20px;
    background: #fff;
    margin-bottom: -10px;
    padding-left: 3px;
    padding-right: 3px;
  }
`;
const StyledPhoneInput = styled(PhoneInput)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 15px;
  position: static;
  width: 100%;
  height: 58px;
  left: 0px;
  top: 0px;
  background: #f6f6f6;
  border: none;
  box-sizing: border-box;
  border-radius: 8px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin-top: 20px;
  > input {
    background: #f6f6f6;
  }
`;

let days = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
];
let months = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
];

const years = [];
for (var i = 2024; i >= 1900; i--) {
  years.push(i.toString());
}

export const PersonalForm = (props) => {
  const valores = window.location.search;
  const urlParams = new URLSearchParams(valores);
  var doctorId = urlParams.get("docId");
  const patientFromLpq = urlParams.get("patient");
  //console.log('docid: ', doctorId);
  var isOrder = urlParams.get("doctor") === "1";
  var notForMe = urlParams.get("notForMe") === "1";
  const isDoctor =
    useSelector((state) => state.user?.data?.data?.profile?.type) === 1;
  const [necesitoFactura, setNecesitoFactura] = useState(0);
  const [medicoTratante, setMedicoTratante] = useState(doctorId ? 1 : 0);
  const logged = useSelector((state) => state.auth.uid);
  const user = useSelector((state) => state.user?.data?.data);
  const [persona, setPersona] = useState(1);
  const [edit, setEdit] = useState(false);
  const [userName, setUserName] = useState("");
  const theUID = useSelector((state) => state.auth?.uid || "");
  const handleInputChange = (e) => {
    setUserName(e.target.value);  // Actualiza el estado con el valor del input
  };
  const [day, setDay] = useState(
    props.state?.birthday?.charAt(2) === "-" ||
      props.state?.birthday?.charAt(2) === "/"
      ? props.state?.birthday?.substring(0, 2)
      : props.state?.birthday?.substring(8, 10)
  );
  const [month, setMonth] = useState(
    props.state?.birthday?.charAt(2) === "-" ||
      props.state?.birthday?.charAt(2) === "/"
      ? props.state?.birthday?.substring(3, 5)
      : props.state?.birthday?.substring(5, 7)
  );
  const [year, setYear] = useState(
    props.state?.birthday?.charAt(2) === "-" ||
      props.state?.birthday?.charAt(2) === "/"
      ? props.state?.birthday?.substring(6, 11)
      : props.state?.birthday?.substring(0, 4)
  );
  const [showEditModal, setShowEditModal] = useState(false);
  const [regimen, setRegimen] = useState([
    { id: 1, nombre: "General de Ley Personas Morales", clave: "601", tipo: 2 },
    {
      id: 2,
      nombre: "Personas Morales con Fines no Lucrativos",
      clave: "603",
      tipo: 2,
    },
    {
      id: 3,
      nombre: "Sueldos y Salarios e Ingresos Asimilados a Salarios",
      clave: "605",
      tipo: 1,
    },
    { id: 4, nombre: "Arrendamiento", clave: "606", tipo: 1 },
    {
      id: 5,
      nombre: "Régimen de Enajenación o Adquisición de Bienes",
      clave: "607",
      tipo: 1,
    },
    { id: 6, nombre: "Demás ingresos", clave: "608", tipo: 1 },
    {
      id: 7,
      nombre:
        "Residentes en el Extranjero sin Establecimiento Permanente en México",
      clave: "610",
      tipo: 0,
    },
    {
      id: 8,
      nombre: "Ingresos por Dividendos (socios y accionistas)",
      clave: "611",
      tipo: 1,
    },
    {
      id: 9,
      nombre: "Personas Físicas con Actividades Empresariales y Profesionales",
      clave: "612",
      tipo: 1,
    },
    { id: 10, nombre: "Ingresos por intereses", clave: "614", tipo: 1 },
    {
      id: 11,
      nombre: "Régimen de los ingresos por obtención de premios",
      clave: "615",
      tipo: 1,
    },
    { id: 12, nombre: "Sin obligaciones fiscales", clave: "616", tipo: 1 },
    {
      id: 13,
      nombre:
        "Sociedades Cooperativas de Producción que optan por diferir sus ingresos",
      clave: "620",
      tipo: 2,
    },
    { id: 14, nombre: "Incorporación Fiscal", clave: "621", tipo: 1 },
    {
      id: 15,
      nombre: "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras",
      clave: "622",
      tipo: 2,
    },
    {
      id: 16,
      nombre: "Opcional para Grupos de Sociedades",
      clave: "623",
      tipo: 2,
    },
    { id: 17, nombre: "Coordinados", clave: "624", tipo: 2 },
    {
      id: 18,
      nombre:
        "Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas",
      clave: "625",
      tipo: 1,
    },
    {
      id: 19,
      nombre: "Régimen Simplificado de Confianza",
      clave: "626",
      tipo: 0,
    },
  ]);
  const [cfdi, setcfdi] = useState([
    //{ id: 1,clave: "G01",nombre: "Adquisición de mercancías.",tipo: 0 },
    //{ id: 2,clave: "G02",nombre: "Devoluciones, descuentos o bonificaciones.",tipo: 0 },
    { id: 3, clave: "G03", nombre: "Gastos en general.", tipo: 0 },
    /*{ id: 4,clave: "I01",nombre: "Construcciones.",tipo: 0 },
      { id: 5,clave: "I02",nombre: "Mobiliario y equipo de oficina por inversiones.",tipo: 0 },
      { id: 6,clave: "I03",nombre: "Equipo de transporte.",tipo: 0 },
      { id: 7,clave: "I04",nombre: "Equipo de computo y accesorios.",tipo: 0 },
      { id: 8,clave: "I05",nombre: "Dados, troqueles, moldes, matrices y herramental.",tipo: 0 },
      { id: 9,clave: "I06",nombre: "Comunicaciones telefónicas.",tipo: 0 },
      { id: 10,clave: "I07",nombre: "Comunicaciones satelitales.",tipo: 0 },
      { id: 11,clave: "I08",nombre: "Otra maquinaria y equipo.",tipo: 0 },*/
    {
      id: 12,
      clave: "D01",
      nombre: "Honorarios médicos, dentales y gastos hospitalarios.",
      tipo: 1,
    },
    {
      id: 13,
      clave: "D02",
      nombre: "Gastos médicos por incapacidad o discapacidad.",
      tipo: 1,
    },
    { id: 14, clave: "D03", nombre: "Gastos funerales.", tipo: 1 },
    /*{ id: 15,clave: "D04",nombre: "Donativos.",tipo: 1 },
      { id: 16,clave: "D05",nombre: "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).",tipo: 1 },
      { id: 17,clave: "D06",nombre: "Aportaciones voluntarias al SAR.",tipo: 1 },*/
    {
      id: 18,
      clave: "D07",
      nombre: "Primas por seguros de gastos médicos.",
      tipo: 1,
    },
    /*{ id: 19,clave: "D08",nombre: "Gastos de transportación escolar obligatoria.",tipo: 1 },
      { id: 20,clave: "D09",nombre: "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.",tipo: 1 },
      { id: 21,clave: "D10",nombre: "Pagos por servicios educativos (colegiaturas).",tipo: 1 },*/
    { id: 22, clave: "S01", nombre: "Sin efectos fiscales.", tipo: 0 },
    /*{ id: 23,clave: "CP01",nombre: "Pagos",tipo: 0 },
      { id: 24,clave: "CN01",nombre: "Nómina",tipo: 1 },*/
  ]);

  const setUser = async () => {
    //console.log("the uid", theUID)
    let user = await getUserInfo(theUID);
    var drGender = "Dr. ";
    if (user.data.gender === "Femenino") {
      drGender = "Dra. ";
    }
    var name = "";
    if (user.data?.profile?.type === 1) {
      name = `${drGender}${user.data.name} ${user.data.fathersLastname} ${user.data.mothersLastname}`;
    } else {
      name = `${user.data.name} ${user.data.fathersLastname} ${user.data.mothersLastname}`;
    }
    setUserName(name);
  };

  useEffect(() => {
    setUser();
  }, []);

  const wrongStyle = {
    outline: "1px solid rgba(255,0,0,0.2)",
    WebkitBoxShadow: "0px 0px 5px 5px rgba(255,0,0,0.4)",
    boxShadow: "0px 0px 3px 3px rgba(255,0,0,0.4)",
  };

  const fillWhenDoesntExistField = (field) => {
    if (props.state?.birthday?.length > 1) {
      if (
        props.state.birthday.charAt(2) === "-" ||
        props.state.birthday.charAt(2) === "/"
      ) {
        if (field === "day") {
          setDay(props.state.birthday.substring(0, 2));
        } else if (field === "month") {
          setMonth(props.state.birthday.substring(3, 5));
        } else {
          setYear(props.state.birthday.substring(6, 11));
        }
      } else {
        if (field === "day") {
          setDay(props.state.birthday.substring(8, 10));
        } else if (field === "month") {
          setMonth(props.state.birthday.substring(5, 7));
        } else {
          setYear(props.state.birthday.substring(0, 4));
        }
      }
    } else {
      if (field === "day") {
        setDay("DD");
      } else if (field === "month") {
        setMonth("MM");
      } else {
        setYear("AAAA");
      }
    }
  };

  useEffect(() => {
    if (props.state?.day?.length >= 1) {
      setDay(props.state.day);
    } else {
      fillWhenDoesntExistField("day");
    }

    if (props.state?.month?.length >= 1) {
      setMonth(props.state.month);
    } else {
      fillWhenDoesntExistField("month");
    }

    if (props.state?.year?.length >= 1) {
      setYear(props.state.year);
    } else {
      fillWhenDoesntExistField("year");
    }
  }, [props.state, props.state?.birthday, props.state?.day]);

  useEffect(() => {
    if (
      logged &&
      !edit &&
      !isOrder &&
      !notForMe &&
      !props.otherPersonCheck &&
      !user?.profile.idPacienteLabopat
    ) {
      setShowEditModal(true);
    }
  }, []);

  var input = document.getElementById("floatingRFC");
  input?.addEventListener("input", function () {
    if (this.value.length > 13) this.value = this.value.slice(0, 13);
  });

  //console.log('Date', day, 'Month', month, 'Day', day);
  console.log(props.state);

  return (
    <>
      <hr />
      {/*<RegularP>
        {props.user?.doctor ? '' : 'Escribe los datos para la solicitud'}
      </RegularP>*/}
      {(logged && !edit && !isOrder && !notForMe && !props.otherPersonCheck) ||
      props.currentPatient ||
      (patientFromLpq && patientFromLpq !== "0" && !props.otherPersonCheck) ? (
        // Se mostrará el cuadro con información cuando haya sesión iniciada y sea para sí mismo la solicitud
        <InfoDiv>
          <h4>Tu información:</h4>
          {(!isOrder && !patientFromLpq) ||
          (logged &&
            !edit &&
            !isOrder &&
            !notForMe &&
            !props.otherPersonCheck &&
            !user?.profile.idPacienteLabopat) ? (
            <UilEdit
              color={isDoctor ? "#4D99E5" : "#00AC90"}
              id="edit-button"
              onClick={() => setShowEditModal(true)}
            />
          ) : null}
          <p>
            <br />
            <span>Nombre: </span>
            {props.state?.name} {props.state?.fathersLastname}{" "}
            {props.state?.mothersLastname}
          </p>
          <p>
            <span>Celular: </span>
            {props.state?.phone}
          </p>
          <p>
            <span>Email: </span>
            {props.state?.email}
          </p>
          <p>
            <span>Fecha de nacimiento: </span>
            {props.state?.birthday}
          </p>
          <p>
            <span>Género: </span>
            {props.state?.gender}
          </p>
        </InfoDiv>
      ) : (
        <>
          <FloatingLabel controlId="floatingName" label="Nombre *">
            <Form.Control
              style={!props.validFormValues.name ? { ...wrongStyle } : {}}
              type={"text"}
              {...props.register("name", {
                required: true,
              })}
              placeholder="Nombre"
              value={props.state?.name}
              onChange={props.handleChange}
            />
          </FloatingLabel>
          {/* {errors.name ? <p>{errors.name.message}</p> : null} */}
          {/* {!(props.pay && props.pay === 'orderToPatient') &&
            <> */}
          <FloatingLabel
            controlId="floatingSecondName"
            label="Apellido paterno *"
          >
            <Form.Control
              style={
                !props.validFormValues.fathersLastname ? { ...wrongStyle } : {}
              }
              type="text"
              {...props.register("fathersLastname", {
                required: true,
              })}
              placeholder="Apellido Paterno"
              value={props.state?.fathersLastname}
              onChange={props.handleChange}
            />
          </FloatingLabel>
          <FloatingLabel
            controlId="floatingLastName"
            label="Apellido materno *"
          >
            <Form.Control
              style={
                !props.validFormValues.mothersLastname ? { ...wrongStyle } : {}
              }
              type="text"
              {...props.register("mothersLastname", {
                required: true,
              })}
              placeholder="Apellido Materno"
              value={props.state?.mothersLastname}
              onChange={props.handleChange}
            />
          </FloatingLabel>
          <StyledPhoneInput
            style={!props.validFormValues.phone ? { ...wrongStyle } : {}}
            {...props.register("phone", {
              required: true,
            })}
            international
            defaultCountry="MX"
            placeholder="Teléfono celular *"
            id="phoneNumber"
            value={props.state?.phone}
            onChange={(phone) => {
              props.handleChange({ target: { value: phone, name: "phone" } });
            }}
          />
          {/* </>
          } */}
          <FloatingLabel
            controlId="floatingInput"
            label="Correo electrónico del paciente"
            className="mb-3"
          >
            <Form.Control
              style={!props.validFormValues.email ? { ...wrongStyle } : {}}
              {...props.register("email", {
                required: true,
                pattern: {
                  //value: /^[A-Za-z0-9._%+-]+@[a-z0-9._]+\.[a-z]{2,4}$/i,
                  message: "El correo no cumple con el formato",
                },
              })}
              type="email"
              placeholder="name@example.com"
              value={props.state?.email}
              onChange={props.handleChange}
            />
          </FloatingLabel>

          <RegularP>Fecha de nacimiento</RegularP>
          <Birthday>
            <Col xs={4} style={{ paddingLeft: "0px" }}>
              <Form.Label>Día *</Form.Label>
              <Form.Select
                style={!props.validFormValues.day ? { ...wrongStyle } : {}}
                // hidden={props.pay && props.pay === 'orderToPatient' ? true : false}
                {...props.register("day", {
                  required:
                    props.pay && props.pay === "orderToPatient" ? false : true,
                  pattern: {
                    value: /[0-9]/i,
                    message: "Selecciona una fecha",
                  },
                })}
                value={day}
                // defaultValue="DD"
                id="day"
                onChange={props.handleChange}
              >
                <option disabled hidden>
                  DD
                </option>
                {days.map((day, index) => (
                  <option value={day} key={index}>
                    {day}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col xs={4} style={{ paddingLeft: "0px" }}>
              <Form.Label>Mes *</Form.Label>
              <Form.Select
                style={!props.validFormValues.month ? { ...wrongStyle } : {}}
                // hidden={props.pay && props.pay === 'orderToPatient' ? true : false}
                {...props.register("month", {
                  required:
                    props.pay && props.pay === "orderToPatient" ? false : true,
                  pattern: {
                    value: /[0-9]/i,
                    message: "Selecciona una fecha",
                  },
                })}
                value={month}
                // defaultValue="MM"
                onChange={props.handleChange}
                id="month"
              >
                <option disabled hidden>
                  MM
                </option>
                {months.map((month, index) => (
                  <option value={month} key={index}>
                    {month}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col xs={4} style={{ paddingRight: "0px" }}>
              <Form.Label>Año *</Form.Label>
              <Form.Select
                style={!props.validFormValues.year ? { ...wrongStyle } : {}}
                className="form-control"
                // hidden={props.pay && props.pay === 'orderToPatient' ? true : false}
                {...props.register("year", {
                  required:
                    props.pay && props.pay === "orderToPatient" ? false : true,
                  pattern: {
                    value: /[0-9]/i,
                    message: "Selecciona una fecha",
                  },
                })}
                value={year}
                // defaultValue="AAAA"
                id="year"
                onChange={props.handleChange}
              >
                <option disabled hidden>
                  AAAA
                </option>
                {years.map((year, index) => (
                  <option value={year} key={index}>
                    {year}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Birthday>
          <br />
          <br />
          <RegularP
            style={{
              display:
                (logged &&
                  !edit &&
                  !isOrder &&
                  !notForMe &&
                  !props.otherPersonCheck) ||
                props.currentPatient
                  ? "none"
                  : "block",
              color: !props.validFormValues.gender ? "red" : "black",
            }}
          >
            Sexo *
          </RegularP>
          <Form.Group controlId="genderM">
            <Form.Check
              {...props.register("gender", {
                required: true,
              })}
              value="Masculino"
              id="gender"
              type="radio"
              aria-label="radio 1"
              label="Masculino"
              checked={props.state?.gender === "Masculino"}
              onChange={props.handleChange}
            />
          </Form.Group>
          <hr />
          <Form.Group controlId="genderF">
            <Form.Check
              {...props.register("gender", {
                required: false,
              })}
              value="Femenino"
              id="gender"
              type="radio"
              aria-label="radio 3"
              label="Femenino"
              checked={props.state.gender === "Femenino"}
              onChange={props.handleChange}
            />
          </Form.Group>
        </>
      )}
      <br />
      {UIDsHome.includes(logged) ? (
        <>
          <Form.Label>Nombre de quién realiza la cotización</Form.Label>
          <Form.Group
            className="mt-4"
            style={{ display: props.user?.doctor ? "block" : "none" }}
          >
            <textarea
              className="form-control"
              {...props.register("dx", {
                required: false,
              })}
              id="elDx"
              value={userName}
              autoComplete="off"
              list="autocompleteOff"
              onChange={handleInputChange} // Maneja el cambio de valor del input
            ></textarea>
          </Form.Group>
        </>
      ) : (
        <Form.Group
          className="mt-4"
          style={{ display: props.user?.doctor ? "block" : "none" }}
        >
          <Form.Label>Impresión diagnóstica</Form.Label>
          <textarea
            className="form-control"
            {...props.register("dx", {
              required: false,
            })}
            id="elDx"
          ></textarea>
        </Form.Group>
      )}

      {!props.orderToPatient && !doctorId ? (
        <>
          <br />
          <RegularP>¿Cuentas con médico tratante?</RegularP>
          <Form.Group controlId="medicoTratanteTrue">
            <Form.Check
              {...props.register("medicoTratante", {
                required: true,
              })}
              value={1}
              type="radio"
              id="medicoTratante"
              aria-label="radio 1"
              label="Sí"
              checked={medicoTratante === 1 ? true : false}
              onChange={(e) => {
                props.handleChange(e);
                setMedicoTratante(1);
              }}
            />
          </Form.Group>
          <hr />
          <Form.Group controlId="medicoTratanteFalse">
            <Form.Check
              {...props.register("medicoTratante", {
                required: true,
              })}
              value={0}
              type="radio"
              id="medicoTratante"
              aria-label="radio 3"
              label="No"
              checked={medicoTratante === 0 ? true : false}
              onChange={(e) => {
                props.handleChange(e);
                setMedicoTratante(0);
              }}
            />
          </Form.Group>
          <input type="hidden" name="refMedico" value=" " id="refMedico" />
        </>
      ) : (
        <>
          {doctorId === null ? (
            <input
              type="hidden"
              name="refMedico"
              value={
                user.profile?.idMedicoLabopat
                  ? user.profile?.idMedicoLabopat
                  : 12
              }
              id="refMedico"
            />
          ) : (
            <input
              type="hidden"
              name="refMedico"
              value={doctorId}
              id="refMedico"
            />
          )}
        </>
      )}

      {medicoTratante === 1 && !doctorId && (
        <>
          <FloatingLabel
            controlId="floatingDoctorName"
            label="Nombre del Médico(a) *"
          >
            <Form.Control
              type="text"
              {...props.register("doctorName", {
                required: true,
              })}
              placeholder="Nombre del Médico(a) *"
              // value={props.orderToPatient ? null : props.state.name}
              onChange={props.handleChange}
            />
          </FloatingLabel>
          <FloatingLabel
            controlId="floatingInput"
            label="E-mail del Médico(a)"
            className="mb-3"
          >
            <Form.Control
              {...props.register("doctorEmail", {
                required: false,
                pattern: {
                  value: /^[A-Za-z0-9._%+-]+@[a-z0-9._]+\.[a-z]{2,4}$/i,
                  message: "El correo no cumple con el formato",
                },
              })}
              type="email"
              placeholder="E-mail del Médico(a) *"
              // value={}
              onChange={props.handleChange}
            />
          </FloatingLabel>
          <Form.Check
            {...props.register("shareResults", {})}
            type="checkbox"
            id="shareResults"
            label="Acepto enviar los resultados al médico tratante"
            onChange={props.handleChange}
          />
        </>
      )}

      {doctorId && (
        <>
          <br />
          <RegularP>Compartir resultados</RegularP>
          <Form.Check
            {...props.register("shareResults", {})}
            type="checkbox"
            id="shareResults"
            label="Acepto enviar los resultados al médico tratante"
            onChange={props.handleChange}
          />
          <input
            type="hidden"
            {...props.register("doctorId")}
            onChange={props.handleChange}
            name="doctorId"
            value={props.state.doctorId}
            id="doctorId"
          />
        </>
      )}

      {/* Billing info */}
      {!props.orderToPatient && (
        <>
          <br />
          <RegularP>Necesito factura *</RegularP>
          <Form.Group controlId="facturaTrue">
            <Form.Check
              {...props.register("necesitoFactura", {
                required: true,
              })}
              value={1}
              type="radio"
              id="necesitoFactura"
              aria-label="radio 1"
              label="Sí"
              checked={necesitoFactura === 1 ? true : false}
              onChange={(e) => {
                props.handleChange(e);
                setNecesitoFactura(1);
              }}
            />
          </Form.Group>
          <hr />
          <Form.Group controlId="necesitoFactura">
            <Form.Check
              {...props.register("necesitoFactura", {
                required: true,
              })}
              value={0}
              type="radio"
              id="necesitoFactura"
              aria-label="radio 3"
              label="No"
              checked={necesitoFactura === 0 ? true : false}
              onChange={(e) => {
                props.handleChange(e);
                setNecesitoFactura(0);
              }}
            />
          </Form.Group>
        </>
      )}
      {necesitoFactura === 1 && (
        <>
          <br />
          <h1 style={{ textAlign: "center", fontSize: "20px" }}>
            Datos de facturación
          </h1>
          <FloatingLabel controlId="floatingRFC" label="RFC *">
            <Form.Control
              type="text"
              {...props.register("rfc", {
                required: true,
                minLength: 12,
                maxLength: 13,
              })}
              placeholder="RFC *"
              value={props.orderToPatient ? null : props.state.rfc}
              // onChange={props.handleChange}
              onChange={(e) => {
                props.handleChange(e);
                var valor = e.target.value;
                console.log(valor);
                if (isNaN(valor[3])) {
                  setPersona(1);
                  console.log("fisica");
                } else {
                  setPersona(2);
                  console.log("moral");
                }
              }}
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingName" label="Nombre/razón social *">
            <Form.Control
              type="text"
              {...props.register("razon", {
                required: true,
              })}
              placeholder="Nombre/razón social"
              value={props.orderToPatient ? null : props.state.razon}
              onChange={props.handleChange}
            />
          </FloatingLabel>
          <FloatingLabel
            controlId="floatingInput"
            label="Correo electrónico *"
            className="mb-3"
          >
            <Form.Control
              {...props.register("emailRFC", {
                required: true,
                pattern: {
                  //value: /^[A-Za-z0-9._%+-]+@[a-z0-9._]+\.[a-z]{2,4}$/i,
                  message: "El correo no cumple con el formato",
                },
              })}
              type="email"
              placeholder="name@example.com"
              value={props.orderToPatient ? null : props.state.emailFac}
              // value={
              //   props.orderToPatient
              //     ? null
              //     : props.state.email
              // }
              onChange={props.handleChange}
            />
          </FloatingLabel>
          <Form.Label>Regimen fiscal *</Form.Label>
          <Form.Select
            className="form-control"
            {...props.register("regimen", {
              required: true,
            })}           
            defaultValue={props.regimen ? "Selecciona" : "Selecciona"}
            onChange={(e) => {
              props.handleChange(e);
              if (e.target.value == "605") {
                setcfdi([
                  {
                    id: 12,
                    clave: "D01",
                    nombre:
                      "Honorarios médicos, dentales y gastos hospitalarios.",
                    tipo: 1,
                  },
                  {
                    id: 13,
                    clave: "D02",
                    nombre: "Gastos médicos por incapacidad o discapacidad.",
                    tipo: 1,
                  },
                  {
                    id: 14,
                    clave: "D03",
                    nombre: "Gastos funerales.",
                    tipo: 1,
                  },
                  {
                    id: 18,
                    clave: "D07",
                    nombre: "Primas por seguros de gastos médicos.",
                    tipo: 1,
                  },
                  {
                    id: 22,
                    clave: "S01",
                    nombre: "Sin efectos fiscales.",
                    tipo: 0,
                  },
                ]);
              } else if (e.target.value == "610") {
                setcfdi([
                  {
                    id: 22,
                    clave: "S01",
                    nombre: "Sin efectos fiscales.",
                    tipo: 0,
                  },
                ]);
              } else {
                setcfdi([
                  {
                    id: 3,
                    clave: "G03",
                    nombre: "Gastos en general.",
                    tipo: 0,
                  },
                  {
                    id: 12,
                    clave: "D01",
                    nombre:
                      "Honorarios médicos, dentales y gastos hospitalarios.",
                    tipo: 1,
                  },
                  {
                    id: 13,
                    clave: "D02",
                    nombre: "Gastos médicos por incapacidad o discapacidad.",
                    tipo: 1,
                  },
                  {
                    id: 14,
                    clave: "D03",
                    nombre: "Gastos funerales.",
                    tipo: 1,
                  },
                  {
                    id: 18,
                    clave: "D07",
                    nombre: "Primas por seguros de gastos médicos.",
                    tipo: 1,
                  },
                  {
                    id: 22,
                    clave: "S01",
                    nombre: "Sin efectos fiscales.",
                    tipo: 0,
                  },
                ]);
              }
            }}
          >
            <option value="Selecciona">Selecciona</option>
            {regimen.map((reg) =>
              persona === 1 ? (
                reg.tipo === 1 || reg.tipo === 0 ? (
                  <option value={reg.clave}>
                    {reg.clave} {reg.nombre}
                  </option>
                ) : (
                  <></>
                )
              ) : persona === 2 ? (
                reg.tipo === 2 || reg.tipo === 0 ? (
                  <option value={reg.clave}>
                    {reg.clave} {reg.nombre}
                  </option>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )
            )}
          </Form.Select>
          <FloatingLabel controlId="floatingCalle1" label="Calle *">
            <Form.Control
              type="text"
              {...props.register("calle1", {
                required: true,
              })}
              placeholder="Calle"
              value={props.orderToPatient ? null : props.state.calleFac}
              onChange={props.handleChange}
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingNext1" label="No. Exterior *">
            <Form.Control
              type="text"
              {...props.register("next1", {
                required: true,
              })}
              placeholder="No. Exterior *"
              value={props.orderToPatient ? null : props.state.extFac}
              onChange={props.handleChange}
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingNint1" label="No. Interior *">
            <Form.Control
              type="text"
              {...props.register("nint1", {
                required: true,
              })}
              placeholder="No. Interior *"
              value={props.orderToPatient ? null : props.state.intFac}
              onChange={props.handleChange}
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingCP1" label="C.P. *">
            <Form.Control
              type="text"
              {...props.register("cp1", {
                required: true,
              })}
              placeholder="C.P. *"
              value={props.orderToPatient ? null : props.state.cpFac}
              onChange={props.handleChange}
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingColonia1" label="Colonia *">
            <Form.Control
              type="text"
              {...props.register("colonia1", {
                required: true,
              })}
              placeholder="Colonia *"
              value={props.orderToPatient ? null : props.state.coloniaFac}
              onChange={props.handleChange}
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingMunicipio1" label="Municipio *">
            <Form.Control
              type="text"
              {...props.register("municipio1", {
                required: true,
              })}
              placeholder="Municipio *"
              value={props.orderToPatient ? null : props.state.municipioFac}
              onChange={props.handleChange}
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingEstado1" label="Estado *">
            <Form.Control
              type="text"
              {...props.register("estado1", {
                required: true,
              })}
              placeholder="Estado *"
              value={props.orderToPatient ? null : props.state.estadoFac}
              onChange={props.handleChange}
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingPais1" label="País *">
            <Form.Control
              type="text"
              {...props.register("pais1", {
                required: true,
              })}
              placeholder="País"
              value={props.orderToPatient ? null : props.state.paisFac}
              onChange={props.handleChange}
            />
          </FloatingLabel>
          <br />
          <Form.Label>Uso de la factura *</Form.Label>
          <Form.Select
            className="form-control"
            {...props.register("usoFactura", {
              required: true,
            })}
            defaultValue={"Selecciona"}
            onChange={props.handleChange}
          >
            <option value="Selecciona">Selecciona</option>
            {cfdi.map((c) =>
              persona === 1 ? (
                c.tipo === 1 || c.tipo === 0 ? (
                  <option value={c.clave}>
                    {c.clave} {c.nombre}
                  </option>
                ) : (
                  <></>
                )
              ) : persona === 2 ? (
                c.tipo === 2 || c.tipo === 0 ? (
                  <option value={c.clave}>
                    {c.clave} {c.nombre}
                  </option>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )
            )}
          </Form.Select>
          <FloatingLabel
            controlId="floatingcomentarioFactura"
            label="Comentarios y observaciones facturación"
          >
            <Form.Control
              as="textarea"
              {...props.register("comentarioFactura", {})}
              placeholder="Comentarios y observaciones facturación"
              value={props.orderToPatient ? null : props.state.obsFac}
              onChange={props.handleChange}
            />
          </FloatingLabel>
        </>
      )}
      <Form.Group
        className="mt-4"
        style={{
          display: UIDsHome.includes(logged) && !isOrder ? "block" : "none",
        }}
      >
        <Form.Label>Dirección</Form.Label>
        <textarea
          className="form-control"
          {...props.register("address", {
            required: false,
          })}
          id="theAddress"
        ></textarea>
      </Form.Group>
      {UIDsHome.includes(logged) ? null : (
        <Form.Group
          className="mt-4"
          style={{ display: isOrder ? "block" : "none" }}
        >
          <Form.Label>Estudios Adicionales</Form.Label>
          <textarea
            className="form-control"
            {...props.register("additionalStudies", {
              required: false,
            })}
            id="theAdditionalStudies"
          ></textarea>
        </Form.Group>
      )}
      <Form.Group
        className="mt-4"
        style={{ display: isOrder ? "block" : "none" }}
      >
        <Form.Label>Observaciones</Form.Label>
        <textarea
          className="form-control"
          {...props.register("observations", {
            required: false,
          })}
          id="theObservations"
        ></textarea>
      </Form.Group>
      {UIDsHome.includes(logged) ? null : (
        <Form.Group>
          <br />
          <Form.Check
            {...props.register("termsAndConditions", {
              required: true,
            })}
            type="checkbox"
            id="termsAndConditions"
            label={
              <Form.Label>
                *He leído y acepto las políticas de privacidad y consentimiento
                (Para conocerlas dar click
                <a
                  href="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fprivacy%2F2024%2FLP_formato_AP_integral_Pacientes%20(1).pdf?alt=media&token=29b9cce7-7a94-4ace-991f-423ba6964f14"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  aquí
                </a>
                ).
              </Form.Label>
            }
            onChange={props.handleChange}
          />
        </Form.Group>
      )}

      {showEditModal && (
        <ChangeInfoModal
          show={showEditModal}
          handleClose={() => setShowEditModal(false)}
          state={{ ...props.state, day: day, month: month, year: year }}
          handleChange={(e) => props.handleChange(e)}
          otherPersonCheck={props.otherPersonCheck}
          user={props.user}
          setStateAfterUpdate={props.setStateAfterUpdate}
          //completeProfile={(logged && !edit && !isOrder && !notForMe && !props.otherPersonCheck && !user.profile.idPacienteLabopat)} // When a logged user is making a request for himself and he hasn't idPacienteLabopat
          completeProfile={user.profile.idPacienteLabopat} // When a logged user is making a request for himself and he hasn't idPacienteLabopat
        />
      )}
    </>
  );
};
