import axios from 'axios';
import { setPatientOrders } from '../actions/patientOrders';
import { addItemToCart, setTotalPrice } from '../actions/shoppingCart';
import moment from 'moment';

export const addCartDB = () => {
  return (dispatch, getState) => {
    const { items, totalPrice } = getState().shoppingCart;
    const { uid } = getState().auth;
    const { api } = getState();
    const endpoint = api + '/create-cart';

    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    };

    const data = {
      study: items,
      uid: uid ? uid : '',
      totalPrice: totalPrice,
    };

    axios
      .post(endpoint, data, options)
      .then((res) => {
        // console.log(res);
        // Add to local storage
        dispatch(getCartDB(res.data.id));
        localStorage.setItem('cartId', res.data.id);
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const dashForSlash = (date) => date.replace('-', '/').replace('-', '/');

export const sortArrayByDates = (array) =>
  array.sort(
    (a, b) =>
      moment(dashForSlash(a.fechaYhora.split(' ')[0]), 'DD/MM/YYYY').format(
        'YYYYMMDD'
      ) -
      moment(dashForSlash(b.fechaYhora.split(' ')[1]), 'DD/MM/YYYY').format(
        'YYYYMMDD'
      )
  );

export const getPatientOrders = () => {
  return (dispatch, getState) => {
    const { uid } = getState().auth;
    const { api } = getState();
    const endpoint = api + '/orders/';

    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    };

    // paciente que sabemos que sí tiene orden (pa' probar): '02xl4Svj5OYlfApfWo4018yqNnr1'

    axios
      .post(endpoint, { user: uid }, options)
      .then(({ data }) => {
        // Add data to Redux
        dispatch(setPatientOrders(sortArrayByDates(data.data).reverse()));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getCartDB = (cartId) => {
  return (dispatch, getState) => {
    const { api } = getState();
    const endpoint = api + '/get-cart/?id=' + cartId;

    const options = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };

    axios
      .get(endpoint, options)
      .then((res) => {
        // console.log("get cart: ",res);
        // Add data to Redux
        dispatch(addItemToCart(res.data.cart.items));
        dispatch(setTotalPrice(res.data.cart.totalPrice));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getStudyInfo = async (id, cliente = 0) => {
  //console.log((`https://lpqsystem.com/get-study/${id}`));
  const response = await axios.get(
    `https://lpqsystem.com/get-study-test/${id}/${cliente}`
  );
  //console.log("study test; ",response);
  return response;
};

export const updateCartDB = (newItems, totalPrice, redirectTo = '') => {
  return (dispatch, getState) => {
    const { api } = getState();
    const cartId = localStorage.getItem('cartId');
    const endpoint = api + '/update-cart';

    newItems.forEach((item) => delete item?.description);

    const options = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
    };

    const data = { items: newItems, id: cartId, totalPrice: totalPrice };

    axios
      .put(endpoint, data, options)
      .then((res) => {
        if(redirectTo !== ''){
          window.location.href = redirectTo;
        }
        // console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
};
