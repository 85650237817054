import React from 'react';
import styled from 'styled-components';
import { UilArrowRight } from '@iconscout/react-unicons';
import { CATEOGORIES, TEXT_BLACK } from '../colors';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const StyledCard = styled.div`
  width: 100%;
  position: relative;
  padding: 15px 20px 40px;
  margin-top: 20px;
  #color-hr {
    color: ${props => props?.color};
    opacity: 1;
    width: 34%;
    position: absolute;
    left: -4%;
    top: -5px;
  }
  h1 {
    color: ${TEXT_BLACK};
    font-family: Faustina;
    font-size: ${({isForNavbar}) => isForNavbar ? '20px' : '27px'} !important;
    font-style: normal;
    font-weight: 700;
    line-height: 129.63%;
    letter-spacing: 0.27px;
    margin-top: ${({isForNavbar}) => isForNavbar ? '0' : '-10px'} !important;
    margin-bottom: ${({isForNavbar}) => isForNavbar ? '3px' : '10px'};

    max-height: 50px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  #category {
    color: #A28EDC;
    font-family: IBM Plex Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.48px;
  }
  #continue {
    padding: ${({isForNavbar}) => isForNavbar ? '5px 10px' : '10px 17px'};
    background: #F2F7F9;
    position: absolute;
    right: 0;
    bottom: 0;
    cursor: pointer;
    transition: all 0.3s ease;
    :hover {
      background: ${props => props?.color};
      color: #fff;
      svg {
        color: #fff;
      }
    }
  }
`;

const ContentP = styled.p`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  max-height: 8em; /* Adjust as needed */
  line-height: 2em; /* Adjust as needed */
`;

export const SearchResult = ({data, navbar}) => {
  const history = useHistory();

  const extractPlainText = (html) => {
    const div = document.createElement('div');
    div.innerHTML = html;
  
    document.body.appendChild(div);
  
    // Get the rendered text content
    const plainText = div.innerText;
  
    // Remove the temporary div
    document.body.removeChild(div);
  
    return plainText;
  };

  // Extract plain text from HTML content (3 lines)
  const truncatedText = extractPlainText(data?.content);

  const currentCat = CATEOGORIES.find(cat => cat?.id === data?.category);

  return (
    <StyledCard color={currentCat?.color} isForNavbar={navbar}>
      <hr id='color-hr'/>
      <p>{currentCat?.name}</p>
      <h1>{data?.title}</h1>
      <ContentP className='desc'>{truncatedText}</ContentP>
      <div id='continue' onClick={() => history.push(`/post/${data?.id ?? 0}`)}>Seguir leyendo <UilArrowRight/></div>
    </StyledCard>
  );
};
