import React from 'react';
import { PasswordForm } from "../components/login/passwordForm";
import { HelmetTitle } from '../helpers/HelmetTitle';

export const Password = () => {
    return (
        <>
            <HelmetTitle
                title="Cambiar contraseña"
                name="description"
                content="content"
            />
            <PasswordForm />
        </>
    );
}
