import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

const ContainerStyled = styled(Container)`
  width: 100%;
  padding: 70px 0px 111px 0px;
  @media screen and (max-width: 576px) {
    padding: 20px 0px 40px 0px;
  }
`;

const CategoriasTitle = styled.h2`
  color: #474a4b;
  text-align: center;
  font-family: "IBM Plex Sans";
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 22px;
`;
const CategoriaName = styled.span`
  color: #00ae8e;
  font-family: "IBM Plex Sans";
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 0px 0px 0px 40px;
`;
const ItemCategoria = styled.div`
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #e0ecec;
  display: flex;
  align-items: center;
  justify-content: start;

  @media screen and (max-width: 576px) {
    border-left: none;
    border-right: none;
    border-radius: 0;

    &:last-child {
      border-bottom: none;
    }
  }
`;

// const LinkCategoria = styled.a`

// &:hover{
//   :hover {
//       background: #EFEFEF40;
//     }
// }
// `

const AvailableServices = ({ dataBranch }) => {
  const { services } = dataBranch;
  return (
    <>
      {services && services.length > 0 && (
        <ContainerStyled>
          <Container>
            <CategoriasTitle>
              Servicios disponibles en esta sucursal:
            </CategoriasTitle>
            <Row className="">
              {services.map((service) => (
                <Col className="mt-0 mt-sm-4 " sm={12} md={6} lg={4} xl={4}>
                  <ItemCategoria>
                    <img
                      className=""
                      src={service.icon}
                      alt={service.name}
                      loading="lazy"
                      style={{ width: service.width }}
                    />
                    <CategoriaName className="">{service.name}</CategoriaName>
                  </ItemCategoria>
                </Col>
              ))}
            </Row>
          </Container>
        </ContainerStyled>
      )}
      {/* <ContainerStyled>
            <Container>
            <CategoriasTitle>Servicios disponibles en esta sucursal:</CategoriasTitle>
              <Row className=''>
              { categorias.map((categoria) => (
                <Col className='mt-0 mt-sm-4 ' sm={12} md={6} lg={4} xl={4}>
                  <ItemCategoria>
                   <img className='' src={categoria.icon} alt={categoria.name} loading='lazy' style={{width: categoria.width}}/>
                   <CategoriaName className=''>{categoria.name}</CategoriaName>
                  </ItemCategoria>
                </Col>
                ))}
              </Row>
            </Container>
      </ContainerStyled> */}
    </>
  );
};

export default AvailableServices;
