import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { BsPencilSquare, BsTrash } from 'react-icons/bs';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { Button, Modal } from 'react-bootstrap';
import { deletePost, getAllPosts } from '../../../actions/blog';
import { timestampToSpanishDate } from '../../../helpers/hours';
import handleAlert from '../../../helpers/handleAlert';
import { allowedUsersToPost } from '../colors';
import { useSelector } from 'react-redux';

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`;

const Th = styled.th`
  background-color: #f8f9fa;
  padding: 10px;
  text-align: left;
`;

const Td = styled.td`
  padding: 10px;
  border-bottom: 1px solid #dee2e6;
`;

const ActionButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 10px;
`;

const DeleteButton = styled(ActionButton)`
  color: #dc3545;
`;

const EditButton = styled(ActionButton)`
  color: #007bff;
`;

const MainDiv = styled.div`
  min-height: 60vh;
  padding: 30px;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

const PageButton = styled.button`
  background-color: ${(props) => (props.active ? '#4D99E5' : 'transparent')};
  color: ${(props) => (props.active ? '#fff' : '#4D99E5')};
  border: 1px solid #007bff;
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
`;

export const AdminBlog = () => {
  const [data, setData] = useState(/* Your data here */);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const uid = useSelector((state) => state?.auth?.uid);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);

  const handleDelete = async (id) => {
    const successDeleted = await deletePost(id);
    if (successDeleted) {
      setShowDeleteModal(false);
      setItemToDelete(null);
      fetchPosts();
      handleAlert('success', 'El post ha sido eliminado correctamente', 'Post eliminado');
    } else {
      handleAlert('error', 'Hubo un error al intentar el eliminar el post', 'Ocurrió un error');
    }
  };

  const handleTrashClick = (id) => {
    setItemToDelete(id);
    setShowDeleteModal(true);
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  useEffect(()=> {
    if(!allowedUsersToPost?.includes(uid)){
      window.location.href = '/';
    }
  })

  const fetchPosts = async () => {
    try {
      const posts = await getAllPosts();
      setData(posts);
    } catch (error) {
      // Handle error
      console.error('Error fetching posts: ', error);
    }
  };

  return (
    <MainDiv>
      <h1>Posts</h1>
      <Table>
        <thead>
          <tr>
            <Th>ID</Th>
            <Th>Título</Th>
            <Th>Fecha de creación</Th>
            <Th>Acciones</Th>
          </tr>
        </thead>
        <tbody>
          {currentItems?.map((item) => (
            <tr key={item.id}>
              <Td>{item.id}</Td>
              <Td>{item.title}</Td>
              <Td>{timestampToSpanishDate(item?.created) ?? 'Sin fecha'}</Td>
              <Td>
                <EditButton>
                  <BsPencilSquare onClick={() => window.location.href = `/editar-post/${item?.id}`}/>
                </EditButton>
                <DeleteButton onClick={() => handleTrashClick(item?.id)}>
                  <BsTrash />
                </DeleteButton>
              </Td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Pagination */}
      <PaginationContainer>
        {Array.from({ length: Math.ceil(data?.length / itemsPerPage) }, (_, index) => (
          <PageButton
            key={index}
            onClick={() => handlePageChange(index + 1)}
            active={currentPage === index + 1}
          >
            {index + 1}
          </PageButton>
        ))}
      </PaginationContainer>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Eliminar post</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AiOutlineExclamationCircle size={50} color="#dc3545" />
          <br/>
          <p>¿Estás seguro de eliminar este post?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => handleDelete(itemToDelete)}>
            Confirmar
          </Button>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </MainDiv>
  );
};