import React from 'react';
import { Accordion, Container } from 'react-bootstrap';
import { UilPlusCircle, UilMinusCircle } from '@iconscout/react-unicons';
import styled from 'styled-components';

const Title = styled.h1`
  font-weight: 800;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #25A78D;
  margin-bottom: 34px;
`;

const StyledAccordion = styled(Accordion)`
  .accordion-item {
    position: relative;
    border: none !important;
    border-top: 1px solid rgba(0, 173, 144, 0.2) !important;
    @media only screen and (min-width: 768px) {
      background: transparent;
    }
    .icon {
      position: absolute;
      right: 3px;
      top: 10px;
      @media only screen and (max-width: 768px) {
        display: none;
      }
    }
    .plus {
      display: none;
    }
    .collapsed {
      @media only screen and (min-width: 768px) {
        .plus {
          display: block !important;
        }
      }
    }
  }
  .accordion-header {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #00AD90;
    button {
      background: transparent;
      display: flex;
      justify-content: left;
      padding-top: 12px;
      padding-bottom: 12px;
      min-height: 40px;
      height: auto !important;
      @media only screen and (min-width: 768px) {
        font-size: 20px !important;
        line-height: 24px;
      }
      ::after {
        display: none;
      }

    }
  }
  .accordion-body {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    color: #46494A;
    @media only screen and (min-width: 768px) {
      font-size: 18px;
      line-height: 24px;
    }
    span {
      cursor: pointer;
      color: #00AD90;
      :hover {
        text-decoration: underline;
      }
    }
  }
`;

const Background = styled.div`
  padding-top: 39px;
  padding-bottom: 29px;
  @media only screen and (min-width: 768px) {
    padding-top: 54px;
    padding-bottom: 34px;
    background: #E9FBF4;
    .container {      
      max-width: 968px;
    }
  }
`;

export const FAQs = () => {
  return (
    <Background>
      <Container>
        <Title>Preguntas frecuentes</Title>
        <StyledAccordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              ¿Cuánto dura el programa?
              <UilMinusCircle className="minus icon" color="#00AD90" size={25}/>
              <UilPlusCircle className="plus icon" color="#00AD90" size={25}/>
            </Accordion.Header>
            <Accordion.Body>
              El programa tiene una duración de 12 semanas o 3 meses desde que se asiste a la
              primera consulta de nutrición y acondicionamiento físico.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              ¿Qué incluye el programa?
              <UilMinusCircle className="minus icon" color="#00AD90" size={25}/>
              <UilPlusCircle className="plus icon" color="#00AD90" size={25}/>
            </Accordion.Header>
            <Accordion.Body>
              <ul>
                <li>Kit de bienvenida (tazas medidoras y botella de agua brandeada)</li>
                <li>Check up: análisis de laboratorio (exámen general de orina, biometría hemática y química sanguínea de 6 elementos)</li>
                <li>3 consultas de nutrición con plan de alimentación</li>
                <li>3 consultas de acondicionamiento físico con plan de ejercicios</li>
                <li>3 mediciones de antropometría a través de la báscula InBody para conocer la composición corporal</li>
                <li>Registro saludable para llevar seguimiento</li>
                <li>Videos educativos</li>
                <li>12 clases/talleres a lo largo de los tres meses (1 por semana) con nuestras alianzas en diferentes ubicaciones de Puebla.</li>
                <li>Material para talleres</li>
                <li>Diploma por haber concluido el programa de manera satisfactoria</li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              ¿Es un Programa personalizado?
              <UilMinusCircle className="minus icon" color="#00AD90" size={25}/>
              <UilPlusCircle className="plus icon" color="#00AD90" size={25}/>
            </Accordion.Header>
            <Accordion.Body>
              Si, el programa se ajustará de acuerdo a tus objetivos, necesidades, gustos y estilo
              de vida, para que sea algo realista que puedas llevar a cabo durante estos tres
              meses, enamorándote de tu proceso.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              ¿Cuál es la finalidad del Programa Transforma?
              <UilMinusCircle className="minus icon" color="#00AD90" size={25}/>
              <UilPlusCircle className="plus icon" color="#00AD90" size={25}/>
            </Accordion.Header>
            <Accordion.Body>
              El Programa Transforma está hecho para mejorar tu calidad de vida a través de
              hábitos nutricios y un estilo de vida saludable, tú serás el dueño de tu Programa, por
              lo que los objetivos los defines tú. Sin embargo, es importante tomar en cuenta que
              tu salud siempre va primero, por lo que tu nutrióloga te asesorará sobre cómo llegar
              a estos objetivos de una forma saludable con herramientas que te serán útiles para
              TODA LA VIDA.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              ¿Qué precio tiene y cómo puedo pagar?
              <UilMinusCircle className="minus icon" color="#00AD90" size={25}/>
              <UilPlusCircle className="plus icon" color="#00AD90" size={25}/>
            </Accordion.Header>
            <Accordion.Body>
              El programa tiene un precio de $6,500.00 y lo puedes pagar de contado o a meses
              sin intereses desde la página Labopat.mx en la sección <span onClick={()=>window.location.href = '/nutrición'}>Nutrición</span> o directamente en nuestra sucursal
              en Palmas Plaza, local #42.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>
              ¿Los talleres son obligatorios?
              <UilMinusCircle className="minus icon" color="#00AD90" size={25}/>
              <UilPlusCircle className="plus icon" color="#00AD90" size={25}/>
            </Accordion.Header>
            <Accordion.Body>
              Sí, los talleres están pensados para mejorar habilidades que te serán de mucha
              ayuda para llegar a tus objetivos, puedes escoger el taller que gustes tomar, pero ten
              en cuenta que al menos deberás de asistir a dos talleres de cada área (Mi mente, mi
              cuerpo y mi entorno).
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6">
            <Accordion.Header>
              ¿Necesito tener algún nivel de ejercicio o entrenamiento para empezar este programa?
              <UilMinusCircle className="minus icon" color="#00AD90" size={25}/>
              <UilPlusCircle className="plus icon" color="#00AD90" size={25}/>
            </Accordion.Header>
            <Accordion.Body>
              No, al ser un Programa PERSONALIZADO tenemos diferentes opciones en donde
              podrás encontrar el nivel adecuado a lo que estás buscando. Este es uno de los
              beneficios de tener varias alianzas para que logres el desempeño que estás deseas.
              ¡El Programa es la perfecta oportunidad para transformar, conectar y probar cosas
              nuevas!
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="7">
            <Accordion.Header>
              ¿Qué pasa si no puedo asistir un fin de semana a un taller?
              <UilMinusCircle className="minus icon" color="#00AD90" size={25}/>
              <UilPlusCircle className="plus icon" color="#00AD90" size={25}/>
            </Accordion.Header>
            <Accordion.Body>
              A lo largo de tú Programa se debe cumplir la asistencia de 12 clases/talleres con
              nuestras alianzas. Es importante avisar si no podrás acudir a alguna activación
              previamente agendada, para que te podamos guiar con la siguiente opción
              disponible. NOS ENCONTRAMOS SUJETOS AL CUPO LIMITADO de cada
              activación para brindar la mejor experiencia posible.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="8">
            <Accordion.Header>
              ¿Para quién está dirigido el Programa Transforma?
              <UilMinusCircle className="minus icon" color="#00AD90" size={25}/>
              <UilPlusCircle className="plus icon" color="#00AD90" size={25}/>
            </Accordion.Header>
            <Accordion.Body>
              El Programa Transforma está dirigido a toda población mayor de edad que vive en
              Puebla o pueda acudir a sus activaciones con nuestras alianzas y desee realizar una
              transformación en su salud y en su vida.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="9">
            <Accordion.Header>
              ¿Si tengo una enfermedad crónica puedo inscribirme al Programa Transforma?
              <UilMinusCircle className="minus icon" color="#00AD90" size={25}/>
              <UilPlusCircle className="plus icon" color="#00AD90" size={25}/>
            </Accordion.Header>
            <Accordion.Body>
              Claro, el Programa Transforma se ajusta a tus necesidades, es importante que
              cualquier condición que tengas nos la hagas saber a tu inscripción y llenado de
              formulario para poder ajustar las activaciones pertinentes. Puede ser que existan
              algunos cambios en las activaciones, dependiendo de las contraindicaciones que se
              tengan.
            </Accordion.Body>
          </Accordion.Item>
        </StyledAccordion>
        <br/>
        {/* <p>Si tienes más dudas, te puedes comunicar al correo electrónico nutricion@labopat.com o escribirnos al WhatsApp +52 222 208 6833.</p> */}
      </Container>
    </Background>
  );
};
