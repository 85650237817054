import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { UilSearchAlt, UilTimesCircle } from '@iconscout/react-unicons'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { FloatingLabel, Form } from 'react-bootstrap';
import { PrivRoutes } from '../../routes/routes';
import { useSelector } from 'react-redux';

const SearchInput = styled(Form.Control)`
  background: #F7F7F7 !important;
  border: none;
  border-radius: 10px !important;
  height: 50px;
  padding-left: 40px;
  z-index: 1;
  ::-webkit-input-placeholder {
    font-size: 16px;
    color: #A6A6A6;
    margin-left: 40px;
  }
  :focus {
    outline: none;
    -webkit-box-shadow: 0px 0px 5px 5px ${(props) => `${props.maincolor}80`}; 
    box-shadow: 0px 0px 5px 5px ${(props) => `${props.maincolor}80`};
  }
`;

const MyFloating = styled(FloatingLabel)`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  .form-control {
    border: none;
    background: #f6f6f6;
    border-radius: 12px;
    font-family: 'IBM Plex Sans';
    padding-left: 40px;
    padding-top: 0.5em !important;
  }
  label {
    font-weight: normal;
    color: #828282;
    transform-origin: -10em -10em;
    background: #f7f7f7;
    border-radius: 8px;
    padding: 2px 6px;
    margin-top: 14px;
    margin-left: 35px;
    height: auto;
    font-family: 'IBM Plex Sans';
  }
`;

export const Search = (props) => {
  const [search, setSearch] = useState('');
  const isDoctor = useSelector((state) => state.user?.data?.data?.profile?.type) === 1;
  const history = useHistory();
  const {pathname} = useLocation();
  const location = useLocation();
  const maincolor = PrivRoutes.includes(location.pathname) && isDoctor ? "#4F90BA" : "#00AC90";
  
  const show = () => {
    if (search.length > 0) {
      props.showSearch();
    }
  };

  const hide = () => {
    if (search.length <= 0) {
      props.hideSearch();
    }
  };

  useEffect(() => {
    props.setSearching(search);
    if (search.length > 0) {
      props.showSearch();
    } else if (search.length === 0) {
      props.hideSearch();
    }
  }, [search]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && search !== '' && !pathname.includes('paquete')) {
      history.push(`/buscador?search=${search}`)
    }
  }

  useEffect(()=>{
    let myInput = document.getElementById("search-input");
    if(!(props.notFocus === 'nope')){
      myInput?.focus();
    }
  },[])

  return (
    <div style={{position: 'relative'}}>
      <UilSearchAlt style={{ zIndex: 2, color: 'rgb(27 130 193 / 58%)', position: 'absolute', left: '10px', top: '15px' }}/>
      {search !== "" &&
        <UilTimesCircle style={{ zIndex: 2, color: '#A6A6A6', position: 'absolute', right: '10px', top: '15px', cursor: 'pointer' }}
          onClick={()=> setSearch("")}
        />
      }
      <MyFloating label='Busca tus estudios o servicios'>
        <SearchInput
          id="search-input"
          autoComplete="off"
          maincolor={maincolor === "#4F90BA" ? "#4F90BA" : "#0CAC8C"}
          type="text"
          placeholder='Busca tus estudios o servicios'
          onFocus={show}
          onBlur={hide}
          value={search}
          onKeyDown={handleKeyDown}
          onChange={(e) => setSearch(e.target.value)}
          autoFocus={props.notFocus === 'nope' ? false : true}
        />
      </MyFloating>
    </div>
  );
};
