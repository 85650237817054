import React, { useEffect, useRef, useState  } from 'react';
import styled from 'styled-components';
import Vimeo from '@u-wave/react-vimeo';

const VideoContainer = styled.div`
    widht: 100%;
    max-width: 1920px;
    margin: 0 auto;
`;

export const OurServices = () => {

    const videoRef = useRef(null);
    const [ isVisible, setIsVisible ] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if(entry.isIntersecting){
                    setIsVisible(true);
                    observer.disconnect();
                }
            },
            {
                threshold: 0.5
            }
        );

        if(videoRef.current){
            observer.observe(videoRef.current)
        }

        return () => {
            if(videoRef.current){
                observer.unobserve(videoRef.current)
            }
        };
    });

    return (
        <VideoContainer ref={videoRef}>
           
           {isVisible && (
                <Vimeo
                color='ffbc45'
                video='https://vimeo.com/873068364'
                responsive={true}
                autoplay loop muted
            />
            )
           }
        </VideoContainer>
    );
};
