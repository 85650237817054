import React from 'react';
import styled from 'styled-components';
import Vimeo from '@u-wave/react-vimeo';

const VideoContainer = styled.div`
    widht: 100%;
    max-width: 1920px;
    margin: 0 auto;
`;

export const VideoAboutUs = () => {
    return (
        <VideoContainer>
            {/* Video */}
            <Vimeo
                color='ffbc45'
                video='https://vimeo.com/855773658'
                responsive={true}
                autoplay loop muted
            />
        </VideoContainer>
    );
};