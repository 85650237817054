import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, Row } from 'react-bootstrap';
import styled from 'styled-components';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const StyledCalendar = styled(Calendar)`
    margin-left: auto;
    margin-right: auto;
    border: none !important;
    > abbr, abbr[title], abbr[data-original-title] {
        text-decoration: none !important;
    }
    > .react-calendar__tile--active {
        background: #000 !important;
        color: white;
    }
    > .react-calendar__tile--active:enabled:hover, .react-calendar__tile--active {
        background: #00AD90 !important;
        border-radius: 18px;
        color: white !important;
    }
    > button.react-calendar__tile {
        width: 40px !important;
    }

    .react-calendar button {
        border: 1px solid #000;
        outline: none;
    }

    .react-calendar__tile--now {
        background: #E0E0E0;
        border-radius: 18px;
    }

    .react-calendar__tile:disabled {
        background-color: #fff;
        color: #E0E0E0;
    }

    .react-calendar__month-view__days__day--weekend {
        color: #000;
    }

    .react-calendar__month-view__days__day--neighboringMonth {
        color: #E0E0E0;
    }
`;

const StyledSelect = styled(Form.Select)`
    border: 1px solid #E9E9E9;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px #F6F6F6;
    border-radius: 12px;
`;

const MonthLabel = styled.p`
    text-align: center;    
    width: 31px;
    font-family: 'IBM Plex Sans';
    background: #ffffff !important;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    color: #A6A6A6;
    margin-bottom: 2px;
    margin-left: 13px;
`;

const ModalHeader = styled(Modal.Header)`
    border-bottom: 0px;
    padding: 0;
    > .modal-title {
        font-family: 'IBM Plex Sans';
        font-style: normal;
        font-weight: 900;
        font-size: 22px;
        line-height: 33px;
        color: #000000;
    }
`;

const StyledModal = styled(Modal)`
    > #contact-modal .modal-content {
        border: none;
        border-radius: 12px;
        padding: 24px;
    }
`;

const ContinueButton = styled(Button)`
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    width: 241px;
    height: 40px;    
    background: #00AD90;
    border: none;
    border-radius: 102px;
    margin-left: auto;
    margin-right: auto;
    :focus{
        background: #0A7A71;
    }
    :hover{
        background: black;
    }
`;

export const CalendarModal = (props) => {

    const [show, setShow] = useState(props.show);
    const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]
    const [date, setDate] = useState(props.date);
    const currentMonth = new Date(Date.now()).getMonth();
    const [month, setMonth] = useState(months[currentMonth]);

    useEffect(() => {
        props.show && setShow(true);
      }, [props.show]);

    const handleMonthSelect=(e)=>{
        date?.setMonth(e.target.value)
        setMonth(months[e.target.value]);
    }

    const handleContinueButton = () => {
        props.onSelectDate(date);
        setShow(false);
    }
    
    return(
        <>
        <StyledModal id="contact-modal" show={show} onHide={()=>{setShow(false)}} centered>
            <ModalHeader>
                <Modal.Title>Selecciona una fecha disponible</Modal.Title>
            </ModalHeader>
            <Modal.Body>
                {/*<MonthLabel>Mes</MonthLabel>
                <br />
                <StyledSelect defaultValue="Fecha" 
                    style={{marginTop: '-10px'}}
                    showNavigation={true}
                    onChange={(e)=>handleMonthSelect(e)}
                    id="monthSelect">
                    <option disabled hidden>{months[currentMonth]}</option>
                    <option id={1} value={currentMonth}>{months[currentMonth]}</option>
                    <option id={2} value={currentMonth <= 10 ? currentMonth+1 : currentMonth-11}>
                        {months[currentMonth <= 10 ? currentMonth+1 : currentMonth-11]}</option>
                    <option id={3} value={currentMonth <= 9 ? currentMonth+2 : currentMonth-10}>
                        {months[currentMonth <= 9 ? currentMonth+2 : currentMonth-10]}</option>
                </StyledSelect>
    <br/>*/}
                <StyledCalendar 
                    onChange={setDate}
                    value={date}
                    showNavigation={true}
                    minDate={new Date(Date.now())}
                    tileDisabled={({ date }) => (date?.getDay() === 0)}
                />
                <br/>
                <p style={{textAlign: 'center', color: '#A6A6A6'}}>
                    {("0" + date?.getDate()).slice(-2) + "-" + (date?.getMonth() + 1) + "-" + date?.getFullYear()}
                </p>
                <br/>
                <Row className='justify-content-center'>
                    <ContinueButton variant="primary" onClick={handleContinueButton}>
                            Continuar
                    </ContinueButton>
                </Row>
            </Modal.Body>      
        </StyledModal>
        </>
    );
};
