import React from 'react';
import styled from 'styled-components';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';

const StyledModal = styled(Modal)`
  display: flex !important;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  .modal-content {
    border-radius: 0;
  }
  .btn-close {
    padding: 0;
    top: 20px;
    right: 20px;
    width: 22px;
    height: 22px;
    z-index: 1;
    background: transparent
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2300AD90'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
      center/1em auto no-repeat;
    background-size: 100% 100%;
    outline: none;
    border: none;
    box-shadow: none;
    :focus {
      box-shadow: none;
    }
    @media only screen and (max-width: 768px) {
      top: 24px;
    }
  }
  .modal-dialog {
    max-width: 400px;
    width: 100%;
    margin: 0;
  }
  .modal-header {
    border: none;
  }
  .modal-body {
    padding-top: 31px;
    padding-bottom: 31px;
  }
`;

const ModalTitle = styled.p`
  font-family: 'Faustina';
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 32px;
  color: #00ad90;
  text-align: left;
  margin-bottom: 4px;
  width: auto;
  @media only screen and (max-width: 768px) {
    text-decoration: underline;
  }
`;

const StyledList = styled.ul`
  margin-bottom: 0;
  list-style: none;
  padding-left: 0;
  li {
    font-family: 'Faustina';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 23px;
    color: #474a4b;
    max-width: 386px;
    @media only screen and (max-width: 768px) {
      font-size: 18px;
      line-height: 25px;
    }
    span {
      font-weight: 700;
    }
  }
  .first-condition {
    font-family: 'Faustina';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    color: #474a4b;
    margin-bottom: 14px;
    @media only screen and (max-width: 992px) {
      margin-bottom: 22px;
    }
  }
  .second-condition {
    font-family: 'Faustina';
    font-style: italic;
    font-weight: 700;
    font-size: 15px;
    line-height: 23px;
    color: #f9ad62;
    margin: 20px 0 0 -17px;
    max-width: 300px;
  }
  .show-green {
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    text-decoration-line: underline;
    color: #00ad90;
    margin-left: 30px;
    @media only screen and (max-width: 768px) {
      font-size: 18px;
      line-height: 23px;
    }
  }
`;

export const ImportantDates = (props) => {

  return (
    <StyledModal
      show={props.showDates}
      onHide={() => props.setShowDates(false)}
    >
      <StyledModal.Header closeButton className='p-0'></StyledModal.Header>
      <StyledModal.Body>
        <Row className='pe-4 ps-4 pt-4 pb-4'>
          <Col md={12} lg={12} className='pe-0' id='mex-req'>
            <ModalTitle style={{ color: '#00AD90' }}>
              Envío de documentación:
            </ModalTitle>
            <StyledList className='mb-4'>
              <li>Fecha límite, 02 de diciembre 2023</li>
            </StyledList>
            <ModalTitle style={{ color: '#00AD90' }}>
              Proceso de Selección:
            </ModalTitle>
            <StyledList className='mb-4'>
              <li>04 al 09 de diciembre 2023</li>
            </StyledList>
            <ModalTitle style={{ color: '#00AD90' }}>
              Examen Teórico, psicométrico y entrevista:
            </ModalTitle>
            <StyledList className='mb-4'>
              <li>13 al 22 de diciembre 2023</li>
            </StyledList>
            <ModalTitle style={{ color: '#00AD90' }}>
              Entrega de Carta de Aceptación:
            </ModalTitle>
            <StyledList className='mb-4'>
              <li>05 de enero 2024</li>
            </StyledList>
          </Col>
        </Row>
      </StyledModal.Body>
    </StyledModal>
  );
};
