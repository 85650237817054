import React, { useState } from 'react';
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { UilPlus, UilAngleLeftB, UilAngleRightB } from '@iconscout/react-unicons';
import Modal from 'react-bootstrap/Modal';
import Slider from 'react-slick';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const DrBg =
  'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fcurso-alta-especialidad%2FacademaBg.png?alt=media&token=31ff3f40-9fc6-4a9b-804a-96218fb1207c';
const TextureBg =
  'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fcurso-alta-especialidad%2Ftextura.png?alt=media&token=1b96716e-8713-4d12-aca0-bb012970f47f';

const ScrollAnnounce =
  'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fcurso-alta-especialidad%2FSCROLL_ANNOUNCE.png?alt=media&token=b0ae6edb-9659-4d4e-a2c0-e6cab9b8e988';

const MainSection = styled.div`
  background-color: #ffffff;
  background-image: url(${DrBg});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top right;
  padding-top: 42px;
  padding-bottom: 72px;
  @media only screen and (max-width: 1200px) {
    padding-top: 0;
    padding-bottom: 38px;
  }
  @media only screen and (max-width: 992px) {
    background-image: none;
  }
  @media only screen and (max-width: 768px) {
    .container {
      padding-left: 25px;
    }
  }
`;

const SectionTitle = styled.h2`
  font-family: 'Faustina';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
  text-transform: uppercase;
  color: #c4c4c4;
  width: auto;
  margin-bottom: 48px;
  @media only screen and (max-width: 1200px) {
    margin-bottom: 24px;
  }
  @media only screen and (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const Subtitle = styled.h3`
  font-family: 'Faustina';
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 45px;
  color: #00ad90;
  text-align: left;
  width: auto;
  margin-bottom: 14px;
  @media only screen and (max-width: 768px) {
    margin-bottom: 10px;
  }
  @media only screen and (max-width: 576px) {
    font-size: 26px;
    line-height: 30px;
  }
`;

const Desc = styled.p`
  font-family: 'Faustina';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #474a4b;
  margin-bottom: 32px;
  @media only screen and (max-width: 768px) {
    margin-bottom: 22px;
  }
  @media only screen and (max-width: 576px) {
    font-size: 17px;
    line-height: 26px;
  }
`;

const FacultyBtn = styled.button`
  position: relative;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  color: #ffffff;
  padding: 12px 20px 12px 48px;
  background: #00ad90;
  outline: none;
  border: none;
  svg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 12px;
    margin: auto;
  }
`;

const StyledModal = styled(Modal)`
  display: flex !important;
  flex-direction: row;
  align-items: center;
  .btn-close {
    padding: 0;
    top: 3.5vw;
    width: 24px;
    height: 24px;
    z-index: 1;
    background: transparent
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2300AD90'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
      center/1em auto no-repeat;
    background-size: 100% 100%;
    outline: none;
    border: none;
    box-shadow: none;
    :focus {
      box-shadow: none;
    }
    @media only screen and (max-width: 768px) {
      top: 24px;
    }
  }
  .modal-dialog {
    max-width: 100%;
    max-height: 100vh !important;
    width: 100%;
    margin: 0;
  }
  .modal-header {
    border: none;
  }
  .modal-body {
    padding-top: 42px;
    padding-bottom: 71px;
    background-image: url(${TextureBg});
    background-repeat: no-repeat;
    background-size: 7.344vw 100%;
    background-position: top left;
    @media only screen and (max-width: 768px) {
      padding-top: 26px;
      padding-left: 24px;
      padding-right: 28px;
      padding-bottom: 31px;
      background-image: none;
    }
  }
`;

const ModalTitle = styled.p`
  font-family: 'Karmina Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  text-transform: uppercase;
  color: #c4c4c4;
  text-align: left;
  margin-bottom: 32px;
  padding-left: 9.531vw;
  @media only screen and (max-width: 768px) {
    font-size: 17px;
    line-height: 22px;
    padding-left: 0;
  }
`;

const ModalSubtitle = styled.p`
  font-family: 'Faustina';
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 45px;
  color: #00ad90;
  margin-bottom: 28px;
  @media only screen and (max-width: 768px) {
    font-size: 24px;
    line-height: 30px;
  }
`;

const ModalSliders = styled.div`
  padding-left: 13.542vw;
  padding-right: 13.542vw;
  @media only screen and (max-width: 992px) {
    padding-left: 14.113vw;
    padding-right: 6.048vw;
  }
  @media only screen and (max-width: 768px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const StyledSlider = styled(Slider)`
  position: relative;
  margin-bottom: 60px;
  padding-bottom: 20px;
  .slick-prev {
    left: -40px;
    background: none;
    padding: 0;
    width: 32px;
    height: 32px;
    :before {
      display: none;
      content: '';
    }
  }
  .slick-next {
    right: -40px;
    background: none;
    padding: 0;
    width: 32px;
    height: 32px;
    :before {
      display: none;
      content: '';
    }
  }
  .slick-slide > div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    border-top: ${props => props.showBorder === 1 ? '2px solid #00AD90' : 'none'};
    padding-top: ${props => props.showBorder === 1 ? '17px' : '0'};
    @media only screen and (max-width: 768px) {
      padding-top: 0;
      border-top: none;
    }
  }
  .slick-dots {
    li {
      margin: 0;
      width: 33%;
      @media only screen and (max-width: 576px) {
        width: ${props => props.secondSlider ? '33%' : '16.5%' };
      }
      @media only screen and (min-width: 992px) {
        width: ${props => props.secondSlider ? '50%' : '33%' };
      }
    }
    button{
      width: 100%;
      height: 3px;
      padding: 0;
      background: #F2F7F9; 
      transition: all ease .5s;
      margin-top: 35px;
    }
    button:before {
      display: none;
    }
    .slick-active button {
      background: #C4C4C4;
    }
  }
`;

const Slide = styled(Row)`
  justify-content: center;
`;

const DrPic = styled.img`
  max-width: 100px;
  width: 100%;
  max-height: 100px;
  height: auto;
`;

const DrName = styled.p`
  font-family: 'Faustina';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #474a4b;
  margin-bottom: 0;
  span {
    font-weight: 700;
  }
  @media only screen and (max-width: 768px) {
    font-size: 15px;
    line-height: 23px;
  }
`;

const GuestDrName = styled.p`
  font-family: 'Faustina';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  color: #474A4B;
  margin-bottom: 24px;
  padding-right: 8px;
  padding-left: 8px;
  span {
    font-weight: 700;
  }
  @media only screen and (max-width: 768px) {
    font-size: 15px;
    line-height: 23px;
    padding-top: 10px;
    padding-right: 0;
    padding-left: 0;
    margin-right: 8px;
    margin-left: 8px;
    border-top: 2px solid #00AD90;
  }
`;

const ScrollAnnounceImg = styled.img`
  position: absolute;
  top: 0;
  bottom: 0;
  right: -16px;
  margin: auto;
  max-width: 208px;
  max-height: 208px;
  z-index: 0;
  pointer-events: none;
  user-select: none;
  animation: twinkle .8s infinite alternate;
  @keyframes twinkle {
    0%{
        opacity:.8;
    }
    100%{
        opacity:0;
    }
  }
`;

export const Academy = () => {
  const [show, setShow] = useState(false);
  const { width } = useWindowDimensions();

  const CustomPrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
      >
        <UilAngleLeftB
          className='p-0'
          color={'474A4B'}
          size={'32'}
        />
      </div>
    )
  }

  const CustomNextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
      >
        <UilAngleRightB
          className='p-0'
          color={'474A4B'}
          size={'32'}
        />
      </div>
    )
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    useTransform: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const settings2 = {
    infinite: true,
    slidesToShow: 1,
    speed: 500,
    rows: 2,
    dots: true,
    slidesPerRow: 3,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    useTransform: false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesPerRow: 2,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesPerRow: 2,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesPerRow: 2,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  return (
    <MainSection id='academy'>
      {width <= 992 && (
        <img
          src={DrBg}
          alt='doctores'
          className='img-fluid'
          style={{ marginBottom: '15px' }}
        />
      )}
      <Container>
        <SectionTitle>Academia</SectionTitle>
        <Subtitle>
          Profesor titular:
          <br />
          Dr. Sergio Sánchez Sosa
        </Subtitle>
        <Desc>
          Especialidad en Anatomía Patológica
          <br />
          Maestría en Oncología Molecular
        </Desc>
        <FacultyBtn onClick={() => setShow(!show)}>
          <UilPlus color={'#ffffff'} size={24} />
          VER PROFESORADO COMPLETO
        </FacultyBtn>
      </Container>
      <StyledModal show={show} onHide={() => setShow(!show)}>
        <StyledModal.Header closeButton className='p-0'></StyledModal.Header>
        <StyledModal.Body>
          <div className='w-100'>
            <ModalTitle>
              ACADEMIA / PROFESORES {width <= 768 && <br />}
              ADJUNTOS E INVITADOS
            </ModalTitle>
            <ModalSliders>
              <ModalSubtitle>Profesores adjuntos al curso:</ModalSubtitle>
              <div className='p-0 m-0 position-relative'>
                <StyledSlider {...settings} showBorder={0}>
                  <div>
                    <Slide>
                      <Col xs={2} className='p-0'>
                        <DrPic
                          src='https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fcurso-alta-especialidad%2FdraSilvia.png?alt=media&token=067ff90d-c741-423d-afaa-a9a1aa806a2d'
                          alt='Imagen del doctor'
                        />
                      </Col>
                      <Col xs={10} lg={10} xl={8} className='pe-0'>
                        <DrName>
                          <span>Dra. Silvia Montilla Fonseca</span>
                          <br />
                          Doctorado en Biología Molecular
                        </DrName>
                      </Col>
                    </Slide>
                  </div>
                  <div>
                    <Slide>
                      <Col xs={2} className='p-0'>
                        <DrPic
                          src='https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fcurso-alta-especialidad%2FdraIrina.png?alt=media&token=e56f81a9-3880-4422-8c9f-16ef2d926c31'
                          alt='Imagen del doctor'
                        />
                      </Col>
                      <Col xs={10} lg={10} xl={8} className='pe-0'>
                        <DrName>
                          <span>Dra. Irina Benemelis García</span>
                          <br />
                          Especialidad en Anatomía Patológica
                          <br />
                          Curso de Alta Especialidad en
                          <br />
                          Inmunohistoquímica y Biología Molecular
                        </DrName>
                      </Col>
                    </Slide>
                  </div>
                  <div>
                    <Slide>
                      <Col xs={2} className='p-0'>
                        <DrPic
                          src='https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fcurso-alta-especialidad%2FdraGabriela.png?alt=media&token=83bfb339-6db2-46db-b6d2-5d04885748c7'
                          alt='Imagen del doctor'
                        />
                      </Col>
                      <Col xs={10} lg={10} xl={8} className='pe-0'>
                        <DrName>
                          <span>Dra. Gabriela Cristina Cuauro Ruíz</span>
                          <br />
                          Especialidad en Anatomía Patológica
                          <br />
                          Curso de Alta Especialidad en
                          <br />
                          Inmunohistoquímica y Biología Molecular
                        </DrName>
                      </Col>
                    </Slide>
                  </div>
                  <div>
                    <Slide>
                      <Col xs={2} className='p-0'>
                        <DrPic
                          src='https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fcurso-alta-especialidad%2FdraAndrea.png?alt=media&token=71f9ccce-f3cc-46e8-a3bf-ae247c0c12fc'
                          alt='Imagen del doctor'
                        />
                      </Col>
                      <Col xs={10} lg={10} xl={8} className='pe-0'>
                        <DrName>
                          <span>Dra. Andrea de la Rosa Bandini</span>
                          <br />
                          Especialidad en Anatomía Patológica
                          <br />
                          Maestría en Dirección de Centros Educativos
                        </DrName>
                      </Col>
                    </Slide>
                  </div>
                  <div>
                    <Slide>
                      <Col xs={2} className='p-0'>
                        <DrPic
                          src='https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fcurso-alta-especialidad%2FdraCynthia.png?alt=media&token=b245d321-0fa2-4ee8-882f-13a80f513901'
                          alt='Imagen del doctor'
                        />
                      </Col>
                      <Col xs={10} lg={10} xl={8} className='pe-0'>
                        <DrName>
                          <span>Dra. Cynthia Peñaloza Coronas</span>
                          <br />
                          Biotecnología
                          <br />
                          Doctorado en Biología Molecular
                        </DrName>
                      </Col>
                    </Slide>
                  </div>
                  <div>
                    <Slide>
                      <Col xs={2} className='p-0'>
                        <DrPic
                          src='https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fcurso-alta-especialidad%2Filse.png?alt=media&token=a8d73e21-92ad-4471-96ad-deca9ef7f37a'
                          alt='Imagen del doctor'
                        />
                      </Col>
                      <Col xs={10} lg={10} xl={8} className='pe-0'>
                        <DrName>
                          <span>Mtra. Ilse Mariana Flores Hernández</span>
                          <br />
                          Especialidad en Biomedicina
                          <br />
                          Maestría en Ciéncias Biológicas
                        </DrName>
                      </Col>
                    </Slide>
                  </div>
                </StyledSlider>
                {width <= 768 && (
                  <ScrollAnnounceImg
                    src={ScrollAnnounce}
                    alt='Desliza para ver más'
                  />
                )}
              </div>
              <ModalSubtitle>
                Profesores invitados:
              </ModalSubtitle>
              <div className='p-0 m-0 position-relative'>
                <StyledSlider {...settings2} showBorder={1} secondSlider={true}>
                  <div>
                    <GuestDrName>
                      <span>
                        Dra. Carmen Lome Maldonado 
                      </span>
                      <br/>
                      Hematopatología
                      {width <= 992 && (
                        <>
                        <br/>
                        <br/>
                        </>
                      )}
                    </GuestDrName>
                  </div>
                  <div>
                    <GuestDrName>
                      <span>
                        Dr. Víctor Reuter
                      </span>
                      <br/>
                      Uropatología
                    </GuestDrName>
                  </div>
                  <div>
                    <GuestDrName>
                      <span>
                        Dr. Iván Romarico González Espinoza
                      </span>
                      <br/>
                      Oncología médica. HAP.
                    </GuestDrName>
                  </div>
                  <div>
                    <GuestDrName>
                      <span>
                        Dra. María Arcila
                      </span>
                      <br/>
                      Memorial Sloan Kettering Cancer{' '}
                      {width > 576 && (
                        <br/> 
                      )}
                      Center, NY.
                    </GuestDrName>
                  </div>
                  <div>
                    <GuestDrName>
                      <span>
                        Dr. Rafael Jiménez
                      </span>
                      <br/>
                      Uropatología, Mayo Clinic, US.
                    </GuestDrName>
                  </div>
                  <div>
                    <GuestDrName>
                      <span>
                        Dr. Isidro Machado
                      </span>
                      <br/>
                      Tejidos Blandos. IVO Valencia,{' '}
                      <br/>
                      España.
                    </GuestDrName>
                  </div>
                  <div>
                    <GuestDrName>
                      <span>
                        Dr. Víctor Prieto
                      </span>
                      <br/>
                      Dermatopatología. MD Anderson, US.
                    </GuestDrName>
                  </div>
                  <div>
                    <GuestDrName>
                      <span>
                        Dr. Juan Miguel Mosquera
                      </span>
                      <br/>
                      Patología Molecular, Cornell U, NY.
                    </GuestDrName>
                  </div>
                  {width >= 992 && (
                    <div></div>
                  )}
                  <div>
                    <GuestDrName>
                      <span>
                        Dr. Llombart Bosh
                      </span>
                      <br/>
                      Tejidos Blandos. IVO Valencia,{' '}
                      <br/>
                      España.
                    </GuestDrName>
                  </div>
                  <div>
                    <GuestDrName>
                      <span>
                        Dr. Nicole Cipriani
                      </span>
                      <br/>
                      Patología en Cabeza y Cuello,{' '}
                      <br/>
                      Universidad NWU, Chicago.
                    </GuestDrName>
                  </div>
                </StyledSlider>
                {width <= 768 && (
                  <ScrollAnnounceImg
                    src={ScrollAnnounce}
                    alt='Desliza para ver más'
                  />
                )}
              </div>
            </ModalSliders>
          </div>
        </StyledModal.Body>
      </StyledModal>
    </MainSection>
  );
};
