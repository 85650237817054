import React, { useEffect, useRef, useState } from 'react';
import { Button, Container, FloatingLabel, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { CATEOGORIES, allowedUsersToPost } from '../colors';
import JoditEditor from 'jodit-react';
import { useDispatch, useSelector } from 'react-redux';
import { createPost } from '../../../actions/blog';
import { CA_GRADIENT_BUTTON } from '../../../helpers/colors';
import handleAlert from '../../../helpers/handleAlert';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Loader } from '../../general/spinner';

const StyledContainer = styled(Container)`
  padding: 20px 20px;
  h1{
    font-weight: 400;
    font-size: 36px;
    line-height: 38px;
    color: rgb(105, 112, 119);
    margin-top: 24px;
    margin-bottom: 24px;
  }
  h3{
    font-weight: 400;
    font-size: 20px;
    line-height: 38px;
    color: rgb(105, 112, 119);
    margin-top: 20px;
    margin-bottom: 0;
  }
  button {
    transition: all 0.3s ease;
    :hover {
      filter: brightness(1.1);
    }
  }
  #confirm-btn {
    font-size: 18px;
    padding: 8px 20px;
    border: none;
    color: #FFF;
    border-radius: 30px;
    background: ${CA_GRADIENT_BUTTON};
  }
  #cancel-btn {
    font-size: 18px;
    padding: 8px 20px;
    border: none;
    color: #FFF;
    border-radius: 30px;
    background: #DF0C0C;
  }
  @media only screen and (width <= 768px) {
    padding: 90px 20px 20px;
  }
`;

const StyledLabel = styled(FloatingLabel)`
  width: 100%;
  padding: 2px;
  border-radius: 12px;
  transition: all ease .5s;
  position: relative;
  .input-icon {
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translate(0,-50%);
    z-index: 1;
  }
  .form-control {
    border: none;
    border-radius: 12px;
    font-family: 'IBM Plex Sans';
    max-height: 45px;
    padding: 0 0 0 20px;
    :not(:placeholder-shown) {
      padding-top: 0.6em;
    }
    :focus {
      padding-top: 0.6em;
    }
  }
  .form-select {
    border: none;
    background-color: #f6f6f6;
    border-radius: 12px;
    font-family: 'IBM Plex Sans';
    max-height: 45px;
    padding: 0 0 0 40px !important;
    :not(:placeholder-shown) {
      padding-top: 0.6em;
    }
  }
  label {
    font-weight: normal;
    color: #828282;
    transform-origin: -12em -8em;
    border-radius: 4px;
    padding: 5px 10px;
    margin: 5px 0 0 30px;
    height: auto;
    font-family: 'IBM Plex Sans';
    transition: 300ms;
  }
  :active,
  :focus-within {
    background: linear-gradient(245deg, rgb(247, 238, 27) -32.78%, rgb(53, 225, 155) 24.37%, rgb(0, 174, 142) 75.27%, rgb(80, 151, 227) 138.67%);
    label {
      margin: 5px 0 0 35px;
      padding: 4px 10px;
      opacity: 1 !important;
      transition: 200ms;
    }
  }
`;

const StyledInput = styled(Form.Control)`
  background-color: #f6f6f6;
  border: none;
  border-radius: 10px !important;
  height: 50px;
  padding-left: 20px;
  z-index: 1;
  ::-webkit-input-placeholder {
    font-size: 16px;
    color: #a6a6a6;
    margin-left: 40px;
  }
  :focus{
    filter: drop-shadow(0px 0px 8px rgba(59, 209, 230, 0.6));
    box-shadow: none;
    border-color: #00000000;
    border-radius: 10px;
    background: #FFFFFF;
  }
`;

const StyledSelect = styled(Form.Select)`
  border-radius: 12px;
`;

export const CreatePost = () => {
  const editor = useRef(null);
  const user = useSelector((state) => state.user?.data?.data);
  const uid = useSelector((state) => state?.auth?.uid);
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  // Post structure
  const [post, setPost] = useState({
      title: '',
      content: '',
      category: '',
      author: `${user?.name ?? ''} ${user?.fathersLastname ?? ''} ${user?.mothersLastname ?? ''}`,
      authorUID: uid
  });

  const dispatch = useDispatch();

  // To save image
  const [image, setImage] = useState(null);

  //field changed function
  const fieldChanged = (event) => {
    // console.log(event)
    setPost({ ...post, category: parseInt(event.target.value) })
  }

  const contentFieldChanaged = (data) => {
    setPost({ ...post, 'content': data })
  }

  //handling file chagne event
  const handleFileChange=(event)=>{
    console.log(event.target.files[0]);
    setImage(event.target.files[0]);
  }


  // FORM THINGS
  const { handleSubmit } = useForm();

  const onSubmit = async (data) => {
    setLoading(true)
    if(checkFields()){
      dispatch(createPost(post, image, history))
    }else{
      handleAlert('warning', 'Ingresa información, todos los campos son obligatorios', 'Datos faltantes');
      setLoading(false);
    }
  };

  const checkFields = () => {
    const isValid = 
      post?.title?.length >= 1 &&
      post?.content?.length >= 1 &&
      post?.category >= 0 &&
      post?.author?.length >= 1 &&
      image !== null && image;
    return isValid;
  };


  useEffect(()=> {
    if(!allowedUsersToPost?.includes(uid)){
      window.location.href = '/';
    }
  })

  return (
    <StyledContainer>
      <h1>Crear post</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Title */}
        <StyledLabel controlId='name' label='Título *'>
          <StyledInput
            type='text'
            placeholder='Título *'
            name='title'
            autoComplete='off'
            list='autocompleteOff'
            value={post?.title}
            onChange={(event) => 
              setPost({ ...post, title: event.target.value })
            }
          />
        </StyledLabel>

        {/* CONTENT */}
        <div className="my-3">
          <JoditEditor
            ref={editor}
            value={post.content}
            onChange={(newContent) => contentFieldChanaged(newContent)}
          />
        </div>

        {/* Banner */}
        <div className="mt-3">
            <h3>Imagen banner</h3>
            {/* <Label for="image">Select Post banner</Label> */}
            <input id="image" type="file" accept="image/png, image/gif, image/jpeg" onChange={handleFileChange}/>
            <br />
            <br />
            <br />
        </div>

        <StyledLabel label='Categoría'>
          <StyledSelect
            name='category'
            onChange={fieldChanged}
            value={post?.category}
          >
            <option disabled>Categoría</option>
            {CATEOGORIES.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </StyledSelect>
        </StyledLabel>
        <br />
        
        {/* BUTTONS */}
        <div className='d-flex align-items-center justify-content-center'>
          <Button id='confirm-btn' type='submit' style={{marginRight: '10px'}}>Crear post</Button>
          <Button id='cancel-btn'>Cancelar</Button>
        </div>
      </form>
      {loading ? <Loader show={loading} /> : null}
    </StyledContainer>
  );
};
