// Import the needed functions from the SDKs.
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { FacebookAuthProvider, GoogleAuthProvider } from 'firebase/auth';

import 'firebase/firestore';
import 'firebase/auth';

// Firebase configuration

const firebaseConfig = {
    apiKey: 'AIzaSyBUuKqop68Fw7DSute0q4R0cfj6w8PLm-M',
    authDomain: 'covid19-cc1fe.firebaseapp.com',
    databaseURL: process.env.REACT_APP_API,
    projectId: 'covid19-cc1fe',
    storageBucket: 'covid19-cc1fe.appspot.com',
    messagingSenderId: '317378592417',
    appId: '1:317378592417:web:06c897c7ee4b367c093ae0',
    measurementId: 'G-5TSPMRXZJN',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore();
const googleAuthProvider = new GoogleAuthProvider();
const facebookAuthProvider = new FacebookAuthProvider();

export { app, db, googleAuthProvider, facebookAuthProvider };
