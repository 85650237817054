import {
  addCartDB,
  getCartDB,
  updateCartDB,
  getStudyInfo,
} from "../helpers/api";
import handleAlert from "../helpers/handleAlert";
import { types } from "../types/types";

export const addItemToCart = (items) => ({
  type: types.addItemToCart,
  payload: items,
});

export const removeItemFromCart = (items) => ({
  type: types.removeItemFromCart,
  payload: items,
});

export const setTotalPrice = (data) => ({
  type: types.totalPrice,
  payload: data,
});

export const resetCart = () => ({
  type: types.resetCart,
});

export const addPersonalData = (data) => ({
  type: types.addPersonalData,
  payload: data,
});

export const getTotalPrice = (newCart) => {
  let newPrice = 0.0;
  //console.log("new cart..: ", newCart);
  newCart.forEach((item) => {
    if (item?.price) {
      newPrice += item.price;
    } else {
    newPrice += item.promo_price;
    }
  });
  //console.log(newPrice);
  return newPrice;
};

export const checkCart = (cliente = 0) => {
  return (dispatch, getState) => {
    // console.log("CHECKING LOCAL STORAGE");
    // console.log("cliente: ", cliente);
    //const { user } = getState()t;
    //const idClienteLabopat = useSelector(
    //  (state) => state.user?.data?.data?.profile?.idClienteLabopat
    //);
    const cartId = localStorage.getItem("cartId");
    // console.log("cartid: ", cartId);
    if (cartId) {
      // console.log("GETTING CART FIREBASE", cartId);
      dispatch(getCartDB(cartId));
      setTimeout(() => {
        dispatch(checkForPricesUpdates(cliente));
      }, 1000);
    } else {
      dispatch(addCartDB());
    }
  };
};

export const checkForPricesUpdates = (cliente = 0) => {
  return (dispatch, getState) => {
    const { items } = getState().shoppingCart;
    let newCart = [];

    items.forEach((item) => {
      newCart.push(
        getStudyInfo(item.id, cliente)
          .then(({ data }) => {
            // console.log("datay: ", data.price);
            return {
              ...item,
              oldPrice: parseFloat(data.normal_price),
              price: parseFloat(data.price),
            };
          })
          .catch((error) => {
            console.log("Error: ", error);
          })
      );
    });

    Promise.all(newCart).then((values) => {
      // Update cart Redux
      dispatch(addItemToCart(values));
      // Update cart in DB
      // console.log("Get total 20: ", values);
      dispatch(updateCartDB(values, getTotalPrice(values)));
    });
  };
};

export const handleAddItemToCart = (element) => {
  return (dispatch, getState) => {
    // console.log(element);
    const { items } = getState().shoppingCart;

    let repeated = 1;
    let newItems = [];

    items.forEach((item) => {
      delete item.description;
      // If it's repeated then count how many times it appears
      if (item.id === element.id) {
        repeated = item.howMany + 1;
      } else {
        // If not then just add it to a new array
        newItems.push(item);
      }
    });

    // Create new element
    const newElement = {
      ...element,
      howMany: repeated,
      // If it's not repeated then it'll be multiplied by 1
      //price: element.price * repeated,
      price: element.price,
    };

    // Add new element to a new array
    newItems.push(newElement);
    // console.log("get total price 10", newItems);
    const currentPrice = getTotalPrice(newItems);

    try {
      // Update cart in DB
      dispatch(updateCartDB(newItems, currentPrice));
      // Update cart
      dispatch(addItemToCart(newItems));
      // Update total price
      dispatch(setTotalPrice(currentPrice));

      // Message success
      handleAlert('success', 'El artículo se ha agregado a tu carrito', 'Artículo añadido')
    } catch (error) {
      // Message error
      handleAlert('error', 'Hubo un error al tratar de añadir al carrito.', 'No se pudo agregar el artículo')
    }
  };
};
