import React, { useEffect } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { UilEnvelopes } from '@iconscout/react-unicons'
import { useDispatch, useSelector } from 'react-redux';
import { addCheckin, setCurrentCheckin } from '../../actions/checkin';
import { Loader } from '../general/spinner';

const ScanDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 30px;
  > div svg {
    color: #00AD90;
  }
  > h1 {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 900;
    font-size: 22px;
    line-height: 33px;
    text-align: center;
    color: #00AD90;
  }
`;

const Qr = styled.div`
  padding: 20px;
  border-radius: 16px;
`;

const ConfirmButton = styled.button`
  width: 297px;
  height: 48px;
  background: #6FBEA0;
  border: none;
  border-radius: 24px;
  font-weight: 800;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #FFFFFF;
  :hover {
    background: #81d6b6;
  }
`;

export const Results = (props) => {
  const { currentCheckin } = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const confirm = () => {
    if(!props.payedNow){
      if (currentCheckin) {
        const data = {
          referencia: 'KIOSCO-CLIP', // referencia: "Kiosco clip"
          total: currentCheckin.estudios.reduce(
            (n, { precioNeto }) => n + precioNeto,
            0
          ), // total a pagar
          age: currentCheckin.edad,
          folio: currentCheckin.drive.folio, // folio,
          birthday: currentCheckin.fnac, // No sé si es necesario
          branch: currentCheckin.sucursal.toString(), // Sucursal desde "-1" hasta "5"
          coupon: {
            comment: currentCheckin.drive.cupon + ' coment',
            name: currentCheckin.drive.cupon,
            total: '0',
          },
          datetime: currentCheckin.drive?.fechaYhora
            ? currentCheckin.drive?.fechaYhora
            : '03/05/2022 - 09:40', // Formato --> "DD/MM/YYYY - HH:mm"
          // El "datetime" yo lo paso a timestamp con una función específica de Firebase.
          gender: currentCheckin.genero,
          idS: currentCheckin.solicitud.id,
          id: currentCheckin.drive.id,
          invoice: false, // "Sí" | "No" o un Booleano y yo lo convierto
          paid: true,
          paidInKiosko: false,
          methodPayment: currentCheckin.drive.tipopago,
          patient: `${currentCheckin.nombre} ${currentCheckin.apellidos}`, // Nombre del paciente
          study: currentCheckin.estudios,
        };
        console.log(data);
        saveData(data);
      }
    }
  }
  const saveData = async (data) => {
    await setLoading(true);
    dispatch(addCheckin(data));
    await setLoading(false);
  };

  return (
    <ScanDiv>
      <h1>Resultados</h1>
      <Qr id="qrdiv" onClick={ () => console.log("Clicked") }>
        <UilEnvelopes size={200} />
      </Qr>
      <br/>
      <h1 style={{color: '#000'}}>Enviaremos los resultados a tu correo electrónico</h1>
      <div className='d-flex justify-content-center'>
        <ConfirmButton onClick={() => dispatch(setCurrentCheckin(null))}>Confirmar</ConfirmButton>
      </div>
      <br/>
      <br/>
    </ScanDiv>
  )
}