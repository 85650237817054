import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';

const Slide = styled(Container)`
  height: 460px;
  position: relative;
  overflow: hidden;
  h1 {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 130%;
    color: #F3F3F3;
    margin-bottom: 50px;
    max-width: 653px;
    margin-left: auto;
    text-align: right;
  }
  p {
    font-family: 'IBM Plex Sans';
    font-weight: 400;
    font-size: 25px;
    line-height: 130%;
    color: #F3F3F3;
    margin-bottom: 43px;
    margin-left: auto;
    text-align: right;
  }
  span {
    font-weight: 600;
  }
  #text-col {
    z-index: 2;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    .row {
      width: 100%;
    }
  }
  #img-col {
    z-index: 1;
    height: 460px;
    display: flex;
    align-items: end;
    justify-content: center;
  }
  img {
    max-width: 750px;
    max-height: 100%;
  }
  @media only screen and (min-width: 1920px) {
    #img-col {
      padding: 0;
    }
    img {
      max-width: 120%;
      max-height: 105%;
    }
  }
  @media only screen and (max-width: 1920px) {
    width: 100% !important;
    max-width: 100% !important;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1920px) {
    padding: 0 0 0 8%;
  }
  @media only screen and (min-width: 768px) and (max-width: 1300px) {
    padding: 0 0 0 5%;
    h1 {
      font-size: 40px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 992px) {
    #img-col {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img {
      max-width: 160%;
    }
  }

  @media only screen and (max-width: 768px) {
    height: 520px;
    padding: 40px 0 0;
    h1 {
      font-size: 28px;
      width: 100%;
      max-width: 215px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }
    p {
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      font-size: 20px;
      margin-bottom: 20px;
      padding: 0 10px;
      max-width: 282px;
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translate(-50%, 0);
      width: 100%;
    }
    img {
      max-width: 125%;
      max-height: 260px;
      position: absolute;
      bottom: 110px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
`;

export const PayOnlineBanner = () => {
  return (
    <div style={{background: 'linear-gradient(137deg, rgba(0,174,142,1) 47%, rgba(53,225,155,1) 75%, rgba(206,247,25,1) 96%, rgba(247,238,27,1) 100%)'}}>
      <Slide>
        <Row>
          <Col xs={12} md={6} xxl={6} id='text-col'>
            <h1>Paga tus análisis de laboratorio de <span>forma segura.</span></h1>
            <p><span>Labopat,</span> la nueva solución en <span>servicios de laboratorio.</span></p>
          </Col>
          <Col xs={12} md={6} xxl={5} id='img-col'>
            <img src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2FhomeSlider%2FLaptop%20Mockup.png?alt=media&token=18ffe943-966e-4346-89d1-da8faa69660f" alt="laptop" />
          </Col>
        </Row>
      </Slide>
    </div>
  );
};
