
export const setPatientInfo = (data) => ({
    type: 'setPatientInfo',
    payload: data
});

export const resetPatientInfo = (data = {}) => ({
    type: 'resetPatientInfo',
     payload: data
});
