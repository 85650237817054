import React from "react";
import { Modal } from "react-bootstrap";
import { UilTimes } from '@iconscout/react-unicons';
import styled from "styled-components";

const privacyDocuments = [
  {title: 'Aviso de privacidad - Colaboradores', link: 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fprivacy%2F2024%2FLP_formato_AP_integral_Colaboradores.pdf%20OK.pdf?alt=media&token=0984c051-8366-4185-a346-b1f713e4c186'},
  {title: 'Aviso de privacidad - Sujetos Obligados', link: 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fprivacy%2F2024%2FLP_formato_AP_integral_Sujetos_Obligados%20(1).pdf?alt=media&token=5899545d-03c2-4769-a8dd-27692fdb7d4b'},
  {title: 'Aviso de privacidad - Clientes', link: 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fprivacy%2F2024%2FLP_formato_AP_integral_Clientes%20(1).pdf?alt=media&token=b279e676-ab46-4f2c-8e20-397ce617cbf6'},
  {title: 'Aviso de privacidad - Pacientes', link: 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fprivacy%2F2024%2FLP_formato_AP_integral_Pacientes%20(1).pdf?alt=media&token=29b9cce7-7a94-4ace-991f-423ba6964f14'},
]

const StyledModal = styled(Modal)`
  .modal-dialog {
    height: 80%;
    display: flex;
    align-items: center;
  }
  .modal-header {
    border-bottom: 1px solid #c1d9d8;
  }
  .modal-title {
    font-weight: 900;
    font-size: 22px;
    line-height: 24px;
    width: 100%;
    text-align: center;
    color: ${(props) => props.mainColor};
  }
  p {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #717171;
    span {
      font-weight: 800;
    }
  }
  .btn-close {
    width: 40px;
    height: 40px;
    background: ${(props) => props.mainColor};
    border-radius: 20px;
    padding: 0px !important;
    top: -12px;
    right: 33px;
    opacity: 1;
  }
  svg {
    z-index: 1000;
    top: -13px;
    right: 33px;
    position: absolute;
  }
  ul {
    list-style-type: none;
    padding-left: 0;
    li {
      display: flex;
      justify-content: center;
      aling-items: center;
    }
  }
  a {
    font-size: 18px; 
    color: ${(props) => props.mainColor};
    text-decoration: underline;
    :hover {
      color: #30DF99;
    }
  }
`;

export const PrivacyModal = ({show, hide}) => {
  return (
    <StyledModal show={show} onHide={hide} mainColor={'#00AC90'}>
      <div onClick={hide}>
        <UilTimes color='#fff' style={{ cursor: 'pointer' }} />
      </div>
      <Modal.Header closeButton>
        <Modal.Title>Avisos de privacidad</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul>
          {privacyDocuments?.map((pDoc, index) =>
            <li key={index}>
              <a
                href={pDoc?.link}
                target="_blank"
                rel="noreferrer"
              >
                {pDoc?.title}
              </a>
            </li>
          )}
        </ul>
      </Modal.Body>
    </StyledModal>
  )
};