import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { UilPlayCircle } from '@iconscout/react-unicons';
import styled from 'styled-components';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { useDispatch, useSelector } from 'react-redux';
import { handleAddItemToCart } from '../../actions/shoppingCart';
import handleAlert from '../../helpers/handleAlert';

const MainDiv = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  background: url("https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fprograma-transforma%2Ffondobanner.png?alt=media&token=a87f7d34-74be-4c44-b007-c7f14a8e8bea");
  background-size: 100% 100%;
  @media only screen and (max-width: 768px){
    background: url("https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fprograma-transforma%2Ffondobannermobile.png?alt=media&token=9ddeb4ac-72d1-405e-bc36-90a347b1c066");
    background-size: 100% 100%;
  }
  #logo-transforma {
    margin-top: 36px;
    margin-bottom: 17px;
    width: 524px;
    @media only screen and (max-width: 768px){
      margin-top: 27px;
      margin-bottom: 29px;
      width: 267px;
    }
  }
  h1 {
    font-weight: 800;
    font-size: 64px;
    line-height: 70px;
    color: #F2F7F9;
    max-width: 494px;
    @media only screen and (max-width: 768px){
      max-width: 233px;
      font-size: 32px;
      line-height: 34px;
    }
  }
  p {
    max-width: 474px;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #F2F7F9;
    margin-bottom: 31px;
    @media only screen and (max-width: 768px){
      max-width: 221px;
      margin-top: 15px;
      font-size: 14px;
      line-height: 130%;
    }
  }
  #main-col {
    padding-left: 2px;
  }
  #smiling {
    position: absolute;
    bottom: 0;
    right: 0;
    max-height: 105%;
    max-width: 50%;
    @media only screen and (min-width: 1800px){
      right: 15%;
    }
    @media only screen and (min-width: 1400px) and (max-width: 1400px){
      right: 5%;
    }
    @media only screen and (max-width: 768px){
      right: -20%;
      max-width: 70%;
    }
  }
`;

const OrangeButton = styled.button`
  width: 80%;
  max-width: 306px;
  height: 48px;
  background: #f9ad62;
  border-radius: 30px !important;
  border: none;
  font-size: 16px;
  padding: 0;
  font-weight: 800;
  margin-top: 6px;
  margin-bottom: 58px;
  color: #fff;
  transition: all 0.4s ease;
  :hover {
    transition: all 0.4s ease;
    background: #ffbf80;
  }
  @media only screen and (max-width: 768px){
    margin-bottom: 31px;
    max-width: 163px;
  }
`;

const GrayBar = styled.div`
  padding: 17px 0;
  background: rgba(0, 173, 144, 0.15);
  #left-col {
    border-right: 1px solid #00AD92;
  }
  .col-6 {
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 130%;
      color: #00AD92;
      margin-bottom: 0px;
      @media only screen and (max-width: 768px){
        font-weight: 400;
        font-size: 15px;
        line-height: 130%;
      }
      span {
        font-weight: 800;
        font-size: 24px;
        line-height: 130%;
        margin-right: 14px;
        @media only screen and (max-width: 768px){
          font-size: 10px;
        }
      }
    }
  }
  #timer {
    width: 42px;
    @media only screen and (max-width: 768px){
      width: 33px;
    }
  }
  #location {
    width: 52px;
    @media only screen and (max-width: 768px){
      width: 35px;
    }
  }
  img {
    margin-right: 14px;
    @media only screen and (max-width: 768px){
      margin-right: 10px;
    }
  }
`;

const GrayBg = styled.div`
  width: 100%;
  background: rgba(0, 173, 144, 0.08);
  padding: 39px 22px;
  h1 {
    font-weight: 800;
    font-size: 32px;
    line-height: 28px;
    text-align: center;
    color: #00AD90;
    margin-bottom: 40px;
    @media only screen and (max-width: 768px){
      margin-bottom: 13px;
      font-size: 24px;
    }
  }
  button {
    height: auto !important;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100% !important;
    max-width: 398px;
    margin: 0;
    font-weight: 800;
    text-align: center;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
    @media only screen and (min-width: 768px){
      max-width: 470px;
      font-size: 24px;
      line-height: 36px;      
    }
  }
`;

export const Banner = ({study, setShowCart}) => {
  const {width} = useWindowDimensions();
  const { items } = useSelector((state) => state.shoppingCart);
  const dispatch = useDispatch();

  const addStudy = () => {
    const exists = items.filter((item) => item.id === study.id);
    if (exists.length === 1) {
      handleAlert('warning', 'Este estudio ya lo tienes agregado en tu solicitud', 'Estudio ya agregado');
    } else {
      dispatch(handleAddItemToCart(study));
    }
    setShowCart(true);
  };

  return (
    <>
      <MainDiv>
        <Container>
          <Row>
            <Col xs={8} sm={8} md={7} lg={6} id="main-col">
              <img id='logo-transforma' src='https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fprograma-transforma%2FLogotipoTransforma.png?alt=media&token=475cdb4e-9b7a-495f-96b3-521472629545' alt='programa-transforma'/>
              <h1>Revoluciona tu salud y estilo de vida</h1>
              <p>Alcanza tus metas de salud y aprende las herramientas necesarias para llevar una vida saludable para siempre</p>
              <Row>
                <Col xs={12}>
                  <OrangeButton onClick={() => addStudy()}>
                    Reserva tu lugar
                  </OrangeButton>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <img id='smiling' src='https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fprograma-transforma%2Ffeli.png?alt=media&token=7d5dcbc2-da9f-4adf-9c1e-2959cfedb5c0' alt='smile'/>
      </MainDiv>
      <GrayBar>
        <Container>
          <Row>
            <Col xs={6} id="left-col">
              <img id='timer' alt='timer' src='https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fprograma-transforma%2Ftimer-icon.png?alt=media&token=cc94e336-4b80-4368-9baf-9736f769352e'/>
              <p><span>Duración:</span>{width < 768 && <br/>}3 meses</p>
            </Col>
            <Col xs={6}>
              <img id='location' alt='location' src='https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fprograma-transforma%2Flocation-icon.png?alt=media&token=255243a8-0225-4d20-8bea-87986368d54d'/>
              <p><span>Ubicación:</span>{width < 768 && <br/>}Puebla</p>
            </Col>
          </Row>
        </Container>
      </GrayBar>
      <Container>
        <div style={{width: "100%", height: "269px", left: "14px", top: "705px", background: "rgba(233, 233, 233, 0.8)", borderRadius: "20px", display: "flex", alignItems: "center", justifyContent: "center", margin: "37px 0"}}>
          <UilPlayCircle color="#A6A6A6" size={55}/>
        </div>
      </Container>
      <GrayBg>
        <h1>¿Quieres conocer tu estado de salud actual?</h1>
        <div className='d-flex w-100 justify-content-center'>
          <OrangeButton>Tomar cuestionario de hábitos</OrangeButton>
        </div>
      </GrayBg>
    </>
  );
};
