import { useHistory } from "react-router-dom/cjs/react-router-dom";
import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row, Modal } from "react-bootstrap";
import styled from "styled-components";
import {
  UilSearchMinus,
  UilSearchPlus,
  UilClipboardAlt,
  UilTrashAlt,
  UilTimes,
} from "@iconscout/react-unicons";
import { useDispatch, useSelector } from "react-redux";
import { PrivRoutes } from "../../../routes/routes";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { branches } from "../../../helpers/branches";
import { HelmetTitle } from "../../../helpers/HelmetTitle";
import {
  getTotalPrice,
  removeItemFromCart,
  setTotalPrice,
} from "../../../actions/shoppingCart";
import { updateCartDB } from "../../../helpers/api";
import { ListItemCart } from "../../shopping-cart/listItemCart";

import useAnalyticsEventTracker from "../../../analytics/useAnalyticsEventTracker";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
const loading = "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2Fecommerce%2Floading.gif?alt=media&token=629f78d3-10a2-44f9-9143-668f5bc19ace";

const StyledForDesktop = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 40px;
  @media only screen and (max-width: 700px) {
    padding: 25px;
  }
`;
const Title = styled.h1`
  font-family: "IBM Plex Sans";
  font-size: 44px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  margin-top: 100px;
  @media only screen and (min-width: 993px) {
    text-align: center;
    margin-bottom: 24px;
  }
`;
const StyledModal = styled(Modal)`
  .modal-header {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #4f90ba;
  }
  .btn-close {
    left: 93%;
  }
  .modal-content {
    padding: 10px;
  }
  .modal-title {
    font-weight: 900;
    font-size: 22px;
    line-height: 33px;
    margin-right: auto;
    color: #4f90ba;
  }
  .modal-footer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn-primary {
    background: #4f90ba;
    border: none;
  }
  .foot-btn {
    max-width: 368px;
    width: 90%;
    margin: 0 auto;
    border: 2px solid #4f90ba;
    border-radius: 18px;
  }
  .row {
    width: 100%;
  }
  span {
    font-weight: 800;
  }
`;
const Nearest = styled.div`
  background: #dbf1ff;
  border-radius: 12px;
  padding: 12px 16px;
  > p {
    margin-bottom: 4px;
  }
  > :nth-child(1) {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #3b3b3b;
  }
  > :nth-child(2) {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #3b3b3b;
  }
  > div div p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: inline;
    > a {
      color: #4f90ba;
    }
  }
  > div div p:hover {
    text-decoration: underline;
    color: #4f90ba;
  }
  > div {
    padding-left: 0;
    > div {
      padding-left: 0;
    }
  }
`;
const ButtonMenu = styled.div`
  width: 100%;
  text-align: left;
  color: black;
  padding-left: 0px;
  padding-right: 0px;
  background-color: white;
  border: none;
  margin-bottom: 20px;
`;
const StyledPlaces = styled.div`
  cursor: pointer;
  .simple {
    background: none !important;
    padding-bottom: 0px !important;
    padding-top: 0px !important;
  }
  a {
    font-family: "IBM Plex Sans";
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #4f90ba;
    :hover {
      text-decoration: underline;
    }
  }
  @media only screen and (max-width: 576px) {
    #desc {
      padding-left: 0px;
      padding-right: 20px;
    }
    #col-llegar {
      padding-left: 0px !important;
      padding-right: 20px;
    }
  }
  > div > div > div {
    position: relative;
    @media only screen and (max-width: 576px) {
      padding-bottom: 80px;
    }
  }
  .phone-link {
    color: #4f90ba;
    font-weight: 700;
    padding-left: 0px;
    @media only screen and (max-width: 576px) {
      margin-top: 5px;
    }
    @media only screen and (min-width: 576px) {
      position: absolute;
      top: 3px;
      right: 20px;
      width: auto;
      display: flex;
    }
  }
  .form-check-input {
    display: none !important;
  }
  .form-check {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    padding-left: 0;
  }
`;
const StyledImg = styled.div`
  padding: 12px;
  position: absolute;
  top: ${(props) => (props.photo ? "-60" : "-470")}px;
  right: -100px;
  z-index: 1;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0px 4px 4px rgba(71, 74, 75, 0.15);
  transition: all ease 0.4;
  width: auto;
  img {
    width: 426px;
  }
  @media only screen and (max-width: 600px) {
    display: ${(props) => (props.photo ? "block" : "none")};
    right: 15px;
    top: -20px;
    img {
      width: 100%;
    }
  }
  #plus-icon {
    width: 41px;
    height: 41px;
    background: #ffffff;
    border-radius: 50%;
    position: absolute;
    top: -15px;
    right: -15px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 4px rgba(71, 74, 75, 0.15);
    cursor: pointer;
  }
`;
const MiniImage = styled.div`
  cursor: pointer;
  @media only screen and (max-width: 576px) {
    width: auto;
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
  #mini-image {
    position: relative;
    width: 70px;
    height: 72px;
    background: #f6f6f6;
    border-radius: 12px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #plus-icon {
    width: 41px;
    height: 41px;
    background: #ffffff;
    border-radius: 50%;
    position: absolute;
    top: -20px;
    right: -20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img {
    width: 58px;
    height: 58px;
    border-radius: 10px;
  }
`;

const EmptyCart = styled.div`
  #empty {
    margin: 40px auto 24px;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    left: 110px;
    top: 112px;
    background: #f9f9f9;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 27px;
    text-align: center;
    color: #474a4b;
    margin-bottom: 60px;
  }
`;
const CardCont = styled.div`
  max-width: 100%;
  max-height: auto;
  padding: 0rem;
  margin-left: 75px;
  margin-top: 130px;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(45deg, #fff, #fff),
    linear-gradient(
      90deg,
      #5097e3 -2.05%,
      #0cac8c 72.3%,
      #30df99 101.61%,
      #cef719 133.72%,
      #f5ec1d 211.91%
    );
  border: 10 px;
  border: 2px solid transparent;
  border-radius: 12px;
  position: absolute;
`;

const WhiteButton1 = styled.button`
  width: 100%;
  //margin-left: 20%;
  height: 40px;
  background: none;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  margin-top: -5px;
  transition: all 0.4s ease;
  :hover {
    color: #fff;
    transition: all 0.4s ease;
    background: ${(props) => props.mainColor};
  }
`;
const ListDiv = styled.div`
  overflow-y: auto;
  //padding-right: 7px;
  margin-right: 5px;
  max-height: 50vh;
  @media only screen and (max-width: 1600px) {
    max-height: 40vh;
  }
  @media only screen and (max-width: 1400px) {
    max-height: 68vh;
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 117, 98, 0.3);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;
const CardDiv = styled.div`
  padding: 10px;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(45deg, #fff, #fff),
    linear-gradient(
      90deg,
      #5097e3 -2.05%,
      #0cac8c 72.3%,
      #30df99 101.61%,
      #cef719 133.72%,
      #f5ec1d 211.91%
    );
  border-radius: 10px;
  border: 2px solid transparent;
  overflow-y: auto;
  margin: 5px;
  max-height: 40vh;
  max-width: 23vw;
`;
const StyledDrawer = styled.div`
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(
    90deg,
    #5097e3 -2.05%,
    #0cac8c 72.3%,
    #30df99 101.61%,
    #cef719 133.72%,
    #f5ec1d 211.91%
  );
  overflow: hidden;
  width: 100%;
  padding: 0.1rem;
  border-radius: 10px;
  border: 2px solid transparent;
  margin-top: -2px;
  margin-bottom: 20px;
  height: 90px;
  p {
    color: #fff;
    font-weight: 800;
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 25px;
    line-height: 20px;
    margin-top: 0px;
    text-align: initial;
    margin-bottom: 12px;
    padding: 2rem;
  }
`;
const Bottom = styled.div`
  background: #fff;
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 10px;
  border-radius:10px;
  @media only screen and (max-width: 1900px) {
    //bottom: 100px;
  }
  p {
    text-align: center;
    //margin-botton: 24px;
    .disabled {
      pointer-events: none;
      color: #c4c4c4;
    }
  }
  #genOrder {
    font-weight: 800;
    font-size: 15px;
    line-height: 22px;
    color: ${(props) => props.mainColor};
    text-decoration: underline;
    cursor: pointer;
  }
  #noItems {
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;
    text-align: center;
    color: #a6a6a6;
    span {
      font-weight: 800;
    }
  }
`;
const Total = styled.h5`
  color: #6a6a6a;
  font-weight: 800;
  font-size: 15px;
  margin-top: 16px;
  position: relative;
  span {
    position: absolute;
    font-weight: 500;
    right: 0px;
    color: ${(props) => props.mainColor};
    text-align: right;
  }
  #black {
    color: #6a6a6a;
  }
`;

const BlueButton = styled.button`
  width: 80%;
  margin-left: 10%;
  height: 48px;
  background: ${(props) =>
    props.mainColor === "#4D99E5"
      ? "linear-gradient(84.3deg, #759ABD 15.24%, #97C6C0 97.95%)"
      : "linear-gradient(45deg, #5097E3 -97.58%, #0CAC8C -34.75%, #30DF99 77.36%, #CEF719 119.25%, #F5EC1D 138.96%)"};
  border-radius: 16px;
  border: 2px solid
    ${(props) => (props.mainColor === "#4D99E5" ? "#fff" : "#30DF99")};
  font-size: 16px;
  padding: 0;
  font-weight: 800;
  margin-bottom: 24px;
  color: #fff;
  transition: all 0.4s ease;
  :hover {
    transition: all 0.4s ease;
    background: #fff;
    border: 2px solid ${(props) => props.mainColor};
    color: ${(props) => props.mainColor};
  }
  @media only screen and (max-width: 992px) {
    margin-bottom: 20px;
  }
`;
const ContinueButton = styled(Button)`
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  width: 241px;
  height: 40px;
  background: ${(props) => props.mainColor};
  border: none;
  border-radius: 102px;
  margin-top: 16px;
  margin-bottom: 0px;
  :hover {
    border: 2px solid ${(props) => props.mainColor};
    color: ${(props) => props.mainColor};
    background: #fff;
  }
`;
export const BranchesM = (props) => {
  const { items, totalPrice } = useSelector((state) => state.shoppingCart);
  const location = useLocation();
  const [postal, setPostal] = useState("");
  const dispatch = useDispatch();
  const valores = window.location.search;
  const urlParams = new URLSearchParams(valores);
  const [nearestPlace, setNearestPlace] = useState();
  const [showPlaces, setShowPlaces] = useState(true);
  const [checking, setChecking] = useState(false);
  const [isShown, setIsShown] = useState(["", ""]);
  const [isShownPhoto, setIsShownPhoto] = useState(["", ""]);
  const { width } = useWindowDimensions();
  const isDoctor =
    useSelector((state) => state.user?.data?.data?.profile?.type) === 1;
  const uid = useSelector((state) => state?.auth?.uid);
  const isMobile = width < 768;
  const mainColor =
    PrivRoutes.includes(location.pathname) && isDoctor ? "#4F90BA" : "#4F90BA";
  const [totalSucursal, setTotalSucursal] = useState();
  const gaEventTracker = useAnalyticsEventTracker("Cart");
  var isOrder = urlParams.get("doctor");
  const idClienteLabopat = useSelector(
    (state) => state.user?.data?.data?.profile?.idClienteLabopat
  );
  useEffect(() => {
    getTotal();
  }, [items]);
  const [showCallModal, setShowCallModal] = useState(false);
  const getTotal = () => {
    const pricesArray = items?.map((item) => item.oldPrice);
    const final = pricesArray.reduce(
      (a, b) => parseFloat(a) + parseFloat(b),
      0
    );
    setTotalSucursal(final);
  };
  const history = useHistory();
  const openBranchDetails = (branch) => {
    const { link } = branch;
    history.push(`/sucursales${link}`);
    console.log("diste click en la branch", link);
  };
  const handleRemoveItemFromCart = (id) => {
    let currentItem = items.find((item) => item.id === id);
    const newCart = items.filter((item) => item.id !== id);
    let data = newCart;
    console.log(currentItem);
    if (currentItem.howMany > 1) {
      console.log("IF");
      // Original price
      const originalPrice = currentItem.price / currentItem.howMany;
      const newHowMany = currentItem.howMany - 1;
      currentItem.price = originalPrice * newHowMany;
      currentItem.howMany = newHowMany;
      data = [...newCart, currentItem];
      console.log("DATA: ", data);
    } else {
      console.log("else 3");
    }
    // console.log("get total 4: ", data)
    const price = getTotalPrice(data);
    // Update cart
    dispatch(removeItemFromCart(data));
    // Update total price
    dispatch(setTotalPrice(price));
    // Update cart in DB
    dispatch(updateCartDB(data, price));
  };
  const vaciarCarrito = () => {
    // Eliminar todos los elementos del carrito
    dispatch(removeItemFromCart([])); // Pasar un array vacío elimina todos los elementos
    // Actualizar el precio total a cero
    dispatch(setTotalPrice(0));
    // Actualizar el carrito en la base de datos
    dispatch(updateCartDB([], 0)); // Pasar un array vacío y cero como precio
  };
  const someNotAvailableOnline = () => {
    return items.some(
      (study) =>
        study.categoria === 10 ||
        study.categoria === 8 ||
        study.ventaOnline === 0
    );
  };
  const callToSchedule = () => {
    window.location.href =
      `https://wa.me/522212311313?text=Quiero agendar una cita para:` +
      items.map((study) => " " + study.name);
  };
  const handleOrder = () => {
    const patientInfo = JSON.parse(localStorage?.getItem("patientInfo"));
    gaEventTracker("carrito-generar-order");
    if (window.location.pathname === "/checkout" && !isOrder) {
      history.push(
        `/checkout?doctor=1${patientInfo?.id >= 1 ? "&forPatient=1" : ""}`
      );
      window.location.reload();
    } else {
      history.push(
        `/checkout?doctor=1${patientInfo?.id >= 1 ? "&forPatient=1" : ""}`
      );
    }
  };
  const currency = function (number) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    }).format(number);
  };
  return (
    <>
      <HelmetTitle
        title="Seccion Medica"
        name="description"
        content="content"
      />

      <Row className="justify-content-center mb-5">
        <Col md={6}>
          <StyledForDesktop>
            {checking && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "80vh",
                  width: "100%",
                  position: "absolute",
                  background: "rgba(256, 256, 256, 0.8)",
                }}
              >
                <img src={loading} alt="loading" width={75} />
              </div>
            )}
            <Title>Sucursales</Title>
            {nearestPlace && (
              <Nearest>
                <p>{nearestPlace.name}</p>
                <p>{nearestPlace.address}</p>
                <p>
                  <a
                    href={nearestPlace.mapURL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Abrir dirección en google maps
                  </a>
                </p>
              </Nearest>
            )}
            <Row style={{ marginTop: "10px" }}>
              <Col xs={12}>
                <hr />
              </Col>
            </Row>
            <ButtonMenu variant="light">
              <Row style={{ marginTop: "10px" }}>
                <Col sm={5} xs={5} style={{ paddingLeft: "5px" }}>
                  <p style={{ fontSize: "16px" }}>Sucursales disponibles </p>
                </Col>
                <Col sm={6} xs={6}>
                {branches.map((branch) => (
                  <a
                    className="phone-link"
                    href={`tel:522212311313`}
                    style={{ fontSize: "18px" }}
                  >
                  {branch.phone}
                  </a>
                ))}
                </Col>
              </Row>
            </ButtonMenu>
            {showPlaces ? (
              <StyledPlaces>
                {branches.map((branch) => (
                  <>
                    <Row>
                      <Col
                        xs={12}
                        sm={10}
                        onClick={() => openBranchDetails(branch)}
                      >
                        <Form.Group controlId={`place${branch.id}`}>
                          <Form.Check
                            value={branch.id}
                            aria-label="radio 3"
                            label={branch.name}
                          />
                          <p
                            id="desc"
                            style={{
                              color: "#6A6A6A",
                              fontSize: "14px",
                              fontWeight: "400",
                              marginBottom: "0px",
                            }}
                          >
                            {branch.address}
                          </p>
                          <div style={{ display: "flex" }}>
                            <Col md={6}>
                              <p
                                style={{
                                  color: "#717171",
                                  fontSize: "12px",
                                  fontFamily: "400",
                                  marginBottom: "0px",
                                  marginLeft: "0px",
                                }}
                              >
                                <span style={{ color: "#000" }}>
                                  {branch.timetext1}{" "}
                                </span>
                                <br />
                                <span>{branch.time1} </span> <br />
                                <span>{branch.time2} </span>
                              </p>
                            </Col>
                            <Col md={6}>
                              <p
                                style={{
                                  color: "#717171",
                                  fontSize: "12px",
                                  fontFamily: "400",
                                  marginBottom: "0px",
                                  marginLeft: "0px",
                                }}
                              >
                                <span style={{ color: "#000" }}>
                                  {branch.timetext2}{" "}
                                </span>
                                <br />
                                <span>{branch.time3} </span> <br />
                                <span>{branch.time4} </span>
                              </p>
                            </Col>
                          </div>
                          <Row>
                            <Col
                              xs={12}
                              sm={6}
                              md={8}
                              style={{ position: "relative", paddingLeft: "0" }}
                            >
                              <p
                                style={{
                                  marginBottom: "0px",
                                  marginTop: "5px",
                                }}
                              >
                                <a
                                  href={branch.mapURL}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  onMouseEnter={() =>
                                    setIsShown([branch.name, 0])
                                  }
                                  onMouseLeave={() => setIsShown(["", ""])}
                                >
                                  Abrir dirección en google maps
                                </a>
                              </p>
                              {branch.image &&
                                isShown[0] === branch.name &&
                                isShown[1] === 0 && (
                                  <StyledImg>
                                    <img
                                      src={branch.image}
                                      alt="ubicacion sucursal"
                                    />
                                  </StyledImg>
                                )}
                            </Col>
                            <Col
                              xs={12}
                              sm={6}
                              md={4}
                              id="col-llegar"
                              style={{
                                position: "relative",
                                paddingLeft: "20px",
                              }}
                            >
                              <p
                                style={{
                                  marginTop: "5px",
                                  marginBottom: "0px",
                                  color: "#4F90BA",
                                }}
                              >
                                <a
                                  href={`https://www.waze.com/ul?ll=${branch.coordinates[0]},${branch.coordinates[1]}&navigate=yes&zoom=17`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  onMouseEnter={() =>
                                    setIsShown([branch.name, 1])
                                  }
                                  onMouseLeave={() => setIsShown(["", ""])}
                                >
                                  ¿Cómo llegar?
                                </a>
                              </p>
                              {branch.image &&
                                isShown[0] === branch.name &&
                                isShown[1] === 1 && (
                                  <StyledImg style={{ right: "5px" }}>
                                    <img
                                      src={branch.image}
                                      alt="ubicacion sucursal"
                                    />
                                  </StyledImg>
                                )}
                              {branch.photo &&
                                isShownPhoto[0] === branch.name &&
                                isShownPhoto[1] === 1 && (
                                  <StyledImg photo={true}>
                                    <img
                                      src={branch.photo}
                                      alt="ubicacion sucursal"
                                    />
                                    <div
                                      id="plus-icon"
                                      onClick={() => setIsShownPhoto(["", ""])}
                                    >
                                      <UilSearchMinus
                                        size={28}
                                        color="#4F90BA"
                                      />
                                    </div>
                                  </StyledImg>
                                )}
                            </Col>
                            {width < 585 && (
                              <MiniImage
                                onClick={() =>
                                  setIsShownPhoto([branch.name, 1])
                                }
                              >
                                <div id="mini-image">
                                  <img alt="foto sucursal" src={branch.photo} />
                                  <div id="plus-icon">
                                    <UilSearchPlus size={28} color="#4F90BA" />
                                  </div>
                                </div>
                              </MiniImage>
                            )}
                          </Row>
                        </Form.Group>
                      </Col>
                      {width >= 585 && (
                        <Col sm={2}>
                          <MiniImage
                            onClick={() => setIsShownPhoto([branch.name, 1])}
                            style={{ marginTop: "10px" }}
                          >
                            <div id="mini-image">
                              <img alt="foto sucursal" src={branch.photo} />
                              <div id="plus-icon">
                                <UilSearchPlus size={28} color="#4F90BA" />
                              </div>
                            </div>
                          </MiniImage>
                          <p
                            style={{
                              color: "#4F90BA",
                              fontSize: "16px",
                              lineHeight: "24px",
                              marginTop: "8px",
                              textAlign: "center",
                              cursor: "pointer",
                            }}
                            onClick={() => setIsShownPhoto([branch.name, 1])}
                          >
                            Ver foto
                          </p>
                        </Col>
                      )}
                    </Row>
                    <hr />
                  </>
                ))}
              </StyledPlaces>
            ) : null}
          </StyledForDesktop>
        </Col>
        {isMobile ? null : (
          <>
            <Col md={4} className="mb-4 mt-4 pb-2 pt-2">
              <CardCont>
                <StyledDrawer>
                  <p>Estudios solicitados</p>
                </StyledDrawer>
                {items?.length > 0 ? (
                  <div style={{ position: "relative", paddingBottom: "106px" }}>
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <Col lg={6} style={{ padding: "2px" }}>
                          <WhiteButton1
                            style={{
                              color: "rgb(0, 172, 144)",
                              border: "2px solid rgb(0, 172, 144)",
                            }}
                            onClick={vaciarCarrito}
                          >
                            <UilTrashAlt size={20} />
                            Vaciar lista
                          </WhiteButton1>
                        </Col>
                      </div>
                    </>
                    <br />
                    <ListDiv>
                      {items.map((item) => (
                        <CardDiv>
                          <ListItemCart
                            key={item.id}
                            item={item}
                            mainColor={mainColor}
                            onDelete={handleRemoveItemFromCart}
                            idClienteLabopat={idClienteLabopat}
                          />
                        </CardDiv>
                      ))}
                    </ListDiv>
                    <br />
                  </div>
                ) : (
                  <EmptyCart>
                    <div id="empty">
                      <UilClipboardAlt color="#A6A6A6" size={100} />
                    </div>
                    <p>No hay estudios agregados</p>
                  </EmptyCart>
                )}
                <Bottom minColor={mainColor}>
                  {items?.length > 0 ? (
                    <>
                      {idClienteLabopat && idClienteLabopat !== 0 ? (
                        <>
                          <Total
                            mainColor={
                              mainColor === "#4F90BA" ? "#4D99E5" : "#0CAC8C"
                            }
                          >
                            Total a pagar :{" "}
                            <span>{currency(totalSucursal)} MXN</span>
                          </Total>
                        </>
                      ) : (
                        <>
                          <Total
                            mainColor={
                              mainColor === "#4F90BA" ? "#4D99E5" : "#0CAC8C"
                            }
                          >
                            Total a pagar :{" "}
                            <span>{currency(totalSucursal)} MXN</span>
                          </Total>
                        </>
                      )}
                      {isDoctor && (
                        <>
                          <BlueButton
                            id="genOrder"
                            onClick={handleOrder}
                            mainColor={
                              mainColor === "#4F90BA" ? "#4D99E5" : "#4D99E5"
                            }
                          >
                            Genera una orden aquí
                          </BlueButton>
                        </>
                      )}
                    </>
                  ) : (
                    isDoctor && (
                      <p id="noItems">
                        Para generar una orden{" "}
                        <span>necesitas agregar al menos 1 estudio.</span>
                      </p>
                    )
                  )}
                </Bottom>
                <StyledModal
                  show={showCallModal}
                  onHide={() => setShowCallModal(false)}
                  mainColor={mainColor}
                >
                  <div onClick={() => setShowCallModal(false)}>
                    <UilTimes color="#fff" style={{ cursor: "pointer" }} />
                  </div>
                  <Modal.Header closeButton>
                    <Modal.Title>
                      Estudios no disponibles para reserva en linea
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>
                      Los siguientes estudios solo se pueden agendar mediante
                      llamada:
                    </p>
                    <ul>
                      {items.map((study) => {
                        if (
                          study.categoria === 10 ||
                          study.categoria === 8 ||
                          study.ventaOnline === 0
                        ) {
                          return <li>{study.name}</li>;
                        }
                      })}
                    </ul>
                    <ContinueButton
                      onClick={() => callToSchedule()}
                      mainColor={mainColor}
                    >
                      Llamar para agendar
                    </ContinueButton>
                  </Modal.Body>
                </StyledModal>
              </CardCont>
            </Col>
          </>
        )}
      </Row>
    </>
  );
};
