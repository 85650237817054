import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { UilFacebookF, UilTwitter, UilInstagram, UilLinkedin } from '@iconscout/react-unicons';
import { ORANGE, TEXT_BLACK } from './colors';
import { GREEN_LAB } from '../../helpers/colors';
import { PostsSlider } from './headerSlider/PostsSlider';
import { BlogSearcher } from './blogSearcher/blogSearcher';

const StyledContainer = styled(Container)`
  padding-top: 110px;
  h1, h2, p, svg {
    color: ${TEXT_BLACK};
  }
  h1, h2, #hashtag {
    font-family: Faustina;
  }
  span {
    font-weight: 800;
  }
  h1 {
    font-size: 160px;
    font-weight: 300;
    margin-top: -45px;
    line-height: 105%;
    text-align: center;
  }
  h2 {
    font-size: 30px;
    font-weight: 500;
  }
  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 144.444%;
  }
  #hashtag {
    color: ${GREEN_LAB};
    font-size: 19px;
    font-style: italic;
    font-weight: 500;
    line-height: 136.842%;
  }
  #icons-div {
    width: 192px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto 40px;
    svg {
      height: 32px;
    }
  }
  #left-text {
    text-align: center;
    p {
      max-width: 405px;
      margin: 0 auto 10px;
    }
    #italic {
      font-size: 18px;
      font-style: italic;
      font-weight: 300;
      line-height: 26px;
    }
    #author {
      font-size: 14px;
      font-weight: 500;
      line-height: 26px;
      margin-bottom: 20px;
    }
  }
  @media screen and ( width <= 768px) {
    h1 {
      font-size: 60px;
    }
    #icons-div {
      width: 156px;
      svg {
        height: 24px;
      }
    }
    #welcome-div > h2, #welcome-div > p {
      text-align: center;
    }
  }
`;

export const NewsHeader = () => {
  return (
    <StyledContainer>
      <Row>
        {/* Welcome news */}
        <Col xs={{span: 12, offset: 0}} lg={{span: 10, offset: 1}}>
          <Row>
            <Col xs={12} md={6}>
              <h1>Blog</h1>
            </Col>
            <Col xs={12} md={6} id='welcome-div'>
              <h2>¡Bienvenido!</h2>
              <p>Éste es un espacio más que Labopat crea para que el conocimiento sobre temas de salud sea colectivo y se expanda cada vez más.</p>
              <p id='hashtag'>#HacemosCiencia</p>
            </Col>

            <hr style={{ color: ORANGE, opacity: '1' }}/>

            {/* Network icons */}
            <div id='icons-div'>
              <UilFacebookF onClick={()=> window.open('https://www.facebook.com/labopatdiagnosticos', '_blank')}/>
              <UilTwitter onClick={()=> window.open('https://twitter.com/LabopatD', '_blank')}/>
              <UilInstagram onClick={()=> window.open('https://www.instagram.com/labopat_diagnosticos', '_blank')}/>
              <UilLinkedin onClick={()=> window.open('https://www.linkedin.com/company/labopat', '_blank')}/>
            </div>
          </Row>
        </Col>

        {/* Searcher */}
        <Col xs={12} lg={8} id='left-text'>
          <h2>¿Qué quieres <span>aprender</span> hoy?</h2>
          <p id='italic'>“Nuestros cuerpos son nuestros jardines; nuestras decisiones, nuestros jardineros”.</p>
          <p id='author'>William Shakespeare</p>
          <BlogSearcher/>
        </Col>

        {/* Slider */}
        <Col xs={12} lg={4}>
          <PostsSlider/>
        </Col>
      </Row>
    </StyledContainer>
  );
};
