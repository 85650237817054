import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { CATEOGORIES, TEXT_BLACK } from '../colors';
import styled from 'styled-components';
import { UilArrowRight } from '@iconscout/react-unicons';

const StyledCard = styled.div`
  width: 90%;
  max-width: 491px;
  height: 529px;
  border-radius: 16px 16px 0px 0px;
  border: 1px solid #E9E9E9;
  background: #FFF;
  margin: 13px auto;
  position: relative;
  hr {
    background: ${props => props.customColor};
    opacity: 1;
    width: 100px;
    height: 20px;
    margin: 0 45px 3px auto;
  }
  .text {
    padding: 0 48px;
    h1 {
      color: ${TEXT_BLACK};
      font-size: 30px;
      font-weight: 700;
      line-height: 116%;
      letter-spacing: 0.3px;
    }
    p {
      color: ${TEXT_BLACK};
      font-size: 16px;
      font-weight: 400;
      line-height: 150%;
    }
    span {
      font-weight: 700;
    }
  }
  .img-div {
    width: 100%;
    height: 265px;
    background-image: url('${props => props.banner}');
    background-position: center;
    background-size: cover;
    border-radius: 16px;
  }
  .continue {
    width: 226px;
    height: 38px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    font-family: IBM Plex Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 0.16px;
    background: ${props => props.customColor};
    cursor: pointer;
    transition: all 0.3s ease;
    position: absolute;
    bottom: 0;
    right: 0;
    :hover {
      filter: brightness(1.2);
    }
  }
  @media only screen and (width < 768px) {
    .text {
      padding: 0 23px;
      h1 {
        font-size: 24px;
      }
      p {
        font-size: 16px;
      }
    }
    hr {
      margin-bottom: 10px;
    }
    .continue {
      background: #F3F3F3;
      color: ${TEXT_BLACK};
    }
  }
`;

export const GridCategories = () => {
  return (
    <Container style={{padding: '55px 0'}}>
      <Row>
        {CATEOGORIES?.map((cat, index)=>
          <Col xs={12} lg={6} key={index}>
            <StyledCard customColor={cat?.color} banner={cat?.banner}>
              <div className='img-div'/>
              <hr />
              <div className='text'>
                <h1>{cat?.name}</h1>
                <p dangerouslySetInnerHTML={{ __html: cat?.desc }} />
              </div>
              <div className='continue' onClick={()=> window.location.href = `/blog/${cat?.link}`}>Ver todos los post <UilArrowRight/></div>
            </StyledCard>
          </Col>
        )}
      </Row>
    </Container>
  );
};
