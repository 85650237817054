import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { HelmetTitle } from '../../helpers/HelmetTitle';
import { Doubts } from '../doubts/doubts';
import { Banner } from './banner';
import { FAQs } from './faqs';
import { Learnings } from './learnings';
import { SliderProgram } from './sliderProgram';
import { StartNow } from './startNow';
import { SuccessStories } from './successStories';

export const ProgramaTransforma = (props) => {
  const [study, setStudy] = useState();
  const idClienteLabopat = useSelector(
    (state) => state.user?.data?.data?.profile?.idClienteLabopat
  );
  const getInfo = async () => {
    try {
      let request = axios.get(
        `https://lpqsystem.com/get-study-test/675/${
          idClienteLabopat ? `${idClienteLabopat}` : '0'
        }`
      );
      let response = await request;
      setStudy({
        ...response.data,
        title: response.data.name,
        price: response.data.promo_price,
        oldPrice: response.data.normal_price,
      });
    } catch (error) {
      console.log(error);
      window.location.href = '/not-found';
    }
  };

  useEffect(()=>{
    getInfo();
  },[])

  return (
    <>
      <HelmetTitle
        title="Programa Transforma"
        name="description"
        content="content"
      />
      <Banner study={study} setShowCart={()=>props.setShowCart(true)}/>
      <SliderProgram/>
      <Learnings/>
      <StartNow study={study} setShowCart={()=>props.setShowCart(true)}/>
      <SuccessStories/>
      <FAQs/>
      <Doubts/>
    </>
  );
};
