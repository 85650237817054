import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { StepOne } from './stepOne';
import { StepTwo } from './stepTwo';
import { StepThree } from './stepThree';
import { Loader } from '../general/spinner';
import axios from 'axios';
import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { UilArrowLeft } from '@iconscout/react-unicons';

const StyledContainer = styled(Container)`
  @media only screen and (max-width: 768px){
    margin-top: 3rem;
    padding-top: 1.5rem;
  }
`;

const BackBtn = styled(Button)`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  background-color: white;
  color: #4D99E5;
  border: none;
  margin-bottom: 15px;
`;

const Top = styled.div`
  h1 {
    margin-top: 24px;
    font-weight: 900;
    font-size: 22px;
    line-height: 33px;
    text-align: center;
    color: #000;
  }
  h2 {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #000;
    margin-bottom: 24px;
  }
  @media only screen and (min-width: 992px) {
    h1 {
      font-size: 30px;
      margin: 40px auto 24px;
    }
    h2 {
      font-size: 24px;
      margin-bottom: 40px;
    }
  }
  @media only screen and (max-width: 575px) {
    background: linear-gradient(180deg, #c4c4c4 0%, #85b4d2 100%);
  }
`;

const Progress = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h2 {
    font-weight: 800;
    font-size: 13px;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 24px;
  }
  .circle {
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background: linear-gradient(90deg, #5097E3 -2.05%, #0CAC8C 72.3%, #30DF99 101.61%, #CEF719 133.72%, #F5EC1D 211.91%);
    margin-left: auto;
    margin-right: auto;
  }
  .unavailable {
    .circle {
      background: #E9E9E9;
    }
    h2 {
      font-weight: 500;
      color: #afafaf;
    }
  }
`;

const ProgressBar = styled.div`
  margin-bottom: -10px;
  width: 22%;
  height: 3px;
`;

const BackgroundDiv = styled.div`
  padding-bottom: 117px;
  background: #fff;
  min-height: 100vh;
  @media only screen and (max-width: 768px) {
    padding-bottom: 0;
  }
`;

const StyledDiv = styled.div`
  background: #fff;
  padding: 24px 16px 40px 16px;
  max-width: 700px;
  margin: 0 auto;
  border: 1px solid #E9E9E9;
  box-shadow: 0px 4px 4px rgba(71, 74, 75, 0.15);
  border-radius: 16px;
  @media only screen and (min-width: 575px) {
    border-radius: 16px;
  }
`;

export const Packs = () => {
  const [step, setStep] = useState(1);
  const [editingBaseStudies, setEditingBaseStudies] = useState([]);
  const uid = useSelector((state) => state.auth.uid);
  const [loading, setLoading] = useState(false);
  const isDoctor =
    useSelector((state) => state.user?.data?.data?.profile?.type) === 1;
  const history = useHistory();
  const location = useLocation();
  const {id} = useParams();
  const editing = location.pathname.includes('/editar-paquete/');
  const [pack, setPack] = useState({
    uid: uid,
    name: '',
    studies: [],
  });
  const idClienteLabopat = useSelector(
    (state) => state.user?.data?.data?.profile?.idClienteLabopat
  );

  useEffect(() => {
    !isDoctor && history.push('/');
    editing && getPackageInfo();
  }, []);

  const getPackageInfo = async () => {
    try {
      setLoading(true);
      let requestPackages = await axios.get(
        `https://lpqsystem.com/getPaqueteDetalle?id=${id}`
      );

      console.log('AAA',requestPackages);
      if (requestPackages?.data?.ok) {

        const responses = await Promise.all(requestPackages?.data?.estudios?.map((study) => 
          axios.get(`https://lpqsystem.com/get-study-test/${study?.study_id}/${idClienteLabopat ? `${idClienteLabopat}` : '0'}`)
        ));
        const results = await Promise.all(responses?.map(({data}) => data));
        const dataObj = [];
        results.forEach((result, index) => {
          dataObj.push({
            ...result,
            title: result?.name,
            precio_normal: result?.normal_price,
          });
        });

        setEditingBaseStudies(dataObj);
        setPack({
          ...pack,
          name: requestPackages?.data?.paquete_medico?.name,
          studies: dataObj
        });
        setLoading(false);
      } else {
        console.log('Error al obtener paquete');
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  useEffect(()=>{
    console.log('PAAACK',pack);
  },[pack]);

  const altaPaquetes = async (data, studies) => {
    setLoading(true);
    var formData = new FormData();
    formData.append('uid', data.uid);
    formData.append('name', data.name);
    console.log(data);
    studies.forEach((element) => {
      console.log(element.estudios);
      formData.append('products[]', element.id);
        formData.append('productTitle[]', element.title);
        formData.append('studiesPrice[]', element.price);
        formData.append('studiesOldPrice[]', element.oldPrice);
      element.estudios.forEach((elem) => {
        //console.log(elem);
        formData.append('studies[]', elem.id);
      });
      //
    });

    let response = await axios.post(
      'https://lpqsystem.com/insertarPaquetes',
      formData
    );
    console.log(response);
    if (response.data.ok) {
      setPack({
        ...pack,
        studies: studies,
      });
      setLoading(false);
      window.location.href = "/mis-paquetes"
    } else {
      window.alert(response.data.error);
    }
  };

  const editPackages = async (data, studies) => {
    setLoading(true);
    var formData = new FormData();
    formData.append('id', id);
    formData.append('name', data.name);
    // Base ids
    const elementsStudiesIDs = [];
    editingBaseStudies.forEach((element)=>{
      elementsStudiesIDs.push(element?.id)
    })
    // Final ids
    const finalIDs = [];
    studies.forEach((element)=>{
      finalIDs.push(element?.id)
    })
    // To get deleted studies
    elementsStudiesIDs?.forEach((eStudy)=> {
      if(!finalIDs?.some((i)=> i===eStudy)){
        formData.append('deleted[]', eStudy);
      }
    })
    // To get added studies
    finalIDs?.forEach((eStudy)=> {
      if(!elementsStudiesIDs?.some((i)=> i===eStudy)){
        formData.append('studies[]', eStudy);
        formData.append('names[]', studies?.find((st)=>st.id === eStudy)?.name);
      }
    })

    let response = await axios.post(
      'https://lpqsystem.com/actualizarPaquetes',
      formData
    );
    console.log(response);
    if (response.data.ok) {
      setPack({
        ...pack,
        studies: studies,
      });
      setLoading(false);
      window.location.href = "/mis-paquetes"
    } else {
      setLoading(false);
      console.log(response.data.error);
      window.alert(response.data.error);
    }
  }

  const handleStepOne = async (name) => {
    setPack({
      ...pack,
      name: name,
    });
    setStep(step + 1);
  };

  const handleStepTwo = async (studies) => {
    console.log(studies);
    console.log(pack);
    console.log("*****____")
    editing 
      ? await editPackages(pack, studies)
      : await altaPaquetes(pack, studies)
  };

  return (
    <BackgroundDiv className='position-relative'>
      <StyledContainer>
        {loading ? <Loader show={loading} /> : null}
        <Top
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <h1>{editing ? `Editar "${pack?.name}"` : 'Paquete de estudios'}</h1>
          {editing ?
            step === 1 
            ? <h2>Cambia el nombre de este paquete.</h2>
            : <h2>Agrega o elimina estudios de este paquete.</h2>
          :
            <h2>
              Primero escribe un nombre y después agrega los
              <br />
              estudios que necesites.
            </h2>
          }
        </Top>
        <StyledDiv>
          {step > 1 && (
            <BackBtn variant='light' onClick={() => setStep(step - 1)}>
              <UilArrowLeft color='#4F90BA' size='26' />
              Regresar 
            </BackBtn>
          )}
          <Progress>
            <ProgressBar
              style={{
                background: `linear-gradient(to right, #30DF99 ${
                  (step - 1) * 100
                }%, #E9E9E9 0%)`,
              }}
            />
            <Row style={{ width: '100%' }}>
              <Col xs={{span: 3, offset: 3}} className={step < 1 && 'unavailable'}>
                <div className='circle'></div>
                <h2>Paso 1</h2>
              </Col>
              <Col xs={3} className={step < 2 && 'unavailable'}>
                <div className='circle'></div>
                <h2>Paso 2</h2>
              </Col>
              {/* <Col xs={4} className={step < 3 && 'unavailable'}>
                <div className='circle'></div>
                <h2>Paso 3</h2>
              </Col> */}
            </Row>
          </Progress>

          {step <= 1 ? (
            <StepOne onStepOne={handleStepOne} data={pack} editing={editing}/>
          ) : step <= 2 ? (
            <StepTwo onStepTwo={handleStepTwo} data={pack} editing={editing}/>
          ) : (
            <StepThree data={pack} />
          )}
        </StyledDiv>
      </StyledContainer>
    </BackgroundDiv>
  );
};
