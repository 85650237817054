import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { UilSearchAlt } from '@iconscout/react-unicons';
import { SearchResult } from './searchResult';
import { CA_GRADIENT_BUTTON } from '../../../helpers/colors';
import { getPostsByTitle } from '../../../actions/blog';

const StyledDiv = styled.div`
  z-index: 2;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  filter: drop-shadow(0px 4px 10px rgba(71, 74, 75, 0.15));
  border-radius: 30px;
  background: #FFF;
  position: relative;
  padding: 8px 10px 8px 60px;
  #search-icon {
    position: absolute;
    left: 21px;
    top: 50%;
    transform: translate(0, -50%);
  }
  input {
    width: 100%;
    border: none;
    color: #00AD90;
    font-family: IBM Plex Sans;
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
  }
  #blog-search-button {
    color: #FFF;
    text-align: center;
    font-family: Rounded Mplus 1c;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 190px;
    border-radius: 30px;
    border: none;
    background: ${CA_GRADIENT_BUTTON};
    margin-left: 10px;
    transition: all 0.3s ease;
  }
  .disabled {
    background: #C4C4C4 !important;
    pointer-events: none;
  }
`;

const StyledDropdown = styled.div`
  z-index: 1;
  width: 100%;
  max-height: 365px;
  overflow: scroll;
  position: absolute;
  top: 50%;
  left: 0;
  background: #FFF;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15));
  padding: 45px 20px 0;
`;

export const BlogSearcher = () => {
  const [searchText, setSearchText] = useState("");
  const [searched, setSearched] = useState(false);
  const [results, setResults] = useState([]);

  const searchedRef = useRef();

  const handleClick = (e) => {
    if (e.target.id !== 'blog-search-results' && e.target.id !== 'blog-search-button') {
      // Set searched to false here
      setSearched(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const fetchPosts = async () => {
    try {
      const posts = await getPostsByTitle(searchText);
      setResults(posts);
      setSearched(true);
    } catch (error) {
      // Handle error
      console.error('Error fetching posts by title: ', error);
    }
  };

  return (
    <div style={{position: 'relative'}}>
      <StyledDiv>
        <UilSearchAlt size={35} color='#30DF99' id='search-icon'/>
        <input type="text" placeholder='Escribe palabras clave' value={searchText} onChange={(e) => setSearchText(e.target.value)}/>
        <button onClick={() => fetchPosts()} className={searchText === "" ? 'disabled' : ''} id='blog-search-button'>Buscar</button>
      </StyledDiv>
      {searched &&
        <StyledDropdown id='blog-search-results' ref={searchedRef}>
          {results?.map((result)=>
            <React.Fragment key={result?.id ?? 0}>
              <SearchResult data={result}/>
              <hr />
            </React.Fragment>
          )}
        </StyledDropdown>
      }
    </div>
  );
};
