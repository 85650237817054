import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";
import {
  addPersonalData,
  getTotalPrice,
  handleAddItemToCart,
  removeItemFromCart,
  resetCart,
  setTotalPrice,
} from "../../actions/shoppingCart";
import { Container, Form } from "react-bootstrap";
import { updateCartDB } from "../../helpers/api";
import { ListItemCart } from "../shopping-cart/listItemCart";

const Title = styled.h1`
  margin-top: 40px;
  margin-bottom: 24px;
  text-align: center;
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  text-align: center;
  color: #000000;
`;

const MainDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  button {
    color: #fff;
    width: 180px;
    font-size: 16px;
    border: none;
    border-radius: 20px;
  }
  .green {
    background: #00ac90;
  }
  .red {
    background: #b53737;
  }
`;

const Total = styled.h5`
  color: #45494a;
  font-weight: 800;
  font-size: 15px;
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #c1d9d8;
  position: relative;
  span {
    position: absolute;
    right: 0px;
    color: #0CAC8C;
    text-align: right;
  }
`;

const OrangeButton = styled.button`
  width: 80%;
  height: 48px;
  background: linear-gradient(79.06deg, #5097E3 -41.6%, #0CAC8C 26.14%, #30DF99 84.04%, #CEF719 107.01%, #F5EC1D 132.38%);
  border-radius: 10px !important;
  border: none;
  font-size: 16px;
  padding: 0;
  font-weight: 800;
  margin-top: 6px;
  margin-top: 14px;
  margin-bottom: 16px;
  color: #fff;
  transition: all 0.4s ease;
  :hover {
    transition: all 0.4s ease;
    color: #30DF99;
    background: #fff;
    border: 2px solid #30DF99;
  }
  :disabled {
    background: #c4c4c4;
    color: #fff;
    border: 2px solid #c4c4c4;
  }
  @media only screen and (max-width: 992px) {
    margin-bottom: 12px;
  }
`;

const WhiteButton = styled.button`
  width: 80%;
  height: 48px;
  background: none;
  color: #00ad90 !important;
  border: 2px solid #00ad90 !important;
  border-radius: 10px !important;
  font-size: 16px;
  margin-bottom: 16px;
  transition: all 0.4s ease;
  :hover {
    color: #fff !important;
    transition: all 0.4s ease;
    background: #00ad90;
  }
`;

const Bottom = styled.div`
  background: #fff;
  border-top: 1px solid #c1d9d8;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  p {
    text-align: center;
    margin-botton: 24px;
    .disabled {
      pointer-events: none;
      color: #c4c4c4;
    }
  }
  span {
    font-weight: 800;
    font-size: 15px;
    line-height: 22px;
    color: #00ad90;
    text-decoration: underline;
    cursor: pointer;
  }
`;

const ListDiv = styled.div`
  overflow-y: auto;
  padding-right: 3px;
  margin-right: -5px;
  max-height: 40vh;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 117, 98, 0.3);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const DivBackground = styled.div`
  position: relative;
  padding-bottom: 20px;
  background-image: url("https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fcompletar-cita-bg.png?alt=media&token=c5f25baa-c158-474c-8596-deeb2a791ed2");
  background-position: center top;
  background-size: 100% auto;
  box-shadow: inset 0px -60px 60px #fff;
  background-repeat: no-repeat;
  @media only screen and (min-width: 992px) {
    background-size: 50% auto;
  }
`;

const MyCheckDiv = styled.div`
  display: flex;
  justify-content: center;
  label {
    font-size: 16px;
    font-weight: 600;
    margin-top: -3px;
    margin-bottom: 15px;
    cursor: pointer;
  }
  input {
    cursor: pointer;
  }
`;

export const CompleteAppointment = (props) => {
  const { items, totalPrice, personalData } = useSelector(
    (state) => state.shoppingCart
  );
  const [error, setError] = useState();
  const [notForMe, setNotForMe] = useState(false);
  const dispatch = useDispatch();
  const logged = useSelector((state) => state.auth.uid);
  const [doctorId, setDoctorId] = useState(12);
  const [idClienteLabopat, setidClienteLabopat] = useState(0);
  const [patientId, setPatientId] = useState(0);
  const valores = window.location.search;
  const urlParams = new URLSearchParams(valores);
  var order = urlParams.get("order");
  const [totalSucursal, setTotalSucursal] = useState();
  // order id to test 162803

  const getData = async (id) => {
    try {
      const appointment = await axios.get(
        `https://labopat-5792e.appspot.com/get-order-detail?id=${id}`
      );
      if (appointment?.data) {
        console.log("RES*: ", appointment.data.estudios);
        dispatch(resetCart());
        appointment.data.estudios.map((item) => {
          dispatch(
            handleAddItemToCart({
              ...item,
              title: item.name,
              price: item.promo_price,
              oldPrice: item.normal_price,
            })
          );
        });
        dispatch(addPersonalData(appointment.data.solicitud));
        setDoctorId(appointment.data.doctorId);
        setidClienteLabopat(
          appointment.data.drive?.idClienteLabopat
            ? appointment.data.drive?.idClienteLabopat
            : 0
        );
        setPatientId(
          appointment.data.drive?.idCatPaciente
            ? appointment.data.drive?.idCatPaciente
            : 0
        );
      } else {
        setError("No se encontró no se encontraron datos de tu solicitud");
      }
    } catch (error) {
      setError("Hubo un error al acceder a tu solicitud");
    }
  };

  useEffect(() => {
    getData(order);
  }, []);

  useEffect(() => {
    if (items.length > 0) {
      getTotal();
    }
  }, [items]);

  const getTotal = () => {
    const pricesArray = items?.map((item) => item.price);
    console.log(items);
    console.log("---------");
    const final = pricesArray.reduce(
      (a, b) => parseFloat(a) + parseFloat(b),
      0
    );
    setTotalSucursal(final);
  };

  const currency = function (number) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    }).format(number);
  };

  const handleRemoveItemFromCart = (id) => {
    let currentItem = items.find((item) => item.id === id);
    const newCart = items.filter((item) => item.id !== id);
    let data = newCart;
    console.log(currentItem);
    if (currentItem.howMany > 1) {
      console.log("IF");
      // Original price
      const originalPrice = currentItem.price / currentItem.howMany;
      const newHowMany = currentItem.howMany - 1;
      currentItem.price = originalPrice * newHowMany;
      currentItem.howMany = newHowMany;
      data = [...newCart, currentItem];
      console.log("DATA: ", data);
    }
    // console.log("get total 2: ", data);
    const price = getTotalPrice(data);

    // Update cart
    dispatch(removeItemFromCart(data));

    // Update total price
    dispatch(setTotalPrice(price));

    // Update cart in DB
    dispatch(updateCartDB(data, price));
  };

  return (
    <MainDiv>
      <Container style={{ paddingLeft: "20px", paddingRight: "20px" }}>
        <DivBackground>
          <Title>Te damos la bienvenida a nuestro laboratorio</Title>
          <p>
            {personalData?.doctorTexto}ha iniciado una solicitud para realizarte
            los estudios:
          </p>
          <ListDiv>
            {items.map((item) => (
              <ListItemCart
                key={item.id}
                item={item}
                onDelete={handleRemoveItemFromCart}
                idClienteLabopat={idClienteLabopat}
                completeScreen={true}
              />
            ))}
          </ListDiv>
          <Total>
            Total a pagar:{" "}
            <span>
              {currency(idClienteLabopat !== 0 ? totalSucursal : totalPrice)}{" "}
              MXN
            </span>
          </Total>
          <br />
        </DivBackground>
        {logged && (
          <MyCheckDiv>
            <Form.Check
              type="checkbox"
              id="notForMe"
              label="Los estudios no son para mi"
              checked={notForMe}
              onChange={() => setNotForMe(!notForMe)}
            />
          </MyCheckDiv>
        )}
      </Container>
      <Bottom>
        <OrangeButton
          onClick={() => {
            window.location.href = `/checkout?order=${order}&docId=${doctorId}${
              notForMe ? "&notForMe=1" : ""
            }&patient=${patientId}`;
            props.onClose();
          }}
          disabled={items?.length === 0}
        >
          Pagar en línea
        </OrangeButton>
        <WhiteButton
          onClick={() => {
            window.location.href = `/checkout?sucursal=1&order=${order}&docId=${doctorId}${
              notForMe ? "&notForMe=1" : ""
            }&patient=${patientId}`;
            props.onClose();
          }}
          disabled={items?.length === 0}
        >
          Pagar en sucursal
        </WhiteButton>
      </Bottom>
    </MainDiv>
  );
};
