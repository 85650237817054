import {
  addDoc,
  collection,
  getDocs,
  query,
  Timestamp,
  where,
  updateDoc,
  doc,
} from 'firebase/firestore';
import moment from 'moment';
import { db } from '../firebase/firebase-config';
import { types } from '../types/types';
import axios from 'axios';

const options = {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
  },
};

export const timestampToNormalDate = (date) => {
  date = new Date(date.seconds * 1000).toLocaleDateString('es');
  return date;
};

export const sortByTimestamp = (array) =>
  array
    .sort(
      (a, b) =>
        moment(timestampToNormalDate(a.datetime), 'DD/MM/YYYY').format(
          'DDMMYYYY'
        ) -
        moment(timestampToNormalDate(b.datetime), 'DD/MM/YYYY').format(
          'DDMMYYYY'
        )
    )
    .reverse();

export const setCheckins = (data) => ({
  type: types.setCheckin,
  payload: data,
});

export const setCurrentCheckin = (data) => ({
  type: types.setCurrentCheckin,
  payload: data,
});

export const addCheckin = (data) => {
  return async () => {
    const colRef = collection(db, 'checkin');
    const datetime = data?.datetime;
    delete data?.datetime;

    const q = query(
      collection(db, 'checkin'),
      where('folio', '==', data.folio)
    );
    const querySnapshot = await getDocs(q);

    if (
      querySnapshot._snapshot.docChanges.size &&
      querySnapshot._snapshot.docChanges.size !== 0
    ) {
      data = {
        ...data,
        datetime: Timestamp.fromDate(new Date(datetime)),
      };
      querySnapshot.forEach((document) => {
        console.log('dasssss');
        console.log(document.id, ' => ', document.data());
        const dbRef = doc(db, 'checkin', document.id);
        updateDoc(dbRef, data)
          .then((res) => {
            console.log('Checkin updated');
            window.alert(
              'Se ha confirmado correctamente tu asistencia, ingresa a la sucursal'
            );
            window.location.href = '/checkin';
          })
          .catch((error) => {
            console.log('[Error] ', error);
          });
      });
    } else {
      console.log('nooo dasssss');
      data = {
        ...data,
        datetime: Timestamp.fromDate(new Date(datetime)),
        created: Timestamp.fromDate(new Date()),
      };
      addDoc(colRef, data)
        .then((e) => {
          console.log(e);
          console.log('New checkin saved');
          window.alert(
            'Se ha confirmado correctamente tu asistencia, ingresa a la sucursal'
          );
          window.location.href = '/checkin';
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
};

export const updateCheckinLPQ = async (data) => {
  return () => {
    axios
      .post('https://clienteslabopat.com/editapac', data, options)
      .then((res) => {
        alert(res);
      })
      .catch((error) => {
        alert(error);
      });
  };
};

export const updateCheckin = (data, uid) => {
  return () => {
    data = {
      ...data,
      updated: Timestamp.fromDate(new Date()),
    };

    updateDoc(doc(db, 'checkin', `${uid}`), data)
      .then(() => {
        alert('Checkin actualizado');
      })
      .catch((error) => {
        alert(error);
      });
  };
};

export const updateConfirm = (uid) => {
  return () => {
    const data = {
      confirmed: false,
      updated: Timestamp.fromDate(new Date()),
    };

    updateDoc(doc(db, 'checkin', `${uid}`), data)
      .then(() => {
        console.log('Checkin confirmado');
        //window.location.reload();
      })
      .catch((error) => {
        alert(error);
      });
  };
};

export const getCurrentCheckinDB = async (id) => {
  const userRef = collection(db, 'checkin');
  const q = query(userRef, where('id', '==', parseInt(id)));
  const querySnapshot = await getDocs(q);
  const checkins = [];
  querySnapshot.forEach((doc) => {
    checkins.push({ uid: doc.id, ...doc.data() });
  });
  console.log(checkins);
  return checkins.length > 0 ? checkins[0] : false;
};

export const getCheckins = (limit = 20) => {
  return (dispatch) => {
    const colRef = collection(db, 'checkin');
    let checkins = [];

    getDocs(query(colRef)).then((snapshot) => {
      snapshot.forEach((doc) => {
        checkins.push({
          uid: doc.id,
          ...doc.data(),
        });
      });
      // if (checkins.length > 0) {
      //     dispatch(setCheckins(sortByTimestamp(checkins)));
      // } else {
      //     dispatch(setCheckins(checkins));
      // }
      dispatch(setCheckins(checkins));
    });
  };
};

// export const getCheckinPaginated = async (lastDoc, limitDocs) => {
//     const userRef = collection(db, "checkin");
//     const reference = query(userRef, where("id", "==", lastDoc.id));
//     const q = query(userRef, orderBy("datetime"), startAfter(reference), limit(limitDocs));
//     const querySnapshot = await getDocs(q);
//     const users = [];
//     querySnapshot.forEach((doc) => {
//         users.push({ uid: doc.id, data: doc.data() })
//     });
//     return users.length > 0 ? users[0] : false;
// }

// export const getCheckinPaginated = (reference, limitDocs = 20) => {
//     return async (dispatch) => {
// const { checkins } = getState();
// let lastDocReference = localStorage.getItem('lastDocReference');

// Get docs
// const querySnapshot = await getDocs(q);
// Get the last visible document
// const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
// Save last reference in local storage
// localStorage.setItem('lastDocReference', JSON.stringify(lastVisible));

//         getDocs(q)
//             .then((querySnapshot) => {
//                 const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
//                 localStorage.setItem('lastDocReference', JSON.stringify(lastVisible) || null);
//                 const res = [];
//                 querySnapshot.forEach((doc) => {
//                     console.log(doc.data());
//                     res.push({ uid: doc.id, data: doc.data() });
//                 });
//                 // return checkins.length > 0 ? checkins[0] : false;
//                 console.log(querySnapshot);
//                 console.log(res);
//                 dispatch(setCheckins([...checkins, ...res]));
//             })
//             .catch((error) => {
//                 console.log(error);
//             });

//     };
// };
//     /*
//         Check state if data is already fetched. For instance:
//         a user selects 50 results and then go back to the original 20.
//         In this case I shouldnt' make another query but use the one that
//         is in the state.
//     */
//     const userRef = collection(db, "checkin");
//     const q = query(userRef, startAfter=);
//     const querySnapshot = await getDocs(q);
//     const users = [];
//     querySnapshot.forEach((doc) => {
//         users.push({ uid: doc.id, data: doc.data() })
//     });
//     return users.length > 0 ? users[0] : false;
// };

export const getNL = async (id) => {
  return await axios.get(`https://clienteslabopat.com/getNL?id=${id}`, options);
};

// TODO: do tests
export const editCheckinLPQ = async (data) => {
  return await axios.post(
    `https://clienteslabopat.com/editapac`,
    JSON.stringify(data),
    options
  );
};

export const confirmCheckinLPQ = async (id) => {
  return await axios.get(`https://clienteslabopat.com/generaCHK`, {
    params: {
      id: id,
    },
  });
};
