import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const TextCol = styled(Col)`
  padding: 40px 10px;
  font-family: 'Faustina' !important;
  .col-lg-6 {
    padding-left: 0;
    ul {
      padding-left: 18px;
    }
  }
  h1 {
    font-weight: 800;
    font-size: 33px;
    line-height: 45px;
    color: #00AD90;
    margin-bottom: 22px;
  }
  h2 {
    font-weight: 700;
    font-size: 17px;
    line-height: 22px;
    text-transform: uppercase;
    color: #C4C4C4;
    margin-bottom: 22px;
  }
  #subjects {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #474A4B;
    margin-bottom: 16px;
  }
  li {
    font-weight: 500;
    font-size: 17px;
    line-height: 23px;
    color: #474A4B;
  }
  #green-text {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    div {
      margin-top: auto;
    }
    p {
      font-style: italic;
      font-weight: 500;
      font-size: 17px;
      line-height: 23px;
      color: #00AD90;
      max-width: 230px;
    }
  }
`;

const StyledImage = styled.img`
  position: absolute;
  top: 50%;
  left:0;
  transform: translate(0, -50%);
  width: 50vw;
  height: 100%
  @media only screen and (max-width: 992px) {
    display: none;
  }
`;

const ColMobile = styled(Col)`
  margin-top: 50px;
  p {
    font-style: italic;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    color: #00AD90;
    max-width: 225px;
    margin-bottom: 0;
  }
  #one, #two {
    margin: auto;
    width: 100%;
    padding: 0;
    z-index: 1;
    transition: all ease .5s;
    padding-bottom: 0;
    overflow: hidden;
    height: 50%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    @media only screen and (max-width: 576px) {
      margin-left: -22px;
      margin-right: -22px;
    }
  }
  #one {
    background: url('https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fcurso-alta-especialidad%2Fseminarios.png?alt=media&token=8fd4220f-8711-410e-95a5-9d083c505b64') center center no-repeat;
    background-size: cover;
    margin-bottom: 0;
  }
  #two {
    margin-top: 0;
    background: url('https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fcurso-alta-especialidad%2Fseminarios-2.png?alt=media&token=28ceb920-900c-42b5-b345-7c80d5f11600') center center no-repeat;
    background-size: cover;
  }
  #text-two {
    margin-top: 35px;
  }
  .col-4 {
    padding: 0;
  }
  .col-8 {
    @media only screen and (max-width: 576px) {
      padding-left: 0px;
    }
  }
`;

export const PlanEstudios = () => {
  const {width} = useWindowDimensions();
  return (
    <div className="position-relative overflow-hidden">
      <Container id='planDeEstudios'>
        {width > 992 &&
          <StyledImage src='https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fcurso-alta-especialidad%2FbgSubjects.png?alt=media&token=205e2368-30b7-4743-8cc3-18e023b3ea3c'/>
        }
        <Row>
          <Col lg={6}/>
          <TextCol lg={6}>
            <h2>Plan de estudios</h2>
            <h1>Plan de estudios</h1>
            <p id='subjects'>Asignaturas</p>
            <Row>
              <Col xs={12} lg={6}>
                <ul>
                  <li>Inmunohistoquímica (FISH, CISH)</li>
                  <li>Hibridación{' '}<span style={{fontStyle: 'italic'}}>In Situ</span></li>
                  <li>PCR y sus variantes</li>
                  <li>Secuenciación de Nueva Generación</li>
                  <li>Biopsia líquida</li>
                  <li>Control de calidad en laboratorio</li>
                  <li>Metodología de la investigación</li>
                </ul>
              </Col>
              {width > 992 ? (
                <Col xs={12} lg={6} id='green-text'>
                  <div>
                    <p>Aplica técnicas auxiliares de inmunohistoquímica y biología molecular en la práctica de la patología quirúrgica general.</p>
                    <p>Aprende las limitaciones y alcances de las técnicas auxiliares del diagnóstico morfológico.</p>
                  </div>
                </Col>
              ) : (
                <ColMobile xs={12} lg={6}>
                  <Row>
                    <Col xs={4}>
                      <div id='one'></div>
                      <div id='two'></div>
                    </Col>
                    <Col xs={8}>
                      <p>Aplica técnicas auxiliares de inmunohistoquímica y biología molecular en la práctica de la patología quirúrgica general.</p>
                      <p id='text-two'>Aprende las limitaciones y alcances de las técnicas auxiliares del diagnóstico morfológico.</p>
                    </Col>
                  </Row>
                </ColMobile>
              )}
            </Row>
          </TextCol>
        </Row>
      </Container>
    </div>
  )
};