import React from "react";
import styled from "styled-components";
import { ALMOST_WHITE, CA_GRADIENT_BUTTON, GREEN_LAB, PINK_OCTOBER } from "../../helpers/colors";
import { handleAddItemToCart } from "../../actions/shoppingCart";
import { currency, studyInCart } from "../../helpers/cart";
import handleAlert from "../../helpers/handleAlert";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";

const StyledDiv = styled.div`
  border-radius: 10px;
  background: ${ ({green}) => green ? GREEN_LAB : ALMOST_WHITE};
  padding: 10px;
  width: 100%;
  max-width: 364px;
  margin: 0 auto 18px;
  h4 {
    overflow: hidden;
    color: ${ ({green}) => green ? ALMOST_WHITE : PINK_OCTOBER};
    text-align: center;
    text-overflow: ellipsis;
    font-size: 22px;
    font-weight: 600;
    line-height: 120%;
  }
  span {
    font-weight: 700;
  }
  h5, h6 {
    margin-bottom: 0;
    text-align: center;
    line-height: 135%;
    color: ${ ({green}) => green ? '#FFF' : '#051036'};
  }
  h5 {
    font-size: 16px;
    font-weight: 400;
  }
  h6 {
    font-size: 14px;
    font-weight: 400;
  }
  #button-div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
  }
  button {
    display: flex;
    padding: 5px 7px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-size: 16px;
    font-weight: 600;
    color: #FFF;
    border: none;
    transition: all 0.4s ease;
    :hover {
      filter: brightness(115%);
    }
  }
  .secondary-button {
    background: transparent;
    font-weight: 400;
    border-radius: 10px;
    padding: 3px 7px;
    color: ${({green}) => green ? '#FFF' : GREEN_LAB};
    border: 2px solid ${({green}) => green ? ALMOST_WHITE : GREEN_LAB};
  }
  .main-button {
    margin-left: 11px;
    border-radius: 10px;
    background: ${({green}) => green ? PINK_OCTOBER : CA_GRADIENT_BUTTON};
  }
  p {
    color: #F3F3F3 !important;
    font-size: 22px;
    font-weight: 400;
    line-height: 120%;
    max-width: 300px;
    margin: 0 auto 7px;
  }
`;

const StudyDiv = ({isGreen, study, extraInfo}) => {
  const isDoctor = useSelector((state) => state.user?.data?.data?.profile?.type) === 1;
  const { items } = useSelector((state) => state.shoppingCart);
  const dispatch = useDispatch();
  const history = useHistory();

  const addStudy = () => {
    const exists = studyInCart(study, items);
    if (exists?.exists) {
      handleAlert("warning", exists?.message, "Estudio/paquete ya agregado");
    } else {
      dispatch(handleAddItemToCart(study));
    }
  };

  const payStudy = () => {
    const exists = studyInCart(study, items);
    if (exists?.exists) {
      handleAlert("warning", exists?.message, "Estudio/paquete ya agregado");
      history.push("/checkout");
    } else {
      dispatch(handleAddItemToCart(study));
      history.push("/checkout");
    }
  };

  const callToSchedule = () => {
    window.open(`https://wa.me/522212311313?text=Quiero agendar una cita para ${study?.title}`)
  };

  return (
    <StyledDiv green={isGreen}>

      <h4>{study?.title}</h4>

      {extraInfo ? 
        <p>Incluye: Ultrasonido de mama y Mastografía.</p>
      : null}

      <h5>
        Pago en 
          {(study.categoria !== 10 && study.categoria !== 8 && study.ventaOnline!==0) ? (' línea '):(' sucursal ')}
          <span>{currency(study?.promo_price)} MXN</span>
      </h5>
      {(study.categoria !== 10 && study.categoria !== 8 && study.ventaOnline!==0) ? (
        <h6>(precio sucursal {currency(study?.regular_price !== 0 ? study?.regular_price : study?.normal_price)} MXN)</h6>
      ): null}

      <div id='button-div'>
        {(study.categoria !== 10 && study.categoria !== 8 && study.ventaOnline!==0) ? (
          <>
          <button className="secondary-button" onClick={addStudy}>Agregar estudio</button>
          <button className="main-button" onClick={payStudy}>Pagar ahora</button>
          </>
        ) : (
          <button className="main-button" onClick={callToSchedule}>Llamar para agendar</button>
        )}
      </div>
    </StyledDiv>
  )
};

export default StudyDiv;