import React, { useEffect, useState } from 'react';
import { Button, Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import PhoneInput from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { startFacebookLogin, startGoogleLogin, startLoginEmailPassword, startLoginWithPhoneNumber, startRegisterWithEmailPasswordName, verifiedPhone } from '../../actions/auth';
import { UilEye, UilEyeSlash, UilCheckCircle } from '@iconscout/react-unicons';
import { getAuth, RecaptchaVerifier } from 'firebase/auth';
import { HelmetSEO } from "../../helpers/HelmetSEO";
import { addMember, getLists } from '../../mailchimp/maichimp';

const googleIcon = 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2Fsocial-media-icons%2Fgoogle-icon.png?alt=media&token=00b8a8f5-6cc9-4a33-bdf3-1ae03ad92928';
const facebookIcon = 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2Fsocial-media-icons%2Ffacebook-icon.png?alt=media&token=d782baf4-bd83-4dc6-be7b-348db43424e0';

const DataForm = styled.form`
  width: 100%;
  max-width: 358px;
  > .form-floating {
    margin-top: 24px;
  }

  > div .form-control {
    border: none;
    background: #f6f6f6;
    border-radius: 12px;
    font-family: 'IBM Plex Sans';
  }

  > .form-floating > label {
    font-family: Open Sans;
    font-size: 16px;
    color: #A6A6A6;
    transform-origin: 0 -10em;
    background: #f6f6f6;
    border-radius: 8px;
    padding: 2px 6px;
    margin-top: 14px;
    margin-left: 16px;
    height: auto;
    font-family: 'IBM Plex Sans';
  }

  > .form-floating > .form-control {
    padding-left: 16px;
    padding-top: 0.625em;
    :focus {
      outline: none;
      -webkit-box-shadow: 0px 0px 5px 5px #00AC9080; 
      box-shadow: 0px 0px 5px 5px #00AC9080;
    }
  }
  .form-floating {
    position: relative;
    .icon-see-password, .icon-right-email {
      position: absolute;
      right: 15px;
      bottom: 15px;
    }
    .icon-see-password {
      cursor: pointer;
    }
  }
  .not-coincidence {
    outline: none;
    -webkit-box-shadow: 0px 0px 5px 5px #FF000080; 
    box-shadow: 0px 0px 5px 5px #FF000080;
  }
`;

const StyledPhoneInput = styled(PhoneInput)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 15px;
  position: static;
  width: 100%;
  height: 58px;
  left: 0px;
  top: 0px;
  background: #f6f6f6;
  border: none;
  box-sizing: border-box;
  border-radius: 8px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin-top: 20px;
  > input {
    background: #f6f6f6;
  }
`;

const LogContainer = styled.div`
  width: 90%;
  margin-left: 5%;
  max-width: 483px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  padding: 40px 0;
  margin: 0 auto;
  h1 {
    font-weight: 400;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    color: #3B3B3B;
  }
  h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    a {
      color: #00AC90;
      text-decoration: underline;
    }
  }
  hr {
    width: 100%;
    border: 1px solid #C1D9D8;
    margin-top: 0px;
    margin-bottom: 24px;
  }
  .phone-row {
    width: 100%;
    max-width: 358px;
    margin-bottom: 24px;
  }
`;

const GreenButton = styled(Button)`
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  width: 100%;
  height: 40px;
  background: #00ad90;
  border: none;
  border-radius: 102px;
  margin-top: 28px;
  margin-bottom: 24px;
  margin-left: auto;
  margin-right: auto;
  :focus {
    background: #0a7a71;
  }
  :hover {
    background: #00ad90;
  }
  :disabled {
    background: #e2e2e2;
    color: #aaa;
  }
`;

const SignInOptions = styled(Row)`
  flex-direction: row !important;
  width: 100%;
  max-width: 483px;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  p {
    margin-bottom: 24px;
  }
`;

const Circle = styled.div`
  background: #FFFFFF;
  border: 1px solid #E9E9E9;
  border-radius: 50%;
  box-shadow: 0px 4px 4px #F6F6F6;
  cursor: pointer;
  width: 48px;
  height: 48px;
  margin-bottom: 8px;
`;

const InputStyled = styled(Form.Check)`
  display: flex;
  margin-top: 26px;
  > input {
    width: 20px !important;
    height: 20px !important;
    border: 1px solid #00AC90 !important;
    border-radius: 0px !important;
    :checked {
      border: 1px solid #00AC90 !important;
      background-color: #00AC90 !important;
    }
    :focus {
      outline: none;
      -webkit-box-shadow: 0px 0px 5px 5px #00AC9080; 
      box-shadow: 0px 0px 5px 5px #00AC9080;
    }
  }
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #3B3B3B;
    margin-top: 3px;
    margin-bottom: 0px;
    margin-left: 10px;
  }
  a {
    color: #00AC90;
  }
`;

export const RegisterForm = () => {
  const [terms, setTerms] = useState(false);
  const [newsletter, setNewsletter] = useState(false);
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [hideCaptcha, setHideCaptcha] = useState(false);

  const dispatch = useDispatch();
  const auth = getAuth();

  useEffect(() => {
    dispatch(verifiedPhone(phone, false, 'Dispatch', ''));
  }, [dispatch, phone]);

  useEffect(() => {
    // const phoneNumber = document.getElementById('phoneNumber')?.value;
    if (showCaptcha) {
      if ( document.getElementById('recaptcha-container') && (phone?.length > 6) ) {
        console.log("Entra al segundo if");
        try {
          let appVerifier = window.recaptchaVerifier;
          appVerifier = new RecaptchaVerifier(
            'recaptcha-container',
            {
              size: 'normal',
              callback: () => {
                if (phone.length > 10) {
                  dispatch(startLoginWithPhoneNumber(phone, appVerifier, () => {
                    setShowCaptcha(false);
                    setHideCaptcha(false);
                  }));
                  setHideCaptcha(true);
                  // setCaptcha(true);
                } else {
                  alert('NO HAY NÚMERO');
                }
              },
              'expired-callback': () => {
                setShowCaptcha(false);
                setHideCaptcha(false);
                // Response expired. Ask user to solve reCAPTCHA again.
                // ...
              },
            },
            auth
          );
          appVerifier.render().then((widgetId) => {
            window.recaptchaWidgetId = widgetId;
          });
          console.log('OK RECAPTCHA');
        } catch (error) {
          // TODO: handle error when reCAPTCHA doesn't work
          console.log(String(error).split('.')[0]);
        }
      }
    }
  }, [showCaptcha, auth, dispatch, phone]);

  const checkPattern = () => {
    return /^[A-Za-z0-9._%+-]+@[a-z0-9._]+\.[a-z]{2,4}$/i.test(email)
  }

  const {
      register,
      handleSubmit,
      watch,
      formState: { errors }
  } = useForm();

  const onSubmit = async (data) => {
    if(newsletter){
      await addMember(data.email);
    }
    try {
      dispatch(startRegisterWithEmailPasswordName(data.email, data.password, '', '', '', ''));
    } catch (error) {
      setTerms(false);
      console.log(error);
    }
  };

  const handleGoogleRegister = () => {
    dispatch(startGoogleLogin(phone));
  };

  const handleFacebookRegister = () => {
    dispatch(startFacebookLogin(phone));
  };

  console.log(phone);

  return (
    <LogContainer >
     <HelmetSEO
      title='Crea tu cuenta - Diagnósticos Clínicos - Labopat'
      description='Regístrate en Labopat y accede a diagnósticos médicos rápidos y seguros. Cuidamos tu salud en Puebla. Crea tu cuenta fácil y sencillo ahora.'
     />
      <DataForm onSubmit={handleSubmit(onSubmit)}>
        <h1>Crea tu cuenta</h1>
        <h2>¿Ya tienes cuenta? <Link to="/iniciar-sesion">Inicia sesión</Link></h2>
        <FloatingLabel controlId='floatingMail' label='Correo Electrónico'>
          <Form.Control
            {...register('email', {
              required: true,
              pattern: /^[A-Za-z0-9._%+-]+@[a-z0-9._]+\.[a-z]{2,4}$/i,
            })}
            type="email"
            placeholder="pruebas@labopat.mx"
            value={email}
            onChange={(e)=>setEmail(e.target.value)}
            name="email"
            id="email"
          />
          {checkPattern() && <UilCheckCircle color="#00AC90" className="icon-right-email"/>}
        </FloatingLabel>
        <FloatingLabel controlId='floatingPass' label='Contraseña'>
          <Form.Control
            {...register('password', {
              required: true,
            })}
            type={showPassword ? "text" : "password"}
            placeholder="********"
            value={password}
            name="password"
            id="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          {showPassword 
            ? <UilEye color="#00AC90" className="icon-see-password" onClick={()=>setShowPassword(!showPassword)}/> 
            : <UilEyeSlash color="#00AC90" className="icon-see-password" onClick={()=>setShowPassword(!showPassword)}/>
          }
        </FloatingLabel>
        <FloatingLabel controlId='floatingPassRep' label='Confirmar contraseña'>
          <Form.Control
            {...register('passwordRepeat', {
              required: true,
            })}
            type={showPasswordRepeat ? "text" : "password"}
            placeholder="********"
            value={passwordRepeat}
            name="passwordRepeat"
            id="passwordRepeat"
            onChange={(e) => setPasswordRepeat(e.target.value)}
            className={passwordRepeat.length >= password.length && password !== passwordRepeat ? "not-coincidence" : ""}
          />
          {showPasswordRepeat
            ? <UilEye color="#00AC90" className="icon-see-password" onClick={()=>setShowPasswordRepeat(!showPasswordRepeat)}/> 
            : <UilEyeSlash color="#00AC90" className="icon-see-password" onClick={()=>setShowPasswordRepeat(!showPasswordRepeat)}/>
          }
        </FloatingLabel>
        <InputStyled
          {...register('terms', {
            required: true,
          })}
          key='check-terms'
          id='check-terms'
          type='checkbox'
          name='terms'
          label={
            <p>Acepto <a 
              href='https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2FTerminos%20y%20condiciones%20.pdf?alt=media&token=f94e2aa8-9d69-45fd-ab0b-1073683e4111'
              target='_blank'
              rel='noreferrer'
            >términos y condiciones</a></p>
          }
          checked={terms}
          onChange={() => setTerms(!terms)}
        />
        <InputStyled
          {...register('newsletter', {
            required: false,
          })}
          key='newsletter'
          id='newsletter'
          type='checkbox'
          name='newsletter'
          label={
            <p style={{textAlign: "left", maxWidth: "300px"}}>Acepto recibir información sobre los servicios y novedades de Labopat.</p>
          }
          checked={newsletter}
          onChange={() => setNewsletter(!newsletter)}
        />

        <GreenButton
          variant='primary'
          type='submit'
          disabled={password.length < 1 || email.length < 1 || password !== passwordRepeat}
        >
          Crear cuenta
        </GreenButton>
      </DataForm>
      <hr/>
      <SignInOptions>
        <Col xs={{span:4, offset: 2}}>
          <Circle onClick={handleFacebookRegister}>
            <img
              src={facebookIcon}
              style={{ width: '20px', height: '20px' }}
              alt='facebook-icon'
            />
          </Circle>
          <p>Facebook</p>
        </Col>
        <Col xs={{span:4}}>
          <Circle onClick={handleGoogleRegister}>
            <img
              src={googleIcon}
              style={{ width: '20px', height: '20px' }}
              alt='google-icon'
            />
          </Circle>
          <p>Gmail</p>
        </Col>
      </SignInOptions>
      <p style={{color: "#3B3B3B", fontSize: "16px", fontWeight: "400"}}>O usa tu número celular para registrarte</p>
      <Row className='phone-row'>
        <StyledPhoneInput
          international
          type='tel'
          defaultCountry='MX'
          placeholder='Teléfono celular'
          id='phoneNumber'
          value={phone}
          onChange={setPhone}
        />
      </Row>
      <p>Enviaremos un código por mensaje de texto al número que proporcionaste.</p>
      {!showCaptcha && (
        <GreenButton
          variant='primary'
          onClick={()=>{setShowCaptcha(true)}}
          disabled={phone === undefined || phone?.length < 12}
          >
          Enviar código
        </GreenButton>
      )}
      {showCaptcha && (
        <Row style={{display: hideCaptcha?"none" : "block"}}>
          <div
            id='recaptcha-container'
            className='recaptcha-container'
          />
        </Row>
      )}
      <div id="div-for-code">

      </div>
    </LogContainer>
  );
}