import React from "react";
import { VideoOctober } from "./videoOctober";
import OurStudies from "./ourStudies";
import { HelmetSEO } from "../../helpers/HelmetSEO";

const October = () => {
  return (
    <div>
      <HelmetSEO
        title='Estudios de Laboratorio para Mujer - Labopat'
        description='Cuida tu salud con estudios de mastografía, biopsia, ultrasonido y más en Labopat Puebla. Beneficios exclusivos en línea. Descubre nuestros estudios rosa.'
      />
      <VideoOctober/>
      <OurStudies/>
    </div>
  )
};

export default October;