import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import {
  UilTrashAlt,
  UilTimes,
  UilPen,
  UilAngleDown,
  UilAngleUp,
  UilSetting,
} from "@iconscout/react-unicons";
import axios from "axios";
import {
  getTotalPrice,
  addItemToCart,
  setTotalPrice,
} from "../../actions/shoppingCart";
import { updateCartDB } from "../../helpers/api";
import { PrivRoutes } from "../../routes/routes";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import handleAlert from "../../helpers/handleAlert";

const NormalText = styled.p`
  font-family: "IBM Plex Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: ${(props) => props.mainColor};
  text-align: left;
  margin: 0;
`;

const CardContainer = styled.div`
  max-width: 100%;
  height: 100%;
  padding: 0.5rem;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(45deg, #fff, #fff),linear-gradient(90deg, #5097E3 -2.05%, #0CAC8C 72.3%, #30DF99 101.61%, #CEF719 133.72%, #F5EC1D 211.91%) ;
  border: 10 px;
  border: 2px solid transparent;
  border-radius: 12px;
  position: relative;
`;
const Detail = styled.div`
  max-height: 231px;
  overflow-y: auto;
  padding: 16px 18px;
  border-radius: 8px;
  background: #f2f7f9;
  > h4 {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #000;
    margin-bottom: 6px;
  }
  > div p {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;
    color: #6a6a6a !important;
  }
  #enlace {
    color: ${(props) =>
      props.mainColor === "#4F90BA" ? "#4D99E5" : "#0CAC8C"};
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 0px;
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 2px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #c4c4c4;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const PackageHeader = styled.div`
  position: relative;
  padding: 1rem;
`;

const MenuContainer = styled.div`
  position: absolute;
  top: 18px;
  right: 0;
  width: auto;
  cursor: pointer;
`;

const StyledModal = styled(Modal)`
  .modal-dialog {
    height: 80%;
    display: flex;
    align-items: center;
  }
  .modal-header {
    border-bottom: 1px solid #c1d9d8;
  }
  .modal-title {
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 900;
    font-size: 16px;
    line-height: 24px;
    color: #474a4b;
  }
  p {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #717171;
    span {
      font-weight: 800;
    }
  }
  .btn-close {
    width: 40px;
    height: 40px;
    background: #4f90ba;
    border-radius: 50%;
    padding: 0px !important;
    top: -13px;
    right: 33px;
    opacity: 1;
  }
  svg {
    z-index: 1000;
    top: -13px;
    right: 33px;
    position: absolute;
  }
`;

const ContinueButton = styled(Button)`
  width: 200px;
  height: 40px;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  height: 40px;
  background: #4d99e5;
  border: 2px solid #fff;
  border-radius: 10px;
  margin-top: 24px;
  margin-bottom: 20px;
  transition: all 0.4s ease;
  :focus {
    background: #4d99e5;
  }
  :hover {
    transition: all 0.4s ease;
    color: #4d99e5;
    background: #fff;
  }
`;

const WhiteButton = styled(Button)`
  color: #4f90ba;
  border: 2px solid #fff;
  background: #e2e2e2;
  width: 200px;
  height: 40px;
  border-radius: 10px;
  margin-top: 16px;
  margin-bottom: 0px;
  transition: all 0.4s ease;
  :hover {
    transition: all 0.4s ease;
    border: 2px solid #4d99e5;
    color: #4d99e5;
    background: #fff;
  }
`;

const Options = styled.div`
  position: absolute;
  right: 15px;
  top: 15px;
  padding: 10px;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  box-shadow: 0px 4px 4px rgba(71, 74, 75, 0.15);
  border-radius: 12px;
  z-index: 2;
  hr {
    margin: 10px;
  }
  p {
    margin-bottom: 0px;
    cursor: pointer;
    :hover {
      opacity: 0.5;
    }
  }
`;

const BlueButton = styled.button`
  width: 70%;
  height: 48px;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(
    90deg,
    #5097e3 -2.05%,
    #0cac8c 72.3%,
    #30df99 101.61%,
    #cef719 133.72%,
    #f5ec1d 211.91%
  );
  border-radius: 10px;
  border: 2px solid transparent;
  font-size: 13px;
  padding: 0;
  font-weight: 800;
  margin-top: 6px;
  margin-bottom: 10px;
  color: #fff;
  transition: 300ms;
  :hover {
    transition: 300ms;
    background: #fff;
    border: 2px solid #4f90ba;
    color: #4f90ba;
  }
`;

export const PackageCard = (props) => {
  const [showOptions, setShowOptions] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const valores = window.location.search;
  const urlParams = new URLSearchParams(valores);
  var forPatient = urlParams?.get("forPatient") === "1";
  const idClienteLabopat = useSelector(
    (state) => state.user?.data?.data?.profile?.idClienteLabopat
  );
  const isDoctor =
    useSelector((state) => state.user?.data?.data?.profile?.type) === 1;
  const history = useHistory();
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const location = useLocation();
  const mainColor =
    PrivRoutes.includes(location.pathname) && isDoctor ? "#4F90BA" : "#00AC90";
  const [viewDetail, setViewDetail] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const privateRoute = PrivRoutes?.some(subcadena => location?.pathname?.includes(subcadena));
  const { items } = useSelector((state) => state.shoppingCart);

  const checkAdded = () => {
    const itemsIds = items.map(({id}) => id);
    const estudiosIds = props?.data?.estudios?.map(({study_id}) => study_id);
    return estudiosIds?.every(estudioID => itemsIds.some(itemID => itemID === estudioID))
  }

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowOptions(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const addPackageToCart = async () => {
    if(checkAdded()){
      handleAlert('warning', 'Este paquete ya se encuentra en tu carrito', 'Paquete ya agregado')
    }else{
      var formData = new FormData();
      console.log("*");
      console.log(props.data);
      /*props.data.estudios.forEach((study) => {
        formData.append('studies[]', study.study_id);
      });*/
      for (const study of props.data.estudios) {
        formData.append("studies[]", study.study_id);
      }
      formData.append("idCliente", idClienteLabopat ? idClienteLabopat : 0);
      let resDetail = await axios.post(
        `https://lpqsystem.com/create-cart-from-package-test`,
        formData
      );
      console.log(resDetail.data);

      if (resDetail.data.ok && resDetail.data.cart.length > 0) {
        const estus = resDetail.data.cart;
        // console.log("get total 3: ", estus);
        const totalPrice = getTotalPrice(estus);
        console.log("total price: ", totalPrice);
        dispatch(addItemToCart(estus));
        dispatch(setTotalPrice(totalPrice));
        handleAlert("success", "¡El paquete ha sido agregado correctamente!", "Paquete agregado");
        dispatch(
          updateCartDB(
            estus,
            totalPrice,
            privateRoute
              ? ``
              : ""
          )
        );
      } else {
        console.log("error al crear el cart from lpqsystem");
      }
    }
  };

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  let isMobile = width <= 900;

  return (
    <>
      <CardContainer id="my-package-card">
        <PackageHeader>
          <NormalText
            mainColor={mainColor ? "#4F90BA" : "#4D99E5"}
            onClick={() => {
              //history.push(`/estudio/${props.data.id}`);
            }}
          >
            {props.data.paquete.name}
          </NormalText>
          <MenuContainer onClick={() => setShowOptions(true)}>
            <UilSetting size={24} color={mainColor ? "#4F90BA" : "#4D99E5"} />
          </MenuContainer>
        </PackageHeader>
        {showOptions && (
          <Options ref={wrapperRef}>
            <p
              onClick={() => {
                setShowOptions(false);
                history.push(`editar-paquete/${props?.data?.paquete?.id}`);
              }}
            >
              <UilPen color={mainColor ? "#4F90BA" : "#4D99E5"} /> Editar
            </p>
            <hr style={{ background: "#C4C4C4", padding: ".5px" }} />
            <p
              onClick={() => {
                setShowModal(true);
                setShowOptions(false);
              }}
            >
              <UilTrashAlt color={mainColor ? "#4F90BA" : "#4D99E5"} /> Eliminar
            </p>
          </Options>
        )}
        <hr style={{ background: "linear-gradient(137deg, #5097E3 -2.05%, #0CAC8C 72.3%, #30DF99 101.61%, #CEF719 133.72%, #F5EC1D 211.91%)", padding: ".5px" }} />
        <Row className="mb-2"></Row>
        {isMobile ? (
          <>
            <Row>
              <Col xs={12} sm={6}>
                <ul>
                  {props.data.estudios.slice(0, 3).map((study, index) => (
                    <li style={{ color: "#4D99E5" }}>
                      <NormalText
                        key={index}
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                          width: "220px",
                          height: "55px",
                        }}
                        mainColor={mainColor ? "#4F90BA" : "#4D99E5"}
                      >
                        {study.study_name}
                      </NormalText>
                    </li>
                  ))}
                </ul>
              </Col>
            </Row>

            <Row className="mb-4">
              <NormalText
                className="mb-4"
                style={{ fontSize: "14px", fontWeight: "500" }}
                mainColor={mainColor ? "#4F90BA" : "#4D99E5"}
              >
                <p
                  style={{
                    color: "#4D99E5",
                    textAlign: "center",
                    marginBottom: "0px",
                    cursor: "pointer",
                  }}
                  onClick={() => setViewDetail(!viewDetail)}
                >
                  <span style={{ textDecoration: "underline" }}>
                    {viewDetail ? "Ocultar" : "Ver"} estudios restantes
                  </span>{" "}
                  {viewDetail ? (
                    <UilAngleUp style={{ marginTop: "10px" }} size="28" />
                  ) : (
                    <UilAngleDown size="28" />
                  )}
                </p>

                {viewDetail && (
                  <Detail>
                    <Col xs={12}>
                      <ul>
                        {props.data.estudios.slice(3).map((study, index) => (
                          <li>
                            <NormalText
                              key={index}
                              style={{
                                fontSize: "14px",
                                fontWeight: "500",
                              }}
                              mainColor={mainColor ? "#4F90BA" : "#4D99E5"}
                            >
                              {study.study_name}
                            </NormalText>
                          </li>
                        ))}
                      </ul>
                    </Col>
                  </Detail>
                )}
              </NormalText>
            </Row>
            <Row>
              <BlueButton
                mainColor={mainColor ? "#4F90BA" : "#4D99E5"}
                onClick={() => addPackageToCart()}
                style={{ margin: "auto", marginTop: "0px", width:"230px" }}
              >
                {checkAdded() ? 'Paquete agregado' : 'Solicitar para mi paciente'}
              </BlueButton>
            </Row>
          </>
        ) : (
          <>
            <Row>
              <Col xs={12} sm={6}>
                <ul>
                  {props.data.estudios.slice(0, 3).map((study, index) => (
                    <li style={{ color: "#4D99E5" }}>
                      <NormalText
                        key={index}
                        style={{
                          fontSize: "13px",
                          fontWeight: "500",
                          width: "200px",
                          height: "50px",
                        }}
                        mainColor={mainColor ? "#4F90BA" : "#4D99E5"}
                      >
                        {study.study_name}
                      </NormalText>
                    </li>
                  ))}
                </ul>
              </Col>
            </Row>
            <Row>
              <BlueButton
                mainColor={mainColor ? "#4F90BA" : "#4D99E5"}
                onClick={() => addPackageToCart()}
                style={{ marginLeft: "auto", marginRight:"auto"}}
              >
                {checkAdded() ? 'Paquete agregado' : 'Solicitar para mi paciente'}
              </BlueButton>
            </Row>

            <Row className="mb-4">
              <NormalText
                className="mb-4"
                style={{ fontSize: "14px", fontWeight: "500" }}
                mainColor={mainColor ? "#4F90BA" : "#4D99E5"}
              >
                <p
                  style={{
                    color: "#4D99E5",
                    textAlign: "center",
                    marginBottom: "0px",
                    cursor: "pointer",
                  }}
                  onClick={() => setViewDetail(!viewDetail)}
                >
                  <span style={{ textDecoration: "underline" }}>
                    {viewDetail ? "Ocultar" : "Ver"} estudios restantes
                  </span>{" "}
                  {viewDetail ? (
                    <UilAngleUp style={{ marginTop: "10px" }} size="28" />
                  ) : (
                    <UilAngleDown size="28" />
                  )}
                </p>

                {viewDetail && (
                  <Detail>
                    <Col xs={12}>
                      <ul>
                        {props.data.estudios.slice(3).map((study, index) => (
                          <li>
                            <NormalText
                              key={index}
                              style={{
                                fontSize: "14px",
                                fontWeight: "500",
                              }}
                              mainColor={mainColor ? "#4F90BA" : "#4D99E5"}
                            >
                              {study.study_name}
                            </NormalText>
                          </li>
                        ))}
                      </ul>
                    </Col>
                  </Detail>
                )}
              </NormalText>
            </Row>
          </>
        )}

        <StyledModal
          show={showModal}
          onHide={() => {
            setShowModal(false);
          }}
        >
          <div onClick={() => setShowModal(false)}>
            <UilTimes color="#fff" style={{ cursor: "pointer" }} />
          </div>
          <Modal.Header closeButton>
            <Modal.Title color="#000">Eliminar paquete</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              ¿Estás seguro de querer eliminar el paquete:{" "}
              <span>{props.data.paquete?.name}</span>?
            </p>
            <ContinueButton
              onClick={() => {
                setShowModal(false);
                props.onDelete(props.data.paquete.id);
              }}
            >
              Eliminar
            </ContinueButton>
            <WhiteButton variant="light" onClick={() => setShowModal(false)}>
              Cancelar
            </WhiteButton>
          </Modal.Body>
        </StyledModal>
        {/* <EditPackageDrawer onClose={()=>{setShowDrawer(false)}} visible={showDrawer} data={props.data}/> */}
      </CardContainer>
    </>
  );
};