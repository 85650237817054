export const PrivRoutes = [
  '/perfil',
  '/indicaciones',
  '/detalle-historial',
  '/datos-personales',
  '/historial-medico',
  '/pacientes',
  '/paciente/',
  '/paquetes',
  '/sucursalesM',
  '/mis-paquetes',
  '/editar-paquete/',
  '/resultados',
  '/dashboard',
  '/crear-post',
  '/editar-post',
  '/admin-blog',
]

export const BlogRoutes = [
  '/blog',
  '/post',
]