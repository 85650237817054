import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Container, Col, Row, Modal } from "react-bootstrap";
import { UilMultiply } from '@iconscout/react-unicons';

const services = [
  {
    name: "Patología",
    title: "Patología quirúrgica",
    desc: "Brindamos un servicio integral a médicos y pacientes a través de nuestro equipo de médicos anatomopatólogos con gran experiencia y especialización.",
    icon: "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2FaboutUs%2Fpatologi%CC%81a.png?alt=media&token=ead86d12-f6ee-48c9-9025-d6a33c201a75",
    bg: "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2FaboutUs%2FPATOLOGI%CC%81A%20TEXT%20activeee.png?alt=media&token=580c6f42-5b7d-4f7f-a419-10ad2d9772c0",
    width: 99
  },
  {
    name: "Estudios de laboratorio",
    title: "Estudios de laboratorio",
    desc: "Servicio de análisis eficiente, innovador y de confianza para la detección, tratamiento y prevención oportuna de patologías.",
    icon: "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2FaboutUs%2Fclinicos.png?alt=media&token=8b601141-c9b5-4845-bbbf-b87ad5aaedc3",
    bg: "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2FaboutUs%2FLABORATORIO%20TEXT%20active.png?alt=media&token=e214d0a8-c9c0-413e-97da-fca812e1588b",
    width: 28
  },
  {
    name: "Servicios genéticos",
    title: "Servicios genéticos",
    desc: "Pruebas genéticas para la detección de alteraciones causantes de trastornos o enfermedades.",
    icon: "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2FaboutUs%2Fservs%20genes.png?alt=media&token=185ccf7c-dc77-47cc-abef-ace2f04b492f",
    bg: "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2FaboutUs%2FSERVS%20GENE%20TEXT%20active.png?alt=media&token=366e17a7-a6d6-479f-a76f-ccc4fa45f778",
    width: 88
  },
  {
    name: "Toma de biopsia",
    title: "Toma de biopsia",
    desc: "Toma de biopsias guiadas por ultrasonido con médicos especialistas en radiología.",
    icon: "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2FaboutUs%2Fbiop.png?alt=media&token=65696a2b-f255-479d-8f25-894d9f0c4675",
    bg: "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2FaboutUs%2FBIOPSIAS%20TEXT%20active.png?alt=media&token=0f52323e-2126-4130-bc04-f346d03aa05a",
    width: 111
  },
  {
    name: "Ultrasonido",
    title: "Ultrasonido",
    desc: "Ultrasonido especializado e integral con equipo de última generación.",
    icon: "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2FaboutUs%2Fultrasonido.png?alt=media&token=7b82a40b-bfbc-4776-baa4-16c09713569f",
    bg: "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2FaboutUs%2FULTRASONIDO%20TEXT%20active.png?alt=media&token=6b41c199-ab14-4e63-a46e-1eaa365487f6",
    width: 85
  },
  {
    name: "Toma de Papanicolaou",
    desc: "Prueba diagnóstica que examina las células del cuello uterino para detección oportuna de carcinoma.",
    icon: "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2FaboutUs%2FPAP2.png?alt=media&token=e35c1d3c-67ec-445e-b174-a43f7e9fd4d5",
    bg: "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2FaboutUs%2FPAP%20TEXT%20active.png?alt=media&token=b77fe8b9-a851-4873-a76c-fd90fe60e100",
    width: 100
  },
]

const Title = styled.h1`
  color: #474A4B;
  text-align: center;
  font-size: 70px;
  font-weight: 300;
  line-height: 105.5%;
  span {
    background: linear-gradient(90deg, #5097E3 0%, #0CAC8C 26.56%, #30DF99 73.96%, #CEF719 110%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
    position: relative;
    ::before {
      content: "";
      position: absolute;
      top: 95%;
      width: 100%;
      left: 0;
      height: 7px;
      border-radius: 2px;
      background: linear-gradient(90deg, #5097E3 0%, #0CAC8C 26.56%, #30DF99 73.96%, #CEF719 110%);
    }
  }
  @media only screen and (max-width: 768px) {
    font-size: 32px;
    span::before {
      height: 4px;
    }
  }
`;

const StyledCol = styled(Col)`
  min-height: 260px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .bg-icon {
    width: 150px;
    height: 150px;
    background: #474A4B;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 14px;
    transition: all 0.4s ease;
    cursor: pointer;
    img {
      width: ${({width}) => `${width}px`};
      transition: all 0.4s ease;
    }
  }
  p {
    color: #474A4B;
    text-align: center;
    font-family: IBM Plex Sans;
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
  }
  :hover {
    .bg-icon {
      transition: all 0.4s ease;
      width: 200px;
      height: 200px;
      background: linear-gradient(90deg, #5097E3 0%, #0CAC8C 26.56%, #30DF99 73.96%, #CEF719 110%);
      border-radius: 40px;
      img {
        width: ${({width}) => `${width*1.25}px`};
        transition: all 0.4s ease;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .bg-icon {
      width: 100px;
      height: 100px;
      img {
        width: ${({width}) => `${width*.6}px`};
      }
    }
    :hover {
      .bg-icon {
        width: 170px;
        height: 170px;
        border-radius: 50%;
        img {
          width: ${({width}) => `${width*1}px`};
        }
      }
    }
    min-height: 220px;
  }
`;

const StyledModal = styled(Modal)`
  .modal-dialog {
    width: 100%;
    max-width: 715px !important;
  }
  .modal-content {
    width: 96%;
    max-width: 715px !important;
    border-radius: 40px;
    overflow: hidden;
  }
  .modal-body {
    height: 440px;
    background: ${({bg}) => `url('${bg}') no-repeat center`};
    padding: 0;
    transition: all 0.4s ease;
    filter: grayscale(1);
    position: relative;
    :hover {
      filter: grayscale(0);
      transition: all 0.4s ease;
      #text-div {
        transition: all 0.4s ease;
        opacity: 0;
      }
      #close-button {
        opacity: 1;
        transition: all 0.4s ease;
      }
    }
  }
  #close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    opacity: 0;
    transition: all 0.4s ease;
    cursor: pointer;
  }
  #text-div {
    width: 100%;
    height: 270px;
    background: #F9F9F9;
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: all 0.4s ease;
    padding: 25px;
  }
  h1 {
    color: #474A4B;
    text-align: center;
    font-size: 44px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  p {
    width: 85%;
    color: #474A4B;
    text-align: center;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  @media only screen and (max-width: 768px) {
    h1 {
      font-size: 32px;
    }
    p {
      font-size: 20px;
    }
    .modal-content {
      border-radius: 20px;
    }
  }
`;

export const OurServices = () => {
  const [show, setShow] = useState(false);
  const [modalInfo, setModalInfo] = useState();

  // We get window width
  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  let isMobile = width <= 768;

  const handleOpen = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleClick = (service) => {
    setModalInfo(service);
    if(!isMobile){
      handleOpen();
    }
  }

  return(
    <div style={{width: "100%", background: "#FFF"}}>
      <Container className="d-flex flex-column align-items-center" style={{padding: "80px 0 0 0", background: "#FFF"}}>
        <Row>
          <Col xs={12} lg={4} xl={5} className="d-flex justify-content-center align-items-center">
            <Title>Nuestros<br/><span>servicios</span></Title>
          </Col>
          <Col xs={12} lg={8} xl={7}>
            <Row>
              {services?.map((service)=>
                <StyledCol xs={12} md={6} lg={4} width={service?.width} onClick={()=> handleClick(service)} onMouseLeave={()=>{ if(isMobile){setModalInfo(undefined)}}}>
                  <div className="bg-icon">
                    <img src={service?.icon} alt={service?.name} />
                  </div>
                  <p>{service?.name}</p>
                  {isMobile && modalInfo?.name === service?.name ? 
                    <p>{modalInfo?.desc}</p>
                  : null} 
                </StyledCol>
              )}
            </Row>
          </Col>
        </Row>
      </Container>
      <StyledModal
        show={show}
        onHide={handleClose}
        dialogClassName="modal-dialog-slide"
        bg={modalInfo?.bg}
        centered
      >
        <Modal.Body closeButton>
          <div id="close-button" onClick={handleClose}>
            <UilMultiply color="#F3F3F3" size={52}/>
          </div>
          <div id="text-div">
            <h1>{modalInfo?.title}</h1>
            <p>{modalInfo?.desc}</p>
          </div>
        </Modal.Body>
      </StyledModal>
    </div>
  );
}