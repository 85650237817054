import { types } from "../types/types";

export const patientInfoReducer = (state = {}, action) => {

    switch (action.type) {
        case 'setPatientInfo':
            return {
                name: action.payload.name,
                email: action.payload.email
            };

        case 'resetPatientInfo':
            return {};

        default:
            return state;
    }

}