import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { Hero } from "./Hero";
import AvailableServices from "./AvailableServices";
import MakeDate from "./MakeDate";

export const Branch = () => {
  const { name_branch } = useParams();
  const [dataBranch, setDataBranch] = useState({});
  useEffect(() => {
    const getDataBranch = async () => {
      //colocar un if que obtenga una comparacion de branch de nombres y añadir else de comparativa

      try {
        const { data } = await axios.get(
          `https://branches-c4511-default-rtdb.firebaseio.com/branches/${name_branch}.json`
        );
        setDataBranch(data);
      } catch (e) {
        console.log("Error al obtener la data", e);
      }
    };

    getDataBranch();
  }, [name_branch]);

  return (
    <div>
      <Hero dataBranch={dataBranch} />
      <AvailableServices dataBranch={dataBranch} />
      <MakeDate />
    </div>
  );
};
