import React, { useEffect, useState } from 'react';
import { NewBlogCardGrid } from './newBlogCardGrid';
import { Col, Container, Row } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';
import AppsIcon from '@mui/icons-material/Apps';
import MenuIcon from '@mui/icons-material/Menu';
import { AQUA } from './colors';
import { NewBlogCardList } from './newBlogCardList';
import { getAllPosts } from '../../actions/blog';

const PER_PAGE = 6;

const StyledContainer = styled(Container)`
  .pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    cursor: pointer;
  }
  li {
    margin: 15px 5px;
  }
  .previous {
    margin-right: auto;
  }
  .next {
    margin-left: auto;
  }

  .pagination a {
    padding: 7px 10px;
    border-radius: 5px;
    // border: 1px solid #6c7ac9;
    color: #C4C4C4;
  }

  .pagination__link {
    font-weight: bold;
  }

  .pagination__link--active a {
    color: #C4C4C4;
    background: #F3F3F3;
  }

  .pagination__link--disabled a {
    color: rgb(198, 197, 202);
    // border: 1px solid rgb(198, 197, 202);
  }
`;

const StyledButton = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 5px;
  background: #F3F3F3;
  margin: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    transition: all 0.3s ease;
    color: #C4C4C4;
  }
  .active {
    color: ${AQUA};
  }
`;

export const Posts = () => {
  const [layout, setLayout] = useState(true); // true -> Grid   false -> List
  const [currentPage, setCurrentPage] = useState(0);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchPosts = async () => {
    try {
      const posts = await getAllPosts();
      setData(posts);
    } catch (error) {
      // Handle error
      console.error('Error fetching posts without comments: ', error);
    }
  };

  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }

  const offset = currentPage * PER_PAGE;

  const currentPageData = data
    .slice(offset, offset + PER_PAGE)
    .map(( post, index ) =>       
      <Col xs={12} md={layout ? 6 : 12} lg={layout ? 4 : 12} key={index}>
        {layout ? <NewBlogCardGrid data={post}/> : <NewBlogCardList data={post}/>}
      </Col>
    );

  const pageCount = Math.ceil(data.length / PER_PAGE);

  return (
    <StyledContainer>
      <div className='d-flex align-items-center justify-content-center'>
        <StyledButton>
          <AppsIcon className={layout ? 'active' : ''} onClick={()=> setLayout(true)}/>
        </StyledButton>
        <StyledButton>
          <MenuIcon className={layout ? '' : 'active'} onClick={()=> setLayout(false)}/>
        </StyledButton>
      </div>
      <Row>
        {/* {myPosts?.map((post)=>
          <Col xs={12} md={6} lg={4} key={post}>
            <NewBlogCard/>
          </Col>
        )} */}
        {currentPageData}
        <ReactPaginate
          previousLabel={"← Anterior"}
          nextLabel={"Siguiente →"}
          pageCount={pageCount}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          previousLinkClassName={"pagination__link"}
          nextLinkClassName={"pagination__link"}
          disabledClassName={"pagination__link--disabled"}
          activeClassName={"pagination__link--active"}
        />
      </Row>
    </StyledContainer>
  );
};

