import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { UilTimes, UilAngleDown, UilAngleUp, UilTrashAlt } from '@iconscout/react-unicons';

const Title = styled.h1`
  text-align: left;
  font-weight: 800;
  font-size: 15px;
  line-height: 130%;
  letter-spacing: -0.03em;
  color: #00AD90;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;
const Price = styled.p`
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  color: #B5B5B5;
  margin-top: 15px;
`;

const StyledModal = styled(Modal)`
  .modal-dialog {
    height: 80%;
    display: flex;
    align-items: center;
  }
  .modal-header {
    border-bottom: 1px solid #C1D9D8;
  }
  .modal-title {
    font-weight: 900;
    font-size: 16px;
    line-height: 24px;
    color: #00AC90;
  }
  p {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #717171;
    span {
      font-weight: 800;
    }
  }
  .btn-close {
    width: 40px;
    height: 40px;
    background: #00AC90;
    border-radius: 20px;
    padding: 0px !important;
    top: -12px;
    right: 33px;
    opacity: 1;
  }
  svg {
    z-index: 1000;
    top: -13px;
    right: 33px;
    position: absolute;
  }
`;

const Detail = styled.div`
  max-height: 231px;
  overflow-y: auto;
  padding: 16px 18px;
  > h4 {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #00ad90;
    margin-bottom: 6px;
  }
  > p {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #45494a;
    margin-bottom: 10px;
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 2px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #c4c4c4;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const ContinueButton = styled(Button)`
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  width: 241px;
  height: 40px;
  background: #00ad90;
  border: none;
  border-radius: 102px;
  margin-top: 16px;
  margin-bottom: 0px;
  :hover {
    background: #00ad90;
  }
`;

const WhiteButton = styled(Button)`
  color: #00AC90;
  width: 241px;
  height: 40px;
  border: none;
  border-radius: 102px;
  margin-top: 16px;
  margin-bottom: 0px;
`;

export const StudyEdit = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [viewDetail, setViewDetail] = useState(false);
  const currency = function (number) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    }).format(number);
  };

  return (
    <div style={{position: 'relative'}}>
      <Price>En línea: {currency(props.data.price)} MXN</Price>
      <UilTrashAlt color="#00AC90" size={25} style={{position: 'absolute', top: '0px', right: '0px', cursor: 'pointer'}} onClick={()=>setShowModal(true)}/>
      <Title>{props.index+1}. {props.data.title}</Title>
      <p
        style={{
          color: '#C4C4C4',
          textAlign: 'left',
          marginBottom: '0px',
          cursor: 'pointer',
        }}
        onClick={() => setViewDetail(!viewDetail)}
      >
        <span style={{ textDecoration: 'underline' }}>Ver detalles</span>{' '}
        {viewDetail ? <UilAngleUp size='28' /> : <UilAngleDown size='28' />}
      </p>
      {viewDetail && (
        <Detail>
          {props.data.tEntrega && (
            <>
              <h4>Tiempo de entrega</h4>
              <div
                dangerouslySetInnerHTML={{ __html: props.data.tEntrega }}
              ></div>
            </>
          )}

          {props.data.muestra && (
            <>
              <h4>Tipo de muestra</h4>
              <div
                dangerouslySetInnerHTML={{ __html: props.data.muestra }}
              ></div>
            </>
          )}

          {props.data.indicaciones && (
            <>
              <h4>Indicaciones</h4>
              <div
                dangerouslySetInnerHTML={{ __html: props.data.indicaciones }}
              ></div>
            </>
          )}

          {props.data.info && (
            <>
              <h4>Información</h4>
              <div
                dangerouslySetInnerHTML={{ __html: props.data.info }}
              ></div>
            </>
          )}
        </Detail>
      )}
      {showModal &&
        <StyledModal
          show={showModal}
          onHide={() => {
            setShowModal(false);
          }}
        >
          <div onClick={()=>setShowModal(false)}>
            <UilTimes color="#fff" style={{cursor: 'pointer'}}/>
          </div>
          <Modal.Header closeButton>
            <Modal.Title>Eliminar estudio</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>¿Estás seguro de querer eliminar <span>{props.data.title}</span> del paquete?</p>
            <ContinueButton onClick={()=>{
              props.removeItem(props.data.id);
              setShowModal(false);
            }}>
              Eliminar
            </ContinueButton>
            <WhiteButton variant="light" onClick={()=>setShowModal(false)}>Cancelar</WhiteButton>
          </Modal.Body>
        </StyledModal>
      }
      <hr/>
    </div>
  );
  };
  