import React, { useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { UilTrashAlt, UilTimes } from '@iconscout/react-unicons';
import { updateCartDB } from '../../helpers/api';
import { useDispatch, useSelector } from 'react-redux';
import {
  getTotalPrice,
  setTotalPrice,
  removeItemFromCart,
} from '../../actions/shoppingCart';

const Title = styled.p`
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 22px;
  color: ${(props) => props.mainColor};
  margin-bottom: 0px;
`;

const Price = styled.p`
  color: ${(props) => props.mainColor};
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  margin-top: 7px;
  span {
    color: ${(props) => props.mainColor};
    font-weight: 500;
  }
`;

const StyledModal = styled(Modal)`
  .modal-dialog {
    height: 80%;
    display: flex;
    align-items: center;
  }
  .modal-header {
    border-bottom: 1px solid #c1d9d8;
  }
  .modal-title {
    font-weight: 900;
    font-size: 16px;
    line-height: 24px;
    color: ${(props) => props.mainColor};
  }
  p {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #717171;
    span {
      font-weight: 800;
    }
  }
  .btn-close {
    width: 40px;
    height: 40px;
    background: ${(props) => props.mainColor};
    border-radius: 20px;
    padding: 0px !important;
    top: -12px;
    right: 33px;
    opacity: 1;
  }
  svg {
    z-index: 1000;
    top: -13px;
    right: 33px;
    position: absolute;
  }
`;

const ContinueButton = styled(Button)`
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  width: 241px;
  height: 40px;
  background: ${(props) => props.mainColor};
  border: none;
  border-radius: 102px;
  margin-top: 16px;
  margin-bottom: 0px;
  :hover {
    border: 2px solid ${(props) => props.mainColor};
    color: ${(props) => props.mainColor};
    background: #fff;
  }
`;

const WhiteButton = styled(Button)`
  color: ${(props) => props.mainColor};
  width: 241px;
  height: 40px;
  border: none;
  border-radius: 102px;
  margin-top: 16px;
  margin-bottom: 0px;
`;

const Column = styled(Col)`
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 5px;
  text-align: left;
`;

const StyledHr = styled.hr`
  margin: -2px auto 3px auto;
`;

export const ListItemCart = (props) => {
  const { items } = useSelector((state) => state.shoppingCart);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const mainColor = props.mainColor ? props.mainColor : "#00AC90";

  const handleRemoveItemFromCart = (id) => {
    let currentItem = items.find((item) => item.id === id);
    const newCart = items.filter((item) => item.id !== id);
    let data = newCart;
    console.log(currentItem);
    if (currentItem.howMany > 1) {
      console.log('IF');
      // Original price
      const originalPrice = currentItem.price / currentItem.howMany;
      const newHowMany = currentItem.howMany - 1;
      currentItem.price = originalPrice * newHowMany;
      currentItem.howMany = newHowMany;
      data = [...newCart, currentItem];
      console.log('DATA: ', data);
    }else{
      console.log("else 2")
    }
    // console.log("get total 5: ", data)
    const price = getTotalPrice(data);

    // Update cart
    dispatch(removeItemFromCart(data));

    // Update total price
    dispatch(setTotalPrice(price));

    // Update cart in DB
    dispatch(updateCartDB(data, price));
  };

  // Transform number to currency
  const currency = function (number) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    }).format(number);
  };

  const confirmDelente = () => {
    setShow(false);
    handleRemoveItemFromCart(props.item.id);
  };

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <>
      <Row className='row'>
        <Column xs={10} id='titles'>
          <Title mainColor={"#474A4B"}>{props.item.title}</Title>
        </Column>
        <Column xs={2} className='d-flex justify-content-end'>
          <UilTrashAlt
            onClick={() => handleShow()}
            style={{ cursor: 'pointer', color: mainColor }}
            size={25}
          />
        </Column>
        {props.idClienteLabopat && props.idClienteLabopat !== 0 ? (
          <>
            <Column xs={6} >
              <Price mainColor={mainColor === "#4F90BA" ? "#4D99E5" : "#4D99E5"}>
                Precio de convenio:{' '}
                <span>
                  <br />
                  {currency(props.item.price)} MXN
                </span>
              </Price>

            </Column>
            <Column xs={6} >
              <Price>
                En sucursal:{' '}
                <span style={{textDecoration: 'line-through'}}>
                  <br />
                  {currency(props.item.oldPrice)} MXN
                </span>
              </Price>

            </Column>
            
          </>
        ) : (
          <>
            <Column xs={props.completeScreen ? 12 : 12} >
              <Price  style={props.completeScreen ? {marginBottom: '-10px', marginTop: '-3px',} : {}}>
                En sucursal: <span style={{textDecoration: 'line-through', marginLeft: '32%'}}>{currency(props.item.oldPrice)} MXN</span>
              </Price>
              <Price mainColor={mainColor ? "#4F90BA" : "#0CAC8C"}>
                En línea:{' '} <span style={{marginLeft: '40%'}}>{currency(props.item.price)} MXN</span>
              </Price>
            </Column>
          </>
        )}
      </Row>
      {/*<hr
        style={{
          width: '90%',
          marginLeft: 'auto',
          marginRight: 'auto',
          color: '#C1D9D8',
          marginBottom: '0px',
          marginTop: '0px',
          border: '1px solid #C1D9D8',
        }}
      />*/}
      <StyledModal show={show} onHide={handleClose} mainColor={mainColor}>
        <div onClick={handleClose}>
          <UilTimes color='#fff' style={{ cursor: 'pointer' }} />
        </div>
        <Modal.Header closeButton>
          <Modal.Title>Eliminar estudio</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            ¿Estás seguro de querer eliminar <span>{props.item.title}</span> del
            carrito?
          </p>
          <ContinueButton onClick={confirmDelente} mainColor={mainColor}>Eliminar</ContinueButton>
          <WhiteButton variant='light' onClick={handleClose} mainColor={mainColor}>
            Cancelar
          </WhiteButton>
        </Modal.Body>
      </StyledModal>
    </>
  );
};