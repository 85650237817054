import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import { PostCardForSlider } from './postCardForSlider';
import { getBestScoredPosts } from '../../../actions/blog';

const StyledSlider = styled(Slider)`
  margin-bottom: 50px;
  > .slick-dots li {
    width: 8px;
    height: 8px;
    padding: 0px;
    background: #C4C4C4;
    border-radius: 4px;
  }

  > .slick-dots li button:before {
    display: none;
  }

  > .slick-dots li.slick-active {
    width: 29px;
    background: #30DF99;
  }
`;

export const PostsSlider = (props) => {
  const [myPosts, setMyPosts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const posts = await getBestScoredPosts();
        setMyPosts(posts);
      } catch (error) {
        console.error('Error fetching best scored posts: ', error);
      }
    };

    fetchData();
  }, []);

  return (
    <StyledSlider
      dots={true}
      infinite={true}
      slidesToShow={1}
      slidesToScroll={1}
      autoplay={false}
      autoplaySpeed={4000}
      cssEase={'linear'}
      speed={600}
    >
      {myPosts?.map((post, index) => (
        <PostCardForSlider
          data={post}
          key={index}
        />
      ))}
    </StyledSlider>
  );
};
