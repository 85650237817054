import React from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
const BG_DK = 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2Fbanner-home%2FholidayDkBanner.png?alt=media&token=98e93bc4-74a6-47cc-9f14-437d6c7801d1';
const BG_MB = 'https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2Fbanner-home%2FholidayMbBanner.png?alt=media&token=7d04c496-2dea-4325-b1d7-9a23ac49951c';

const Background = styled.div`
  background: url('${BG_DK}') right center no-repeat #15111A;
  overflow: hidden;
  position: relative;
  @media only screen and (width <= 992px) {
    background: url('${BG_MB}') center bottom / cover no-repeat #15111A;
  }
`;

const Slide = styled(Container)`
  height: 460px;
  display: flex;
  justify-content: start;
  align-items: center;
  div {
    width: 80%;
  }
  p {
    color: var(--Aqua, #30DF99);
    text-align: center;
    font-family: IBM Plex Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 5px;
  }
  h1 {
    color: var(--axionacolors-axiona-offwhite, #F2F7F7);
    text-align: center;
    font-family: IBM Plex Sans;
    font-size: 92px;
    font-style: italic;
    font-weight: 600;
    line-height: normal;
  }
  @media only screen and (min-width: 992px) {
    div {
      max-width: 730px;
    }
  }
  @media only screen and (max-width: 992px) {
    height: 520px;
    div {
      width: 100%;
    }
    h1 {
      font-size: 72px;
    }
  }
`;

export const HolidaysBanner = () => {

  return (
    <Background>
      <Slide>
        <div>
          <p>LABOPAT TE DESEA</p>
          <h1>¡Felices fiestas!</h1>
        </div>
      </Slide>
    </Background>
  );
};
