import React from 'react';
import { Modal, Container, Row, Col, Button } from 'react-bootstrap';
import { MobileSubmenu } from './mobileSubMenu';
import styled from 'styled-components';
import { UilTimes } from '@iconscout/react-unicons';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { PINK_OCTOBER } from '../../helpers/colors';

const LinkContainer = styled.div`
  padding-top: 24px;
  padding-bottom: 24px;
  border-bottom-color: #c1d9d8;
  border-bottom-width: 1px;
  border-bottom-style: solid;
`;

const ButtonMenu = styled.a`
  text-align: inherit;
  color: black;
  background-color: #F1F6F6;
  border: none;
`;

const MenuTitle = styled.span`
  font-family: 'IBM Plex Sans';
  font-size: 16px;
  font-weight: 500;
`;

const ModalScroll = styled(Modal)`
  height: 100vh;
`;

const Title = styled.h1`
  font-weight: 500;
  font-size: 22px;
  line-height: 130%;
  color: #00AD90;
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 15px;
  right: 15px;
`;

const GreenButton = styled.button`
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  width: 180px;
  height: 40px;
  background: #00ad90;
  border: none;
  border-radius: 102px;
  margin-top: 21px;
  margin-bottom: 24px;
  margin-left: auto;
  margin-right: auto;
  transition: .4s all;
  :hover {
    background: #fff;
    color: #00ad90;
  }
`;

export const Menu = (props) => {
  const isLoggedIn = useSelector((state) => state.auth?.uid || false);

  return (
    <ModalScroll show={props.show} fullscreen={true} onHide={props.hide}>
      <Modal.Body
        style={{
          display: props.opened ? 'block' : 'flex',
          paddingTop: 'auto',
          paddingBottom: 'auto',
          background: '#F1F6F6'
        }}
      >
        <Container>
          <CloseButton variant='link' onClick={props.hide}>
            <UilTimes size='32' color='#00AD90' className=' bd-highlight' />
          </CloseButton>
          <Title style={{marginTop: props.opened ? '40px' : '0px'}}>Te damos la bienvenida</Title>
          <GreenButton onClick={()=>window.location.href = isLoggedIn ? "/perfil" : "/iniciar-sesion"}>{isLoggedIn ? "Mi Perfil" : "Iniciar sesión"}</GreenButton>

          {props.menu.map((item, index) => (
            <LinkContainer
            key={index}
            className=' d-flex align-items-left justify-content-left'
            style={{borderTop: index === 0 ? "1px solid #c1d9d8" : "none"}}
            >
              {!item.arrow ? (
                <ButtonMenu
                  variant='light'
                  href={item.link}
                  target={item.link.includes('.com') ? '_blank' :'_self'}
                  style={{ width: '100%', color: item?.name === 'Estudios rosas' ? PINK_OCTOBER : '' }}
                >
                  <MenuTitle>{item.name}</MenuTitle>
                </ButtonMenu>
              ) : (
                <MobileSubmenu
                  menu={item}
                  onHide={props.hide}
                  opened={props.opened}
                  setOpened={e=>props.setOpened(e)}
                />
              )}
            </LinkContainer>
          ))}
        </Container>
      </Modal.Body>
    </ModalScroll>
  );
};
