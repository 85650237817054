
import { Container, ListGroup, Row } from "react-bootstrap";
import { UilArrowLeft, UilInvoice } from '@iconscout/react-unicons';
import { useHistory } from 'react-router-dom';
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useState } from "react";
import qrTest from './qr-test.jpg';

export const RecordDetail = () => {
    const containerStyle = {
        // border: '1px solid red',
        margin: '20px auto 0 auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    };

    const text0Style = {
        fontFamily: 'IBM Plex Sans',
        fontStyle: 'normal',
        fontWeight: '900',
        fontSize: '24px',
        lineHeight: '36px',
        margin: '20px 0 10px 0',
        color: '#00AD90',
    };

    const text1Style = {
        fontFamily: 'IBM Plex Sans',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '24px',
        textAlign: 'center',
        color: '#000000',
    };

    const goBackArrowStyle = {
        display: 'flex',
        justifyContent: 'flex-start',
        textAlign: 'left',
        alignItems: 'left',
        cursor: 'pointer',
        width: '100%',
    };

    const rowContent = {
        width: '100%',
    };

    const contentStyle = {
        height: '764px',
        width: '100%',
        borderRadius: '12px',
        padding: 0,
        marginBottom: '20px',
    };

    const row2Style = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    };

    const textItemBold = {
        fontFamily: 'IBM Plex Sans',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: '800',
        letterSpacing: '0em',
        textAlign: 'left',
    };

    const textItemSubtitle = {
        fontFamily: 'IBM Plex Sans',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '500',
        letterSpacing: '0em',
        textAlign: 'left',
        color: '#3B3B3B',
    };

    const textItemNormal = {
        fontFamily: 'IBM Plex Sans',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '500',
        letterSpacing: '0em',
        textAlign: 'left',
    };

    const linkItem = {
        fontFamily: 'IBM Plex Sans',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '800',
        letterSpacing: '0em',
        textAlign: 'left',
        textDecoration: 'underline',
        color: '#00AD90',
    };

    const cursor = {
        cursor: 'pointer'
    };

    const itemListStyle = {
        fontFamily: 'IBM Plex Sans',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '17px',
        lineHeight: '24px',
        textAlign: 'left',
        color: '#000000',
        padding: 0,
    };

    // const dispatch = useDispatch();
    const history = useHistory();
    const { width } = useWindowDimensions();

    // Toogle
    const [radioValue, setRadioValue] = useState('1');

    const radios = [
        { name: 'Pendientes', value: '1' },
        { name: 'Historial', value: '2' },
    ];

    // Go back arrow
    const goBackLabel = {
        ...text1Style,
        width: '40px',
        margin: width < 680 ? '0px -29px' : '0px -15px',
    };

    const arrowStyle = {
        width: width < 680 ? '20%' : '6%',
        margin: width < 680 ? '0 0 10px -21px' : '0',
    };

    const listItemStyle = {
        padding: '15px',
        cursor: 'pointer',
        background: '#ffffff',
        display: 'flex',
        flexDirection: 'column',
        border: 'none'
    };

    const qrCode = {
        borderRadius: '12px',
        backgroundColor: '#FFFFFF',
        textAlign: 'center'
    };

    return (
        <Container style={containerStyle}>
            <Row style={goBackArrowStyle} onClick={() => history.push('/perfil')}>
                <UilArrowLeft
                    size="26"
                    color="#00AD90"
                    style={arrowStyle}
                />
                <p style={goBackLabel}>
                    Regresar
                </p>
            </Row>
            <Row style={rowContent}>
                <span className='mb-2' style={textItemNormal}>
                    Examen general de orina
                </span><br />
            </Row>
            <Row style={{
                ...rowContent,
                display: 'flex',
                justifyContent: 'right',
                alignItems: 'right',
                flexDirection: 'row'
            }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '46%'
                    }}
                >
                    <span className='mb-2' style={textItemBold}>
                        Total a pagar:
                    </span>

                    <span className='mabo-8px' style={textItemBold}>
                        $999,999 MXN
                    </span>
                </div>
                <div
                    style={{
                        width: '15%',
                        padding: '10px',
                    }}
                >
                    <UilInvoice
                        size="45"
                        color="#000000"
                    />
                </div>
            </Row>
            <Row style={{ ...rowContent, marginTop: '30px' }}>
                <ListGroup style={itemListStyle}>
                    <ListGroup.Item style={listItemStyle}>
                        <span className='mabo-5px' style={textItemBold}>
                            LABOPAT - Palmas Plaza
                        </span>
                        <span className='mabo-8px' style={textItemSubtitle}>
                            Local 20, Palmas Plaza, Av Zeta del Cochero 403...
                        </span>
                        <span className='mabo-8px' style={textItemNormal}>
                            Martes 28 de diciembre del 2021 a las 9:00 AM
                        </span>
                    </ListGroup.Item>
                    <ListGroup.Item style={listItemStyle}>
                        <div style={{ ...qrCode, ...row2Style, padding: '25px' }}>
                            <span className='mb-2' style={textItemBold}>
                                Código de tu compra
                            </span>
                            <span className='mb-2' style={{ ...textItemNormal }}>
                                Muestra este código en la caja para
                                acumular los puntos de tu estudio.
                            </span>
                            <img src={qrTest} alt="qr-code" />
                            <span className='mb-2' style={{ ...textItemNormal }}>
                                Es necesario aumentar el brillo de la pantalla
                            </span>
                        </div>
                    </ListGroup.Item>
                </ListGroup>
            </Row>
        </Container>
    )
}
