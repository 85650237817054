import { types } from "../types/types";

const initialState = {};

export const registerReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.setRegisterTmp:
            return action.payload;

        case types.resetRegisterTmp:
            return initialState;

        default:
            return state;
    }

}