import React, { useEffect } from 'react';
import { useState } from 'react';
import { TableResults } from './resultsTable';
import { Result } from './Result';

export const UserResults = (props) => {
  const [lastResult, setLastResult] = useState([]);
  console.log(props.data);

  useEffect(() => {
    setLastResult(props.data[props.data.length -1]);
  }, []);

  return(
    <>
      {props.tab === 0 && (
        <div>
          <Result data={lastResult} />
        </div>
      )}
      {props.tab === 1 && (
        <>
          <TableResults data={props.data} tab={props.tab} isDoctor={props.isDoctor} />
        </>
      )}
    </>
  );
}