import { types } from "../types/types";

export const spinnerReducer = (state = false, action) => {
    switch (action.type) {
        case types.showSpinner:
            return action.payload;
        default:
            return state;
    }
}
