import React from "react";
import { UilDownloadAlt } from '@iconscout/react-unicons';
import styled from "styled-components";
import { Container } from "react-bootstrap";

const Background = styled.div`
  background: url("https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fcurso-alta-especialidad%2FbackContacto.png?alt=media&token=85db78df-c53d-4b83-82ef-f503574fd600") right center/contain no-repeat;
  @media only screen and (max-width: 992px) {
    background: none;
  }
  @media only screen and (max-width: 768px) {
    .container {
      padding-left: 24px;
    }
  }
`;

const TextDiv = styled.div`
  padding-top: 42px;
  padding-bottom: 82px;
  font-family: 'Faustina' !important;
  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    text-transform: uppercase;
    color: #C4C4C4;
  }
  h1 {
    font-family: 'Faustina';
    font-style: normal;
    font-weight: 700;
    font-size: 33px;
    line-height: 45px;
    color: #00AD90;
  }
  h3 {
    margin-bottom: 25px;
  }
  h4 {
    font-family: 'Faustina';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 24px;
    color: #474A4B;
  }
  a {
    font-size: 18px;
    text-decoration: underline;
    color: #474A4B;
  }
  h5 {
    font-family: 'Faustina';
    font-style: italic;
    font-weight: 800;
    font-size: 22px;
    line-height: 30px;
    color: #474A4B;
    margin-bottom: 26px;
    @media only screen and (max-width: 768px) {
      font-weight: 600;
      font-size: 17px;
      line-height: 20px;
    }
  }
  p {
    margin-bottom: 24px;
    font-size: 18px;
  }
`;

const Download = styled.a`
  font-family: 'IBM Plex Sans';
  color: #FFF !important;
  text-decoration: none !important;
  padding: 12px 20px 12px 12px;
  font-size: 15px !important;
  background: #F9AD62;
  margin-bottom: 233px;
  transition: all ease .5s;
  :hover {
    color: #FFF;
    background: #e3964b;
  }
`;

const StyledImg = styled.img`
  @media only screen and (min-width: 992px) {
    display: none;
  }
  max-width: 100%;
`;

export const Contact = () => {
  return (
    <Background id="contact">
      <Container>
        <TextDiv>
          <h2>CONTACTO</h2>
          <h1>Contacto</h1>
          <h3>Jefatura de Educación y carrera</h3>
          <a className='link'
            href='mailto:academia@labopat.com'
            target='_blank'
            rel='noreferrer'
          >academia@labopat.com</a>
          <p>T. 22 12 31 1313 ext. 122</p>
          <h5>Consulta la convocatoria y el programa.</h5>
          <Download
            target='_blank' 
            rel='noreferrer'
            href="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fcurso-alta-especialidad%2FConvocatoria_2023.pdf?alt=media&token=9e05ae67-4b43-4542-9108-558d9ca1b98b"
          >
            <UilDownloadAlt/> DESCARGA LA CONVOCATORIA</Download><br/><br/><br/>
          <Download
            target='_blank' 
            rel='noreferrer'
            href="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fcurso-alta-especialidad%2FCurso%20patologia.pdf?alt=media&token=ecddd25e-5998-4638-9926-3c85ede533bf"
          >
            <UilDownloadAlt/> DESCARGA EL PROGRAMA</Download>
        </TextDiv>
      </Container>
      <StyledImg src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fcurso-alta-especialidad%2FbackContacto.png?alt=media&token=85db78df-c53d-4b83-82ef-f503574fd600"/>
    </Background>
  )
};