import { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import styled from 'styled-components';


const DigitInput = styled(Form.Control)`
  width: 45px;
  height: 60px;
  background: #F6F6F6;
  border-radius: 12px;
  font-size: 35px;
  text-align: center;
  margin-right: 10px;
  border: none;
  @media only screen and (max-width: 768px) {
    width: 40px;
    height: 45px;
    margin-right: 7px;
    font-size: 16px;
  }
`;

const GreenButton = styled(Button)`
  width: 100%;
  max-width: 358px;
  height: 40px;
  border: none;
  background: #00AC90;
  border-radius: 102px;
  font-weight: 800;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DigitsDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: space-between;
  margin: 15px auto 0 auto;
`;

export const CodeVerificationModal = ({ resolve, handleClose }) => {
  const [digit1, setDigit1] = useState('');
  const [digit2, setDigit2] = useState('');
  const [digit3, setDigit3] = useState('');
  const [digit4, setDigit4] = useState('');
  const [digit5, setDigit5] = useState('');
  const [digit6, setDigit6] = useState('');
  const refDigit1 = document.getElementById('digit-1');
  const refDigit2 = document.getElementById('digit-2');
  const refDigit3 = document.getElementById('digit-3');
  const refDigit4 = document.getElementById('digit-4');
  const refDigit5 = document.getElementById('digit-5');
  const refDigit6 = document.getElementById('digit-6');
  const digitsEvent = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];

  useEffect(() => {
    if (document.getElementById('digit-1'))
      document.getElementById('digit-1').focus();
  }, []);

  const handleResolve = () => {
    const code = `${digit1}${digit2}${digit3}${digit4}${digit5}${digit6}`;
    handleClose();
    resolve(code);
  };

  const digitInput = (value, reference, referenceBack, id, handleRef) => (
    <DigitInput
      type={"password"}
      placeholder="-"
      value={value}
      onKeyUp={(e) => {
        if (reference && digitsEvent.includes(e.key))
          reference.focus();
        else if (referenceBack && e.key === 'Backspace')
          referenceBack.focus();
      }}
      id={id}
      maxLength={1}
      onChange={({ target }) => handleRef(target.value)}
    />
  );

  return (
    <>
      <DigitsDiv>
        {digitInput(digit1, refDigit2, null, 'digit-1', setDigit1)}
        {digitInput(digit2, refDigit3, refDigit1, 'digit-2', setDigit2)}
        {digitInput(digit3, refDigit4, refDigit2, 'digit-3', setDigit3)}
        {digitInput(digit4, refDigit5, refDigit3, 'digit-4', setDigit4)}
        {digitInput(digit5, refDigit6, refDigit4, 'digit-5', setDigit5)}
        {digitInput(digit6, null, refDigit5, 'digit-6', setDigit6)}
      </DigitsDiv>
      <br />
      <p style={{textAlign: 'center', color: '#00ac90'}}>Si no recibes el mensaje de verificación, intenta con otra opción.</p>
      <GreenButton
        variant="primary"
        onClick={handleResolve}
      >
        <b>Continuar</b>
      </GreenButton>
      <br />
    </>
  )
}
