import React from "react";
import { OurServices } from "./ourServices";
import { VideoAboutUs } from "./videoAboutUs";
import { MisionVision } from "./misionVision";
import MapComponent from "./mapComponent";
import OurValues from "./ourValues";
import ServicesNetwork from "./servicesNetwork";
import { Doubts } from "../doubts/doubts";
import { HelmetSEO } from "../../helpers/HelmetSEO";

const AboutUs = () => {
  return (
    <div style={{backgroundColor: "#F2F7F9"}}>
      <HelmetSEO
        title='Servicios de Laboratorio de alta especialidad | Labopat'
        description='Obtén descuentos exclusivos en nuestra web. Conoce nuestros servicios de laboratorio como rayos X, ultrasonidos y más. Regístrate ahora en Labopat.'
      />
      <VideoAboutUs />
      <OurServices />
      <MisionVision />
      <OurValues />
      <ServicesNetwork />
      <MapComponent/>
      <Doubts/>
    </div>
  )
};

export default AboutUs;