import React from 'react';
import ReactDOM from 'react-dom';
import { Toast } from './toast'; // Import your Toast component

const handleAlert = (type, message, title = "") => {
    const container = document.createElement('div');
    document.body.appendChild(container);

    const removeContainer = () => {
        ReactDOM.unmountComponentAtNode(container);
        document.body.removeChild(container);
    };

    ReactDOM.render(
        <Toast
            message={message}
            type={type}
            title={title}
            onClose={removeContainer}
        />,
        container
    );
};

export default handleAlert;