import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { handleAddItemToCart } from "../../actions/shoppingCart";
import { UilAngleDown, UilAngleUp } from "@iconscout/react-unicons";
import { studyInCart } from "../../helpers/cart";
import handleAlert from "../../helpers/handleAlert";

const StudyContainer = styled.div`
  justify-content: center;
  position: relative;
  background: #fff;
  border: 2px solid transparent;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(45deg, #fff, #fff),
    linear-gradient(
      45deg,
      #5097e3 -97.58%,
      #0cac8c -34.75%,
      #30df99 77.36%,
      #cef719 119.25%,
      #f5ec1d 138.96%
    );
  box-shadow: 0px 4px 4px #f6f6f6;
  width: 100%;
  border-radius: 16px;
  padding: 0 24px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  > svg:hover {
    cursor: pointer;
  }
  @media only screen and {
    max-width: 1200px;
  }
   {
    padding: 18px 12px;
  }
`;

const Tag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 24px;
  border-radius: 12px;
  font-size: 11px;
  margin: 0px auto;
  margin-top: -12px;
  margin-bottom: -12px;
  @media only screen and {
    min-width: 768;
  }
   {
    margin-left: 0px;
  }
`;

const Title = styled.h3`
  text-align: left;
  margin-top: 25px;
  > a {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: #474a4b;
    :hover {
      color: #474a4b;
      text-decoration: underline;
    }
  }
`;

const AddButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin-left: 5%;
  height: 40px;
  background: none;
  font-size: 16px;
  font-weight: 800;
  color: #30df99;
  border-radius: 16px;
  border: 2px solid #30df99;
  padding: 8px 26px;
  margin-bottom: 8px;
  transition: all 0.4s ease;
  :hover {
    color: #fff;
    transition: all 0.4s ease;
    background: #30df99;
  }
  @media only screen and (max-width: 390px) {
    font-size: 10px;
  }
`;

const PayButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin-left: 5%;
  height: 40px;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #fff;
  border-radius: 16px;
  padding: 8px 26px;
  margin-top: 8px;
  border: 2px solid #f9ad62;
  background: linear-gradient(
    45deg,
    #5097e3 -97.58%,
    #0cac8c -34.75%,
    #30df99 77.36%,
    #cef719 119.25%,
    #f5ec1d 138.96%
  );
  border: 2px solid #30df99;
  :hover {
    color: #30df99;
    transition: all 0.4s ease;
    background: #fff;
    border: 2px solid #30df99;
  }
`;

const Prices = styled.div`
  border-radius: 5px;
  width: 100%;
  margin: 8px auto 18px auto;
  padding-top: 9px;
  display: flex;
  justify-content: right;

  p {
    margin-bottom: 7px;
    text-align: right;
  }
  #linea {
    margin-top: 2px;
    font-weight: 800;
    font-size: 15px;
    line-height: 22px;
    color: #0cac8c;
    display: flex;
    @media only screen and (min-width: 768px) {
      margin-left: 16px;
    }
  }
  #sucursal {
    margin-top: 2px;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: #6a6a6a;
    display: flex;
  }
`;

const Detail = styled(Row)`
  padding: 16px 18px;
  background: #f2f7f9;
  border-radius: 8px;
  > div h4 {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #000;
    margin-bottom: 6px;
  }
  > div p {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #6a6a6a;
    margin-bottom: 10px;
  }
`;

export const StudyL = (props) => {
  const isDoctor =
    useSelector((state) => state.user?.data?.data?.profile?.type) === 1;
  const { items } = useSelector((state) => state.shoppingCart);
  const dispatch = useDispatch();
  const [viewDetail, setViewDetail] = useState(false);
  const history = useHistory();
  // We get window width
  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  let isMobile = width <= 1200;

  const addStudy = () => {
    const exists = studyInCart(props.data, items);
    if (exists?.exists) {
      handleAlert("warning", exists?.message, "Estudio/paquete ya agregado");
    } else {
      dispatch(handleAddItemToCart(props.data));
    }
  };

  const payStudy = () => {
    const exists = studyInCart(props.data, items);
    if (exists?.exists) {
      handleAlert("warning", exists?.message, "Estudio/paquete ya agregado");
      history.push("/checkout");
    } else {
      dispatch(handleAddItemToCart(props.data));
      history.push("/checkout");
    }
  };

  // Transform number to currency
  const currency = function (number) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    }).format(number);
  };

  const callToSchedule = () => {
    window.location.href =
      `https://wa.me/522212311313?text=Quiero agendar una cita para ` +
      props.data.title;
  };

  return (
    <>
      <div className="col-12">
        <StudyContainer data-aos="fade-up">
          {props.data.tag ? (
            <Tag
              style={{
                backgroundColor: props.data.tag.background,
                color: props.data.tag.color,
              }}
            >
              {props.data.tag.content}
            </Tag>
          ) : null}
          <Row>
            {isMobile ? (
              <>
                <Col xs={12} style={{ paddingRight: "0px" }}>
                  <Title>
                    <a href={`/estudio/${props.data.link}`}>{props.data.title}</a>
                  </Title>
                  <Prices>
                    {props.idClienteLabopat && props.idClienteLabopat !== 0 ? (
                      <>
                        <Col xs={6} style={{ paddingRight: "0px" }}>
                          <p id="sucursal">Precio de convenio:</p>
                        </Col>
                        <Col xs={6} style={{ paddingLeft: "0px" }}>
                          <p id="price-sucursal" className="mb-3">
                            {currency(props.data.price)} MXN
                          </p>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col xs={10} style={{ paddingRight: "0px" }}>
                          <p id="sucursal">
                            En sucursal {currency(props.data.oldPrice)} MXN
                          </p>
                          {props.data.categoria !== 10 &&
                            props.data.categoria !== 8 &&
                            props.data.ventaOnline !== 0 && (
                              <p id="linea">
                                En línea {currency(props.data.price)} MXN
                              </p>
                            )}
                        </Col>
                      </>
                    )}
                  </Prices>
                </Col>
                <Col
                  className="d-flex justify-content-center align-items-center"
                  style={{ flexDirection: "column" }}
                >
                  {(props.data.categoria !== 10 &&
                    props.data.categoria !== 8 &&
                    props.data.ventaOnline !== 0) ||
                  isDoctor ? (
                    <>
                      <AddButton style={{fontSize:"14px"}} onClick={() => addStudy()}>Agregar</AddButton>
                      <PayButton onClick={() => payStudy()}>
                        Pagar
                      </PayButton>{" "}
                    </>
                  ) : (
                    <AddButton style={{fontSize:"14px"}} onClick={() => callToSchedule()}>
                      Llamar para agendar
                    </AddButton>
                  )}
                </Col>
                <Col xs={12}>
                  <p
                    style={{
                      color: "#C4C4C4",
                      textAlign: "center",
                      cursor: "pointer",
                      marginBottom: "10px",
                    }}
                    onClick={() => setViewDetail(!viewDetail)}
                  >
                    <span style={{ textDecoration: "underline" , color: "#C4C4C4"}}>
                      {viewDetail ? "Ocultar" : "Ver"} detalles
                    </span>{" "}
                    {viewDetail ? (
                      <UilAngleUp size="28" />
                    ) : (
                      <UilAngleDown size="28" />
                    )}
                  </p>
                </Col>
              </>
            ) : (
              <>
                <Col lg={12} xl={5}>
                  <Title>
                    <a href={`/estudio/${props.data.link}`}>{props.data.title}</a>
                  </Title>
                  <p
                    style={{
                      color: "#C4C4C4",
                      textAlign: "left",
                      cursor: "pointer",
                      marginBottom: "10px",
                    }}
                    onClick={() => setViewDetail(!viewDetail)}
                  >
                    <span style={{ textDecoration: "underline" }}>
                      {viewDetail ? "Ocultar" : "Ver"} detalles
                    </span>{" "}
                    {viewDetail ? (
                      <UilAngleUp size="28" />
                    ) : (
                      <UilAngleDown size="28" />
                    )}
                  </p>
                </Col>
                <Col
                  lg={12}
                  xl={5}
                  className="d-flex justify-content-center align-items-center"
                >
                  <Prices style={{ marginTop: isMobile && "-20px" }}>
                    {props.idClienteLabopat && props.idClienteLabopat !== 0 ? (
                      <>
                        <Col xs={4} style={{ paddingRight: "0px" }}>
                          <p id="sucursal">Precio de convenio:</p>
                        </Col>
                        <Col xs={4} style={{ paddingLeft: "0px" }}>
                          <p id="price-sucursal" className="mb-3">
                            {currency(props.data.price)} MXN
                          </p>
                        </Col>
                      </>
                    ) : (
                      <>
                        <p id="sucursal">
                          En sucursal {currency(props.data.oldPrice)} MXN
                        </p>
                        {props.data.categoria !== 10 &&
                          props.data.categoria !== 8 &&
                          props.data.ventaOnline !== 0 && (
                            <p id="linea">
                              En línea {currency(props.data.price)} MXN
                            </p>
                          )}
                      </>
                    )}
                  </Prices>
                </Col>
                <Col
                  xl={2}
                  className="d-flex justify-content-center align-items-center"
                  style={{ flexDirection: "column" }}
                >
                  {(props.data.categoria !== 10 &&
                    props.data.categoria !== 8 &&
                    props.data.ventaOnline !== 0) ||
                  isDoctor ? (
                    <>
                      <AddButton onClick={() => addStudy()}>Agregar</AddButton>
                      <PayButton onClick={() => payStudy()}>Pagar</PayButton>
                    </>
                  ) : (
                    <AddButton
                      onClick={() => callToSchedule()}
                      style={{ marginTop: "5px", fontSize: "14px" }}
                    >
                      Llamar para agendar
                    </AddButton>
                  )}
                </Col>
              </>
            )}
          </Row>
          {viewDetail && (
            <Detail>
              {props.data.tEntrega && (
                <Col sm={12} md={6} lg={4} xl={3}>
                  <h4>Tiempo de entrega</h4>
                  <div
                    dangerouslySetInnerHTML={{ __html: props.data.tEntrega }}
                  ></div>
                </Col>
              )}
              {props.data.muestra && (
                <Col sm={12} md={6} lg={4} xl={3}>
                  <h4>Tipo de muestra</h4>
                  <div
                    dangerouslySetInnerHTML={{ __html: props.data.muestra }}
                  ></div>
                </Col>
              )}
              {props.data.indicaciones && (
                <Col sm={12} md={6} lg={4} xl={3}>
                  <h4>Indicaciones</h4>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: props.data.indicaciones,
                    }}
                  ></div>
                </Col>
              )}
              {props.data.info && (
                <Col sm={12} md={6} lg={4} xl={3}>
                  <h4>Información</h4>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: props.data.info,
                    }}
                  ></div>
                </Col>
              )}
            </Detail>
          )}
        </StudyContainer>
      </div>
    </>
  );
};
