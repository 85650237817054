import { Modal } from "react-bootstrap";
import { useState } from 'react';
import { CodeVerificationModal } from "./CodeVerificationModal";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

const titleModal = {
    fontFamily: 'IBM Plex Sans',
    fontStyle: 'normal',
    fontWeight: '900',
    fontSize: '19px',
    textAlign: 'left',
    color: '#000000',
    marginTop: '4px',
    paddingRight: '17px',
};

const normalText = {
    fontFamily: 'IBM Plex Sans',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    textAlign: 'left',
    color: '#000000',
    margin: '0 21px 0 15px',
};

export const ModalCode = ({ show, classSize, resolve }) => {
    const [showVal, setShow] = useState(show);

    const handleClose = () => setShow(!showVal);

    const { width } = useWindowDimensions();

    return (
        // <Modal
        //     show={showVal}
        //     onHide={handleClose}
        //     backdrop="static"
        //     keyboard={false}
        //     dialogClassName={`modal-verification-code${width < 680 ? '-responsive' : ''}`}
        // >
        //     <Modal.Header closeButton style={{ borderBottom: 'none', marginBottom: 0 }}>
        //         <span style={titleModal}>Ingresa el código que te enviamos</span>
        //     </Modal.Header>
        //     <span style={normalText}>Te enviamos un código a tu celular para verificar tu cuenta.</span>
        //     <Modal.Body
        //         style={{
        //             paddingTop: 0,
        //             marginTop: 0,
        //         }}
        //     >
                <CodeVerificationModal resolve={resolve} handleClose={handleClose} />
        //     </Modal.Body>
        // </Modal>
    );
}