import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import { UilArrowCircleLeft } from '@iconscout/react-unicons';
import { UilArrowCircleRight } from '@iconscout/react-unicons';
import { TestCard } from '../studies/testCard';
import { useSelector } from "react-redux";

const StyledSlider = styled(Slider)`
    margin-bottom: 50px;
  > .slick-dots li {
    width: 8px;
    height: 8px;
    padding: 0px;
    background: #0A7A71;
    border-radius: 4px;
  }

  > .slick-dots li button:before {
    display: none;
  }

  > .slick-dots li.slick-active {
    width: 29px;
    background: #fff;
  }

  > svg {
    @media only screen and (max-width: 767px) {
      display: none !important;
    }
  }
`;

export const StudiesSlider = (props) => {
    // const [index, setIndex] = useState(0);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const idClienteLabopat = useSelector(
      (state) => state.user?.data?.data?.profile?.idClienteLabopat
    );
  
    function handleWindowSizeChange() {
      setScreenWidth(window.innerWidth);
    }
    useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
      };
    }, []);

  
    function PrevArrow(props) {
      const { className, style, onClick } = props;
      return (
        <UilArrowCircleLeft
          className={className}
          style={{ ...style, display: 'block', color: '#00AD90' }}
          onClick={onClick}
        />
      );
    }
    function NextArrow(props) {
      const { className, style, onClick } = props;
      return (
        <UilArrowCircleRight
          className={className}
          style={{ ...style, display: 'block', color: '#00AD90' }}
          onClick={onClick}
        />
      );
    }
    // const handleSelect = (selectedIndex, e) => {
    //   setIndex(selectedIndex);
    // };
  
    return (
        <StyledSlider
          dots={false}
          infinite={true}
          slidesToShow={
            (screenWidth > 1199 && props?.studies?.length >= 4)
              ? 3
              : (screenWidth > 991 && props?.studies?.length >= 3)
              ? 3
              : (screenWidth > 767 && props?.studies?.length >= 2)
              ? 2
              : 1
          }
          slidesToScroll={
            (screenWidth > 991 && props?.studies?.length >= 3)
              ? 3
              : (screenWidth > 767 && props?.studies?.length >= 2)
              ? 2
              : 1
          }
          autoplay={true}
          autoplaySpeed={4000}
          cssEase={'linear'}
          speed={600}
          prevArrow={<PrevArrow />}
          nextArrow={<NextArrow />}
        >
          {props?.studies?.map((test, index) => (
            <TestCard idClienteLabopat={idClienteLabopat} data={test} key={index} highlight={true}/>
          ))}
        </StyledSlider>
    );
  };