import React from 'react';
import ContactForm from "../components/contact/ContactForm";
import { HelmetTitle } from '../helpers/HelmetTitle';

export const Contact = () => {
    return (
        <>
            <HelmetTitle
                title="Contacto"
                name="description"
                content="content"
            />
            <ContactForm />
        </>
    );
}
