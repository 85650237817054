import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import { Pay } from './pay';
import { Results } from './results';
import { Scan } from './scan';
import { Thanks } from './thanks';
import { Welcome } from './welcome';
import { QRreader } from './QRreader';
import { useSelector } from 'react-redux';
import { getNL, setCurrentCheckin } from '../../actions/checkin';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { Checked } from './checked';
import { Loader } from '../general/spinner';

const Background = styled.div`
  background: #e0ecec;
  @media only screen and (min-width: 768px) {
    padding-top: 16px;
    padding-bottom: 40px;
  }
  > .container {
    background: #fff;
    max-width: 1120px;
    @media only screen and (min-width: 768px) {
      border-radius: 16px;
      padding-top: 24px;
    }
  }
`;

export const CheckIn = () => {
  const { currentCheckin } = useSelector((state) => state);
  const [checked, setChecked] = useState(false); // Para cuando ya se hizo checkin de esa cita
  const [scanned, setScanned] = useState(false);
  const [payedNow, setPayedNow] = useState(false);
  const [qrInfo, setQrInfo] = useState('');
  const [data, setData] = useState({
    pay: currentCheckin?.solicitud?.fase !== 0,
    sampleTaked: false,
  });
  const [loading, setLoading] = useState(false);

  const valores = window.location.search;
  const urlParams = new URLSearchParams(valores);
  const dispatch = useDispatch();

  const handleScan = (data, idSol) => {
    window.location.href = `/checkin?idSol=${idSol}`;
  };

  const handlePay = async () => {
    completeCheckinClientes(currentCheckin?.solicitud?.driveThru);
    console.log('payyy');
    setData({
      ...data,
      pay: true,
    });
    setPayedNow(true);
  };

  useEffect(() => {
    console.log('data', data);
  }, [data]);

  useEffect(() => {
    if (!currentCheckin) {
      setScanned(false);
    }
  }, [currentCheckin]);

  useEffect(()=>{
    var idSol = urlParams.get('idSol');
    if(idSol){
      getData(idSol);
    }
  },[])

  const getData = async (result) => {
    setLoading(true);
    try {
      const nlResult = await getNL(result);
      if (nlResult?.data) {
        console.log("🚀 ~ file: CheckIn.jsx ~ line 84 ~ getData ~ nlResult?.data", nlResult?.data)
        dispatch(setCurrentCheckin(nlResult.data));
        console.log('Pagado??? ', nlResult.data.drive.fase === 0);
        setLoading(false);
        if(nlResult?.data.drive?.checkIn === 0){
          setData({
            pay: nlResult.data.drive.fase === 0,
            sampleTaked: false,
          });
          setScanned(!scanned);
        }else{
          setChecked(true);
        }
      } else {
        setQrInfo('No se encontró checkin');
      }
    } catch (error) {
      setLoading(false);
      setQrInfo('No se encontró checkin');
    }
  };

  const completeCheckinClientes = async (driveThruID) => {
    setLoading(true);
    try {
      const result = await axios.get(`https://clienteslabopat.com/set-checkin?id=${driveThruID}`);
      if (result?.ok) {
        setLoading(false);
        console.log("🚀 ~ file: welcome.jsx ~ line 114 ~ completeCheckinClientes ~ result.data", result.data);
      } else {
        console.log("Error al completar checkin");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  return (
    <Background>
      <Container
        style={{ paddingLeft: '16px', paddingRight: '16px', margin: 'auto' }}
      >
        {!scanned && !checked ? (
          <Scan onScan={handleScan} qrInfo={qrInfo} setChecked={setChecked}/>
        ) : (
          checked ? (
            <Checked/>
          ) : (
            !data.pay ? (
              <Pay onPay={handlePay} />
            ) : payedNow ? (
              <Thanks
                isDoctor={false}
                pay={'web'}
                total={1000}
                otherPerson={false}
                firstTimeDoctor={false}
                onContinue={async() => {
                  await completeCheckinClientes(currentCheckin.solicitud?.driveThru);
                  setPayedNow(false);
                }}
              />
            ) : data.sampleTaked ? (
              <Results payedNow={payedNow} />
            ) : (
              <Welcome payedNow={payedNow} />
            )
          )
        )}
      </Container>
      {loading && <Loader show={loading} />}
    </Background>
  );
};
