import React from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { TEXT_BLACK } from "../../helpers/colors";

const MenuContainer = styled.div`
  background-color: white;
  border: 1px solid #c1d9d8;
  box-shadow: 0px 44px 90px #c4cbd2;
  position: relative;
  padding: 9px 12px;
  .col-md-9 {
    flex-direction: column;
    p {
      color: ${TEXT_BLACK};
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.14px;
      margin-bottom: 0;
    }
  }
`;

const Title = styled.span`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
  color: #00ad90;
`;

const ItemCol = styled(Col)`
  padding: 5px 10px 5px 10px;
  .row {
    width: 100%;
    border-radius: 10px;
    border: 1px solid #E0ECEC;
    padding: 4px 0px;
  }
  a {
    width: 100%;
    display: flex;
    height: 95px;
    transition: all 0.3s ease;
    :hover {
      background: #EFEFEF40;
    }
  }
  img {
    height: 60px;
  }
`;

export const DesktopMenu = (props) => {
  document.addEventListener("click", function (event) {
    if (event.target.id !== "botonMenu" && event.target.id !== "myMenu") {
      // document.getElementById('myMenu').style.display = 'none';
      props.onHide();
    }
  });

  return (
    <Row className="mt-1" style={{position: 'absolute', top: '-4px', width: '100%', maxWidth: '1200px', left: '50%', transform: 'translate(-50%, 0)'}}>
      <Col md={{ span: 12 }}>
        <MenuContainer id="myMenu">
          {/* <CloseButton onClick={props.onHide}><UilTimes/></CloseButton> */}
          <Row className="flex">
            {props.menu.submenu.map((item, index) => (
              <ItemCol md={4} key={index}>
                <a href={item.link}>
                  <Row onClick={()=>window.location.href = item.link}>
                    <Col md={3} className="text-center d-flex align-items-center justify-content-center">
                      <img
                        src={item.icon}
                        className="img-fluid"
                        alt="category-icon"
                      />
                    </Col>
                    <Col md={9} className="d-flex justify-content-center">
                      <Title>{item.name}</Title>
                      {/* <p>{item.description}</p> */}
                      {/* <Description>{item.description}</Description> */}
                    </Col>
                  </Row>
                </a>
              </ItemCol>
            ))}
          </Row>
          {/* <Row
            style={{
              backgroundColor: '#007C71',
              marginLeft: '0px',
              marginRight: '0px',
            }}
            className='justify-content-center'
          >
            <Col md={4} className='text-center mt-3 mb-3'>
              <Link>Ver todos los estudios y servicios</Link>
            </Col>
          </Row> */}
        </MenuContainer>
      </Col>
    </Row>
  );
};
