import React, { useEffect } from "react";
import styled from "styled-components";
import { Row, Button } from "react-bootstrap";
import { Menu } from "../menu/mobile";
import { UilTimes } from "@iconscout/react-unicons";
import { SearchModal } from "../login/modals/SearchModal";
import useAnalyticsEventTracker from "../../analytics/useAnalyticsEventTracker";

const menu = "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2Fmenu%2Fmenu.png?alt=media&token=5fa822a8-58e4-4e78-8c49-3561e63941c6";
const logoB = "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2Flogos%2Flogo1.png?alt=media&token=dffc6866-cf6d-4c14-a494-60ceaf3f3219";
const searchLogo = "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2Fecommerce%2FUilSearchAlt.png?alt=media&token=86c6da69-77b9-4c8a-ae2c-611fbd49e1f8";

const Mobile = styled(Row)`
  display: none;
  background: #ffffff;
  padding-top: 1rem !important;
  @media only screen and (max-width: 768px) {
    display: block;
  }
  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

const TopBar = styled.div`
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledButton = styled(Button)`
  background: #00AC90;
  border-radius: 50%;
  width: 40px;
  height: 40px;
`;

export const MobileMenu = (props) => {
  // For Google Analytics
  const gaEventTracker = useAnalyticsEventTracker("Navbar");

  useEffect(() => {
    const modalSearch = document.getElementById("modalSearch");
    const handleOpenModalSearch = () => {
      modalSearch.style.display = "block";
    };
    if (props.search?.length > 0) {
      handleOpenModalSearch();
    }
  }, [props.search]);

  const handleOpenMenu = () => {
    props.setShowMenu(true);
    gaEventTracker("abrir-buscador");
  }

  return (
    <Mobile style={{ display: 'block' }}>

      {/* Top bar with burguer, logo and searcher button */}
      <TopBar>
        {/* Open MENU button */}
        <Button variant="link" size="sm" onClick={handleOpenMenu}>
          <img src={menu} width={"40px"} height={"40px"} alt="menu" />
        </Button>

        {/* Labopat logo */}
        <a href="/">
          <img src={logoB} alt="labopat logo" style={{ width: "135px" }} />
        </a>

        {/* Open/close SEARCHER button */}
        {props.showSearcher ? (
          <StyledButton variant="link" size="sm" onClick={props.hideSearch}>
            <UilTimes size="25" color="#fff" style={{ marginLeft: "-2px", marginTop: "-1px" }}/>
          </StyledButton>
        ) : (
          <Button variant="link" size="sm" onClick={props.showSearch}>
            <img src={searchLogo} width={"40px"} height={"40px"} alt="searchLogo" />
          </Button>
        )}

      </TopBar>

      {/* Mobile MENU */}
      <Menu
        show={props.showMenu}
        hide={() => props.setShowMenu(false)}
        menu={props.menu}
        opened={props.opened}
        setOpened={(e) => props.setOpened(e)}
      />

      {/* Search modal */}
      <SearchModal search={props.search} />
    </Mobile>
  );
};
