import { types } from "../types/types";

export const auxReducer = (state = { bannerOpen: true }, action) => {
    switch (action.type) {
        case types.aux:
            return {
                ...state,
                aux: action.payload,
            };
        case types.offerBanner:
            return {
                ...state,
                bannerOpen: action.payload
            };
        default:
            return state;
    }

}