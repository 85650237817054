import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Container, Dropdown } from 'react-bootstrap';
import { UilListUl, UilApps } from '@iconscout/react-unicons';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { TestCard } from '../../components/studies/testCard';
import { StudyL } from '../../components/studies/studyL';
import { Loader } from '../../components/general/spinner';

const Title = styled.h4`
  font-weight: 500;
  font-size: 38px;
  line-height: 56px;
  color: #45494A;
`;

const FilterButton = styled(Button)`
  background-color: white;
  border: none;
  border: 1px solid #e9e9e9;
  box-shadow: 0px 4px 4px #f6f6f6;
  border-radius: 12px;
`;

const Description = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  text-align: center;

  color: #717171;
`;

const ShowMoreButton = styled.button`
  width: 297px;
  height: 48px;
  background: #6fbea0;
  border: none;
  border-radius: 24px;
  font-weight: 800;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
  :hover {
    background: #81d6b6;
  }
`;

export const Studies = (props) => {
  const [items, setItems] = useState([]);
  const [itemsToShow, setItemsToShow] = useState(12);
  const [loading, setLoading] = useState(false);
  const idClienteLabopat = useSelector(
    (state) => state.user?.data?.data?.profile?.idClienteLabopat
  );

  const [showList, setShowList] = useState(false);

  const getStudies = async (id) => {
    setLoading(true);
    try {
      let request = axios.get(
        `https://lpqsystem.com/get-studies-test/0/${id}/${
          idClienteLabopat ? `${idClienteLabopat}` : '0'
        }`
        // 'https://lpqsystem.com/get-studies/0/' + id + '/'
      ); //orden/service
      let response = await request;
      setItems(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // Fetch items from another resources.
    getStudies(props.data.id);
  }, [props.data.id]);

  useEffect(()=>{
    if(items.length===0){
      setLoading(true);
    }else{
      setLoading(false);
    }
  },[items])

  return (
    <Container>
      <Row>
        <Col className='text-center mt-5'>
          <Title>Todos los estudios</Title>
        </Col>
      </Row>
      <Row className='mt-1 mb-3'>
        <Col className='text-center'>
          <Description>
          Haz click sobre el estudio para ver más detalles.
          </Description>
        </Col>
      </Row>

      <Row>
        <Col
          md={12}
          lg={12}
          xl={12}
          style={{ paddingLeft: '0', paddingRight: '0' }}
        >
          <Row>
            <Col
              xs={{ span: 3, offset: 6 }}
              sm={{ span: 3, offset: 6 }}
              md={{ span: 2, offset: 4 }}
              lg={{ span: 1, offset: 5}}
            >
              <FilterButton
                className='view'
                variant='light'
                onClick={() => setShowList(!showList)}
                style={{ background: !showList ? '#F3F3F3' : '#00AD90' }}
              >
                <UilListUl size='30' color={!showList ? '#C4C4C4' : '#FFF'} />
              </FilterButton>
            </Col>
            <Col
              xs={{ span: 3 }}
              sm={{ span: 3 }}
              md={{ span: 2 }}
              lg={{ span: 1 }}
            >
              <FilterButton
                className='view'
                variant='light'
                onClick={() => setShowList(!showList)}
                style={{ background: showList ? '#FFF' : '#F3F3F3' }}
              >
                <UilApps size='30' color={showList ? '#00AD90' : '#0CAC8C'} />
              </FilterButton>
            </Col>
          </Row>
          <Row>
            {items &&
              items.map((item, index) => (
                <>
                  {index < itemsToShow ? (
                    showList ? (
                      <StudyL
                        key={index}
                        data={item}
                        idClienteLabopat={idClienteLabopat}
                      />
                    ) : (
                      <TestCard
                        key={index}
                        data={item}
                        xs={12}
                        sm={6}
                        md={6}
                        lg={4}
                        xl={3}
                        xxl={3}
                        idClienteLabopat={idClienteLabopat}
                      />
                    )
                  ) : null}
                </>
              ))}
          </Row>
          <p
            style={{ textAlign: 'center', fontSize: '18px', marginTop: '40px' }}
          >
            Mostrando {itemsToShow > items.length ? items.length : itemsToShow}{' '}
            de {items.length} estudios
          </p>
          <div className='d-flex justify-content-center'>
            {itemsToShow < items.length && (
              <ShowMoreButton onClick={() => setItemsToShow(itemsToShow + 6)}>
                Mostrar más
              </ShowMoreButton>
            )}
          </div>
        </Col>
      </Row>
      {loading && <Loader show={loading} />}
    </Container>
  );
};
