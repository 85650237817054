import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';

const Slide = styled(Container)`
  height: 460px;
  position: relative;
  background: none;
  overflow: hidden;
  h1 {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 130%;
    color: #474A4B;
    margin-bottom: 50px;
    max-width: 653px;
  }
  p {
    font-family: 'IBM Plex Sans';
    font-weight: 400;
    font-size: 25px;
    line-height: 130%;
    color: #474A4B;
    margin-bottom: 43px;
  }
  span {
    font-weight: 600;
  }
  #text-col {
    z-index: 2;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    .row {
      width: 100%;
    }
  }
  #img-col {
    z-index: 1;
    height: 460px;
    display: flex;
    align-items: end;
    justify-content: center;
  }
  img {
    max-width: 750px;
    max-height: 100%;
  }
  @media only screen and (min-width: 1920px) {
    #img-col {
      padding: 0;
    }
    img {
      max-width: 120%;
      max-height: 105%;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1920px) {
    padding: 0 0 0 8%;
  }
  @media only screen and (min-width: 768px) and (max-width: 1300px) {
    padding: 0 0 0 5%;
    h1 {
      font-size: 40px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 992px) {
    #img-col {
      height: 520px;
    }
  }

  @media only screen and (max-width: 768px) {
    height: 520px;
    padding: 30px 0 0;
    h1 {
      font-size: 28px;
      width: 100%;
      max-width: 288px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }
    p {
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      font-size: 20px;
      margin-bottom: 20px;
      padding: 0 10px;
      max-width: 282px;
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translate(-50%, 0);
      width: 100%;
    }
    img {
      max-width: 125%;
      max-height: 260px;
      position: absolute;
      bottom: 70px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
`;

export const UseELabBanner = () => {
  return (
    <Slide>
      <Row>
        <Col xs={12} md={6} xxl={7} id='text-col'>
          <h1>Encuentra los estudios que necesitas de forma <span>rápida y sencilla.</span></h1>
          <p><span>Labopat,</span> la nueva solución en <span>servicios de laboratorio.</span></p>
        </Col>
        <Col xs={12} md={6} xxl={5} id='img-col'>
          <img src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2FhomeSlider%2FhandMockup.png?alt=media&token=07a7c97f-9208-4a7e-ad55-716eadc15a12" alt="phone" />
        </Col>
      </Row>
    </Slide>
  );
};
